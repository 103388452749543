import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useAuth } from "context";

import { useDataTableGenerate, useMedia, useMediaQueries } from "hooks";
import { Button, Icon, ActionsCell, Table } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { useDeleteInvoiceManager, useDownloadInvoicesManagers, useInvoiceManagers } from "hooks/query/invoiceManager";
import { InvoiceManagerModal } from "modules/invoice-manager";
import { useNavigate } from "react-router-dom";

const InvoiceManager = () => {
    // const navigate = useNavigate();
    const { t } = useTranslation();
    const { userCan } = useAuth();

    const { MEDIUM } = useMediaQueries();
    const isMedium = useMedia(MEDIUM);

    const [modal, setModal] = useState({
        state: false,
        payload: null,
        view: false,
    });



    const { mutate } = useDeleteInvoiceManager();
    const { mutate: download } = useDownloadInvoicesManagers();

    const closeModal = () => setModal({ state: false, view: false, payload: null });

    const navigate = useNavigate();

    const columns = useCallback(
        (columns) => {
            return columns.map((col) => {
                if (col.accessor === "actions") {
                    return {
                        ...col,
                        Cell: ({ cell }) => (
                            <ActionsCell>
                                <a className='mr-2' href={cell.row.original?.export_csv} target='_blank' rel='noopener noreferrer' download>
                                    <Button small bg status='success' iconLeft={<Icon>download</Icon>}>
                                        CSV
                                    </Button>
                                </a>
                                <a className='mr-2' href={cell.row.original?.export_pdf} target='_blank' rel='noopener noreferrer' download>
                                    <Button small bg status='danger' iconLeft={<Icon>download</Icon>}>
                                        PDF
                                    </Button>
                                </a>


                                <Button small bg status='warning' onClick={() => navigate(`/app/invoice-manager/${cell.row.original.id}`)}>
                                    <Icon>edit</Icon>
                                </Button>

                                <Button
                                    small
                                    bg
                                    status='accent'
                                    onClick={() => setModal({ payload: cell.row.original, state: true, view: true })}
                                >
                                    <Icon>visibility</Icon>
                                </Button>
                                <Button small bg status='danger' onClick={() => mutate(cell.row.original?.id)}>
                                    <Icon>delete</Icon>
                                </Button>
                            </ActionsCell>
                        ),
                    };
                }
                return null;
            });
        },
        [mutate, navigate]
    );

    const { tableProps } = useDataTableGenerate(useInvoiceManagers, columns, {
        // includes: ["owner", "membersLimited", "membersLimited.media"],
    });

    return (
        <PageWrapper>
            <Helmet>
                <title>Veniway | Invoice Manager</title>
            </Helmet>
            <PageHeader title={t("invoice-manager")}>
                <div className='v__table__extras__actions'>
                    {/* {userCan("add_groups") && ( */}
                    <Button small bg status='danger' round={!isMedium} onClick={() => setModal({ state: true, payload: null })}>
                        {isMedium ? t("addNew") : <Icon>add</Icon>}
                    </Button>
                    {userCan("download_fuel") && (
                        <Button onClick={() => download()} small bg status='success' iconLeft={<Icon>download</Icon>}>
                            Download CSV
                        </Button>
                    )}

                    {/* )} */}
                </div>
            </PageHeader>
            <PageContent>
                <Table {...tableProps} scrollToTopOnChange />
            </PageContent>
            {modal.state && <InvoiceManagerModal payload={modal.payload} view={modal.view} onClose={closeModal} />}
        </PageWrapper>
    );
};

export default InvoiceManager;
