import { useEffect, useMemo } from "react";
import cn from "classnames";

import { useReportsState } from "context";

import { Card, DateWeekMonthPicker } from "components";

import { REPORTS_ACTION_TYPES, REPORT_SELECTION_TYPES } from "reducers/const";

const ReportsPeriodPicker = ({ files }) => {
    const { state, dispatch } = useReportsState();

    //TODO filter only for_all 
    //filter((a) => a.for_all) before .map
    const calendarDepoFiles = useMemo(() => {
        if (!files) return [];
        return files.data.map((b) => new Date(b.date));
    }, [files]);


    useEffect(() => {

        let dispatchableObject = {};

        if (calendarDepoFiles?.length > 0 && !state.selected.date && !state.selected.week && !state.selected.month) {
            dispatchableObject = {
                type: REPORTS_ACTION_TYPES.TIME_SELECTION_SET,
                payload: {
                    type: REPORT_SELECTION_TYPES.DATE,
                    payload: new Date(calendarDepoFiles[calendarDepoFiles.length - 1]),
                }
            }
        }

        if (state.selected.week) {
            dispatchableObject = {
                type: REPORTS_ACTION_TYPES.TIME_SELECTION_SET,
                payload: {
                    type: REPORT_SELECTION_TYPES.WEEK,
                    payload: state.selected.week,
                }
            }
        }

        if (state.selected.month) {
            dispatchableObject = {
                type: REPORTS_ACTION_TYPES.TIME_SELECTION_SET,
                payload: {
                    type: REPORT_SELECTION_TYPES.MONTH,
                    payload: state.selected.month,
                }
            }
        }

        //TODO dynamic payload and type
        if (calendarDepoFiles?.length > 0 && !state.selected.date) {
            dispatch(dispatchableObject);
        }
    }, [calendarDepoFiles, state.selected.date, state.selected.month, state.selected.week, dispatch]);

    return (
        <Card className={cn("body-no-pad v__card--overflow-able print:hidden")}>
            <DateWeekMonthPicker
                selected={state.selected}
                reportType={state.reportType}
                files={calendarDepoFiles}
                onChange={(selection) => dispatch({ type: REPORTS_ACTION_TYPES.TIME_SELECTION_SET, payload: selection })}
            />
        </Card>
    );
};

export default ReportsPeriodPicker;
