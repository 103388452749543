import axios from "./axios";

const api = {
	getIssues(params) {
		return axios
			.get("/issues", {
				params,
			})
			.then(({ data }) => data);
	},
	createIssue(data) {
		return axios.post("/issues", formatIssueData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
	deleteIssue(issueId) {
		return axios.delete(`/issues/${issueId}`).then(({ data }) => data);
	},
};

function formatIssueData(data) {
	const formattedData = new FormData();
	formattedData.append("user_id", data.user_id);
	formattedData.append("date", data.date.toLocaleDateString("en-US"));
	formattedData.append("type", data.type.value);
	formattedData.append("description", data.description);
	if (data.image_path) {
		formattedData.append("image_path", data.image_path);
	}

	return formattedData;
}

export default api;
