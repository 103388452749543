import { useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "utils";
import { InvoiceManagerApi } from "../../api";
import { invoicesAdjustmentsPreviewKeys, invoicesProfitPreviewKeys } from "./invoices";
import { tourKeys } from "./tours";

export const invoiceManagerKeys = {
	all: () => ["invoiceManagerKeys"],
	byId: (id) => [...invoiceManagerKeys.all(), id],
	byConfig: (config) => [...invoiceManagerKeys.all(), config],
};

export const invoiceToursKeys = {
	all: () => ["invoiceToursKeys"],
	byId: (id) => [...invoiceToursKeys.all(), id],
	byConfig: (config) => [...invoiceToursKeys.all(), config],
};


export const useInvoiceManagers = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(invoiceManagerKeys.byConfig(custom), () => InvoiceManagerApi.getInvoiceManagers(custom), restConfig);
};

export const useInvoiceManager = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(invoiceManagerKeys.byId(id), () => InvoiceManagerApi.getInvoiceManager(id, custom), restConfig);
};


export const useCreateInvoiceManager = (config) => {
	const queryClient = useQueryClient();
	return useMutation(InvoiceManagerApi.createInvoiceManager, {
		...config,
		onSuccess: callAll(() => {
            queryClient.invalidateQueries(tourKeys.all());
			queryClient.invalidateQueries(invoiceManagerKeys.all());
		}, config?.onSuccess),
	});
};

export const useDownloadInvoicesManagers = (config) => {
	return useMutation(InvoiceManagerApi.downloadInvoiceManagers, {
		...config,
	});
};


export const useUpdateInvoiceManager = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => InvoiceManagerApi.updateInvoiceManager(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(invoiceManagerKeys.all());
		}, config?.onSuccess),
	});
};

export const useUpdateInvoiceManagerTours = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => InvoiceManagerApi.updateInvoiceManagerTour(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(invoiceToursKeys.all());
			queryClient.invalidateQueries(invoicesProfitPreviewKeys.all());
		}, config?.onSuccess),
	});
};

export const useUpdateInvoiceManagerTourPrice = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ data }) => InvoiceManagerApi.updateInvoiceManagerTourPrice(data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(invoiceToursKeys.all());
			queryClient.invalidateQueries(invoicesProfitPreviewKeys.all());
		}, config?.onSuccess),
	});
};

export const useDeleteInvoiceManagerTourPrices = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ data }) => InvoiceManagerApi.deleteInvoiceManagerTourPrices(data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(invoiceToursKeys.all());
			queryClient.invalidateQueries(invoicesProfitPreviewKeys.all());
		}, config?.onSuccess),
	});
};





export const useDeleteInvoiceAdjustmentRelations = (config) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => InvoiceManagerApi.deleteInvoiceAdjustmentRelations(data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(invoicesAdjustmentsPreviewKeys.all());
		}, config?.onSuccess),
	});
};



export const useToursEdit = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(invoiceToursKeys.byConfig(custom), () => InvoiceManagerApi.getToursEdit(custom.invoice_manager_id, custom), restConfig);
};



export const useDeleteInvoiceManager = (config) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => InvoiceManagerApi.deleteInvoiceManager(data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(invoiceManagerKeys.all());
		}, config?.onSuccess),
	});
};
