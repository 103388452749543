import { forwardRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const FormTextarea = forwardRef(
	({ groupClassName, className, withLabel = true, label, name, id = name, small, children, error, ...rest }, ref) => (
		<div className={cn(`v__form__group`, groupClassName)}>
			<label htmlFor={id} className={cn("v__form__group__label", !withLabel && "v__form__group__label--invisible")}>
				<span>{label}</span>
			</label>
			<textarea className={cn("v__input", small && "v__input--sm")} id={id} name={name} ref={ref} {...rest} />
			{error && <small className='v__form__group__hint'>{error}</small>}
			{children}
		</div>
	)
);

FormTextarea.propTypes = {
	groupClassName: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	small: PropTypes.bool,
	label: PropTypes.string,
	withLabel: PropTypes.bool,
};

export default FormTextarea;
