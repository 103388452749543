import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { MutationCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import "focus-visible";

import App from "./App";
import { Portal } from "./components";
import { Toast } from "components/Toast";

import "./assets/scss/app.scss";
import "./index.css";
import "./i18n";

const mutationCache = new MutationCache({
	onSuccess: (res) => {
		if (Boolean(res?.success?.message)) {
			toast.success(res.success.message);
		}
	},
});

const queryClient = new QueryClient({
	mutationCache,
	defaultOptions: {
		queries: {
			retryDelay: 5000,
			retry: 1,
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			staleTime: 1000 * 60 * 60, // 1hr
			cacheTime: 1000 * 60 * 60, // 1hr
			onError: (err) => {
				const responseData = err?.response;

				if (responseData.status === 500) {
					toast.error("Something went wrong.");
					return;
				}

				Object.entries(responseData.data?.errors).forEach(([key, value]) => {
					value.forEach((x) => toast.error(x));
				});
			},
		},
		mutations: {
			onError: (err) => {
				const responseData = err?.response;

				if (responseData.status === 500) {
					toast.error("Something went wrong.");
					return;
				}

				Object.entries(responseData.data?.errors).forEach(([key, value]) => {
					value.forEach((x) => toast.error(x));
				});
			},
		},
	},
});

const toastConfig = {
	reverseOrder: true,
	position: "top-right",
	gutter: 10,
	containerStyle: {
		top: 60,
	},
	toastOptions: {
		duration: 5000,
	},
};

console.log("%c Greetings hooman! 👽", "font-weight: bold; font-size: 1.25rem; color: #79db75;");
//console.log("Website By: %c 👨‍💻 @gogbog (backend), 👨‍💻 @dodobrat (frontend)", "font-size: 0.9rem; color: #f6b93f;");

ReactDOM.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
			<Portal>
				<Toaster {...toastConfig}>{(t) => <Toast t={t} />}</Toaster>
			</Portal>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</StrictMode>,
	document.getElementById("root")
);
