import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Button, Collapsible, Icon } from "components";
import { ButtonGroup, IndeterminateCheckbox } from "components/forms";

import { arraysMatch } from "utils";

function ReportsControls({
	columns,
	allColumns,
	getToggleHideAllColumnsProps,
	toggleHideAllColumns,
	setHiddenColumns,
	hiddenColumns,
	dark,
	//reports specific props
	showPrintBtn,
	presets,
	hidePresets = false,
	columnPreset,
	onPresetChange,
	feedbackOnly,
}) {
	const { t } = useTranslation();

	const [glossaryToggled, setGlossaryToggled] = useState(true);

	const parsedPresets = useMemo(() => parsePresets(presets), [presets]);

	if (!presets || !columnPreset) return null;

	return (
		<>
			<div className='flex lg:items-end lg:flex-row flex-col mb-4 justify-between space-y-2 lg:space-y-0 print:hidden'>
				<div className='flex items-end overflow-x-auto space-x-2'>
					{!feedbackOnly && !hidePresets &&
						parsedPresets.map((preset) => {
							const isGrouped = Boolean(preset?.groupTranslationKey);

							if (isGrouped) {
								return (
									<div className='flex flex-col items-center' key={preset.groupTranslationKey}>
										<span className='text-sm opacity-80 mb-1'>{t(preset.groupTranslationKey)}</span>
										<ButtonGroup>
											{preset.options.map((option) => {
												return (
													<Button
														bg
														status={columnPreset.value === option.value ? "accent" : dark ? "darker" : "none"}
														key={option.labelTranslationKey}
														iconLeft={<Icon>{option.iconKey}</Icon>}
														small
														onClick={() =>
															onPresetChange?.({ value: option.value, columns: option?.columns ?? [] })
														}
														className='whitespace-nowrap shrink-0'
													>
														{t(option.labelTranslationKey)}
													</Button>
												);
											})}
										</ButtonGroup>
									</div>
								);
							}

							return (
								<Button
									bg
									small
									status={columnPreset.value === preset.value ? "accent" : dark ? "darker" : "none"}
									key={preset.labelTranslationKey}
									iconLeft={<Icon>{preset.iconKey}</Icon>}
									onClick={() => onPresetChange?.({ value: preset.value, columns: preset?.columns ?? [] })}
									className={cn(
										"whitespace-nowrap shrink-0",
										preset?.isInvisible && "w-0 h-0 opacity-0 absolute invisible"
									)}
								>
									{t(preset.labelTranslationKey)}
								</Button>
							);
						})}
				</div>

				{Boolean(allColumns.length) && (
					<div className='flex items-center space-x-2'>
						<Button
							bg
							small
							status='darker'
							iconLeft={<Icon>help_outline</Icon>}
							onClick={() => setGlossaryToggled((prev) => !prev)}
						>
							{glossaryToggled ? t("showColumnInfo") : t("hideColumnInfo")}
						</Button>
						{showPrintBtn && (
							<Button small status='darker' iconLeft={<Icon>print</Icon>} bg onClick={window.print}>
								{t("print")}
							</Button>
						)}
					</div>
				)}
			</div>

			{Boolean(allColumns.length) && (
				<Collapsible closed={glossaryToggled} allowOverflow={false} blended>
					<div className='md:-mx-3'>
						<div>{presets.find((x) => x.value === columnPreset.value).description}</div>

						<div className='flex justify-between md:items-center md:flex-row flex-col pb-4'>
							<span className='opacity-75 text-xs'>*{t("columnVisibilityTip")}</span>
							<div className='flex items-center space-x-4 h-10'>
								{!arraysMatch(columnPreset.columns, hiddenColumns) && (
									<Button
										bg
										status='warning'
										iconLeft={<Icon>undo</Icon>}
										small
										onClick={() => setHiddenColumns(columnPreset.columns)}
									>
										{t("reset")}
									</Button>
								)}
								<label className='flex items-center cursor-pointer space-x-2'>
									<IndeterminateCheckbox
										{...getToggleHideAllColumnsProps({
											onChange: () => toggleHideAllColumns(),
										})}
									/>
									<span className='whitespace-nowrap'>{t("toggleAll")}</span>
								</label>
							</div>
						</div>

						<div className='grid' style={{ gridTemplateColumns: "repeat(auto-fit, minmax(min(25rem, 100%), 1fr))" }}>
							{columns.map((item, index) => {
								const checkboxId = `${index}_${item.description}`;
								return (
									<label
										htmlFor={checkboxId}
										key={checkboxId}
										className='cursor-pointer flex items-start pr-2 pb-3 space-x-1 hover:shadow-v-accent-dark text-sm group'
									>
										<div className='group-hover:scale-125' title='Toggle column visibility'>
											<IndeterminateCheckbox
												{...allColumns[index]?.getToggleHiddenProps({
													onChange: () => allColumns[index].toggleHidden(),
													id: checkboxId,
												})}
											/>
										</div>
										<strong className='text-v-accent-dark dark:text-v-accent-lighter'>{item.Header}</strong>
										<span className='group-focus-within:underline group-hover:text-v-accent-dark dark:group-hover:text-v-accent-lighter'>
											{item.description}
										</span>
									</label>
								);
							})}
						</div>
					</div>
				</Collapsible>
			)}
		</>
	);
}

function parsePresets(presets) {
	if (!presets) return [];

	const result = [];
	for (const x of presets) {
		const isGrouped = Boolean(x?.groupTranslationKey);
		if (isGrouped) {
			const existingGroupIndex = result.findIndex((y) => y?.groupTranslationKey === x.groupTranslationKey);
			if (result[existingGroupIndex]) {
				result[existingGroupIndex].options.push(x);
			} else {
				result.push({ groupTranslationKey: x.groupTranslationKey, options: [x] });
			}
		} else {
			result.push(x);
		}
	}
	return result;
}

export default ReportsControls;
