import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { useCreatePcn, usePcn, useUpdatePcn } from "hooks";
import { PcnForm } from ".";

const PcnModal = ({ payload, onClose }) => {
	const { t } = useTranslation();
	const { data: pcn } = usePcn(payload?.id, {
		custom: {
			includes: ["user"],
		},
		enabled: Boolean(payload?.id),
	});

	const { mutate: update, isLoading: isLoadingUpdate } = useUpdatePcn({
		onSuccess: (res) => {
			onClose();
		},
	});

	const { mutate: create, isLoading: isLoadingCreate } = useCreatePcn({
		onSuccess: (res) => {
			onClose();
		},
	});

	const pcnData = pcn?.data;

	//change those ->load pcn
	const defaultValues = {
		number: pcnData?.number,
		van_number: pcnData?.van_number,
		amount: pcnData?.amount,
		amount_for_driver: pcnData?.amount_for_driver,
		admin_fee: pcnData?.admin_fee,
		status: pcnData?.status_plain,
		date: pcnData?.date,
		due_week: pcnData?.due_week,
		image_path: pcnData?.image_path,
		user_id: Boolean(pcnData) ? { value: pcnData?.user?.id, label: pcnData?.user?.full_name } : null,
	};


	const handleOnSubmit = (data) => {
		if (Boolean(payload)) {
			update({ id: payload.id, data });
		} else {
			create(data);
		}
	};

	const shouldRender = (payload && pcn) || !payload;
	const isLoading = isLoadingUpdate || isLoadingCreate;

	return (
		<Modal onClose={onClose}>
			<Card className='v__modal v__card--overflow-able' header={t("pcn")} relativeLoading={isLoading} dismissible={onClose}>
				{shouldRender && <PcnForm onSubmit={handleOnSubmit} defaultValues={defaultValues} onCancel={onClose} />}
			</Card>
		</Modal>
	);
};

export default PcnModal;
