import { format } from "date-fns";
import axios from "./axios";

const api = {
	getDepoStaff(params) {
		return axios
			.get(`/depo/staff`, {
				params,
			})
			.then(({ data }) => data);
	},
	getUsers(params) {
		return axios
			.get(`/users`, {
				params,
			})
			.then(({ data }) => data);
	},
	getUser(id, params) {
		return axios
			.get(`/users/${encodeURI(id)}`, {
				params,
			})
			.then(({ data }) => data);
	},
	createUser(data) {
		return axios.post("/users", formatUserData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
	updateUser(id, data) {
		return axios.post(`/users/${encodeURI(id)}/update`, formatUserData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
};

function formatUserData(data) {

	const formattedData = new FormData();
	formattedData.append("first_name", data.first_name);
	formattedData.append("last_name", data.last_name);
	formattedData.append("service_partner_id", data.service_partner_id.value ?? "");

	let i = 0;
	for (const depo_id of Object.values(data.depo_ids)) {
		formattedData.append(`depo_ids[${i}]`, depo_id.id);
		i++;
	}

	i = 0;
	for (const snumber of Object.values(data.snumbers)) {
		formattedData.append(`snumbers[${i}][snumber_id]`, snumber["snumber_id"].value);
		formattedData.append(`snumbers[${i}][operator_name]`, snumber["operator_name"]);
		formattedData.append(`snumbers[${i}][from_date]`, format(new Date(snumber["from_date"]), "dd-MM-yyyy"));
		formattedData.append(`snumbers[${i}][to_date]`, format(new Date(snumber["to_date"]), "dd-MM-yyyy"));
		i++;
	}



	return formattedData;
}

export default api;
