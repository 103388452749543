import { useCallback, useLayoutEffect, useState } from "react";
import cn from "classnames";

import {
    useReports,
    useReportsTableColumnHiding,
    useReportsTableFilteredColumns,
    useReportsTableGenerate,
    useReportsTablePresetsGenerate,
} from "hooks";

import { useAuth, useReportsState } from "context";

import { Card, NoDataRow, ReportsTable } from "components";
import { ReportsPrintFilterState } from "./ReportsPrintFilterState";
import ReportsControls from "./ReportsControls";
import QuickViewReportsModal from "./QuickViewReportsModal";

import { parseAndPreSelectPreset, parseClickedRowData, parsePreset } from "utils";

import { REPORT_COLUMN_PRESETS } from "reducers/const";
import {
    generateFBRPresets,
    generateFuelPresets,
    generateInvoicePresets,
    generateReportsPresets,
    REPORT_FEEDBACK_CELL_ACCESSORS,
    REPORT_POINTS_CELL_ACCESSORS,
} from "./const";
import { FuelModal } from "modules/fuel";
import { useDeleteInvoice, usePayInvoice } from "hooks/query/invoices";
import { InvoicePayModal } from "modules";

const presetFn = {
    reports: generateReportsPresets,
    fbr: generateFBRPresets,
    sp_fbr: generateFBRPresets,
    fuel: generateFuelPresets,
    invoices: generateInvoicePresets,
};

const ReportsContent = ({ primaryDataType = "reports", initialPreset = REPORT_COLUMN_PRESETS.GOLDEN_5, initialConfig }) => {
    // generating and getting all the report presets (extracted function)
    const tablePresetGenerationFn = presetFn[primaryDataType];

    const { userCan } = useAuth();

    const presets = useReportsTablePresetsGenerate(tablePresetGenerationFn, userCan("sp_panel_view") && primaryDataType == "invoices");

    // state for current column preset
    const [columnPreset, setColumnPreset] = useState();
    const dataType = columnPreset?.value === REPORT_COLUMN_PRESETS.FEEDBACK ? "feedback" : primaryDataType;


    // using useLayoutEffect to set state before paint
    useLayoutEffect(() => {
        if (Boolean(presets.length) && !columnPreset) {
            // choose preselected column tab from REPORT_COLUMN_PRESETS
            setColumnPreset(() => parseAndPreSelectPreset(presets, initialPreset));
        }
    }, [presets, columnPreset, initialPreset]);

    // Quick View modal init
    const [quickView, setQuickView] = useState({ state: false, payload: null });
    const [fuelEditModal, setFuelEditModal] = useState({
        state: false,
        payload: null,
    });

    const [invoicesPayModal, setInvoicesPayModal] = useState({
        state: false,
        payload: null,
    });


    const closeModal = () => {
        setFuelEditModal({ state: false, view: false, payload: null });
        setInvoicesPayModal({ state: false, view: false, payload: null });
    };

    const onClose = useCallback(() => setQuickView((prev) => ({ ...prev, state: false })), []);

    // using the context
    const { state } = useReportsState();

    // handling the clock on a non Link cell (open Quick View)
    const onRowClick = useCallback(
        (payload) => {
            const isFeedbackCell = REPORT_FEEDBACK_CELL_ACCESSORS.includes(payload.cell.column.id);
            const isPointCell = REPORT_POINTS_CELL_ACCESSORS.includes(payload.cell.column.id);

            const isFuel = payload.cell.row.original.isFuel;
            const isDate = payload.queryParams["date"] !== undefined && payload.queryParams.date !== null;
            const groupedBy = payload.queryParams.groupBy;

            if (isPointCell) {
                return setQuickView({
                    payload: { ...parseClickedRowData({ ...payload, points: true }), presets, columnPreset },
                    state: true,
                });
            }

            if (isFeedbackCell) {
                return setQuickView({
                    payload: { ...parseClickedRowData(payload, "feedback"), presets, columnPreset },
                    state: true,
                });
            }

            setQuickView({
                payload: { ...parseClickedRowData(payload), presets, columnPreset },
                state: (isFuel && isDate && groupedBy === "user_id") || groupedBy === "fuels.id" ? false : true,
            });
        },
        [columnPreset, presets]
    );

    //creating referance to the reports state and mutating to prevent memory leak.
    //if more props needs to be added just pop them into config
    const config = state.requestParams;
    config.fairplay_id = initialConfig?.fairplay_id;
    config.sp_fairplay_id = initialConfig?.sp_fairplay_id;

    const { mutate: deleteInvoice } = useDeleteInvoice();
    const { mutate: payInvoice } = usePayInvoice();

    // generate Reports Table
    const {
        state: { queryParams, setQueryParams },
        query: { data, isFetched },
        tableProps,
        query: { refetch }
    } = useReportsTableGenerate({
        useFetch: useReports,
        config,
        dataType,
        onRowClick,
        setFuelEditModal,
        setInvoicesPayModal,
        deleteInvoice,
    });

    // parse filtered columns (Advanced filters)
    useReportsTableFilteredColumns({ data, isFetched });

    // load saved hidden columns for reports page
    const { onColumnHide, storedHiddenColumns } = useReportsTableColumnHiding({ columnPreset });

    // handle reports preset (change to page 1 if switching from reports to feedback)
    const onPresetChange = useCallback(
        (preset) => {
            setColumnPreset((prev) => {
                if (parsePreset(prev?.value, primaryDataType) !== parsePreset(preset.value, primaryDataType)) {
                    setQueryParams((params) => ({ ...params, page: 1, sortBy: undefined }));
                    return preset;
                }
                return preset;
            });
        },
        [setQueryParams, primaryDataType]
    );

    if (!data || !columnPreset)
        return (
            <Card loading>
                <NoDataRow asDiv />
            </Card>
        );

    const tableExtraProps = {
        onColumnHide,
        tableHiddenColumns: storedHiddenColumns?.[columnPreset.value] ?? columnPreset.columns,
        filteredColumns: state.requestParams.filter,
        resetPageIndex: JSON.stringify({ state: state.requestParams, dataType }),
        rowHighlighting: columnPreset?.value !== REPORT_COLUMN_PRESETS.FEEDBACK,
    };

    return (
        <>
            <div className={cn(quickView.state && "print:hidden")}>
                <ReportsPrintFilterState
                    columnPreset={columnPreset}
                    columns={tableProps?.columns}
                    state={state}
                    queryParams={queryParams}
                />
                <ReportsTable
                    {...tableProps}
                    {...tableExtraProps}
                    controlComponent={(props) => (
                        <ReportsControls {...props} presets={presets} columnPreset={columnPreset} onPresetChange={onPresetChange} />
                    )}
                />
            </div>

            {fuelEditModal.state && <FuelModal payload={fuelEditModal.payload} refetch={refetch} view={fuelEditModal.view} onClose={closeModal} />}
            {invoicesPayModal.state && <InvoicePayModal payload={invoicesPayModal.payload} view={invoicesPayModal.view} onClose={closeModal} />}
            {quickView.state && (
                <QuickViewReportsModal
                    initialParams={{ fairplay_id: initialConfig?.fairplay_id }}
                    payload={quickView.payload}
                    onClose={onClose}
                    primaryDataType={primaryDataType}
                />
            )}
        </>
    );
};

export default ReportsContent;
