import React from "react";
import cn from "classnames";

const DepoIcon = ({ className, ...rest }) => {
	return (
		<svg width='424.996' height='425' viewBox='0 0 424.996 425' className={cn("depo__icon", className)} {...rest}>
			<g transform='translate(-1006 -188)'>
				<path
					className='b'
					d='M422.222,73.225,216.807,2.393a6.977,6.977,0,0,0-4.618,0L6.774,73.225A7.083,7.083,0,0,0,2,79.919v340A7.083,7.083,0,0,0,9.083,427H37.416a7.083,7.083,0,0,0,7.083-7.083V172h340V419.916A7.083,7.083,0,0,0,391.58,427h28.333A7.083,7.083,0,0,0,427,419.916v-340a7.083,7.083,0,0,0-4.774-6.694ZM16.167,84.969,214.5,16.58,412.83,84.969v30.366H16.167ZM30.333,412.833H16.167V129.5H30.333Zm14.167-255V129.5h340v28.333Zm368.33,255H398.663V129.5H412.83Z'
					transform='translate(1004 186.001)'
				/>
				<path
					className='b'
					d='M56.333,64.666A28.333,28.333,0,1,0,28,36.333,28.333,28.333,0,0,0,56.333,64.666Zm0-42.5A14.167,14.167,0,1,1,42.167,36.333,14.167,14.167,0,0,1,56.333,22.167Z'
					transform='translate(1162.165 222.503)'
				/>
				<path
					className='b'
					d='M314.581,254.665H286.248V148.416a7.083,7.083,0,0,0-7.083-7.083H229.581V35.083A7.083,7.083,0,0,0,222.5,28H109.166a7.083,7.083,0,0,0-7.083,7.083V141.332H52.5a7.083,7.083,0,0,0-7.083,7.083V254.665H17.083a7.083,7.083,0,0,0,0,14.167h297.5a7.083,7.083,0,1,0,0-14.167ZM215.415,155.5h14.167v38.122l-3.917-1.962a7.083,7.083,0,0,0-6.332,0l-3.917,1.962Zm-42.5-113.332V80.289L169,78.327a7.083,7.083,0,0,0-6.332,0l-3.917,1.962V42.167Zm-56.666,0h28.333V91.749a7.083,7.083,0,0,0,10.249,6.375l11-5.539,11,5.5a7.083,7.083,0,0,0,10.249-6.34V42.167h28.333v99.166H116.249ZM109.166,155.5h7.083v38.122l-3.917-1.962a7.083,7.083,0,0,0-6.332,0l-3.917,1.962V155.5Zm-49.583,0H87.916v49.583a7.083,7.083,0,0,0,10.249,6.375l11-5.539,11,5.5a7.083,7.083,0,0,0,10.25-6.34V155.5h28.333v99.166H59.583Zm113.332,0h28.333v49.583a7.083,7.083,0,0,0,10.25,6.375l11-5.539,11,5.5a7.083,7.083,0,0,0,10.25-6.34V155.5h28.333v99.166H172.915Z'
					transform='translate(1052.666 344.169)'
				/>
			</g>
		</svg>
	);
};

export default DepoIcon;
