import cn from "classnames";

const Alert = ({ type = "danger", className, children, ...rest }) => {
	return (
		<div
			className={cn(
				"v__alert",
				{
					[`v__alert--${type}`]: !!type,
				},
				className
			)}
			{...rest}>
			{children}
		</div>
	);
};

export default Alert;
