import { format } from "date-fns";
import axios from "./axios";

const api = {
    getToursCategories(params) {
        return axios
            .get(`/routes_categories`, {
                params,
            })
            .then(({ data }) => data);
    },
    getTourCategory(id, params) {
        return axios.get(`/routes_categories/${encodeURI(id)}`, params).then(({ data }) => data);
    },

    createTourCategory(data) {
        return axios.post("/routes_categories", formatCategoryData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    updateTourCategory(id, data) {
        return axios.post(`/routes_categories/${encodeURI(id)}/update`, formatCategoryData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    deleteTourCategory(id) {
        return axios.delete(`/routes_categories/${id}`).then(({ data }) => data);
    },
};

function formatCategoryData(data) {
    const formattedData = new FormData();
    formattedData.append("title", data.title);
    formattedData.append("from_date", format(new Date(data["from_date"]), "dd-MM-yyyy"));
    formattedData.append("to_date", format(new Date(data["to_date"]), "dd-MM-yyyy"));

    return formattedData;
}



export default api;
