import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useApp, useAuth } from "../../../context";

import { Button, Dropdown, Icon, ThemeSwitch } from "../../../components";

const TopBarExtras = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { user, logout } = useAuth();
	const { toggleTheme } = useApp();

	const [dropdownActive, setDropdownActive] = useState(false);
	const closeDropdown = () => setDropdownActive(false);

	return (
		<div className='v__topbar__extras'>
			<Button round bg status='darker' hoverStatus='accent' onClick={() => !dropdownActive && setDropdownActive((prev) => !prev)}>
				<Icon>expand_more</Icon>
			</Button>
			<Dropdown shown={dropdownActive} hide={closeDropdown} className='v__topbar__extras__dropdown'>
				<Button
					wide
					bg
					alignLeft
					iconLeft={<Icon>visibility</Icon>}
					label={t("logout")}
					title={t("viewProfile")}
					onClick={() => navigate(`/app/users/${user?.id}`)}>
					{t("viewProfile")}
				</Button>
				<Button
					wide
					bg
					alignLeft
					iconLeft={<Icon>edit</Icon>}
					label={t("editProfile")}
					title={t("editProfile")}
					onClick={() => navigate("/app/settings/profile")}>
					{t("editProfile")}
				</Button>
				<div className='section-separator' />
				<Button wide bg alignLeft title={`${t("changeTheme")} - (Ctrl + Alt + T)`} onClick={toggleTheme}>
					<ThemeSwitch /> {t("theme")}
				</Button>
				<Button
					wide
					alignLeft
					iconLeft={<Icon>power_settings_new</Icon>}
					label={t("logout")}
					hoverStatus='danger'
					title={`${t("logout")} - (Ctrl + Alt + X)`}
					onClick={logout}>
					{t("logout")}
				</Button>
			</Dropdown>
		</div>
	);
};

export default TopBarExtras;
