import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { useAuth, useReportsState } from "../../context";
import { Button, Calendar, Icon, VirtualAsyncSelect } from "../../components";
import { useCompanies, useMedia, useMediaQueries, useReportDates } from "../../hooks";
import { FormSelect } from "../../components/forms";
import { LineLoader } from "../../components/ui";
import { REPORTS_ACTION_TYPES, REPORT_PERIOD_TYPES } from "reducers/const";
import { format } from "date-fns";

const CalendarFilters = ({ filters, onChange, isLarge }) => {
	const { t } = useTranslation();
	const [toggled, setToggled] = useState(false);

	const selectProps = {
		useFetch: useCompanies,
		isGrouped: true,
		preFetch: true,
		preSelect: true,
		queryConfig: { custom: { type: "delivery_daily", includes: ["depos"] } },
		groupParse: { label: "title", options: "depos" },
		onChange,
	};

	if (isLarge) {
		return (
			<div className='v__card__header__container__inline-input no-margin'>
				<VirtualAsyncSelect {...selectProps} small />
			</div>
		);
	}

	return (
		<>
			<Button
				bg
				status='accent'
				className={cn("v__card__filter__btn", {
					"v__card__filter__btn--active": toggled,
				})}
				onClick={() => setToggled((prev) => !prev)}
			>
				<Icon>filter_alt</Icon>
				<span>{filters ? 1 : 0}</span>
				<Icon className='down-indicator'>expand_more</Icon>
			</Button>

			{toggled && <div className='v__card__filter__overlay' onClick={() => setToggled(false)} />}

			<div className={cn("v__card__filter", !toggled && "hide")}>
				<FormSelect groupClassName='v__grid__item v__grid__item--col-12' label={t("depo")} async {...selectProps} />
			</div>
		</>
	);
};

const CalendarFileUploader = () => {
	const { dispatch } = useReportsState();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { LARGE } = useMediaQueries();
	const isLarge = useMedia(LARGE);

	const { userCan, userCurrentRole } = useAuth();

	const { setReportsModal } = useReportsState();

	// TODO: use Tip component
	const [tipVisibility, setTipVisibility] = useState(false);
	const [tip2Visibility, setTip2Visibility] = useState(false);
	const [depo, setDepo] = useState();

	const {
		data: files,
		isFetching,
		isFetched,
	} = useReportDates({
		custom: {
			type: "delivery_daily",
			depo_id: depo?.value,
		},
		enabled: Boolean(depo),
	});

	// const calendarFiles = useMemo(() => {
	// 	if (!files) return [];
	// 	return files.data.filter((a) => !a.for_all).map((b) => new Date(b.date));
	// }, [files]);

	const calendarDepoFiles = useMemo(() => {
		if (!files) return [];
		return files.data.filter((a) => a.for_all).map((b) => new Date(b.date));
	}, [files]);

	const handleDateChange = (date) => {
		//if "Driver" redirect to reports for this file date
		//if "Driver" but no file for this date show error
		//else open ReportWizard with preSelected Date
		let time = format(date, "yyyy-MM-dd");
		dispatch({
			type: REPORTS_ACTION_TYPES.SET_FILTERS_MANUALLY,
			payload: {
				date: new Date(time),
				year: time.split("-")[0],
				reportType: { value: REPORT_PERIOD_TYPES.DAILY },
			},
		});

		navigate("/app/reports");
	};

	const isLoading = isFetching && !isFetched;

	return (
		<>
			{userCan("upload_report") && (
				<Button wide className='v__dashboard__report__btn' onClick={() => setReportsModal({ state: true, payload: null })}>
					<Icon>add</Icon>
					{t("addReport")}
				</Button>
			)}
			<div className='v__report__calendar'>
				<div
					className={cn("v__card v__report__calendar__card", {
						// "v__card--overflow-able": isMobile && toggled,
						disabled: isLoading,
					})}
				>
					{isLoading && <LineLoader />}
					<div className={cn("v__card__header", isLarge && "with-inline-filter")}>
						<div className={`v__card__header__container`}>
							<span>
								<span>{t("reports")}</span>
								<Icon onMouseLeave={() => setTipVisibility(false)} onMouseEnter={() => setTipVisibility(true)}>
									help_outline
								</Icon>
								<Icon onMouseLeave={() => setTip2Visibility(false)} onMouseEnter={() => setTip2Visibility(true)}>
									touch_app
								</Icon>
								{tipVisibility && (
									<div
										className='v__card__header__container__hint'
										dangerouslySetInnerHTML={{
											__html: t("calendarTip"),
										}}
									/>
								)}
								{tip2Visibility && (
									<div
										className='v__card__header__container__hint'
										dangerouslySetInnerHTML={{
											__html: userCurrentRole.name === "Driver" ? t("calendarTip2Redirect") : t("calendarTip2"),
										}}
									/>
								)}
							</span>

							<CalendarFilters filters={depo?.value} isLarge={isLarge} onChange={setDepo} />
						</div>
					</div>
					<div className='v__card__body'>
						<Calendar
							selected={new Date()}
							highlightDates={calendarDepoFiles}
							showWeekNumbers={isLarge}
							onChange={handleDateChange}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default CalendarFileUploader;
