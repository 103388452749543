import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useNewNotifications } from "hooks";
import { Button, Dropdown, Icon } from "components";

import { NotificationsWrapper } from "../Notifications";

const NotificationsIndicator = ({ count }) => (
	<div className='new'>
		<small>{count > 9 ? "9+" : count}</small>
	</div>
);

const UserNotification = () => {
	const { t } = useTranslation();

	const [dropdownActive, setDropdownActive] = useState(false);
	const closeDropdown = () => setDropdownActive(false);

	const { data } = useNewNotifications({ refetchInterval: 1000 * 3000000000 }); //30s

	return (
		<div className='v__topbar__notifications'>
			<Button
				round
				bg
				status='darker'
				hoverStatus='accent'
				label={t("notifications")}
				title={t("notifications")}
				className='v__topbar__notifications__btn'
				onClick={() => setDropdownActive((prev) => !prev)}
			>
				<Icon>notifications_none</Icon>
				{Boolean(data?.count) && <NotificationsIndicator count={data.count} />}
			</Button>
			<Dropdown shown={dropdownActive} hide={closeDropdown} className='v__topbar__notifications__dropdown'>
				<div className='v__topbar__notifications__dropdown__title'>{t("notifications")}</div>
				<div className='section-separator' />
				<NotificationsWrapper />
			</Dropdown>
		</div>
	);
};

export default UserNotification;
