import { Navigate } from "react-router-dom";
import { useAuth } from "../../context";

const AuthorizedRoute = ({ children }) => {
	const { token } = useAuth();

	if (!token && !localStorage[process.env.REACT_APP_TOKEN_KEY]) {
		return <Navigate replace to='/login' />;
	}

	return children;
};

export default AuthorizedRoute;
