import React from "react";
import PropTypes from "prop-types";

const Spinner = ({ size = 65, singleColor = false }) => {
	return (
		<svg
			className={`v__spinner ${singleColor ? "v__spinner--single-color" : ""}`}
			width={`${size}px`}
			height={`${size}px`}
			viewBox='0 0 66 66'>
			<circle className='v__spinner__path' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
		</svg>
	);
};

Spinner.propTypes = {
	size: PropTypes.number,
};

export default Spinner;
