import { useTranslation } from "react-i18next";
import { Card, Modal } from "components";
import { useDepoStaff, useSnumbers } from "hooks";
import cn from "classnames";

import { Controller, useForm } from "react-hook-form";
import { FormSelect } from "../../components/forms";
import { Button } from "../../components";
import { useUpdateInvoiceManagerTours } from "hooks/query/invoiceManager";
import { useParams } from "react-router-dom";


const InvoiceManagerProfitEditModal = ({ payload, onClose, refetch }) => {
    const { t } = useTranslation();

    const { mutate: update } = useUpdateInvoiceManagerTours({
        onSuccess: (res) => {
            onClose();

            if (refetch) {
                refetch();
            }
        },
    });

    const { id } = useParams();

    const onSubmit = (data) => {
        let dataToSend = {
            ddr_name_id: data.ddr_id?.value,
            user_id: data.user_id?.value,
            snumber_id: data.snumber_id?.value,
            date: payload.date,
            tour: payload.route,
            invoice_manager_id: id
        };


        update({ id: id, data: dataToSend });
    };


    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm({});


    return (
        <Modal onClose={onClose}>
            <Card className='v__modal v__card--overflow-able' header={t("editProfits") + " - " + payload.date + " - " + payload.route} dismissible={onClose}>
                <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>

                    <Controller
                        render={({ field }) => {
                            return (
                                <FormSelect
                                    groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12 mt-5 md:mt-2 ")}
                                    label={t("snumber")}
                                    isAsync
                                    asyncSearch
                                    isClearable
                                    getValues={getValues}
                                    useFetch={useSnumbers}
                                    queryConfig={{
                                        custom: {
                                            page: 0,
                                            per_page: 25,
                                        },
                                    }}
                                    optionsParse={{ value: "id", label: "combined" }}
                                    {...field}
                                />
                            );
                        }}
                        control={control}
                        name={"snumber_id"}
                        key={"snumber_id"}
                        defaultValue={(payload.snumber_id) ? { value: payload.snumber_id, label: payload.snumber_title } : null}
                        rules={{ required: false }}
                    />

                    <div className="v__grid__item v__grid__item--col-12 my-5">
                        <hr />
                        <p className="text-center text-white  my-3 font-bold">OR</p>
                        <hr />
                    </div>


                    <Controller
                        render={({ field }) => (
                            <FormSelect
                                groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12 mt-5 md:mt-2 ", {
                                    "v__form__group--incorrect": errors?.user_id,
                                })}
                                label={t("chooseDriver")}
                                isAsync
                                asyncSearch
                                useFetch={useDepoStaff}
                                isGrouped
                                isClearable
                                queryConfig={{
                                    custom: {
                                        page: 0,
                                        per_page: 10,
                                    },
                                }}
                                groupParse={{ label: "title", options: "staff" }}
                                optionsParse={{ value: "id", label: "full_name" }}
                                error={errors?.user_id && t(`${errors?.user_id?.type}`)}
                                {...field}
                            />
                        )}
                        control={control}
                        name={"user_id"}
                        defaultValue={(payload.user_id) ? { value: payload.user_id, label: payload.full_name } : null}
                        rules={{}}
                    />



                    <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                        <Button bg status='darker' onClick={onClose}>
                            {t("cancel")}
                        </Button>
                        <Button bg status='accent' type='submit'>
                            {t("save")}
                        </Button>
                    </div>
                </form>
            </Card>
        </Modal>
    );
};

export default InvoiceManagerProfitEditModal;
