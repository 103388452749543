import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { Card } from "components";
import { ComingSoon } from "components/ui";
import PageWrapper from "components/util/PageWrapper";
import PageHeader from "components/util/PageHeader";
import PageContent from "components/util/PageContent";

const Tournament = () => {
	const { t } = useTranslation();

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Tournament</title>
			</Helmet>
			<PageHeader title={t("tournament")}></PageHeader>
			<PageContent>
				<Card>
					<ComingSoon />
				</Card>
			</PageContent>
		</PageWrapper>
	);
};

export default Tournament;
