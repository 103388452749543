import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cn from "classnames";

import { FormCheckbox } from "../../components/forms";

const StaffCardItem = ({ sp, onSelect, checked }) => {
	const Wrapper = Boolean(onSelect) ? "div" : Link;
	return (
		<Wrapper className={cn(onSelect && "flex items-center py-1")}>
			{onSelect && (
				<FormCheckbox
					name={String(sp.id)}
					onChange={(status) => onSelect?.({ checked: status, sp })}
					checked={checked ?? false}
					withLabel={false}
					groupClassName='no-margin cursor-pointer'
				/>
			)}
			<div className='v__staff__item__name truncate grow px-2'>{sp.title}</div>
		</Wrapper>
	);
};

StaffCardItem.propTypes = {
	sp: PropTypes.object,
};

export default StaffCardItem;
