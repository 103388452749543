import { format } from "date-fns";
import axios from "./axios";

const api = {
    getReports(params) {
        return axios.get("/reports", { params }).then(({ data }) => data);
    },
    getFeedback(params) {
        return axios.get("/feedback", { params }).then(({ data }) => data);
    },
    getFBR(params) {
        return axios.get("/fbr", { params }).then(({ data }) => data);
    },
    getSPFBR(params) {
        return axios.get("/sp_fbr", { params }).then(({ data }) => data);
    },

    getSPFBR(params) {
        return axios.get("/sp_fbr", { params }).then(({ data }) => data);
    },

    getFuel(params) {
        return axios.get("/fuel", { params }).then(({ data }) => data);
    },
    getInvoices(params) {
        return axios.get("/invoices/statistics", { params }).then(({ data }) => data);
    },

    getReportsHiddenColumns() {
        return axios.get("/reports/hiddenColumns").then(({ data }) => data);
    },
    getPigments() {
        return axios.get("/settings/reports").then(({ data }) => data);
    },
    storePigments(data) {
        return axios.post("/settings/reports/store", data).then(({ data }) => data);
    },
    getReportDates(params) {
        return axios.get("/import-manager/imported", { params }).then(({ data }) => data);
    },
    uploadReport(data) {
        const formData = new FormData();

        if ("date" in data) {
            data.date = format(new Date(data.date), "dd-MM-yyyy");
        }

        if ("files" in data) {
            for (var i = 0; i < data.files.length; i++) {
                let file = data.files[i];
                formData.append(`files[${i}]`, file);
            }
        }



        Object.entries(data).forEach(([key, value]) => {
            if (key == "files") {
                return;
            }
            if (Boolean(value) && value?.value) {
                formData.append(key, value.value);
            } else if (Boolean(value)) {
                formData.append(key, value);
            }
        });

        return axios.post("/import-manager/import", formData, { headers: Headers.FORMDATA }).then(({ data }) => data);
    },
};

export default api;
