import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useApp, useAuth, useReportsState } from "../../../context";
import { useKeyPress, useWindowSize } from "../../../hooks";
import { Footer } from "..";
import { Button, Icon, Modal } from "../../../components";
import { Avatar } from "../../../components/ui";
import SidebarNav from "./SidebarNav";
import { ReportAdd } from "../../../modules";

const Sidebar = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const { sidebar, setSidebar, toggleSidebar, localSidebar, setLocalSidebar, toggleLocalSidebar } = useApp();
	const { user, userCurrentRole, userCan } = useAuth();
	const { reportsModal, setReportsModal, closeReportsModal } = useReportsState();

	const { width } = useWindowSize();
	const isMobile = width < 800;
	const isTablet = width > 800 && width < 1200;
	const isDesktop = width > 1200;

	const scrollable = useRef();
	const sidebarRef = useRef();

	useKeyPress(83, () => (width > 1200 ? toggleSidebar() : toggleLocalSidebar()), true); //Ctrl + Alt + S
	useKeyPress(78, () => setReportsModal({ state: true, payload: null }), true); //Ctrl + Alt + N

	const closeLocalSidebar = useCallback(() => {
		setLocalSidebar(false);
		if (isMobile && sidebarRef.current) {
			sidebarRef.current.style.transform = `translateX(${-sidebarRef.current.getBoundingClientRect().width - 1}px)`;
		}
	}, [isMobile, setLocalSidebar]);

	useEffect(() => {
		if (localSidebar && isMobile && sidebarRef.current) {
			sidebarRef.current.style.transform = `translateX(0)`;
		}
		if (!localSidebar && isMobile && sidebarRef.current) {
			sidebarRef.current.style.transform = `translateX(${-sidebarRef.current.getBoundingClientRect().width}px)`;
		}
	}, [localSidebar, isMobile]);

	const sidebarStateManipulation = () => {
		if (isDesktop && sidebar) {
			setSidebar(true);
			setLocalSidebar(true);
		} else if (isDesktop && !sidebar) {
			setSidebar(false);
			setLocalSidebar(false);
		} else if (isTablet) {
			setLocalSidebar(false);
		} else if (isMobile) {
			closeLocalSidebar();
		}
	};

	let timer;

	const sidebarHoverInStateManipulation = () => {
		if (isDesktop && !sidebar) {
			timer = setTimeout(() => {
				setLocalSidebar(true);
			}, 200);
		}
	};

	const sidebarHoverOutStateManipulation = () => {
		if (isDesktop && !sidebar) {
			clearTimeout(timer);
			setLocalSidebar(false);
		}
	};

	useEffect(() => {
		if (!isDesktop && localSidebar) {
			const detectClicked = (e) => {
				if (e.target.classList.contains("v__sidebar__overlay")) {
					closeLocalSidebar();
				}
			};
			window.addEventListener("click", detectClicked);
			return () => {
				window.removeEventListener("click", detectClicked);
			};
		}
	});

	useEffect(() => {
		sidebarStateManipulation();
		if (!isMobile) {
			sidebarRef.current.style.transform = `unset`;
			if (!localSidebar) {
				sidebarRef.current.classList.add("v__sidebar--inner-minimized");
			}
			if (localSidebar) {
				sidebarRef.current.classList.remove("v__sidebar--inner-minimized");
			}
		}
		if (isMobile) {
			setTimeout(() => {
				sidebarRef.current.style.transform = `translateX(${-sidebarRef.current.getBoundingClientRect().width}px)`;
			}, 100);
			sidebarRef.current.classList.remove("v__sidebar--inner-minimized");
		}
		// eslint-disable-next-line
	}, [width]);

	useEffect(() => {
		return () => {
			if (!isDesktop) {
				closeLocalSidebar();
			}
		};
	}, [location.key, closeLocalSidebar, isDesktop]);

	return (
		<aside
			className={cn("v__sidebar__wrapper", {
				"v__sidebar__wrapper--minimized": !sidebar,
			})}
		>
			{!isDesktop && localSidebar && <div className='v__sidebar__overlay' />}
			<div
				className={cn("v__sidebar", {
					"v__sidebar--inner-minimized": !localSidebar,
				})}
				ref={sidebarRef}
			>
				<div
					className='v__sidebar__scrollable'
					ref={scrollable}
					onMouseEnter={sidebarHoverInStateManipulation}
					onMouseLeave={sidebarHoverOutStateManipulation}
				>
					<div className='v__sidebar__scrollable__profile'>
						<div className='v__sidebar__scrollable__profile__user' onClick={() => navigate(`/app/users/${user?.id}`)}>
							<div className='v__sidebar__scrollable__profile__user__avatar'>
								<Avatar user={user} className='v__sidebar__scrollable__profile__user__avatar__img' />
							</div>
							<div className='v__sidebar__scrollable__profile__user__details'>
								<p className='v__sidebar__scrollable__profile__user__details__name' title={user?.full_name}>
									{user?.full_name}
								</p>
								<p className='v__sidebar__scrollable__profile__user__details__role'>{userCurrentRole?.name}</p>
							</div>
						</div>
						<div className='v__sidebar__scrollable__profile__add'>
							{userCan("upload_report") && (
								<Button
									wide
									bg
									status='accent'
									iconLeft={<Icon>add</Icon>}
									className='v__sidebar__scrollable__profile__add__btn'
									onClick={() => setReportsModal({ state: true, payload: null })}
								>
									<span>{t("addReport")}</span>
								</Button>
							)}
						</div>
					</div>
					<SidebarNav />
					<Footer />
				</div>
			</div>
			{userCan("upload_report") && reportsModal.state && (
				<Modal onClose={closeReportsModal}>
					<ReportAdd />
				</Modal>
			)}
		</aside>
	);
};

export default Sidebar;
