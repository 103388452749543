import { useMutation, useQuery } from "react-query";
import { AccountApi } from "../../api";

export const accountKeys = {
	all: () => ["account"],
	current: () => [...accountKeys.all(), "current"],
	byId: (id) => [...accountKeys.all(), id],
};

export const useLogin = (config) => useMutation(AccountApi.login, config);
export const useRegister = (config) => useMutation(AccountApi.register, config);
export const useSNumberFinder = (config) => useMutation(AccountApi.findSNumber, config);
export const useRefNumberFinder = (config) => useMutation(AccountApi.findRefNumber, config);
export const useUpdateProfile = (config) => useMutation(AccountApi.updateProfile, config);

export const useProfile = (token, config) => {
	const includes = ["roles", "roles.permissions", "servicePartners", "unreadNotifications", "media", "wallet"];

	return useQuery(accountKeys.current(), () => AccountApi.getProfile(includes), {
		enabled: Boolean(token),
		staleTime: 1000 * 60 * 60,
		cacheTime: 1000 * 60 * 60,
		...config,
	});
};
