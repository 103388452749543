import { useEffect, useState } from "react";
import useEventListener from "./useEventListener";

const useMedia = (query) => {
	const [isMatch, setIsMatch] = useState(() => window.matchMedia(query).matches);
	const [mediaQueryList, setMediaQueryList] = useState();

	useEffect(() => {
		const list = window.matchMedia(query);
		setMediaQueryList(list);
		setIsMatch(list.matches);
	}, [query]);

	useEventListener("change", (e) => setIsMatch(e.matches), { el: mediaQueryList });

	return isMatch;
};

export default useMedia;
