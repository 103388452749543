import React, { createContext, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import LineLoader from "./loaders/LineLoader";
import DragContainer from "./DragContainer";
import { Fade } from "..";

export const TabCardContext = createContext();

const TabCardContextProvider = ({ children }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [tabsCount, setTabsCount] = useState(0);

	const activeIndexValue = useMemo(() => ({ activeIndex, setActiveIndex }), [activeIndex, setActiveIndex]);
	const tabsCountValue = useMemo(() => ({ tabsCount, setTabsCount }), [tabsCount, setTabsCount]);

	return (
		<TabCardContext.Provider
			value={{
				activeIndexValue,
				tabsCountValue,
			}}>
			{children}
		</TabCardContext.Provider>
	);
};

const TabCardHeader = forwardRef(({ options, initialActive = 0, className, indicatorClassName, children, ...rest }, ref) => {
	const {
		activeIndexValue: { activeIndex, setActiveIndex },
		tabsCountValue: { setTabsCount },
	} = useContext(TabCardContext);

	const activeTabRef = useRef();
	const indicatorRef = useRef();

	useEffect(() => {
		setTabsCount(options.length);
		//eslint-disable-next-line
	}, [options]);

	useEffect(() => {
		if (initialActive !== activeIndex && initialActive < options.length) {
			setActiveIndex(initialActive);
		}
		//eslint-disable-next-line
	}, [initialActive]);

	useEffect(() => {
		const activeIndicator = indicatorRef.current;
		const activeTabDimensions = activeTabRef.current?.getBoundingClientRect();

		if (activeIndicator && activeTabDimensions) {
			activeIndicator.style.width = `${activeTabDimensions.width}px`;
			activeIndicator.style.left = `${activeTabRef.current.offsetLeft}px`;
			setTimeout(() => {
				activeTabRef.current.scrollIntoView({
					block: "nearest",
					inline: "center",
					behavior: "smooth",
				});
			}, 100);
		}
	}, [activeIndex]);

	return (
		<div className={cn("v__tab__card__header", className)} {...rest} ref={ref}>
			<DragContainer className={cn("v__tab__card__header__list", className)} {...rest}>
				<div className={cn("v__tab__card__header__indicator", indicatorClassName)} ref={indicatorRef} />
				{options?.map((option, idx) => {
					if (activeIndex === idx) {
						return (
							<div
								className={cn("v__tab__card__header__list__item", "v__tab__card__header__list__item--active")}
								key={idx}
								tabIndex='0'
								ref={activeTabRef}>
								<div className='v__tab__card__header__list__item__value'>{option.label}</div>
							</div>
						);
					} else {
						return (
							<div
								className={cn("v__tab__card__header__list__item")}
								key={idx}
								tabIndex='0'
								onFocus={() => setActiveIndex(idx)}
								onClick={() => setActiveIndex(idx)}>
								<div className='v__tab__card__header__list__item__value'>{option.label}</div>
							</div>
						);
					}
				})}
			</DragContainer>
		</div>
	);
});

const TabCardPanel = ({ overflow = true, className, children, ...rest }) => {
	return (
		<div
			className={cn("v__tab__card__panel", {
				"no-overflow": !overflow,
			})}
			{...rest}>
			{children}
		</div>
	);
};

const TabCard = forwardRef(({ onTabSelect, loading, children, ...rest }, ref) => {
	const {
		activeIndexValue: { activeIndex },
	} = useContext(TabCardContext);

	const [tabHeight, setTabHeight] = useState(null);

	const calcHeight = (el) => {
		const height = el?.offsetHeight;
		setTabHeight(height);
	};

	const unsetHeight = () => {
		setTabHeight("auto");
	};

	useEffect(() => {
		if (onTabSelect) {
			onTabSelect(activeIndex);
		}
	}, [activeIndex, onTabSelect]);

	const header = React.Children.map(children, (child) => (child.type?.displayName === "TabCardHeader" ? child : null));
	const panels = React.Children.map(children, (child) => (child.type?.displayName === "TabCardPanel" ? child : null));

	return (
		<>
			{loading && <LineLoader />}
			<div className={cn("v__tab__card", { disabled: loading })} {...rest} ref={ref}>
				{header && header}
				<div className='v__tab__card__body' style={{ height: tabHeight }}>
					{panels.map((panel, idx) => {
						return (
							<Fade in={idx === activeIndex} key={idx} onEnter={calcHeight} onEntered={unsetHeight}>
								{panel}
							</Fade>
						);
					})}
				</div>
			</div>
		</>
	);
});

const TabCardContainer = forwardRef((props, ref) => {
	return (
		<TabCardContextProvider>
			<TabCard {...props} ref={ref} />
		</TabCardContextProvider>
	);
});

TabCardHeader.displayName = "TabCardHeader";
TabCardPanel.displayName = "TabCardPanel";

export { TabCardContainer as TabCard, TabCardHeader, TabCardPanel };
