import { forwardRef, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";

const IndeterminateCheckbox = forwardRef(({ indeterminate, children, className, ...rest }, ref) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;
	const checkboxId = uuidv4();

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<label className={cn("relative", className)}>
			<input type='checkbox' className='opacity-0 w-0 h-0 absolute' id={checkboxId} ref={resolvedRef} {...rest} />
			<div data-checkable className='cursor-pointer'>
				<svg viewBox='0 0 100 100' className='stroke-[10px] m-0 transition-all fill-transparent absolute inset-0'>
					<path
						className='box fill-transparent'
						d='M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z'
					/>
					<polyline className='check fill-transparent transition-all' points='25.5,53.5 39.5,67.5 72.5,34.5 ' />
					<polyline className='indeterminate transition-all' points='30,50 70,50' />
				</svg>
			</div>
		</label>
	);
});

export default IndeterminateCheckbox;
