import { getCss } from "../utils";

const useMediaQueries = () => {
	const breakpointValues = {
		xxs: getCss("--xxs"),
		xs: getCss("--xs"),
		sm: getCss("--sm"),
		md: getCss("--md"),
		lg: getCss("--lg"),
		xl: getCss("--xl"),
		xxl: getCss("--xxl"),
		fhd: getCss("--fhd"),
	};

	const breakpoints = {
		XXSMALL: `(min-width: ${breakpointValues.xxs})`,
		XSMALL: `(min-width: ${breakpointValues.xs})`,
		SMALL: `(min-width: ${breakpointValues.sm})`,
		MEDIUM: `(min-width: ${breakpointValues.md})`,
		LARGE: `(min-width: ${breakpointValues.lg})`,
		XLARGE: `(min-width: ${breakpointValues.xl})`,
		XXLARGE: `(min-width: ${breakpointValues.xxl})`,
		FHD: `(min-width: ${breakpointValues.fhd})`,
	};

	const pointingDevice = {
		POINTER_COARSE: "(pointer: coarse)",
		POINTER_FINE: "(pointer: fine)",
	};

	return { ...breakpointValues, ...breakpoints, ...pointingDevice };
};

export default useMediaQueries;
