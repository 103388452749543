import { useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import cn from "classnames";

import { Card } from "..";
import NoDataRow from "./NoDataRow";
import TablePagination from "./TablePagination";

const renderHeader = (headerGroup) => {
	return (
		<tr {...headerGroup.getHeaderGroupProps()} className='v__table__header__row'>
			{headerGroup.headers.map((column) => (
				<th
					{...column.getHeaderProps(column.getSortByToggleProps())}
					className={cn(
						"v__table__header__row__cell",
						column.sticky && "v__table__header__row__cell--sticky",
						column.isSorted
							? column.isSortedDesc
								? "v__table__header__row__cell--desc"
								: "v__table__header__row__cell--asc"
							: ""
					)}
				>
					{column.render("Header")}
				</th>
			))}
		</tr>
	);
};

const renderRow = (row) => {
	return (
		<tr {...row.getRowProps()} className={cn("v__table__body__row")}>
			{row.cells.map((cell) => (
				<td
					{...cell.getCellProps()}
					className={cn("v__table__body__row__cell", cell.column.sticky && "v__table__body__row__cell--sticky")}
				>
					{cell.render("Cell")}
				</td>
			))}
		</tr>
	);
};

const Table = ({
	columns,
	data,
	fetchData,
	loading,
	paginationComponent,
	serverPageCount,
	serverTotalResults,
	initialPageSize = 10,
	cardClassName,
	scrollToTopOnChange,
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, sortBy },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: initialPageSize, sortBy: [] },
			manualPagination: true,
			manualSortBy: true,
			pageCount: serverPageCount,
			autoResetPage: false,
			autoResetSortBy: false,
		},
		useSortBy,
		usePagination
	);

	useEffect(() => {
		fetchData?.({ pageIndex, pageSize, sortBy });
	}, [fetchData, pageIndex, pageSize, sortBy]);

	const paginationProps = {
		gotoPage,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		pageCount,
		pageIndex,
		pageOptions,
		pageSize,
		setPageSize,
		serverTotalResults,
		scrollToTopOnChange,
	};

	return (
		<>
			<Card
				loading={loading}
				className={cn("v__table__container v__table__container--padded v__table__container--responsive body-no-pad", cardClassName)}
			>
				<table className='v__table' {...getTableProps()}>
					<thead className='v__table__header'>{headerGroups.map((headerGroup) => renderHeader(headerGroup))}</thead>
					<tbody className='v__table__body' {...getTableBodyProps()}>
						{data.length === 0 && <NoDataRow />}
						{page.map((row) => {
							prepareRow(row);
							return renderRow(row);
						})}
					</tbody>
				</table>
			</Card>

			{paginationComponent?.(paginationProps) ?? <TablePagination {...paginationProps} />}
		</>
	);
};

export default Table;
