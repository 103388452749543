import { useAuth } from "context";
import { Reports } from "pages/Reports";
import { REPORT_COLUMN_PRESETS } from "reducers/const";

const InvoicesAdvanced = () => {

    const { userCan } = useAuth();
    const initalPreset = (userCan("sp_panel_view")) ? REPORT_COLUMN_PRESETS.SP_PANEL : REPORT_COLUMN_PRESETS.SALARY;
    return <Reports primaryDataType='invoices' initialPreset={initalPreset} />;
};

export default InvoicesAdvanced;
