import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { FormInput } from "../../components/forms";
import { StepStaffList } from "..";
import { Button, Icon } from "../../components";
import { Badge } from "../../components/ui";

const extractMemberSelections = (obj) => {
	return Object.values(obj).reduce((prev, curr) => {
		const sanitize = Object.entries(curr.members).reduce((p, c) => ({ ...p, [c[0]]: c[1].checked }), {});
		return { ...prev, ...sanitize };
	}, {});
};

const MembersList = ({ members, onRemove }) => {
	const { t } = useTranslation();
	const [collapsedChoiceGroups, setCollapsedChoiceGroups] = useState({});

	return Object.entries(members).map((entry) => {
		const key = entry[0];
		const item = entry[1];
		if (Object.values(item.members).filter((option) => option.checked).length === 0) {
			return null;
		}
		return (
			<div className='v__list-selection__result__group' key={key}>
				<div
					className='v__list-selection__result__group__title'
					onClick={() => setCollapsedChoiceGroups((prev) => ({ ...prev, [item.id]: !prev[item.id] }))}
				>
					{collapsedChoiceGroups[item.id] ? <Icon>expand_more</Icon> : <Icon>expand_less</Icon>}

					<span>{item.title ?? t("others")}</span>

					<Badge type='outline' size='md'>
						{Object.values(item.members).filter((option) => option.checked).length}
						{/* {item?.max_choices ? ` / ${item.max_choices}` : ""} */}
					</Badge>
				</div>
				{!collapsedChoiceGroups[item.id] && (
					<div className='v__list-selection__result__group__body'>
						{Object.values(item.members)
							.filter((option) => option.checked)
							.map((entry) => (
								<div key={Math.random()} className='v__list-selection__result__group__body__item'>
									<span>{entry.full_name}</span>
									<Button
										small
										round
										bg
										status='darker'
										hoverStatus='danger'
										onClick={() => onRemove?.({ sp: item, value: entry })}
									>
										<Icon>delete</Icon>
									</Button>
								</div>
							))}
					</div>
				)}
			</div>
		);
	});
};

const ServicePartnerForm = ({ onSubmit, onCancel, defaultValues: { members, image_path, ...otherDefaultValues } }) => {
	const { t } = useTranslation();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({ defaultValues: otherDefaultValues });

	const [list, setList] = useState(members ?? {});
	const [choices, setChoices] = useState({});

	const handleOnSelectDriver = (v) => {
		setList((prev) => {
			return {
				...prev,
				[v.chain.sp.id]: {
					...v.chain.sp,
					members: {
						...prev[v.chain.sp.id]?.members,
						[v.value.member.id]: {
							...v.value.member,
							checked: v.value.checked,
						},
					},
				},
			};
		});
	};

	const handleOnRemove = (v) => {
		setList((prev) => ({
			...prev,
			[v.sp.id]: {
				...prev[v.sp.id],
				members: {
					...prev[v.sp.id].members,
					[v.value.id]: {
						...prev[v.sp.id].members[v.value.id],
						checked: false,
					},
				},
			},
		}));
	};

	useEffect(() => {
		setValue("service_partners", list);
		setChoices(() => extractMemberSelections(list));
	}, [list, setValue]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
			<Controller
				render={({ field }) => (
					<FormInput
						groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
							"v__form__group--incorrect": errors?.title,
						})}
						label={t("title")}
						error={errors?.title && t(`${errors?.title?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='title'
				defaultValue=''
				rules={{ required: true }}
			/>
			<Controller
				render={({ field }) => (
					<FormInput
						groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
							"v__form__group--incorrect": errors?.title,
						})}
						label={t("import_title")}
						error={errors?.import_title && t(`${errors?.import_title?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='import_title'
				defaultValue=''
				rules={{ required: true }}
			/>
			<Controller
				render={({ field }) => (
					<FormInput
						groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
							"v__form__group--incorrect": errors?.description,
						})}
						label={t("description")}
						error={errors?.description && t(`${errors?.description}`)}
						{...field}
					/>
				)}
				control={control}
				name='description'
				defaultValue=''
				rules={{ required: true }}
			/>

			<div
				className={cn("v__form__group v__grid__item v__grid__item--col-12", {
					// "v__form__group--incorrect": optionsError,
				})}
			>
				<label htmlFor={"group-options"} className={`v__form__group__label`}>
					<span>{t("membersChoices")}</span>
				</label>
				<div className='v__list-selection'>
					<div className='v__list-options pt-2'>
						<StepStaffList.Base onSelect={handleOnSelectDriver} checked={choices} />
					</div>
					<div className='v__list-selection__result'>
						<MembersList members={list} onRemove={handleOnRemove} />
					</div>
				</div>
			</div>
			<div className='v__form__footer v__grid__item v__grid__item--col-12'>
				<Button bg status='darker' onClick={onCancel}>
					{t("cancel")}
				</Button>
				<Button bg status='accent' type='submit'>
					{t("save")}
					{/* {payload ? lang?.save_changes : lang?.add_group} */}
				</Button>
			</div>
		</form>
	);
};

export default ServicePartnerForm;
