import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { useUpdateServicePartner, useCreateServicePartner, useServicePartner } from "hooks";

import { ServicePartnerForm } from ".";

function parseSelectedMembers(members) {
	if (!members) return {};

	const servicePartners = {};

	for (const member of members) {
		const member_sp = member.service_partners?.[0];
		if (!(member_sp?.id in servicePartners)) {
			servicePartners[member_sp.id] = { ...member_sp, members: {} };
		}

		servicePartners[member_sp.id].members[member.id] = { ...member, checked: true };
	}

	return servicePartners;
}

const ServicePartnerModal = ({ payload, onClose }) => {
	const { t } = useTranslation();
	const { data: servicePartner } = useServicePartner(payload?.id, {
		custom: {
			includes: ["staff", "staff.servicePartners", "owner"],
		},
		enabled: Boolean(payload?.id),
	});

	const { mutate: update, isLoading: isLoadingUpdate } = useUpdateServicePartner({
		onSuccess: (res) => {
			onClose();
		},
	});

	const { mutate: create, isLoading: isLoadingCreate } = useCreateServicePartner({
		onSuccess: (res) => {
			onClose();
		},
	});

	const servicePartnerData = servicePartner?.data;

	//change those ->load servicePatner
	const defaultValues = {
		title: servicePartnerData?.title,
		import_title: servicePartnerData?.import_title,
		description: servicePartnerData?.description,
		members: parseSelectedMembers(servicePartnerData?.staff),
		owner_id: Boolean(payload) ? { value: servicePartnerData?.owner_id, label: servicePartnerData?.owner?.full_name } : null,
	};

	const handleOnSubmit = (data) => {
		if (Boolean(payload)) {
			update({ id: payload.id, data });
		} else {
			create(data);
		}
	};

	const shouldRender = (payload && servicePartner) || !payload;
	const isLoading = isLoadingUpdate || isLoadingCreate;

	return (
		<Modal onClose={onClose}>
			<Card className='v__modal v__card--overflow-able' header={t("manageSP")} relativeLoading={isLoading} dismissible={onClose}>
				{shouldRender && <ServicePartnerForm onSubmit={handleOnSubmit} defaultValues={defaultValues} onCancel={onClose} />}
			</Card>
		</Modal>
	);
};

export default ServicePartnerModal;
