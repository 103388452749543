import React from "react";
import PropTypes from "prop-types";

const ComingSoonSvg = ({ withAnimation = false, classes = "v__coming-soon__graphic" }) => {
	return (
		<svg className={classes} viewBox='0 0 470.165 409.292'>
			<path className='a' d='M470.165,746.725,133.6,751.8,0,728.121H208.566Z' transform='translate(0 -342.507)' />
			<circle className='b' cx='53.839' cy='53.839' r='53.839' transform='translate(333.734 0)' />
			<path className='c' d='M335.954,783.929s.788-16.5,16.932-14.584' transform='translate(-240.71 -395.501)' />
			<circle className='d' cx='8.08' cy='8.08' r='8.08' transform='translate(82.602 356.564)' />
			<rect className='c' width='2.281' height='15.966' transform='translate(89.367 378.251)' />
			<path className='c' d='M838.4,789.251s.787-16.5,16.932-14.584' transform='translate(-477.061 -398.004)' />
			<circle className='d' cx='8.08' cy='8.08' r='8.08' transform='translate(348.698 359.383)' />
			<rect className='c' width='2.281' height='15.966' transform='translate(355.463 381.07)' />
			<path className='c' d='M911.851,794.574s.788-16.5,16.932-14.584' transform='translate(-511.612 -400.508)' />
			<circle className='d' cx='8.08' cy='8.08' r='8.08' transform='translate(387.597 362.201)' />
			<rect className='c' width='2.281' height='15.966' transform='translate(394.363 383.888)' />
			<path className='c' d='M456.243,769.025s.788-16.5,16.932-14.584' transform='translate(-297.294 -388.49)' />
			<circle className='d' cx='8.08' cy='8.08' r='8.08' transform='translate(146.307 348.671)' />
			<rect className='c' width='2.281' height='15.966' transform='translate(153.072 370.358)' />
			<path className='c' d='M229.5,774.348s.788-16.5,16.932-14.584' transform='translate(-190.637 -390.994)' />
			<circle className='d' cx='8.08' cy='8.08' r='8.08' transform='translate(26.225 351.49)' />
			<rect className='c' width='2.281' height='15.966' transform='translate(32.991 373.177)' />
			<circle className='c' cx='88.035' cy='88.035' r='88.035' transform='translate(146.196 178.61)' />
			<path
				className='e'
				d='M486.621,540.777a23.3,23.3,0,0,1,35.758,0,25.893,25.893,0,1,0-35.758,0Z'
				transform='translate(-307.814 -267.066)'
			/>
			<path
				className='e'
				d='M618.627,540.777a23.3,23.3,0,0,1,35.758,0,25.893,25.893,0,1,0-35.758,0Z'
				transform='translate(-369.909 -267.066)'
			/>
			<circle className='c' cx='8.907' cy='8.907' r='8.907' transform='translate(178.946 237.259)' />
			<circle className='c' cx='8.907' cy='8.907' r='8.907' transform='translate(248.854 237.259)' />
			<circle className='b' cx='10.357' cy='10.357' r='10.357' transform='translate(160.437 278.296)' />
			<circle className='b' cx='10.357' cy='10.357' r='10.357' transform='translate(279.542 278.296)' />
			<path className='b' d='M428.044,505.926l-7.768,33.66,12.946-12.946Z' transform='translate(-197.697 -237.987)' />
			<path
				className='c'
				d='M485.026,704.349l-8.819-7.4.206,7.4h-2.739l-.219-7.826-11.907,7.826h-4.985l16.8-11.044-.651-23.191-.486-17.514,2.732-.075.493,17.59.65,23.17,13.18,11.065Z'
				transform='translate(-214.767 -306.947)'
			/>
			<path
				className='c'
				d='M406.8,704.349l-8.819-7.4.206,7.4h-2.732l-.219-7.826-11.907,7.826h-4.985l16.8-11.044-.65-23.191L394,652.6l2.739-.075.493,17.59.643,23.17,13.181,11.065Z'
				transform='translate(-177.973 -306.947)'
			/>
			<path
				className='c'
				d='M575.537,379.417c-2.416,0-4.547,1.876-5.971,4.758-1.256-4.334-3.894-7.348-6.975-7.348a4.687,4.687,0,0,0-.622.1c-1.184-4.627-3.937-7.872-7.146-7.872-4.29,0-7.768,5.8-7.768,12.946s3.478,12.946,7.768,12.946a4.692,4.692,0,0,0,.622-.1c1.184,4.627,3.937,7.872,7.146,7.872,2.416,0,4.547-1.876,5.971-4.758,1.256,4.334,3.894,7.348,6.975,7.348,4.29,0,7.768-5.8,7.768-12.946S579.827,379.417,575.537,379.417Z'
				transform='translate(-340.012 -207.28)'
			/>
			<path
				className='d'
				d='M0,0H0A25.893,25.893,0,0,1,25.893,25.893V38.839A25.893,25.893,0,0,1,0,64.731H0Z'
				transform='translate(151.598 300.006) rotate(180)'
			/>
			<path
				className='d'
				d='M598.3,444.248h0A25.893,25.893,0,0,1,624.2,470.141v12.946A25.892,25.892,0,0,1,598.3,508.98h0V444.248Z'
				transform='translate(-281.441 -208.974)'
			/>
			<path
				className='d'
				d='M604.888,472.99h-4.109a94.488,94.488,0,0,0-188.976,0H407.7a98.6,98.6,0,0,1,197.193,0Z'
				transform='translate(-274.457 -209.789)'
			/>
			<path className='c' d='M131.69,368Z' transform='translate(-61.947 -173.105)' />
			<path
				className='note'
				d='M312.44,406.189v0l-1.93-.8-8.143-3.443-2.919-1.234-.224.566h0l-3.921,9.9,11.035,4.67.058.052-.744,1.753a3.11,3.11,0,0,0-.259.506,2.434,2.434,0,0,0,1.393,2.992c.93.368,2.106-.4,2.627-1.714.514-1.3.012-2.946-.895-3.344v0l-1.93-.8-6.6-2.567,3.772-9.525-.559-.221-3.774,9.529-.816-.317,3.789-9.566.1.042,7.761,3.285.058.052-.744,1.753a3.1,3.1,0,0,0-.259.506,2.434,2.434,0,0,0,1.393,2.992c.93.368,2.106-.4,2.627-1.714C313.849,408.235,313.346,406.587,312.44,406.189Z'
				transform='translate(-221.588 -222.167)'
			/>
			<path
				className='note'
				d='M382.25,444.573c-4.006-2.29-3.2-7.316-3.2-7.316-.1-3.265-2.8-2.8-2.8-2.8l-.982.224a2.981,2.981,0,0,0-1.46-.27l-1.493.251c3.4.8,3.121,3.644,3.121,3.644l.476,3.326L367,438.034l1.023-2.412a4.306,4.306,0,0,0,.36-.7,3.38,3.38,0,0,0-1.935-4.155c-1.292-.512-2.926.555-3.649,2.381-.714,1.8-.016,4.091,1.243,4.644v0l2.681,1.116.02-.063Zm-4.525-5.845.286,3.184a7.344,7.344,0,0,1-1.338-4.876,3.084,3.084,0,0,0-.6-1.708,3.618,3.618,0,0,1,1.649,3.4Z'
				transform='translate(-253.203 -236.245)'
			/>
			<path
				className='note'
				d='M255.13,358.245v0l-2.681-1.116-11.2-4.355,2.828-2.516a3.572,3.572,0,0,1,4.881-.8l-.877-1.3s-1.65-2.184-3.959.127c0,0-2.857,4.212-7.345,3.138L252.1,357.9l.08.073-1.033,2.435a4.3,4.3,0,0,0-.36.7,3.38,3.38,0,0,0,1.935,4.155c1.292.512,2.926-.555,3.649-2.381C257.086,361.088,256.388,358.8,255.13,358.245Z'
				transform='translate(-194.056 -197.006)'
			/>
			<path
				className='note'
				d='M335.195,322.388l-1.033,2.435a4.306,4.306,0,0,0-.36.7,3.38,3.38,0,0,0,1.935,4.155c1.292.512,2.926-.555,3.649-2.381.714-1.8.016-4.091-1.243-4.644v0l-2.681-1.116-9.17-3.565,5.24-13.229-.777-.308-5.242,13.234-1.134-.441,5.262-13.286.139.059,10.779,4.562.081.073-1.033,2.435a4.314,4.314,0,0,0-.36.7,3.38,3.38,0,0,0,1.935,4.156c1.292.512,2.926-.555,3.649-2.381.714-1.8.016-4.091-1.243-4.644v0l-2.681-1.116-10.955-4.631h0l-.355-.15-4.054-1.714-5.757,14.536,15.327,6.487ZM325.234,302.078Z'
				transform='translate(-233.106 -175.402)'
			/>
			<path
				className='note'
				d='M229.1,183.7l0,0-4.615-1.922-19.286-7.5,4.868-4.331a6.148,6.148,0,0,1,8.4-1.376l-1.51-2.245s-2.84-3.76-6.815.218c0,0-4.918,7.251-12.644,5.4l26.384,11.167.139.126-1.778,4.192a7.415,7.415,0,0,0-.62,1.21c-1.245,3.145,1.107,6.273,3.331,7.153s5.036-.955,6.282-4.1C232.469,188.588,231.269,184.647,229.1,183.7Z'
				transform='translate(-175.584 -111.155)'
			/>
			<path
				className='note'
				d='M241.991,289.872c-9.578-5.476-7.661-17.493-7.661-17.493-.241-7.807-6.691-6.7-6.691-6.7l-2.347.536a7.128,7.128,0,0,0-3.491-.646l-3.571.6c8.131,1.91,7.463,8.712,7.463,8.712l1.139,7.953-21.305-8.595,2.446-5.768a10.3,10.3,0,0,0,.862-1.68c1.73-4.368-1.537-8.712-4.626-9.935s-6.995,1.326-8.725,5.694c-1.706,4.308-.039,9.781,2.971,11.1l0,.005,6.409,2.669.048-.151ZM231.172,275.9l.686,7.639a17.5,17.5,0,0,1-3.2-11.684,7.375,7.375,0,0,0-1.427-4.084c4.621,2.765,3.942,8.129,3.942,8.129Z'
				transform='translate(-174.312 -154.355)'
			/>
		</svg>
	);
};

ComingSoonSvg.propTypes = {
	animated: PropTypes.bool,
};

export default ComingSoonSvg;
