import { useCallback, useMemo, useState } from "react";
import { parseTableSortBy } from "utils";

const useDataTableGenerate = (useFetch, columns, config) => {
	const [queryParams, setQueryParams] = useState({
		sortBy: [],
		page: 1,
		per_page: 10,
		...config,
	});

	const { data, isFetching, ...rest } = useFetch({
		custom: queryParams,
		enabled: Boolean(config),
	});

	const rowsData = data?.data ?? [];
	const overallData = data?.overall ?? [];

	const fetchedMeta = data?.meta ?? null;

	const fetchedData = [...overallData, ...rowsData];

	const memoColumns = useMemo(() => {
		if (!data) return [];

		const parsedDataColumns = data.columns.map(({ title, accessor, disableSortBy, field, ...rest }) => ({
			Header: title,
			accessor: accessor,
			disableSortBy: disableSortBy,
			id: field,
			...rest,
		}));

        const customColumns = columns ? [...columns?.(parsedDataColumns)].filter(Boolean) : [];

		return parsedDataColumns.map((col) => {
			const duplicateCol = customColumns.find((v) => v.id === col.id);
			if (Boolean(duplicateCol)) {
				return duplicateCol;
			}
			return col;
		});
	}, [data, columns]);

	const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
		setQueryParams((prev) => ({
			...prev,
			sortBy: parseTableSortBy(sortBy),
			page: pageSize !== prev.per_page ? 1 : pageIndex + 1,
			per_page: pageSize,
		}));
	}, []);

	return {
		tableProps: {
			columns: memoColumns,
			data: fetchedData,
			fetchData,
			loading: isFetching,
			serverPageCount: fetchedMeta?.last_page,
			serverTotalResults: fetchedMeta?.total,
		},
		hasOverall: overallData.length > 0,
		state: {
			queryParams,
			setQueryParams,
		},
		query: {
			data,
			isFetching,
			...rest,
		},
	};
};

export default useDataTableGenerate;
