import { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Button, Card, Fade, Icon, Modal, SimpleCollapse } from "../../components";
import { useStoreReportPigments, useWindowSize } from "../../hooks";
import { generateFontColorDependingOnBg } from "../../utils";
import { FormInput } from "../../components/forms";
import { Alert, ColorPicker } from "../../components/ui";

const PRESETS = ["#d53b49", "#f1b512", "#218332", "#7550a4"];

const ReportPigmentsModal = ({ payload, onClose }) => {
	const { t } = useTranslation();

	const { width, height } = useWindowSize();
	const [errorMsg, setErrorMsg] = useState(null);
	const [pickerPageLocation, setPickerPageLocation] = useState({ top: 0, lef: 0 });
	const [columnColors, setColumnColors] = useState(payload?.data?.colors ?? []);
	const [pickerToggled, setPickerToggled] = useState({ state: false, payload: null });
	const [pickedColor, setPickedColor] = useState({
		hex: "#000",
		rgb: {
			r: 0,
			g: 0,
			b: 0,
			a: 1,
		},
		hsl: {
			h: 0,
			s: 0,
			l: 0,
			a: 1,
		},
	});

	const addPigmentCondition = () => {
		const newPigmentEntry = {
			id: new Date().getTime(),
			min: 0,
			max: 0,
			color: "#777777",
		};

		if (columnColors.length < 4) {
			setColumnColors((prev) => prev.concat(newPigmentEntry));
		}
	};

	const { mutate, isLoading } = useStoreReportPigments({
		onSuccess: () => onClose(),
	});

	const onSubmit = (e) => {
		e.preventDefault();
		if (columnColors.length <= 4) {
			for (const item of columnColors) {
				if (item.min !== 0 && item.max !== 0 && (!item.min || !item.max)) {
					return setErrorMsg(() => t("fillInAllFields"));
				}
				if (item.min.floatValue > item.max.floatValue) {
					return setErrorMsg(() => t("minBiggerThanMax"));
				}
			}

			const sanitized = columnColors.reduce((acc, cur) => {
				return acc.concat({
					min: Number(cur.min),
					max: Number(cur.max),
					color: cur.color,
				});
			}, []);

			if (!errorMsg) {
				mutate({
					field: payload.column.field,
					pigments: sanitized,
				});
			}
		}
	};

	const adjustPickerPosition = useCallback(
		(e) => {
			if (width < 900) {
				setPickerPageLocation((prev) => ({ ...prev, left: "50%" }));
			} else {
				setPickerPageLocation((prev) => ({ ...prev, left: e?.clientX }));
			}

			if (height < 500 || height - e?.clientY < 400) {
				setPickerPageLocation((prev) => ({ ...prev, top: 50 }));
			} else {
				setPickerPageLocation((prev) => ({ ...prev, top: e?.clientY + 30 }));
			}
		},
		[height, width]
	);

	useEffect(() => {
		if (width < 600 && height < 1000) {
			adjustPickerPosition();
		}
	}, [width, height, adjustPickerPosition]);

	const ButtonList = ({ color }) => {
		return (
			<div className='v__form__group v__report__pigments__entry__colors'>
				<label htmlFor={Math.random()} className='v__form__group__label'>
					<span>{t("color")}</span>
				</label>
				<div className='v__form__button__list'>
					{PRESETS.map((preset) => (
						<Button
							round
							key={preset}
							style={{ background: preset }}
							className='presets'
							onClick={() => {
								setPickedColor((prev) => ({ ...prev, hex: preset }));
								setColumnColors((prev) =>
									prev.map((result) =>
										result.id === color.id
											? {
													...result,
													color: preset,
											  }
											: result
									)
								);
							}}>
							{preset === color.color && <Icon style={{ color: generateFontColorDependingOnBg(preset) }}>check</Icon>}
						</Button>
					))}
					<Button
						bg
						round
						status={!PRESETS.includes(color.color) ? "none" : "darker"}
						style={{ background: !PRESETS.includes(color.color) && color.color }}
						onClick={(e) => {
							e.persist();
							adjustPickerPosition(e);
							if (!pickerToggled.state) {
								setPickedColor((prev) => ({ ...prev, hex: color.color }));
								setPickerToggled({ state: true, payload: color });
							} else {
								setPickerToggled({ state: false, payload: null });
							}
						}}>
						<Icon style={{ color: !PRESETS.includes(color.color) && generateFontColorDependingOnBg(color.color) }}>
							colorize
						</Icon>
					</Button>
					<Button
						bg
						round
						status='darker'
						hoverStatus='danger'
						onClick={() => {
							setColumnColors((prev) => prev.filter((result) => result.id !== color.id));
						}}>
						<Icon>delete</Icon>
					</Button>
				</div>
			</div>
		);
	};

	return (
		<Modal onClose={onClose}>
			<Card
				className='v__modal v__card--overflow-able'
				header={t("choosePigments")}
				relativeLoading={isLoading}
				dismissible={onClose}>
				<SimpleCollapse in={errorMsg}>
					<Alert>{errorMsg}</Alert>
				</SimpleCollapse>
				<Fade in={pickerToggled.state}>
					<div className='v__color__picker__wrapper'>
						<div className='v__color__picker__overlay' onClick={() => setPickerToggled({ state: false, payload: null })}></div>
						<ColorPicker
							small
							color={pickedColor}
							onChange={(val) => setPickedColor(val)}
							position={pickerPageLocation}
							onChangeComplete={(val) => {
								setColumnColors((prev) =>
									prev.map((result) =>
										result.id === pickerToggled.payload.id
											? {
													...result,
													color: val.hex,
											  }
											: result
									)
								);
							}}
						/>
					</div>
				</Fade>
				<form onSubmit={onSubmit} className='v__grid'>
					{columnColors.map((color, idx) => {
						return (
							<div className='v__grid__item v__grid__item--col-12 v__report__pigments__entry' key={idx}>
								<FormInput
									id={`${new Date().getTime().toString()}_${Math.random()}`}
									name=''
									type='number'
									label={`${t("from")}${payload?.column?.number_type === "percent" ? "(%)" : ""}`}
									groupClassName='v__report__pigments__entry__from'
									min={payload?.column?.number_type === "percent" ? 0.0 : -9999}
									max={payload?.column?.number_type === "percent" ? 100.0 : 9999}
									step={0.01}
									value={color.min}
									onChange={({ target }) => {
										const val = target.value;
										const min = val;

										setColumnColors((prev) =>
											prev.map((result) =>
												result.id === color.id
													? {
															...result,
															min,
													  }
													: result
											)
										);
									}}
								/>
								<FormInput
									id={`${new Date().getTime().toString()}_${Math.random()}`}
									name=''
									type='number'
									label={`${t("to")}${payload?.column?.number_type === "percent" ? "(%)" : ""}`}
									groupClassName='v__report__pigments__entry__from'
									min={payload?.column?.number_type === "percent" ? 0.0 : -9999}
									max={payload?.column?.number_type === "percent" ? 100.0 : 9999}
									step={0.01}
									value={color.max}
									onChange={({ target }) => {
										const val = target.value;
										const max = val;

										setColumnColors((prev) =>
											prev.map((result) =>
												result.id === color.id
													? {
															...result,
															max,
													  }
													: result
											)
										);
									}}
								/>
								<ButtonList color={color} />
							</div>
						);
					})}
					<div className='v__grid__item v__grid__item--col-12'>
						<div className={cn("v__report__pigments__add", { disabled: columnColors.length >= 4 })}>
							<p>{t("addCondition")}</p>
							<Button bg round status='accent' onClick={addPigmentCondition} tabIndex={columnColors.length >= 4 ? -1 : 1}>
								<Icon>add</Icon>
							</Button>
						</div>
					</div>
					<div className='v__form__footer v__grid__item v__grid__item--col-12'>
						<Button bg status='darker' onClick={onClose}>
							{t("cancel")}
						</Button>
						<Button bg status='accent' type='submit'>
							{payload ? t("save") : t("addCondition")}
						</Button>
					</div>
				</form>
			</Card>
		</Modal>
	);
};

export default ReportPigmentsModal;
