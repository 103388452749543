import { useTranslation } from "react-i18next";
import { Card, Modal } from "components";
import { InvoicePayForm } from ".";
import { usePayInvoice } from "hooks/query/invoices";

const InvoicePayModal = ({ payload, onClose }) => {
    const { t } = useTranslation();

    const defaultValues = {
        paid: (payload?.paid_sum) ? payload?.paid_sum : 0,
		status: Boolean(payload) ? { value: payload?.status, label: t(payload.status) } : null,
    };

    console.log(payload);

    const { mutate: update } = usePayInvoice({
        onSuccess: (res) => {
            onClose();
        },
    });

    const handleOnSubmit = (data, e) => {
        const submitter = e.nativeEvent.submitter.name

        if (submitter === "invoicePaySubmit") {
            console.log(data);
            update({ id: payload.invoice_id, data });
        }
    };

    const shouldRender = payload;

    return (
        <Modal onClose={onClose}>
            <Card className='v__modal v__card--overflow-able' header={t("pay_invoice") + " - " + payload.invoice_number} dismissible={onClose}>
                {shouldRender && <InvoicePayForm defaultValues={defaultValues} onSubmit={handleOnSubmit} onCancel={onClose} />}
            </Card>
        </Modal>
    );
};

export default InvoicePayModal;
