import { useState } from "react";

import { CalendarHorizontal, MonthWeekPicker } from ".";

import { REPORT_PERIOD_TYPES, REPORT_SELECTION_TYPES } from "reducers/const";

const DateWeekMonthPicker = ({ onChange, selected, reportType, files, withoutAll }) => {
	const [navRoot, setNavRoot] = useState();

	return (
		<div className='multi__period__picker'>
			<div className='multi__period__picker__nav' ref={(el) => setNavRoot(el)} />
			{navRoot &&
				(reportType.value === REPORT_PERIOD_TYPES.DAILY ? (
					<CalendarHorizontal
						navRoot={navRoot}
						selected={selected?.date}
						onChange={(date) => onChange?.({ type: REPORT_SELECTION_TYPES.DATE, payload: date })}
						highlightDates={files}
					/>
				) : (
					<MonthWeekPicker navRoot={navRoot} withoutAll={withoutAll} selected={selected} onChange={onChange} files={files} reportType={reportType} />
				))}
		</div>
	);
};

export default DateWeekMonthPicker;
