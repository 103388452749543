import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import cn from "classnames";

import { useCompanies, useMedia, useMediaQueries, useDepoStaff, useGroups, useServicePartners } from "hooks";

import { Button, Icon } from "components";
import { FormSelect } from "components/forms";

const setFilterCount = (filters) => {
	const count = Object.values(filters).filter((v) => v?.value).length;
	return parseFloat(count);
};

const PerformanceFilters = ({ filters, onFilter, sortByOptions }) => {
	const { t } = useTranslation();

	const { LARGE } = useMediaQueries();
	const isLarge = useMedia(LARGE);

	const [toggled, setToggled] = useState(false);
	const [appliedFilters, setAppliedFilters] = useState(0);

	useEffect(() => {
		setAppliedFilters(() => setFilterCount(filters));
		setToggled(false);
	}, [filters]);

	return (
		<>
			<Button
				bg
				status='accent'
				className={cn("v__card__filter__btn", {
					"v__card__filter__btn--active": toggled,
				})}
				onClick={() => setToggled(!toggled)}
			>
				<Icon>filter_alt</Icon>
				<span>{appliedFilters}</span>
				<Icon className='v__card__filter__btn__indicator'>expand_more</Icon>
			</Button>

			{toggled && <div className='v__card__filter__overlay' onClick={() => setToggled((prev) => !prev)} />}

			<div className={cn("v__card__filter", !toggled && "hide")}>
				<div className='v__grid'>
					{!isLarge && (
						<FormSelect
							groupClassName='v__grid__item v__grid__item--col-12'
							label={t("performancePeriod")}
							isSearchable={false}
							value={filters.sortBy}
							onChange={(option) =>
								onFilter?.({
									sortBy: option,
								})
							}
							options={sortByOptions}
						/>
					)}

					<FormSelect
						groupClassName='v__grid__item v__grid__item--col-12'
						label={t("depo")}
						isAsync
						useFetch={useCompanies}
						isGrouped
						isClearable
						queryConfig={{ custom: { type: "delivery_daily", includes: ["depos"] } }}
						groupParse={{ label: "title", options: "depos" }}
						value={filters.depo_id}
						onChange={(option) =>
							onFilter?.({
								depo_id: option,
								service_partner_id: null,
								member_group_id: null,
								user_id: null,
							})
						}
					/>

					<FormSelect
						groupClassName='v__grid__item v__grid__item--col-12'
						label={t("servicePartner")}
						isAsync
						asyncSearch
						isClearable
						useFetch={useServicePartners}
						queryConfig={{ custom: { depo_id: filters.depo_id?.value, page: 0, per_page: 25 } }}
						value={filters.service_partner_id}
						onChange={(option) =>
							onFilter?.({
								service_partner_id: option,
								member_group_id: null,
								user_id: null,
							})
						}
					/>

					<FormSelect
						groupClassName='v__grid__item v__grid__item--col-12'
						label={t("group")}
						isAsync
						asyncSearch
						isClearable
						useFetch={useGroups}
						queryConfig={{ custom: { page: 0, per_page: 25 } }}
						value={filters.member_group_id}
						onChange={(option) =>
							onFilter?.({
								member_group_id: option,
								user_id: null,
							})
						}
					/>

					<FormSelect
						groupClassName='v__grid__item v__grid__item--col-12'
						label={t("staff")}
						isAsync
						asyncSearch
						isClearable
						useFetch={useDepoStaff}
						isGrouped
						queryConfig={{
							custom: {
								depo_id: filters.depo_id?.value,
								service_partner_id: filters.service_partner_id?.value,
								member_group_id: filters.member_group_id?.value,
								page: 0,
								per_page: 10,
							},
						}}
						groupParse={{ label: "title", options: "staff" }}
						optionsParse={{ value: "id", label: "full_name" }}
						value={filters.user_id}
						onChange={(option) =>
							onFilter?.({
								user_id: option,
							})
						}
					/>
				</div>
			</div>
		</>
	);
};

export default PerformanceFilters;
