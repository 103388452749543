import { useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "../../utils";
import { TourCategoryApi } from "../../api";

export const tourCategoriesKeys = {
    all: () => ["tours_categories"],
    byId: (id) => [...tourCategoriesKeys.all(), id],
    byConfig: (config) => [...tourCategoriesKeys.all(), config],
};

export const useToursCategories = (config) => {
    const { custom, ...restConfig } = config;
    return useQuery(tourCategoriesKeys.byConfig(custom), () => TourCategoryApi.getToursCategories(custom), restConfig);
};

export const useTourCategory = (id, config) => {
    const { custom, ...restConfig } = config;
    return useQuery(tourCategoriesKeys.byId(id), () => TourCategoryApi.getTourCategory(id, custom), restConfig);
};

export const useCreateTourCategory = (config) => {
    const queryClient = useQueryClient();
    return useMutation(TourCategoryApi.createTourCategory, {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(tourCategoriesKeys.all());
        }, config?.onSuccess),
    });
};



export const useUpdateTourCategory = (config) => {
    const queryClient = useQueryClient();
    return useMutation(({ id, data }) => TourCategoryApi.updateTourCategory(id, data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(tourCategoriesKeys.all());
        }, config?.onSuccess),
    });
};

export const useTourCategoryDelete = (config) => {
    const queryClient = useQueryClient();
    return useMutation(async (data) => TourCategoryApi.deleteTourCategory(data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(tourCategoriesKeys.all());
        }, config?.onSuccess),
    });
};
