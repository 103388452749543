import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useReportsState } from "context";

import { useCompanies, useDepoStaff, useGroups, useMedia, useMediaQueries, useServicePartners } from "hooks";

import { Button, Collapsible, Icon } from "components";
import { FormSelect, SwitchGroup } from "components/forms";

import { INVOICE_GROUP_BY_TYPES, REPORTS_ACTION_TYPES } from "reducers/const";
import ReportsAdvancedFilters from "pages/Reports/ReportsAdvancedFilters";

const groupByOptions = (t) => [
    {
        value: INVOICE_GROUP_BY_TYPES.USER_ID,
        label: t("drivers"),
    },

    {
        value: INVOICE_GROUP_BY_TYPES.INVOICE_ID,
        label: t("invoices"),
    },
];


const InvoiceFilters = ({ title }) => {
    const { t } = useTranslation();
    const { fairplayId } = useParams();

    const { XXLARGE, MEDIUM } = useMediaQueries();
    const isXXL = useMedia(XXLARGE);
    const isMedium = useMedia(MEDIUM);

    const { state, dispatch } = useReportsState();

    // const [filtersToggled, setFiltersToggled] = useState(!isLarge);
    const [filtersToggled, setFiltersToggled] = useState(true);
    const [reportColFiltersModal, setReportColFiltersModal] = useState(false);


    return (
        <>
            <div className='v__page__header__content__title'>
                {isXXL ? (
                    <SwitchGroup
                        dark
                        small
                        options={groupByOptions(t)}
                        onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.GROUP_BY_SET, payload: option })}
                        activeOption={state.groupBy}
                    />
                ) : (
                    title
                )}
            </div>
            <div className='v__table__extras__actions'>

                <Link to={`/app/invoices/create`}>
                    <Button small bg status='danger' round={!isMedium}>
                        {isMedium ? t("createInvoice") : <Icon>add</Icon>}
                    </Button>
                </Link>
     
                <Button small bg round={!isMedium} status='accent' onClick={() => setFiltersToggled((prev) => !prev)}>
                    {isMedium ? t("filters") : <Icon>filter_alt</Icon>}
                </Button>


                <Button small bg round={!isMedium} status='darker' iconLeft={isMedium && <Icon>print</Icon>} onClick={() => window.print()}>
                    {isMedium ? t("print") : <Icon>print</Icon>}
                </Button>
            </div>

            <div className='v__table__extras__filters'>
                <Collapsible closed={filtersToggled} allowOverflow={true} className='body-no-pad no-mar'>
                    <div className='v__grid'>
                        <FormSelect
                            groupClassName='v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12'
                            label={t("depo")}
                            isAsync
                            useFetch={useCompanies}
                            isGrouped
                            isClearable
                            queryConfig={{ custom: { type: "delivery_daily", includes: ["depos"] } }}
                            groupParse={{ label: "title", options: "depos" }}
                            value={state.depo_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.DEPO_SET, payload: option })}
                        />

                        <FormSelect
                            groupClassName={cn("v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12", {
                                disabled: state.groupBy?.value === "depo_id",
                            })}
                            label={t("servicePartner")}
                            isAsync
                            asyncSearch
                            isClearable
                            useFetch={useServicePartners}
                            queryConfig={{ custom: { fairplay_id: fairplayId, depo_id: state.depo_id?.value, page: 0, per_page: 25 } }}
                            value={state.service_partner_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.SP_SET, payload: option })}
                        />

                        <FormSelect
                            groupClassName='v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12'
                            label={t("group")}
                            isAsync
                            asyncSearch
                            isClearable
                            useFetch={useGroups}
                            queryConfig={{ custom: { page: 0, per_page: 25 } }}
                            value={state.member_group_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.GROUP_SET, payload: option })}
                        />

                        <FormSelect
                            groupClassName={cn("v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12", {
                            })}
                            label={t("staff")}
                            isAsync
                            asyncSearch
                            isClearable
                            useFetch={useDepoStaff}
                            isGrouped
                            queryConfig={{
                                custom: {
                                    depo_id: state.depo_id?.value,
                                    service_partner_id: state.service_partner_id?.value,
                                    member_group_id: state.member_group_id?.value,
                                    page: 0,
                                    per_page: 25,
                                },
                            }}
                            groupParse={{ label: "title", options: "staff" }}
                            optionsParse={{ value: "id", label: "full_name" }}
                            value={state.user_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.USER_SET, payload: option })}
                        />
                    </div>
                </Collapsible>
            </div>

            {reportColFiltersModal && (
                <ReportsAdvancedFilters
                    state={state}
                    onClose={() => setReportColFiltersModal(false)}
                    onReset={() => {
                        dispatch({
                            type: REPORTS_ACTION_TYPES.ADVANCED_FILTER_VALUES_SET,
                            payload: null,
                        });
                        setReportColFiltersModal(false);
                    }}
                    onChange={(filter) => {
                        dispatch({
                            type: REPORTS_ACTION_TYPES.ADVANCED_FILTER_VALUES_SET,
                            payload: filter,
                        });
                        setReportColFiltersModal(false);
                    }}
                />
            )}
        </>
    );
};

export default InvoiceFilters;
