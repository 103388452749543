import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import PageWrapper from "../../components/util/PageWrapper";
import PageHeader from "../../components/util/PageHeader";
import PageContent from "../../components/util/PageContent";
import ReportsSection from "./ReportsSection";
import ProfileSection from "./ProfileSection";
import AppearanceSection from "./AppearanceSection";
import { TabCard, TabCardHeader, TabCardPanel } from "../../components/ui";

const Settings = () => {
	const { t } = useTranslation();

	const settingsOptions = useMemo(
		() => [
			{
				label: t("profile"),
				value: "profile",
			},
			{
				label: t("reports"),
				value: "reports",
			},
			{
				label: t("appearance"),
				value: "appearance",
			},
		],
		[t]
	);

	const [activeTabOption, setActiveTabOption] = useState(settingsOptions[0]);

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Settings</title>
			</Helmet>
			<PageHeader title={`${t("settings")}${" - " + activeTabOption.label}`} />
			<PageContent>
				<TabCard onTabSelect={(tabIndex) => setActiveTabOption(settingsOptions[tabIndex])}>
					<TabCardHeader options={settingsOptions} initialActive={0} />
					<TabCardPanel>
						<ProfileSection />
					</TabCardPanel>
					<TabCardPanel overflow={false}>
						<ReportsSection />
					</TabCardPanel>
					<TabCardPanel>
						<AppearanceSection />
					</TabCardPanel>
				</TabCard>
			</PageContent>
		</PageWrapper>
	);
};

export default Settings;
