import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useAuth } from "context";
import { StaffList, StepStaffList } from "modules";

import { PerformanceChartBox, PerformanceFilters, PerformanceSortBySwitch } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import CalendarFileUploader from "./CalendarFileUploader";
import DeliveryMapPoint from "./DeliveryMapPoint";

const Dashboard = () => {
	const { t } = useTranslation();
	const { userCan, userCurrentRole } = useAuth();

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Dashboard</title>
			</Helmet>
			<PageHeader title={t("dashboard")} clock />
			<PageContent>
				<div
					className={cn(
						"v__dashboard__layout",
						!userCan("view_staff") && "v__dashboard__layout--simplified",
						!userCan("upload_report") && "v__dashboard__layout--no-report-btn"
					)}
				>
					<DeliveryMapPoint />

					<CalendarFileUploader />

					{/* onSelect={(v) => console.log(v)} checked={{ 1230: true, 6750: true }} */}
					{userCan("view_staff") && userCurrentRole.name === "Depo" && <StepStaffList />}
					{userCan("view_staff") && userCurrentRole.name !== "Depo" && <StaffList />}

					<PerformanceChartBox
						filtersComponent={({ isLarge, ...props }) => (
							<>
								{isLarge && <PerformanceSortBySwitch {...props} xSmall />}
								<PerformanceFilters {...props} />
							</>
						)}
					/>
				</div>
			</PageContent>
		</PageWrapper>
	);
};

export default Dashboard;
