import { useEffect, useState } from "react";
import cn from "classnames";

import { useOnClickOutside } from "hooks";
import { Portal } from "components/util";
import { useTranslation } from "react-i18next";

const CommentCell = ({ value, containerId }) => {
	const { t } = useTranslation();

	const [elem, setElem] = useState(() => document.getElementById(containerId));

	useEffect(() => {
		if (!elem && document.getElementById(containerId)) {
			setElem(() => document.getElementById(containerId));
		}
	}, [elem, containerId]);

	const [showComment, setShowComment] = useState(false);

	const ref = useOnClickOutside(() => setShowComment(false));

	return (
		<div
			className='flex items-center w-full h-full'
			onClick={() => (Boolean(value) ? setShowComment((prev) => !prev) : {})}
			title={t("viewComment")}
		>
			<span className='truncate'>{value}</span>
			{elem && (
				<Portal element={elem}>
					<div
						ref={ref}
						className={cn(
							[
								"absolute top-10 left-1/2 -translate-x-1/2",
								"max-h-[60vh] overflow-y-auto border-2 max-w-sm z-10 p-4 w-full rounded shadow-2xl",
								"text-black border-v-accent bg-v-light-card",
								"dark:text-white dark:border-v-accent-dark dark:bg-v-dark-card",
							],
							showComment ? "block" : "hidden"
						)}
					>
						{value}
					</div>
				</Portal>
			)}
		</div>
	);
};

export default CommentCell;
