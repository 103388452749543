import { forwardRef } from "react";
import { Icon } from "components";
import { VirtualAsyncSelect } from ".";

function setDisabledOptions(data, value) {
    return data?.map((x) => {
        const isSelected = value?.find((y) => y.id === x.id);
        if (isSelected) {
            return { ...x, isDisabled: true };
        }
        return x;
    });
}

function MultiVirtualAsyncSelect(props, ref) {
    const { onChange, value, getValues, name, queryConfig = {}, values, initialValues, ...rest } = props;

    const handleOnChange = (option) => {
        const values = getValues(name);

        if (Array.isArray(values)) {
            return onChange([...values, option]);
        }

        return onChange([option]);
    };

    const handleOnDelete = (id) => {
        return () => onChange(getValues(name).filter((x) => x.id !== id));
    };

    const modifiedQueryConfig = {
        ...queryConfig,
        select: ({ data }) => {
            if (!rest.isGrouped) {
                return setDisabledOptions(data, value);
            }

            return data?.map((x) => {
                const optionsKey = rest.groupParse?.options ?? "data";
                return {
                    ...x,
                    [optionsKey]: setDisabledOptions(x[optionsKey], value),
                };
            });
        },
    };

    const printingValues = (value) ? value : initialValues;

    return (
        <>
            <VirtualAsyncSelect {...rest} queryConfig={modifiedQueryConfig} name={name} value={null} onChange={handleOnChange} ref={ref} />
            <div className='flex flex-wrap gap-1 pt-2'>
                {printingValues?.map((x) => {
                    const labelKey = rest.optionsParse?.label ?? "title";
                    return (
                        <div
                            key={x.id}
                            className='inline-flex items-center gap-1 rounded pl-2 bg-v-accent-dark text-white leading-tight overflow-hidden'
                        >
                            <span className='font-semibold text-xs'>{x[labelKey]}</span>
                            <button
                                className='hover:bg-v-danger focus:bg-v-danger grid place-content-center px-1 outline-none'
                                onClick={handleOnDelete(x.id)}
                            >
                                <Icon>clear</Icon>
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default forwardRef(MultiVirtualAsyncSelect);
