import { useCallback, useRef } from "react";
import { useEventListener } from ".";

const useOnClickOutside = (handler, config) => {
	const eventDirection = config?.dir ?? "up";
	const ref = useRef();

	const callback = useCallback(
		(e) => {
			if (!ref.current || ref.current.contains(e.target)) {
				return;
			}
			return handler?.(e);
		},
		[handler, ref]
	);

	useEventListener(`pointer${eventDirection}`, callback);

	return ref;
};

export default useOnClickOutside;
