import { useQuery } from "react-query";
import { PerformanceApi } from "../../api";

export const performanceKeys = {
	all: () => ["performance"],
	byConfig: (config) => [...performanceKeys.all(), config],
};

export const usePerformance = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(performanceKeys.byConfig(custom), () => PerformanceApi.getPerformance(custom), restConfig);
};
