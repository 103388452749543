import React from "react";

export const formatDate = (date) => {
	const formatted = new Date(date);
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	return (
		<>
			<span>
				{formatted.getDate()} {monthNames[formatted.getMonth()]} {formatted.getFullYear()}
			</span>
		</>
	);
};

export const formatModalDate = (dateInfo) => {
	const day = dateInfo.getDate() < 10 ? `0${dateInfo.getDate()}` : dateInfo.getDate();
	const month = dateInfo.getMonth() < 10 ? `0${dateInfo.getMonth()}` : dateInfo.getMonth();
	const year = dateInfo.getFullYear();
	return new Date(year, month, day);
};
