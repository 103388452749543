import { useQuery } from "react-query";
import { StatisticsApi } from "../../api";

export const statisticKeys = {
	all: () => ["stats"],
	byConfig: (config) => [...statisticKeys.all(), config],
};

export const useStatistic = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(statisticKeys.byConfig(custom), () => StatisticsApi.getStatisticsById(custom), restConfig);
};
