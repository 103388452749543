import axios from "./axios";

const api = {
	getFuels(params) {
		return axios
			.get(`/fuel`, {
				params,
			})
			.then(({ data }) => data);
	},

	getFuelById(id, params) {
		return axios
			.get(`/fuel/${id}`, {
				params,
			})
			.then(({ data }) => data);
	},

	getUploadedDates(params) {
		return axios
			.get(`/fuel/uploadedDates`, {
				params,
			})
			.then(({ data }) => data);
	},

	updateFuel(id, data) {
		return axios.post(`/fuel/${encodeURI(id)}/update`, formatData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
};

function formatData(data) {
	const formattedData = new FormData();

	formattedData.append("user_id", data.user_id?.value ?? "");

	return formattedData;
}

export default api;
