import { useTranslation } from "react-i18next";

const StaffCountCell = ({ cell }) => {
	const { t } = useTranslation();

	return (
		<>
			<p className='v__table__body__row__cell__main'>{cell.value}</p>
			<small className='sub-text'>
				{t("drivers")}: {cell.row.original?.staff_count}
			</small>
		</>
	);
};

export default StaffCountCell;
