import { useCallback } from "react";
import { StatisticsTable } from "components";
import { useDataTableGenerate } from "hooks";
import { useFairplayStatistics } from "hooks/query/fairplayManagement";

const InvoicesShowFairplayTable = ({ fairplay_id, groupBy, week, year, user_id }) => {


    //how to fire the query when the step is the right one
    const { tableProps, hasOverall } = useDataTableGenerate(useFairplayStatistics, null, {
        fairplay_id,
        user_id,
        groupBy,
        week,
        year
    });

    return <StatisticsTable {...tableProps} hasOverallDataRows={hasOverall} scrollToTopOnChange dark />;
};

export default InvoicesShowFairplayTable;
