import { useCallback, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";
import cn from "classnames";

import { useIssueCreate, useIssueDelete, useIssues } from "hooks";

import { Button, Card, FormDatePicker, Icon, Modal } from "components";
import { FormDropzone, FormSelect, FormTextarea } from "components/forms";
import { Avatar, Badge, LineLoader, Spinner } from "components/ui";

import { formatDate } from "components/util/DateFormatter";
import { validateFile } from "utils";

// [
//     { value: "ticket", label: "Ticket" },
//     { value: "early_time", label: "Early for work" },
//     { value: "late_time", label: "Late for work" },
//     { value: "safety", label: "Safety shoes" },
//     { value: "vis", label: "Without HiVis" },
//     { value: "key", label: "Without Keychain" },
//     { value: "smart", label: "Look smart" },
//     { value: "uniform", label: "Uniform" },
//     { value: "absence", label: "Absent from work" },
//     { value: "behavior", label: "Bad Behavior" },
//     { value: "due_parcel", label: "Due Parcel" },
// ]

const MAX_FILE_SIZE = process.env.REACT_APP_MAX_IMAGE_UPLOAD_SIZE_MB;

const TicketModal = ({ payload, onClose }) => {
	const { t } = useTranslation();

	const options = [
		{ value: "ticket", label: t("ticket") },
		{ value: "early_time", label: t("earlyForWork") },
		{ value: "late_time", label: t("lateForWork") },
		{ value: "very_late_time", label: t("veryLateForWork") },
		{ value: "absence", label: t("absentFromWork") },
		{ value: "safety", label: t("safetyShoes") },
		{ value: "vis", label: t("withoutHiVis") },
		{ value: "key", label: t("withoutKeychain") },
		{ value: "van", label: t("dirtyVan") },
		{ value: "uniform", label: t("uniform") },
		{ value: "behavior", label: t("badBehavior") },
		{ value: "no_debrief", label: t("noDebrief") },
		{ value: "due_parcel", label: t("dueParcel") },
		{ value: "bad_images", label: t("badImages") },
		{ value: "failed_ture", label: t("failedTure") },
		{ value: "smart", label: t("lookSmart") },
	];

	const parentRef = useRef();

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } = useIssues({
		custom: {
			user_id: payload.id,
			per_page: 20,
		},
		enabled: Boolean(payload.id),
	});

	const tickets = useMemo(() => {
		if (!data) {
			return [];
		}
		return data?.pages.map((page) => page.data).reduce((prev, curr) => [...prev, ...curr], []);
	}, [data]);

	const rowVirtualizer = useVirtual({
		size: tickets.length,
		estimateSize: useCallback(() => 50, []),
		parentRef,
	});

	useEffect(() => {
		const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

		if (!lastItem) {
			return;
		}

		if (lastItem.index >= tickets.length - 1 && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, fetchNextPage, tickets.length, isFetchingNextPage, rowVirtualizer.virtualItems]);

	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			user_id: payload.id,
			date: new Date(),
			type: options[0],
			description: "",
			image_path: "",
		},
	});

	const typeWatch = watch("type");

	const { mutate: deleteTicket } = useIssueDelete({
		onSuccess: (res) => {
			// TODO: add toast
			console.log(res);
		},
	});

	const { mutate: createTicket, isLoading } = useIssueCreate({
		onSuccess: (res) => {
			reset();
			// TODO: add toast
			console.log(res);
		},
	});

	return (
		<Modal onClose={onClose}>
			<Card className='v__modal v__card--overflow-able' header={t("addTicket")} dismissible={onClose}>
				<div className='v__ticket__user'>
					<Avatar user={payload} className='v__ticket__user__avatar' />
					<div className='v__ticket__user__details'>
						<p>{payload?.full_name}</p>
						<small>{payload?.email}</small>
					</div>
				</div>
				<form onSubmit={handleSubmit(createTicket)} className='v__grid mb-4'>
					<Controller
						render={({ field: { value, ...restField } }) => (
							<FormDatePicker
								groupClassName={cn("v__grid__item v__grid__item--col-12", errors?.date && "v__form__group--incorrect")}
								label={t("fields.date")}
								error={errors?.date && t(`fieldErrors.${errors?.date?.type}`)}
								selected={value}
								{...restField}
							/>
						)}
						control={control}
						name='date'
						defaultValue={new Date()}
						rules={{ required: true }}
					/>
					<Controller
						render={({ field }) => (
							<FormSelect
								options={options}
								preSelect
								label={t("fields.type")}
								groupClassName={cn("v__grid__item v__grid__item--col-12", errors?.type && "v__form__group--incorrect")}
								error={errors?.type && t(`fieldErrors.${errors?.type?.type}`)}
								{...field}
							/>
						)}
						control={control}
						name='type'
						defaultValue={options[0]}
						rules={{ required: true }}
					/>
					<Controller
						render={({ field }) => (
							<FormTextarea
								groupClassName={cn(
									"v__grid__item v__grid__item--col-12",
									errors?.description && "v__form__group--incorrect"
								)}
								error={errors?.description && t(`fieldErrors.${errors?.description?.type}`)}
								label={t("description")}
								{...field}
							/>
						)}
						control={control}
						name='description'
						defaultValue=''
					/>
					<Controller
						render={({ field: { onChange, ...rest } }) => (
							<FormDropzone
								groupClassName={cn(
									"v__grid__item v__grid__item--col-12",
									errors?.image_path && "v__form__group--incorrect"
								)}
								label={t("fields.file")}
								accept='image/png, image/jpeg'
								error={errors?.image_path && t(`fieldErrors.${errors?.image_path?.type}`)}
								onChange={(e) => onChange(e.target.files[0])}
								{...rest}
							/>
						)}
						control={control}
						name='image_path'
						defaultValue=''
						rules={{
							required: typeWatch?.value === options[0]?.value,
							validate: (v) => validateFile(v) || t("fieldErrors.maxFileSize", { sizeInMb: MAX_FILE_SIZE }),
						}}
					/>
					<div className='v__form__footer v__grid__item v__grid__item--col-12'>
						<Button bg status='darker' onClick={onClose}>
							{t("cancel")}
						</Button>
						<Button bg status='accent' type='submit' iconRight={isLoading ? <Spinner size={16} singleColor /> : null}>
							{t("upload")}
						</Button>
					</div>
				</form>
				<hr />
				{(isFetchingNextPage || isFetching) && <LineLoader />}
				<div className='h-48 overflow-y-auto mt-4' ref={parentRef}>
					<div
						className='relative'
						style={{
							height: `${rowVirtualizer.totalSize}px`,
						}}
					>
						{rowVirtualizer.virtualItems.map((virtualRow) => {
							const child = tickets[virtualRow.index];
							return (
								<div
									key={virtualRow.index}
									ref={virtualRow.measureRef}
									className='absolute top-0 left-0 w-full'
									style={{
										height: `${child}px`,
										transform: `translateY(${virtualRow.start}px)`,
									}}
								>
									<TicketItem ticket={child} options={options} onDelete={deleteTicket} />
								</div>
							);
						})}
						{rowVirtualizer.virtualItems.length === 0 && <div className='text-center pad-xy-2'>{t("common.noData")}</div>}
					</div>
				</div>
			</Card>
		</Modal>
	);
};

function TicketItem({ ticket, options, onDelete }) {
	return (
		<div className='v__ticket__list__item pb-2'>
			{ticket.full_image_path ? (
				<a href={ticket.full_image_path} target='_blank' rel='noopener noreferrer'>
					<img src={ticket.full_image_path} alt='' className='w-full h-full object-cover' />
				</a>
			) : (
				<Icon className='v__ticket__item__img__placeholder'>image</Icon>
			)}
			<div className='v__ticket__list__item__data'>
				<div className='v__ticket__list__item__data__status'>
					{formatDate(ticket.date)}{" "}
					<Badge type='warning' size='md'>
						{options.find((x) => x.value === ticket.type)?.label ?? ""}
					</Badge>
				</div>
				<p>{ticket?.description}</p>
			</div>
			<Button round small hoverStatus='danger' onClick={() => onDelete(ticket.id)}>
				<Icon>delete</Icon>
			</Button>
		</div>
	);
}

export default TicketModal;
