import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import cn from "classnames";

import { Avatar } from "../../ui";

const AvatarCell = ({ cell, redirect }) => {
	const v = cell.row.original;

	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className='v__table__body__row__cell__avatar__container'>
			<Avatar user={v.image_path ? { avatar: v.image_path } : v.owner ? v?.owner : v?.full_name && v} />
			<div className='v__table__body__row__cell__avatar__container__info'>
				<p
					className={cn("avatar__container__info__main", {
						"avatar__container__info__main--clickable": redirect,
					})}
					onClick={() => (redirect ? navigate(redirect(v)) : null)}
				>
					{v.full_name ?? v.title}
				</p>
				{cell.column.secondaryData === "owner.full_name" && (
					<Link className='sub-text sub-text-hoverable' to={`/app/users/${v.owner?.id}`}>
						{v.owner?.full_name}
					</Link>
				)}
				{cell.column.secondaryData === "online_status" && (
					<small className='sub-text'>• {t("unavailable")}</small>
				)}
			</div>
		</div>
	);
};

export default AvatarCell;
