import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CountUp from "react-countup";

import { useProfileView } from "context/ViewContext";

import { useWindowSize } from "hooks";
import { useStatistic } from "hooks/query";

import { Button, Icon } from "components";
import { Modal } from "components/Modal";
import { FormSelect } from "components/forms";
import { Avatar, LineLoader } from "components/ui";
import { SimpleCollapse } from "components/animations";

import DepoIcon from "./DepoIcon";
import SPIcon from "./SPIcon";
import StaffModal from "./StaffModal";
import { PrintAccountType } from "pages/Reports/ReportsPrintFilterState";

const ViewAllStats = ({ currentAccount, accountType }) => {
	const { width } = useWindowSize();
	const { t } = useTranslation();

	const {
		overviewValue: { overviewYearViewingOption, setOverviewYearViewingOption },
	} = useProfileView();

	const profileSectionRef = useRef();
	const statsFinishedAnimating = useRef(false);

	const [mainStatsMinHeight, setMainStatsMinHeight] = useState(0);
	const [otherStatsToggled, setOtherStatsToggled] = useState(false);
	const [staffModal, setStaffModal] = useState({ state: false, payload: null });

	const finishedAnimating = () => (statsFinishedAnimating.current = true);

	const closeGroupModal = () => setStaffModal({ state: false, payload: null });

	const parseWorkingYears = useCallback(() => {
		const yearsWithFiles = currentAccount?.workingYears ?? [];
		const allYears = [...yearsWithFiles, new Date().getFullYear()];

		const workingYears = [...new Set(allYears)];
		let years = workingYears.reverse();

		for (let i = 0; i < workingYears.length; i++) {
			years[i] = {
				value: workingYears[i],
				label: workingYears[i],
			};
		}
		return years.reverse();
	}, [currentAccount]);

	const [yearOptions, setYearOptions] = useState(() => parseWorkingYears());

	const { data, isFetching } = useStatistic({
		custom: {
			id: currentAccount?.id,
			type: accountType?.replace("_id", ""),
			year: overviewYearViewingOption?.value ?? "",
		},
		enabled: Boolean(currentAccount?.id),
	});

	useEffect(() => {
		if (currentAccount && accountType) {
			setMainStatsMinHeight(profileSectionRef.current?.getBoundingClientRect()?.height);
			setYearOptions(() => parseWorkingYears());
		}
	}, [currentAccount, accountType, overviewYearViewingOption, parseWorkingYears]);

	const stats = useMemo(() => {
		if (!data) return [];

		statsFinishedAnimating.current = false;
		return Object.entries(data.data)
			.map(
				(stat) =>
					!stat[0].includes("division") && {
						info: data.keys.find((key) => key.accessor === stat[0]),
						value: stat[1],
					}
			)
			.filter((entry) => !!entry.info && !!entry.value);
	}, [data]);

	const statTypeIcon = (statType) => {
		if (statType === "golden_five") {
			return <Icon>military_tech</Icon>;
		}
		if (statType === "scores") {
			return <Icon>grade</Icon>;
		}
		if (statType === "rating") {
			return <Icon className='text-gold'>emoji_events</Icon>;
		}
		return null;
	};

	useEffect(() => {
		setMainStatsMinHeight(profileSectionRef.current?.getBoundingClientRect()?.height);
	}, [width]);

	return (
		<>
			<PrintAccountType currentAccount={currentAccount} accountType={accountType} />
			<p className='screen-hide print-title text-center'>{t("mainStats")}</p>
			<table className='screen-hide print-main-stats print-only-table'>
				<thead>
					<tr>
						<th>{t("abbreviation")}</th>
						<th>{t("description")}</th>
						<th>{t("value")}</th>
					</tr>
				</thead>
				<tbody>
					{stats.slice(0, 7).map((stat) => (
						<tr key={Math.random()}>
							<td>
								{statTypeIcon(stat.info.type)}
								{stat.info.title}
							</td>
							<td>{stat.info.description}</td>
							<td>{stat.value}</td>
						</tr>
					))}
				</tbody>
			</table>
			<p className='screen-hide print-title text-center'>{t("otherStats")}</p>
			<table className='screen-hide print-other-stats print-only-table'>
				<thead>
					<tr>
						<th>{t("abbreviation")}</th>
						<th>{t("description")}</th>
						<th>{t("value")}</th>
					</tr>
				</thead>
				<tbody>
					{stats.slice(8, stats.length).map((stat) => (
						<tr key={Math.random()}>
							<td>
								{statTypeIcon(stat.info.type)}
								{stat.info.title}
							</td>
							<td>{stat.info.description}</td>
							<td>{stat.value}</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className='v__view__stats print-hide'>
				<div className='v__view__stats__banner'>
					<div className='v__view__stats__banner__profile' ref={profileSectionRef}>
						<ProfileSection
							accountType={accountType}
							currentAccount={currentAccount}
							yearOptions={yearOptions}
							onYearSelect={setOverviewYearViewingOption}
							onMemberClick={() =>
								setStaffModal({
									state: true,
									payload: {
										currentAccount,
										accountType,
									},
								})
							}
						/>
					</div>
					<div className='v__view__stats__banner__main' style={{ minHeight: width > 1200 ? `${mainStatsMinHeight}px` : "auto" }}>
						<StatsGrid
							isLoading={isFetching}
							stats={stats}
							statTypeIcon={statTypeIcon}
							statsFinishedAnimating={statsFinishedAnimating}
							finishedAnimating={finishedAnimating}
						/>
					</div>
				</div>
				{stats.length > 0 && (
					<Button bg hoverStatus='darker' small onClick={() => setOtherStatsToggled((prev) => !prev)}>
						{t("show")} {otherStatsToggled ? t("less") : t("more")} {t("stats")}
					</Button>
				)}

				<SimpleCollapse in={otherStatsToggled}>
					<div className='v__view__stats__others'>
						{stats.slice(8, stats.length).map((stat) => (
							<div key={Math.random()} className='v__view__stats__others__item'>
								<div>
									<p className='v__view__stats__others__item__title'>
										{statTypeIcon(stat.info.type)} {stat.info.title}
									</p>
									<small>{stat.info.description}</small>
									<h4 className='v__view__stats__others__item__value'>{stat.value}</h4>
								</div>
							</div>
						))}
					</div>
				</SimpleCollapse>
			</div>
			{staffModal.state && (
				<Modal onClose={closeGroupModal}>
					<StaffModal onClose={closeGroupModal} payload={staffModal.payload} />
				</Modal>
			)}
		</>
	);
};

const StaffLimited = ({ onClick, accountType, currentAccount }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const avatarMap = ({ users, sp = false }) => (
		<>
			{users?.map((member) => (
				<Avatar
					user={sp ? null : member}
					title={sp ? member?.title : member?.full_name}
					key={Math.random()}
					onClick={() => (sp ? navigate(`/app/partners/${member?.id}`) : navigate(`/app/users/${member?.id}`))}
				/>
			))}
		</>
	);

	const usersLeft = (usersLeft) =>
		usersLeft && (
			<div className='staff__members__left' onClick={onClick}>
				+ {usersLeft} {t("more")}
			</div>
		);

	return (
		<>
			<div className='staff__members'>
				<p>{accountType === "depo_id" ? t("servicePartners") : t("drivers")}</p>
				{(currentAccount?.membersLimited?.length === 0 ||
					currentAccount?.staff?.length === 0 ||
					currentAccount?.servicePartners?.length === 0 ||
					!currentAccount) &&
					t("noResults")}
				{avatarMap({
					users:
						accountType === "group_id"
							? currentAccount?.membersLimited
							: accountType === "service_partner_id"
							? currentAccount?.staff?.slice(0, 5)
							: accountType === "depo_id"
							? currentAccount?.servicePartners?.slice(0, 5)
							: [],
					sp: accountType === "depo_id",
				})}
				{usersLeft(
					accountType === "group_id" && currentAccount?.members_count - currentAccount?.membersLimited?.length > 0
						? currentAccount?.members_count - currentAccount?.membersLimited?.length
						: accountType === "service_partner_id" && currentAccount?.staff?.length > 5
						? currentAccount?.staff?.length - 5
						: accountType === "depo_id" && currentAccount?.servicePartners?.length > 5
						? currentAccount?.servicePartners?.length - 5
						: null
				)}
			</div>
		</>
	);
};

const Connections = ({ currentAccount }) => {
	const { t } = useTranslation();

	return (
		<div className='user__connections'>
			<div>
				<div className='user__connections__item user__sps'>
					<p>{t("servicePartners")}</p>
					{(currentAccount?.service_partners?.length === 0 || !currentAccount) && t("noResults")}
					{currentAccount?.service_partners
						?.map((sp) => (
							<Link key={Math.random()} to={`/app/partners/${sp.id}`}>
								{sp.title}
							</Link>
						))
						.reduce((acc, el) => (acc === null ? [el] : [...acc, ", ", el]), null)}
				</div>
			</div>
			<div>
				<div className='user__connections__item user__depos'>
					<p>{t("depos")}</p>
					{(currentAccount?.joined_depos?.length === 0 || !currentAccount) && t("noResults")}
					{currentAccount?.joined_depos
						?.map((depo) => (
							<Link key={Math.random()} to={`/app/depo/${depo.id}`}>
								{depo.title}
							</Link>
						))
						.reduce((acc, el) => (acc === null ? [el] : [...acc, ", ", el]), null)}
				</div>
			</div>
			<div>
				<div className='user__connections__item user__group'>
					<p>{t("group")}</p>
					{currentAccount?.group ? (
						<Link to={`/app/groups/${currentAccount?.group?.id}`}>{currentAccount?.group?.title}</Link>
					) : (
						t("noResults")
					)}
				</div>
			</div>
		</div>
	);
};

const StatsGrid = ({ isLoading, stats, statTypeIcon, statsFinishedAnimating, finishedAnimating }) => {
	const extractValue = (value) => {
		if (value.includes("%")) {
			return (
				<CountUp
					duration={1}
					decimals={2}
					start={statsFinishedAnimating.current ? Number(value.replace("%", "")) : 0}
					end={Number(value.replace("%", ""))}
					suffix={"%"}
					onEnd={finishedAnimating}
				/>
			);
		}
		if (value.includes(",")) {
			return (
				<CountUp
					duration={1}
					separator={","}
					start={statsFinishedAnimating.current ? Number(value.replaceAll(",", "")) : 0}
					end={Number(value.replaceAll(",", ""))}
					onEnd={finishedAnimating}
				/>
			);
		}
		if (value.includes(".")) {
			return (
				<CountUp
					duration={1}
					decimals={2}
					start={statsFinishedAnimating.current ? Number(value) : 0}
					end={Number(value)}
					onEnd={finishedAnimating}
				/>
			);
		}
		return (
			<CountUp
				duration={1}
				start={statsFinishedAnimating.current ? Number(value) : 0}
				end={Number(value)}
				onEnd={finishedAnimating}
			/>
		);
	};

	if (isLoading) {
		return (
			<div className='v__view__stats__banner__main__loader text-center w-100'>
				<LineLoader />
			</div>
		);
	}

	return stats.slice(0, 7).map((stat) => (
		<div key={Math.random()} className='v__view__stats__banner__main__item'>
			<div>
				<p className='v__view__stats__banner__main__item__title'>
					{statTypeIcon(stat.info.type)}
					{stat.info.title}
				</p>
				<small>{stat.info.description}</small>
				<h2 className='text-2xl font-bold'>{extractValue(stat.value)}</h2>
			</div>
		</div>
	));
};

const ProfileSection = ({ accountType, currentAccount, onMemberClick, onYearSelect, yearOptions }) => {
	const {
		overviewValue: { overviewYearViewingOption },
	} = useProfileView();
	const { t } = useTranslation();

	return (
		<>
			<div className='v__view__stats__banner__profile__info'>
				<div className='v__view__stats__banner__profile__info__avatar'>
					{(accountType === "user_id" || accountType === "group_id") && (
						<Avatar
							user={accountType === "user_id" ? currentAccount : null}
							imgUrl={accountType === "group_id" ? currentAccount?.image_path : null}
						/>
					)}
					{accountType === "depo_id" && <DepoIcon className='depo__icon' />}
					{accountType === "service_partner_id" && <SPIcon className='sp__icon' />}
				</div>
				<div className='v__view__stats__banner__profile__info__details'>
					<h2 className='text-2xl'>{accountType === "user_id" ? currentAccount?.full_name : currentAccount?.title}</h2>
					{currentAccount && (
						<FormSelect
							id='year_value'
							small
							value={yearOptions?.find((item) => item.value === overviewYearViewingOption?.value)}
							withVirtualization={false}
							withLabel={false}
							searchable={false}
							placeholder={`${t("all")} ${t("years")}`}
							onChange={onYearSelect}
							options={yearOptions}
						/>
					)}
				</div>
			</div>
			<div className='v__view__stats__banner__profile__ranking'>
				<div className='v__view__stats__banner__profile__ranking__week'>
					<Icon className='alltime__rank__icon'>emoji_events</Icon>
					<h1>{currentAccount?.current_division.icon ?? "🙉"}</h1>
					<p>{currentAccount?.current_division.name ?? t("unavailable")}</p>
					<small>
						{t("current")} {t("division")}
					</small>
				</div>
				<div className='v__view__stats__banner__profile__ranking__separator'></div>
				<div className='v__view__stats__banner__profile__ranking__month'>
					{currentAccount?.divisions.map((item) => (
						<p key={item.year}>
							<span>{item.icon}</span> {item.year}
						</p>
					))}
				</div>
			</div>

			<div className='v__view__stats__banner__profile__relationships'>
				{accountType !== "user_id" && (
					<StaffLimited accountType={accountType} currentAccount={currentAccount} onClick={onMemberClick} />
				)}
				{accountType === "user_id" && <Connections currentAccount={currentAccount} />}
			</div>
		</>
	);
};

export default ViewAllStats;
