import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { mergeRefs } from "../../utils";
import VirtualSelect from "./VirtualSelect";
import { useDebounce } from "hooks";

const filterOption = (opt, _v) => {
	if (opt.value === "async") {
		return true;
	}
	return true;
};

const parseOptions = (arr, parser) =>
	arr.map((v) => ({
		value: v[parser.value],
		label: v[parser.label],
		...v,
	}));

const parseGroup = (arr, parser, optionsParser) =>
	arr.map((v) => ({
		label: (
			<div className='v__select__group__separator'>
				<span className='v__select__group__separator__label'>{v[parser.label]}</span>
				<div className='v__select__group__separator__results'>{parseOptions(v[parser.options], optionsParser).length}</div>
			</div>
		),
		options: parseOptions(v[parser.options], optionsParser),
	}));

const getFirstOption = (options) => {
	if (options[0]?.options && options[0]?.options?.length > 0) {
		return options[0].options[0];
	}
	return options?.[0];
};

const VirtualAsyncSelect = forwardRef((props, ref) => {
	const {
		useFetch,
		minSearchLength = 2,
		preFetch,
		preSelect,
		asyncSearch,
		searchKey = "search",
		isGrouped,
		queryConfig,
		optionsParse = { value: "id", label: "title" },
		groupParse = { label: "title", options: "data" },
		options,
		...rest
	} = props;

	const { t } = useTranslation();

	const selectRef = useRef();
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);
	const cantSearch = search.length !== 0 && search.length <= minSearchLength;

	const innerQueryConfig = Boolean(asyncSearch)
		? {
				[searchKey]: debouncedSearch,
		  }
		: {};

	const { custom, ...queryConfigProp } = queryConfig;
	const { data, refetch, isFetching, isFetched, isStale } = useFetch({
		enabled: Boolean(preFetch),
		...queryConfigProp,
		custom: {
			...custom,
			...innerQueryConfig,
		},
	});

	useEffect(() => {
		if (debouncedSearch.length > minSearchLength && Boolean(asyncSearch) && isStale) {
			refetch();
		}
	}, [debouncedSearch, minSearchLength, asyncSearch, refetch, isStale]);

	const isLoading = isFetching && !isFetched;

	const parsedOptions = useMemo(() => {
		if (!data) return [];
		const fetchedData = data?.data ?? data;
		if (Boolean(isGrouped) && fetchedData.filter((entry) => entry?.[groupParse.options]).length > 0) {
			return parseGroup(fetchedData, groupParse, optionsParse);
		}
		return parseOptions(fetchedData, optionsParse);
	}, [data, groupParse, optionsParse, isGrouped]);

	const _options = useMemo(() => {
		if (parsedOptions.length > 0) {
			const remainingItems = Number(data?.meta?.total - parsedOptions.length);
			const remainingOption = {
				label: <span className='text-accent-darker'>{t("moreResults", { value: remainingItems })}</span>,
				value: "__moreResults__",
				isDisabled: true,
			};
			const remainingGroupedOption = {
				label: "",
				options: [remainingOption],
			};

			const renderResultsLeftOption = () => {
				if (isNaN(remainingItems) || remainingItems <= 0) {
					return null;
				}
				return isGrouped ? remainingGroupedOption : remainingOption;
			};

			if (cantSearch && asyncSearch) {
				return [{ label: t("minLength", { value: minSearchLength }), value: "async", isDisabled: true }].filter((v) => v);
			}
			return [...parsedOptions, renderResultsLeftOption()].filter((v) => v);
		}
		return [];
	}, [parsedOptions, minSearchLength, cantSearch, asyncSearch, isGrouped, data?.meta?.total, t]);

	useEffect(() => {
		const select = selectRef.current;
		if (parsedOptions.length > 0 && preSelect && select && select.getValue().length === 0) {
			select.setValue(getFirstOption(parsedOptions));
		}
	}, [parsedOptions, preSelect]);

	if (preSelect && !preFetch) throw new Error("Can't use preSelect without preFetch");

	const innerSelectProps = Boolean(asyncSearch)
		? {
				filterOption,
		  }
		: {};

	return (
		<VirtualSelect
			onInputChange={(v) => setSearch(v)}
			isLoading={isLoading}
			isSearchable
			options={_options}
			onFocus={() => !Boolean(preFetch) && isStale && refetch()}
			{...innerSelectProps}
			{...rest}
			ref={mergeRefs([selectRef, ref])}
		/>
	);
});

VirtualAsyncSelect.propTypes = {
	useFetch: PropTypes.func.isRequired,
	minSearchLength: PropTypes.number,
	preFetch: PropTypes.bool,
	preSelect: PropTypes.bool,
	asyncSearch: PropTypes.bool,
	searchKey: PropTypes.string,
	isGrouped: PropTypes.bool,
	queryConfig: PropTypes.object,
	optionsParse: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	groupParse: PropTypes.shape({
		label: PropTypes.string,
		options: PropTypes.string,
	}),
};

export default VirtualAsyncSelect;
