import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/util/PageWrapper";
import { Icon } from "../components";

const NotFound = () => {
	const { t } = useTranslation();

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Oops...</title>
			</Helmet>
			<div className='v__page__missing'>
				<div className='v__page__missing__graphic'>
					<span className='v__page__missing__graphic__code'>4</span>
					<Icon>search</Icon>
					<span className='v__page__missing__graphic__code'>4</span>
				</div>
				<span className='v__page__missing__description'>{t("notFound")}</span>
			</div>
		</PageWrapper>
	);
};

export default NotFound;
