import React from "react";
import cn from "classnames";
import DragContainer from "./DragContainer";

const BadgeList = ({ disableScroll = false, wrapable = false, children, className, ...rest }) => {
	if (wrapable) {
		return (
			<div className={cn("v__badge__list v__badge__list--wrapable", className)} {...rest}>
				{children}
			</div>
		);
	} else {
		return (
			<DragContainer
				disableScroll={disableScroll}
				className={cn("v__badge__list", className)}
				scrollContainerClassName={cn("v__badge__list__container")}
				{...rest}>
				{children}
			</DragContainer>
		);
	}
};

export default BadgeList;
