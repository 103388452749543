import { forwardRef } from "react";
import cn from "classnames";

const ButtonGroup = forwardRef((props, ref) => {
	const { className, children, ...rest } = props;

	const classBase = "v__btn-group";

	return (
		<div role='group' aria-label='button group' tabIndex={-1} className={cn(classBase, className)} {...rest} ref={ref}>
			{children}
		</div>
	);
});

export default ButtonGroup;
