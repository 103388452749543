import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import cn from "classnames";

import { useWindowSize } from "hooks";
import { Fade, Icon } from "..";

const DragContainer = ({ disableScroll, children, scrollContainerClassName, className, ...rest }) => {
	const { width } = useWindowSize();
	const scrollListRef = useRef();
	const [atEndOfScroll, setAtEndOfScroll] = useState(false);
	const [atBeginingOfScroll, setAtBeginingOfScroll] = useState(true);
	const [hasScroll, setHasScroll] = useState(false);

	useEffect(() => {
		if (scrollListRef.current) {
			const containerScrollWidth = scrollListRef.current.container.current.scrollWidth;
			const containerWidth = scrollListRef.current.container.current.offsetWidth;

			setHasScroll(containerScrollWidth > containerWidth);
		}
	}, [scrollListRef, width]);

	const ScrollIndicator = ({ reverse }) => {
		const isVisible = () => {
			if (hasScroll) {
				if (reverse && !atBeginingOfScroll) {
					return true;
				}
				if (!reverse && !atEndOfScroll) {
					return true;
				}
			}
			return false;
		};

		return (
			<Fade in={isVisible()}>
				<div
					className={cn("v__drag__list__scroll-helper", {
						"v__drag__list__scroll-helper--reverse": reverse,
					})}
					onClick={() => {
						const containerWidth = scrollListRef.current.container.current.offsetWidth;
						let scrolled = scrollListRef.current.container.current.scrollLeft;
						if (reverse) {
							scrollListRef.current.container.current.scrollTo({
								top: 0,
								left: scrolled < containerWidth ? 0 : (scrolled -= containerWidth - 150),
								behavior: "smooth",
							});
						} else {
							scrollListRef.current.container.current.scrollTo({
								top: 0,
								left: Math.max((scrolled += containerWidth - 150), 0),
								behavior: "smooth",
							});
						}
					}}
				>
					<Icon>{reverse ? "chevron_left" : "chevron_right"}</Icon>
				</div>
			</Fade>
		);
	};

	return (
		<div className={cn("v__drag__list", className)} {...rest}>
			<ScrollIndicator reverse />
			<ScrollContainer
				className={cn(
					"v__drag__list__container",
					{
						"no-pointer-events": disableScroll,
					},
					scrollContainerClassName
				)}
				ref={scrollListRef}
				onScroll={(scrolled) => {
					if (scrollListRef.current) {
						const containerScrollWidth = scrollListRef.current.container.current.scrollWidth;
						const containerWidth = scrollListRef.current.container.current.offsetWidth;
						if (containerWidth + scrolled < containerScrollWidth) {
							setAtEndOfScroll(false);
							setAtBeginingOfScroll(false);
						}
						if (containerScrollWidth - 10 < containerWidth + scrolled) {
							setAtEndOfScroll(true);
						}
						if (scrolled < 10) {
							setAtBeginingOfScroll(true);
						}
					}
				}}
			>
				{React.Children.map(children, (child, idx) => {
					if (idx === React.Children.count(children) - 1) {
						return <div style={{ paddingRight: "1px" }}>{child}</div>;
					} else {
						return child;
					}
				})}
			</ScrollContainer>
			<ScrollIndicator />
		</div>
	);
};

export default DragContainer;
