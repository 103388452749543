import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";

import { useLocalStorage, useMedia, useMediaQueries } from "../../hooks";
import { FormCheckbox } from "../forms";

const colors = ["#218332", "#6060cc", "#f17e12", "#d53b49", "#2774ae", "#f66d9b"];

const valueFormatter = ({ value, item }) => {
	if (item.value_type === "percentage") {
		return `${value.toFixed(2)}%`;
	} else if (item.value_type === "count") {
		if (value > 999 && value < 1000000) {
			const result = (value / 1000).toFixed(1) + "k";
			return result.toString().replace(".", ",");
		} else if (value > 1000000) {
			const result = (value / 1000000).toFixed(1) + "M";
			return result.toString().replace(".", ",");
		} else {
			return value.toFixed(2);
		}
	} else if (item.value_type === "rating") {
		return value.toFixed(2);
	}
};

const lightValueFormatter = ({ value, item }) => {
	if (value !== undefined) {
		if (item.value_type === "percentage") {
			return `${value.toFixed(2)}%`;
		} else if (item.value_type === "count") {
			return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} else if (item.value_type === "rating") {
			return value.toFixed(2);
		}
	}
};

const PerformanceChart = ({ fetchedData, labels, onSeriesClick = null, className }) => {
	const { t } = useTranslation();

	const [storedLabeledChart, setStoredLabeledChart] = useLocalStorage("labeledPerformanceChart", false);

	const { SMALL } = useMediaQueries();
	const isSmall = useMedia(SMALL);

	const [withDataLabels, setWithDataLabels] = useState(
		isSmall && storedLabeledChart ? true : isSmall && !storedLabeledChart ? false : !isSmall ? false : false
	);

	const chartInfo = useMemo(
		() => ({
			series: fetchedData,
			options: {
				chart: {
					fontFamily: `'Roboto', sans-serif`,
					parentHeightOffset: 0,
					toolbar: {
						show: false,
					},
					events: {
						markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) =>
							onSeriesClick?.({
								event,
								chartContext,
								dataPointIndex,
							}),
					},
					brush: {
						enabled: false,
					},
					selection: {
						enabled: false,
					},
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: isSmall ? withDataLabels : false,
					textAnchor: "middle",
					offsetX: 0,
					offsetY: -5,
					formatter: (value, { seriesIndex }) => {
						return valueFormatter({
							value,
							item: fetchedData[seriesIndex],
						});
					},
					style: {
						fontSize: "12px",
						fontFamily: `'Roboto', sans-serif`,
						fontWeight: "bold",
					},
					background: {
						enabled: true,
						foreColor: "transparent",
						padding: 4,
						borderRadius: 5,
						borderWidth: 0,
						opacity: 1,
						dropShadow: {
							enabled: false,
						},
					},
					dropShadow: {
						enabled: false,
					},
				},
				legend: {
					show: true,
					position: isSmall ? "right" : "top",
					horizontalAlign: "center",
					fontSize: "12px",
					fontFamily: `'Roboto', sans-serif`,
					formatter: (_seriesName, opts) => {
						return [
							fetchedData[opts.seriesIndex]?.short_name,
							`( ${valueFormatter({
								value: Number(fetchedData[opts.seriesIndex]?.constraints?.min),
								item: fetchedData[opts.seriesIndex],
							})} |  ${valueFormatter({
								value: Number(fetchedData[opts.seriesIndex]?.constraints?.max),
								item: fetchedData[opts.seriesIndex],
							})} )`,
						];
					},
				},
				grid: {
					show: true,
					position: "back",
					xaxis: {
						lines: {
							show: false,
						},
					},
					yaxis: {
						lines: {
							show: true,
						},
					},
					padding: {
						top: 0,
						right: 10,
						bottom: 0,
						left: -30,
					},
				},
				noData: {
					text: t("noData"),
					align: "center",
					verticalAlign: "middle",
					offsetX: 0,
					offsetY: -25,
					style: {
						fontSize: "16px",
						fontFamily: `'Poppins', sans-serif`,
					},
				},
				labels: labels,
				stroke: {
					curve: "smooth",
					width: 2,
					lineCap: "round",
				},
				fill: {
					opacity: 1,
					type: "gradient",
					gradient: {
						enabled: true,
						opacityFrom: 0,
						opacityTo: 0,
					},
				},
				markers: {
					size: 3,
					strokeColors: "transparent",
					strokeWidth: 2,
					strokeOpacity: 1,
					shape: "circle",
					hover: {
						size: 4,
						sizeOffset: 0,
					},
				},
				colors,
				tooltip: {
					enabled: true,
					y: {
						formatter: (value, { seriesIndex }) => {
							return lightValueFormatter({
								value,
								item: fetchedData[seriesIndex],
							});
						},
					},
				},
				xaxis: {
					tooltip: {
						enabled: false,
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: true,
					},
				},
				yaxis: fetchedData.map((series) => ({
					tickAmount: 4,
					min:
						Number(series.constraints.min) === Number(series.constraints.max) && Number(series.constraints.min) > 0
							? Number(series.constraints.min) - 1
							: Number(series.constraints.min),
					max:
						Number(series.constraints.max) === Number(series.constraints.min) && Number(series.constraints.max) < 1
							? Number(series.constraints.max) + 1
							: Number(series.constraints.max),
					labels: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				})),
			},
		}),
		[fetchedData, t, labels, onSeriesClick, withDataLabels, isSmall]
	);

	useEffect(() => {
		setWithDataLabels((prev) => (storedLabeledChart ? true : !storedLabeledChart ? false : prev));
	}, [isSmall, storedLabeledChart]);

	return (
		<div className={className}>
			{isSmall && (
				<FormCheckbox
					withLabel={false}
					groupClassName='chart__config no-margin print-hide'
					id='performance_chart_labels_enabled'
					checkboxLabel={t("showLables")}
					checked={withDataLabels}
					onChange={(val) => {
						setStoredLabeledChart(val);
						setWithDataLabels(val);
					}}
				/>
			)}
			<Chart options={chartInfo.options} series={chartInfo.series} type='area' height={300} className='print-hide' />
		</div>
	);
};

export default PerformanceChart;
