import { forwardRef, useEffect, useMemo, useState } from "react";
import cn from "classnames";

import { Collapse, Icon } from ".";

const Collapsible = forwardRef(
	({ children, heading, closed = true, allowOverflow, className, merged = false, blended = false, ...rest }, ref) => {
		const [collapsed, setCollapsed] = useState(closed);
		const [overflowable, setOverflowable] = useState(allowOverflow ? allowOverflow : false);

		useMemo(() => {
			if (collapsed && allowOverflow) {
				setOverflowable(false);
			} else if (collapsed && !allowOverflow) {
				setOverflowable(false);
			} else if (!collapsed && allowOverflow) {
				setOverflowable(true);
			} else if (!collapsed && !allowOverflow) {
				setOverflowable(false);
			}
			//eslint-disable-next-line
		}, [collapsed]);

		useEffect(() => {
			setCollapsed(closed);
		}, [closed]);

		const toggleCollapsible = (e) => {
			if (e.target.classList.contains("v__collapsible__header") || e.target.classList.contains("v__collapsible__header__container")) {
				setCollapsed(!collapsed);
			}
		};

		return (
			<div
				className={cn(
					`v__collapsible ${merged ? "v__collapsible--merged" : ""} ${blended ? "v__collapsible--blended" : ""}`,
					className
				)}
				{...rest}
				data-collapsed={collapsed ? "true" : "false"}
				data-overflowable={overflowable ? "true" : "false"}
				ref={ref}>
				{heading && (
					<div className='v__collapsible__header' onClick={toggleCollapsible}>
						<div className='v__collapsible__header__container'>
							<span>{heading}</span>
							<Icon>more_horiz</Icon>
						</div>
					</div>
				)}
				<Collapse in={!collapsed}>
					<div className='v__collapsible__body'>{children}</div>
				</Collapse>
			</div>
		);
	}
);

export default Collapsible;
