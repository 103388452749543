import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cn from "classnames";

import { FormCheckbox } from "../../components/forms";
import { Avatar, Badge } from "../../components/ui";

const UserCardItem = ({ member, onSelect, checked }) => {
	const Wrapper = Boolean(onSelect) ? "div" : Link;
	return (
		<Wrapper to={!Boolean(onSelect) ? `/app/users/${member?.id}` : ""} className={cn(onSelect && "flex items-center py-1")}>
			{onSelect && (
				<FormCheckbox
					name={String(member.id)}
					onChange={(status) => onSelect?.({ checked: status, member })}
					checked={checked ?? false}
					withLabel={false}
					groupClassName='no-margin cursor-pointer'
				/>
			)}
			<div className='v__staff__item__avatar shrink-0 rounded-full overflow-hidden'>
				<Avatar user={member} className={cn(onSelect && "w-9 h-9")} />
			</div>
			<div className='v__staff__item__name truncate grow px-2'>{member.full_name}</div>
			<div className='v__staff__item__role shrink-0'>
				<Badge
					type={
						member.roles[0]?.name === "Service Partner"
							? "danger"
							: member.roles[0]?.name === "Maintainer"
							? "success"
							: member.roles[0]?.name === "Manager"
							? "accent"
							: "outline"
					}
				>
					{member.roles[0]?.name === "Service Partner" ? "Owner" : member.roles[0]?.name}
				</Badge>
			</div>
		</Wrapper>
	);
};

UserCardItem.propTypes = {
	member: PropTypes.object,
};

export default UserCardItem;
