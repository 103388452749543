import { useCallback } from "react";
import { ActionsCell, Button, Icon, StatisticsTable } from "components";
import { useDataTableGenerate } from "hooks";
import { useToursEdit } from "hooks/query/invoiceManager";

const InvoiceManagerToursTable = ({invoice_manager_id, setModal }) => {
    const columns = useCallback((columns) => {
        return columns.map((col) => {

            const { title, accessor, disableSortBy, field, type, ...rest } = col;
            const columnProps = {
                Header: title,
                accessor,
                disableSortBy,
                id: field,
                sortDescFirst: true,
                icon: type,
                ...rest,
            };


            if (accessor === "tour_actions") {
                return {
                    ...columnProps,
                    Cell: ({ value, row }) => {
                        if (row.original.id === "TOT" || row.original.id === "AVG") {
                            return null;
                        }
                        return (
                            <ActionsCell>
                                <Button small bg status='warning' onClick={() => setModal({ payload: row.original, state: true })}>
                                    <Icon style={{ "fontFamily": "Material Icons Outlined" }} >edit</Icon>
                                </Button>
                            </ActionsCell>
                        );
                    },
                };
            }

            return null;
        });
    }, [setModal]);

    //how to fire the query when the step is the right one
    const { tableProps, hasOverall } = useDataTableGenerate(useToursEdit, columns, {
        invoice_manager_id
    });

    return <StatisticsTable {...tableProps} hasOverallDataRows={hasOverall} scrollToTopOnChange dark />;
};

export default InvoiceManagerToursTable;
