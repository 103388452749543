import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cn from "classnames";

import { useApp } from "../../context";
import { Icon } from "..";

const ThemeSwitch = ({ small = true }) => {
	const { t } = useTranslation();
	const { theme, toggleTheme } = useApp();

	return (
		<div
			className={cn("v__theme__switch", {
				"v__theme__switch--small": small,
			})}
			title={`${t("changeTheme")} - (Ctrl + Alt + T)`}>
			<input type='checkbox' tabIndex='-1' id='theme-switch' onChange={toggleTheme} checked={theme === "dark"} />
			<label htmlFor='theme-switch' className='v__theme__switch__label'>
				<div className='v__theme__switch__label__rail'>
					<div className='v__theme__switch__label__slider'>
						<Icon className='sunny'>wb_sunny</Icon>
						<Icon className='night'>nights_stay</Icon>
					</div>
				</div>
			</label>
		</div>
	);
};

ThemeSwitch.propTypes = {
	small: PropTypes.bool,
};

export default ThemeSwitch;
