import { Container } from "components";
import { memo } from "react";

import TopBarBrand from "./TopBarBrand";
import TopBarExtras from "./TopBarExtras";
import TopBarUser from "./TopBarUser";
import UserNotification from "./UserNotification";

const TopBar = () => {
    return (
        <div className='v__topbar__container'>
            <div className='v__topbar'>
                <TopBarBrand />
                {/* 
        
				<Container
					style={{
						willChange: "background-position",
						backgroundImage: `url(
							"data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0%3Bfill:%23FFFFFF%3B%7D.st3%7Bopacity:0.8%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st3' cx='4' cy='14' r='1'/%3E%3Ccircle class='st3' cx='43' cy='3' r='1'/%3E%3Ccircle class='st3' cx='43' cy='16' r='1'/%3E%3Ccircle class='st3' cx='8' cy='45' r='1'/%3E%3Ccircle class='st3' cx='29' cy='39' r='1'/%3E%3Ccircle class='st3' cx='13' cy='37' r='1'/%3E%3Ccircle class='st3' cx='47' cy='32' r='1'/%3E%3Ccircle class='st3' cx='15' cy='4' r='1'/%3E%3Ccircle class='st3' cx='9' cy='27' r='1'/%3E%3C/svg%3E"
						)`,
						animation: "animatedBackground 15s linear infinite",
						backgroundPosition: "0px 0px",
					}}
				>
                */}

                <Container>
                    <TopBarUser />
                    <UserNotification />
                    <TopBarExtras />
                </Container>
            </div>
        </div>
    );
};

export default memo(TopBar);
