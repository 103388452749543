import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const LinkCell = ({ value, to }) => {
	const { t } = useTranslation();
	return (
		<Link to={to} className='w-full h-full flex items-center px-1' title={t("viewConcat", { value })}>
			<span className='truncate'>{value}</span>
		</Link>
	);
};

export default LinkCell;
