import { useReportFiles } from "hooks";
import ReportsContent from "./ReportsContent";

export default function Reports(props) {
	// getting all fetched reports files
	const files = useReportFiles();

	if (files?.data?.length === 0) {
		return null;
	}

	return <ReportsContent {...props} />;
}
