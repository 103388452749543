import { useTranslation } from "react-i18next";
import { Card, Modal } from "components";
import { useFuel, useUpdateFuel } from "hooks";
import { FuelForm } from ".";

const CardModal = ({ payload, onClose, refetch }) => {
    const { t } = useTranslation();
    console.log(payload);

    const { data: fuel } = useFuel(payload?.fuel_id, {
        custom: {
            includes: ["card", "user"],
        },

        enabled: Boolean(payload?.fuel_id),
    });


    const fuelData = fuel?.data;

    const defaultValues = {
        title: fuelData?.title,
    };


    const { mutate: update } = useUpdateFuel({
        onSuccess: (res) => {
            onClose();

            if (refetch) {
                refetch();
            }

        },
    });

    const handleOnSubmit = (data, e) => {
        const submitter = e.nativeEvent.submitter.name

        if (submitter === "modalSubmit") {
            update({ id: payload.fuel_id, data });
        }
    };

    const shouldRender = payload && fuel;

    return (
        <Modal onClose={onClose}>
            <Card className='v__modal v__card--overflow-able' header={t("editFuel")} dismissible={onClose}>
                {shouldRender && <FuelForm defaultValues={defaultValues} onSubmit={handleOnSubmit} onCancel={onClose} />}
            </Card>
        </Modal>
    );
};

export default CardModal;
