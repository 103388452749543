import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthorizedRoute, Icon, PermissionRoute } from "../components";
import { GuestLayout, UserLayout } from "../layout";
import { ReportsProvider } from "../context";
import { initialInvoicesState } from "../reducers";

import {
    Dashboard,
    Groups,
    Login,
    NotFound,
    Register,
    Reports,
    ReportsWrapper,
    ServicePartners,
    ServicePartnerView,
    Settings,
    Tournament,
    Users,
    UsersView,
    DepoView,
    GroupsView,
    Fairplay,
    FairplaySPTable,
    FairplaySP,
    SpFairplayWrapper,
} from "../pages";

import FairplayTable from "pages/Reports/FairplayTable";
import { InvoiceManager, InvoiceManagerEdit } from "pages/InvoiceManager";
import { Tours } from "pages/Tours";
import { InvoicesAdvanced, InvoiceWrapper } from "pages/Invoices";
import InvoiceCreate from "pages/Invoices/InvoiceCreate";
import { Cards } from "pages/Cards";
import { FuelAdvanced, FuelWrapper } from "pages/Fuel";
import { Pcns } from "pages/Pcn";
import ToursCategories from "pages/Tours/ToursCategories";

const useAppRoutes = () => {
    const { t } = useTranslation();

    return useMemo(
        () => [
            {
                path: "/",
                element: <GuestLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate replace to='login' />,
                    },
                    {
                        path: "login",
                        element: <Login />,
                    },
                    {
                        path: "register",
                        element: <Register />,
                    },
                ],
            },
            {
                path: "app",
                element: (
                    <AuthorizedRoute>
                        <ReportsProvider>
                            <UserLayout />
                        </ReportsProvider>
                    </AuthorizedRoute>
                ),
                children: [
                    { index: true, element: <Navigate replace to='dashboard' /> },
                    { path: "dashboard", element: <Dashboard />, icon: <Icon>dashboard</Icon>, label: t("dashboard") },
                    {
                        path: "reports",
                        element: <SpFairplayWrapper />,
                        icon: <Icon>insert_chart_outlined</Icon>,
                        plainRoute: true,
                        label: t("reports"),
                        end: true,
                        children: [
                            {
                                index: true,
                                element: <Reports />,
                            },
                            {
                                path: "fbr/:fairplayId",
                                element: (
                                    // <PermissionRoute permissions={["view_pedal"]}>
                                    <Fairplay />
                                    // </PermissionRoute>
                                ),
                            },
                            {
                                path: "sp_fbr/:fairplayId",
                                element: (
                                    // <PermissionRoute permissions={["view_pedal"]}>
                                    <FairplaySP />
                                    // </PermissionRoute>
                                ),
                            },

                        ],
                    },

                    { path: "tournament", element: <Tournament />, icon: <Icon>leaderboard</Icon>, label: t("tournament") },
                    // {
                    //     path: "fairplay",
                    //     element: <FairplayTable />,
                    //     icon: <Icon>monetization_on</Icon>,
                    //     label: t("fairplay"),
                    // },
                    {
                        path: "fairplay_sp",
                        element: <FairplaySPTable />,
                        icon: <Icon>monetization_on</Icon>,
                        label: t("sp_fairplay"),
                    },
                    {
                        path: "invoice-manager",
                        permissions: ["upload_invoices"],
                        icon: <Icon>collections_bookmark</Icon>,
                        label: t("invoice-manager"),
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["upload_invoices"]}>
                                        <InvoiceManager />
                                    </PermissionRoute>
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <PermissionRoute permissions={["upload_invoices"]}>
                                        <InvoiceManagerEdit />
                                    </PermissionRoute>
                                ),
                            },
                        ],

                    },
                    { path: "invoices/advanced/statistics", icon: <Icon>list_alt</Icon>, label: t("invoices") },
                    {
                        path: "invoices",
                        plainRoute: true,
                        element: (
                            <ReportsProvider initialState={initialInvoicesState}>
                                <Outlet />
                            </ReportsProvider>
                        ),
                        icon: <Icon>list_alt</Icon>,
                        label: t("invoices"),
                        end: true,
                        children: [
                            {
                                path: "advanced",
                                element: (<InvoiceWrapper />),
                                children: [
                                    {
                                        index: true,
                                        element: <InvoicesAdvanced />,
                                        path: "statistics"
                                    }
                                ],
                            },
                            {
                                path: "create",
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["upload_invoices"]}>
                                        <InvoiceCreate />
                                    </PermissionRoute>
                                ),
                            },

                        ],
                    },
                    {
                        path: "cards",
                        permissions: ["view_cards"],
                        element: (
                            <PermissionRoute permissions={["view_cards"]}>
                                <Cards />
                            </PermissionRoute>
                        ),

                        icon: <Icon>style</Icon>,
                        label: t("cards"),
                    },
                    {
                        path: "fuel",
                        element: <FuelWrapper />,
                        icon: <Icon>local_gas_station</Icon>,
                        label: t("fuel"),
                        end: true,
                        children: [
                            {
                                index: true,
                                element: <FuelAdvanced />,
                            },
                        ],
                    },
                    {
                        path: "partners",
                        permissions: ["view_service_partners"],
                        icon: <Icon>work_outline</Icon>,
                        label: t("servicePartners"),
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["view_service_partners"]}>
                                        <ServicePartners />
                                    </PermissionRoute>
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <PermissionRoute permissions={["view_service_partner"]}>
                                        <ServicePartnerView />
                                    </PermissionRoute>
                                ),
                            },
                        ],
                    },

                    {
                        path: "depo/:id",
                        plainRoute: true,
                        element: (
                            <PermissionRoute permissions={["view_depo_performance"]}>
                                <DepoView />
                            </PermissionRoute>
                        ),
                    },
                    {
                        path: "users",
                        permissions: ["view_users"],
                        icon: <Icon>people_alt</Icon>,
                        label: t("users"),
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["view_users"]}>
                                        <Users />
                                    </PermissionRoute>
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <PermissionRoute permissions={["view_user"]}>
                                        <UsersView />
                                    </PermissionRoute>
                                ),
                            },
                        ],
                    },
                    {
                        path: "pcn",
                        permissions: ["view_users"],
                        icon: <Icon>people_alt</Icon>,
                        label: t("pcn"),
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["view_users"]}>
                                        <Pcns />
                                    </PermissionRoute>
                                ),
                            },
                        ],
                    },

                    {
                        path: "groups",
                        permissions: ["view_groups"],
                        icon: <Icon>connect_without_contact</Icon>,
                        label: t("groups"),
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["view_groups"]}>
                                        <Groups />
                                    </PermissionRoute>
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <PermissionRoute permissions={["view_groups"]}>
                                        <GroupsView />
                                    </PermissionRoute>
                                ),
                            },
                        ],
                    },
                    {
                        path: "tours_categories",
                        permissions: ["view_tours"],
                        icon: <Icon>map</Icon>,
                        label: t("tours"),
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["view_tours"]}>
                                        <ToursCategories />
                                    </PermissionRoute>
                                ),
                            },

                        ],
                    },

                    {
                        path: "tours/:id",
                        permissions: ["view_tours"],
                        plainRoute: true,
                        children: [
                            {
                                index: true,
                                element: (
                                    <PermissionRoute permissions={["view_tours"]}>
                                        <Tours />
                                    </PermissionRoute>
                                ),
                            },
                        ],
                    },

                    { path: "settings", element: <Settings />, icon: <Icon>settings</Icon>, label: t("settings") },
                    { path: "*", element: <NotFound /> },
                ],
            },
        ],
        [t]
    );
};

export default useAppRoutes;
