import axios from "./axios";

const api = {
    getSPFairplays(params) {
        return axios
            .get(`/sp_fbr/management`, {
                params,
            })
            .then(({ data }) => data);
    },
    getSPFairplay(id, params) {
        return axios
            .get(`/sp_fbr/management/${encodeURI(id)}`, {
                params,
            })
            .then(({ data }) => data);
    },

    updateSPFairplay(id, data) {
        return axios
            .post(`/sp_fbr/management/${encodeURI(id)}/update`, formatFairplayData(data), { headers: Headers.FORMDATA })
            .then(({ data }) => data);
    },

    createSPFairplay(data) {
        return axios.post("/sp_fbr/management", formatFairplayData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    deleteSPFairplay(id) {
        return axios.delete(`/sp_fbr/management/${id}`).then(({ data }) => data);
    },
};

function formatFairplayData(data) {
    const formattedData = new FormData();
    formattedData.append("owner_id", data.owner_id?.value ?? "");
    formattedData.append("title", data.title);
    // if (data.image_path) {
    // 	formattedData.append("image_path", data.image_path);
    // }

    for (const sp of Object.values(data.members)) {
        for (const user of Object.values(sp.members)) {
            formattedData.append(`members[]`, user.id);
        }
    }

    for (const sp of Object.values(data.service_partners)) {
        if (Boolean(sp.checked)) {
            formattedData.append(`service_partners[]`, sp.id);
        }
    }

    for (const formula_data of Object.values(data.formulas)) {
        for (const field_key of Object.keys(formula_data)) {
            formattedData.append(`formulas[${formula_data["division_id"]}][${field_key}]`, formula_data[field_key]);
        }
    }

    return formattedData;
}

export default api;
