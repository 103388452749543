import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth } from "context";
import { useDataTableGenerate, useServicePartners } from "hooks";

import { Button, Icon, ActionsCell, AvatarCell, DivisionCell, RateCell, StaffCountCell, Table } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { ServicePartnerModal } from "modules/service-partners";

const ServicePartners = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [modal, setModal] = useState({
		state: false,
		payload: null,
	});

	const closeModal = () => setModal({ state: false, payload: null });

	const { userCan } = useAuth();

	const columns = useCallback(
		(columns) => {
			return columns.map((col) => {
				if (col.accessor === "title") {
					return {
						...col,
						sticky: true,
						Cell: ({ cell }) => <StaffCountCell cell={cell} />,
					};
				}
				if (col.accessor === "owner.full_name") {
					return {
						...col,
						secondaryData: "online_status",
						Cell: ({ cell }) => <AvatarCell cell={cell} redirect={(row) => `/app/users/${row?.owner?.id}`} />,
					};
				}
				if (col.accessor === "rateChart") {
					return {
						...col,
						Cell: ({ cell }) => <RateCell cell={cell} />,
					};
				}
				if (col.accessor === "current_division") {
					return {
						...col,
						Cell: ({ cell }) => <DivisionCell cell={cell} />,
					};
				}
				if (col.accessor === "actions") {
					return {
						...col,
						Cell: ({ cell }) => (
							<ActionsCell>
								{userCan("view_service_partner") && (
									<Button small bg status='accent' onClick={() => navigate(`${cell.row.original?.id}`)}>
										<Icon>visibility</Icon>
									</Button>
								)}
								{userCan("add_service_partner") && (
									<Button small bg status='warning' onClick={() => setModal({ payload: cell.row.original, state: true })}>
										<Icon>edit</Icon>
									</Button>
								)}
							</ActionsCell>
						),
					};
				}
				return null;
			});
		},
		[userCan, navigate]
	);

	const { tableProps } = useDataTableGenerate(useServicePartners, columns, {
		includes: ["owner", "owner.media"],
		lastMonthChart: ["overall_rating_daily_avg"],
	});

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Service Partners</title>
			</Helmet>
			<PageHeader title={t("servicePartners")}></PageHeader>

			<PageContent>
				<Table {...tableProps} scrollToTopOnChange />
			</PageContent>

			{modal.state && <ServicePartnerModal payload={modal.payload} onClose={closeModal} />}
		</PageWrapper>
	);
};

export default ServicePartners;
