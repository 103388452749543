import { Button, Icon } from "..";
import { parseSelectedMonth } from "../../utils";

const CalendarHeaderNavigation = ({ date, changeYear, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
	const parsedDate = parseSelectedMonth(date);

	const currYear = date.getFullYear();
	const increaseYear = () => changeYear(currYear + 1);
	const decreaseYear = () => changeYear(currYear - 1);

	return (
		<div className='react-datepicker__header__container'>
			<Button bg round status='darker' onClick={decreaseYear}>
				<Icon>chevron_left</Icon>
			</Button>
			<Button bg round status='darker' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
				<Icon>arrow_back</Icon>
			</Button>
			<div className='react-datepicker__header__label'>{parsedDate}</div>
			<Button bg round status='darker' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
				<Icon>arrow_forward</Icon>
			</Button>
			<Button bg round status='darker' onClick={increaseYear}>
				<Icon>chevron_right</Icon>
			</Button>
		</div>
	);
};

export default CalendarHeaderNavigation;
