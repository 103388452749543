import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";

import { useCompanies, useInfiniteServicePartners } from "hooks";
import { FormInput } from "components/forms";
import { Button, Icon } from "components";
import { LineLoader } from "components/ui";
import StaffCardItem from "./StaffCardItem";

const MIN_SEARCH_LENGTH = 2;

const StepServicePartnerList = ({ onSelect, checked }) => {
	const { t } = useTranslation();

	const [search, setSearch] = useState("");
	const [chain, setChain] = useState({
		depo: null,
		sp: null,
	});

	const hasSearched = search.length <= MIN_SEARCH_LENGTH && search.length !== 0;

	const handleOnSelect = Boolean(onSelect)
		? (value) => onSelect?.({ value, chain: chain.depo && chain.sp ? chain : value?.chain })
		: undefined;

	return (
		<div className='flex flex-col h-full'>
			<div className='px-3 py-1'>
				<FormInput
					groupClassName='no-margin'
					name='search'
					withLabel={false}
					placeholder={t("search")}
					value={search}
					onChange={({ target }) => setSearch(target.value)}
					error={hasSearched && t("minLength", { value: MIN_SEARCH_LENGTH })}
				/>
			</div>
			<div className='grow relative h-full overflow-y-auto'>
				{search.length > 0 ? (
					<p>Todo</p>
				) : (
					<>
						{!chain.depo && !chain.sp && <DepoList onSelect={(v) => setChain({ depo: v, sp: null })} />}
						{chain.depo && !chain.sp && (
							<SPList
								chain={chain}
								onBack={() => setChain({ depo: null, sp: null })}
								onSelect={handleOnSelect}
								checked={checked}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const DepoList = ({ onSelect }) => {
	const { data, isFetching, isFetched } = useCompanies({
		custom: {
			type: "delivery_daily",
			includes: ["depos"],
		},
	});

	return (
		<div className='v__staff__items'>
			{isFetching && !isFetched && <LineLoader />}
			{data?.data?.map((item) => (
				<div key={item.id} className='v__staff__parent px-3 pb-3'>
					<p className='v__staff__parent__title sticky top-0' title={item.title}>
						{item.title}
					</p>
					{item.depos.map((depo) => (
						<Button bg wide alignLeft key={depo.id} onClick={() => onSelect?.(depo)}>
							{depo.title}
						</Button>
					))}
				</div>
			))}
		</div>
	);
};

const SPList = ({ chain, onBack, onSelect, checked }) => {
	const { t } = useTranslation();

	const parentRef = useRef();

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isFetched } = useInfiniteServicePartners({
		custom: {
			depo_id: chain.depo?.id,
			per_page: 20,
		},
		enabled: Boolean(chain.depo?.id),
		getNextPageParam: ({ meta }) => {
			const nextPage = meta.current_page + 1;
			const lastPage = meta.last_page;
			return nextPage <= lastPage ? nextPage : undefined;
		},
	});

	const depoSp = useMemo(() => {
		if (!data) {
			return [];
		}
		return data?.pages.map((page) => page.data).reduce((prev, curr) => [...prev, ...curr], []);
	}, [data]);

	const rowVirtualizer = useVirtual({
		size: depoSp.length,
		estimateSize: useCallback(() => 40, []),
		parentRef,
	});

	useEffect(() => {
		const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

		if (!lastItem) {
			return;
		}

		if (lastItem.index >= depoSp.length - 1 && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, fetchNextPage, depoSp.length, isFetchingNextPage, rowVirtualizer.virtualItems]);

	return (
		<div className='v__staff__items flex flex-col h-full'>
			{(isFetchingNextPage || (isFetching && !isFetched)) && <LineLoader />}
			<div className='v__staff__items__header flex items-center space-x-2 px-3 py-2 w-full'>
				<Button small round hoverStatus='accent' onClick={() => onBack?.()} className='shrink-0'>
					<Icon>arrow_back</Icon>
				</Button>
				<p className='truncate mb-0 grow'>{chain.depo?.title}</p>
			</div>
			<div className='v__staff__parent grow' ref={parentRef}>
				<div
					style={{
						height: `${rowVirtualizer.totalSize}px`,
					}}
				>
					{rowVirtualizer.virtualItems.map((virtualRow) => {
						const child = depoSp[virtualRow.index];
						return (
							<div
								key={virtualRow.index}
								ref={virtualRow.measureRef}
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: `${child}px`,
									transform: `translateY(${virtualRow.start}px)`,
								}}
								className='px-3'
							>
								<div className='v__staff__item'>
									<StaffCardItem sp={child} onSelect={onSelect} checked={checked?.[child.id]?.checked} />
								</div>
							</div>
						);
					})}
					{rowVirtualizer.virtualItems.length === 0 && <div className='text-center pad-xy-2'>{t("noData")}</div>}
				</div>
			</div>
		</div>
	);
};

export default StepServicePartnerList;
