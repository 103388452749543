import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Icon } from "components";

export default function Error() {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>Veniway | Oops...</title>
			</Helmet>
			<div className='v__page__missing'>
				<div className='v__page__missing__graphic'>
					<Icon style={{ fontSize: "min(35rem, 75vw)" }}>heart_broken</Icon>
				</div>
				<span className='v__page__missing__description'>{t("somethingBroke")}</span>
			</div>
		</>
	);
}
