import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { FormInput, FormSelect } from "../../components/forms";
import { useCompanies, useServicePartners, useSnumbers } from "../../hooks";
import { Button, FormDatePicker } from "../../components";
import { useState } from "react";

const UsersForm = ({ onSubmit, onCancel, defaultValues: { service_partner_id, snumbersPassed, ...otherDefaultValues } }) => {
	const { t } = useTranslation();

	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({ defaultValues: otherDefaultValues });

	const [snumbers, setSnumbers] = useState(() => {
		if (snumbersPassed !== null) {
			return snumbersPassed;
		}

		return [{}];
	});

	const removeSnumber = (data) => {

	    setSnumbers((prev) => prev.slice(0, -1))
        const newSnumbers = [...getValues().snumbers];
	    newSnumbers.splice(newSnumbers.length -1, 1); // remove the second element
	    setValue("snumbers", newSnumbers);

	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
			<Controller
				render={({ field }) => (
					<FormInput
						groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
							"v__form__group--incorrect": errors?.first_name,
						})}
						label={t("first_name")}
						error={errors?.first_name && t(`${errors?.first_name?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='first_name'
				defaultValue=''
				rules={{ required: true }}
			/>
			<Controller
				render={({ field }) => (
					<FormInput
						groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
							"v__form__group--incorrect": errors?.last_name,
						})}
						label={t("last_name")}
						error={errors?.last_name && t(`${errors?.last_name?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='last_name'
				defaultValue=''
				rules={{ required: true }}
			/>
			<Controller
				render={({ field }) => (
					<FormSelect
						groupClassName='v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12'
						label={t("depo")}
						isAsync
						getValues={getValues}
						useFetch={useCompanies}
						isMulti
						isGrouped
						isClearable
						queryConfig={{ custom: { type: "delivery_daily", includes: ["depos"] } }}
						groupParse={{ label: "title", options: "depos" }}
						{...field}
					/>
				)}
				control={control}
				name={"depo_ids"}
				rules={{ required: true }}
			/>

			<Controller
				render={({ field }) => (
					<FormSelect
						groupClassName={cn("v__grid__item v__grid__item--col-md-12 v__grid__item--col-sm-12")}
						label={t("servicePartner")}
						isAsync
						asyncSearch
						isClearable
						useFetch={useServicePartners}
						queryConfig={{ custom: { page: 0, per_page: 25 } }}
						optionsParse={{ value: "id", label: "title" }}
						{...field}
					/>
				)}
				control={control}
				name='service_partner_id'
				defaultValue={Boolean(service_partner_id) ? service_partner_id : ""}
				rules={{ required: true }}
			/>

			<GenerateSnumbers t={t} getValues={getValues} setValue={setValue} control={control} errors={errors} snumbers={snumbers} />
			<div className='v__form__group v__grid__item v__grid__item--col-12'>
				<div className='flex justify-center items-center mt-5'>
					
					<Button className='float-left !mr-5' bg status='accent' onClick={() => setSnumbers((prev) => [...prev, {}])}>
						{t("add_ddr_name")}
					</Button>
					{snumbers.length > 1 && (
						<Button bg status='darker' onClick={removeSnumber}>
							{t("remove_ddr_name")}
						</Button>
					)}

                    
				</div>
			</div>

			<div className='v__form__footer v__grid__item v__grid__item--col-12'>
				<Button bg status='darker' onClick={onCancel}>
					{t("cancel")}
				</Button>
				<Button bg status='accent' type='submit'>
					{t("save")}
					{/* {payload ? lang?.save_changes : lang?.add_group} */}
				</Button>
			</div>
		</form>
	);
};

const GenerateSnumbers = (props) => {
	if (props.snumbers === undefined) {
		return null;
	}

	return props.snumbers.map((value, index) => {
		const snumber_default_value = (value?.snumber !== undefined) ? { value: value?.snumber?.id, label: value?.snumber?.combined } : "";
		const operator_default_value = (value?.snumber !== undefined && value?.snumber?.operator_name !== null) ? value?.snumber?.operator_name : "";

		return (
			<div className='v__grid__item v__grid__item--col-12 mt-2 !p-0' key={"wrapper" + index}>
				<div className='v__grid__item v__grid__item--col-12 mb-5 mt-2 !p-0 !m-0'></div>
				<div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
					<hr />
				</div>
				<Controller
					render={({ field }) => {
						const { onChange, ...restField } = field;
						return (
							<FormSelect
								groupClassName={cn("v__grid__item v__grid__item--col-md-12 v__grid__item--col-sm-12")}
								label={props.t("snumber")}
								isAsync
								asyncSearch
								isClearable
								getValues={props.getValues}
								useFetch={useSnumbers}
								onChange={(option) => {
									onChange(option);
									props.setValue("snumbers[" + index + "][operator_name]", option.operator_name === null ? "" : option.operator_name);
								}}
								queryConfig={{
									custom: {
										page: 0,
										per_page: 25,
									},
								}}
								optionsParse={{ value: "id", label: "combined" }}
								{...restField}
							/>
						);
					}}
					control={props.control}
					name={"snumbers[" + index + "][snumber_id]"}
					defaultValue={snumber_default_value}
					rules={{ required: false }}
				/>

				<Controller
					render={({ field }) => (
						<FormInput
							groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
								"v__form__group--incorrect": props.errors?.operator_name,
							})}
							label={props.t("operator_name")}
							disabled={false}
							error={props.errors?.operator_name && props.t(`${props.errors?.operator_name?.type}`)}
							{...field}
						/>
					)}
					control={props.control}
					name={"snumbers[" + index + "][operator_name]"}
					defaultValue={operator_default_value}
					rules={{ required: false }}
				/>

				<Controller
					render={({ field: { value, ...restField } }) => (
						<FormDatePicker
							groupClassName={cn(
								"flex float-left w-6/12 v__grid__item v__grid__item--col-6 v__grid__item--col-sm-6",
								props.errors?.from_date && "v__form__group--incorrect"
							)}
							label={""}
							error={props.errors?.from_date && props.t(`${props.errors?.from_date?.type}`)}
							maxDate={false}
							selected={value}
							{...restField}
						/>
					)}
					control={props.control}
					name={"snumbers[" + index + "][from_date]"}
					defaultValue={value.from_date !== undefined ? new Date(value.from_date) : new Date()}
					rules={{ required: false }}
				/>
				<Controller
					render={({ field: { value, ...restField } }) => (
						<FormDatePicker
							groupClassName={cn("flex  w-6/12 v__grid__item v__grid__item--col-6 v__grid__item--col-sm-6", props.errors?.to_date && "v__form__group--incorrect")}
							label={""}
							error={props.errors?.to_date && props.t(`${props.errors?.to_date?.type}`)}
							maxDate={false}
							selected={value}
							{...restField}
						/>
					)}
					control={props.control}
					name={"snumbers[" + index + "][to_date]"}
					defaultValue={value.to_date !== undefined ? new Date(value.to_date) : new Date()}
					rules={{ required: false }}
				/>
			</div>
		);
	});
};

export default UsersForm;
