import { useMemo, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useReportsState } from "context";
import { useMedia, useMediaQueries, usePerformance } from "hooks";

import { PerformanceChart, Tip } from "components";
import { LineLoader } from "components/ui";
import PerformancePagination from "components/Chart/PerformancePagination";
import { REPORTS_ACTION_TYPES, REPORT_PERIOD_TYPES } from "reducers/const";

const timePeriod = {
	daily: "week",
	weekly: "month",
	monthly: "year",
};

const applyPerformanceNav = (nav, sortBy, direction) => {
	return new Date(nav[direction]?.[timePeriod[sortBy?.value]]).toLocaleDateString("en-US");
};

const applyPerformanceNavState = (nav, sortBy) => {
	return nav?.current?.[timePeriod[sortBy?.value]];
};

const PerformanceChartBox = ({ filtersComponent, bodyFiltersComponent, filterValues, className }) => {
	const { dispatch } = useReportsState();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const { SMALL, LARGE } = useMediaQueries();
	const isSmall = useMedia(SMALL);
	const isLarge = useMedia(LARGE);

	const sortByOptions = [
		{ value: "daily", label: t("daily") },
		{ value: "weekly", label: t("weekly") },
		{ value: "monthly", label: t("monthly") },
	];

	const [filters, setFilters] = useState({
		sortBy: sortByOptions[0],
		date: null,
		...filterValues,
	});

	useEffect(() => {
		setFilters((filters) => ({
			...filters,
			...filterValues,
		}));
	}, [filterValues]);

	const onFiltersChange = useCallback((newFilters) => setFilters((prev) => ({ ...prev, ...newFilters })), []);

	const {
		data: performance,
		isFetching,
		isFetched,
	} = usePerformance({
		custom: {
			sortBy: filters.sortBy?.value,
			date: filters.date,
			depo_id: filters.depo_id?.value,
			user_id: filters.user_id?.value,
			member_group_id: filters.member_group_id?.value,
			service_partner_id: filters.service_partner_id?.value,
		},
	});

	const performanceData = useMemo(() => {
		if (performance) {
			return {
				labels: performance?.data?.labels ?? [],
				data: performance?.data?.data ?? [],
				labelDates: performance?.data?.labels_dates ?? [],
			};
		}
		return {
			labels: [],
			data: [],
			labelDates: [],
		};
	}, [performance]);

	const performanceNavigation = useMemo(() => {
		if (performance) {
			return performance?.meta;
		}
		return {};
	}, [performance]);

	const performanceNavProps = useMemo(
		() => ({
			onPrev: () =>
				setFilters((prev) => ({
					...prev,
					date: applyPerformanceNav(performanceNavigation, prev.sortBy, "previous"),
				})),
			onNext: () =>
				setFilters((prev) => ({
					...prev,
					date: applyPerformanceNav(performanceNavigation, prev.sortBy, "next"),
				})),
			current: applyPerformanceNavState(performanceNavigation, filters.sortBy),
		}),
		[filters.sortBy, performanceNavigation]
	);

	const isLoading = isFetching && !isFetched;

	const printDataKeys = performanceData?.data?.reduce((prev, curr) => [...prev, curr.key], []);

	return (
		<div
			className={cn("v__card v__performance__chart", "v__card--overflow-able print:!bg-white print:!text-black print:!shadow-none", {
				disabled: isLoading,
			})}
		>
			<p className='screen-hide print-title text-center'>
				{t("performance")} ({performanceNavProps?.current})
			</p>
			<table className='screen-hide print-performance print-only-table'>
				<thead>
					<tr>
						<th>{t("time")}</th>
						{printDataKeys.map((x) => (
							<th key={x}>{t(x)}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{performanceData?.labels.map((x, i) => (
						<tr key={x}>
							<td>{x}</td>
							{printDataKeys.map((y) => (
								<td key={y}>{performanceData?.data.find((j) => j.key === y)?.data?.[i]}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			{isLoading && <LineLoader />}
			<div className='v__card__header print:hidden'>
				<div className='v__card__header__container'>
					<span className='flex items-center space-x-2'>
						<h2>{t("performance")}</h2>
						<Tip message={t("performanceTip")} icon='help_outline' />
						<Tip message={t("performanceTip2")} icon='touch_app' />
					</span>
					{isSmall && <PerformancePagination {...performanceNavProps} />}
					{filtersComponent?.({ filters, onFilter: onFiltersChange, sortByOptions, isLarge })}
				</div>
			</div>
			<div className='v__card__body print:hidden'>
				{bodyFiltersComponent?.({ filters, onFilter: onFiltersChange, sortByOptions, isLarge })}
				{!isSmall && <PerformancePagination {...performanceNavProps} />}
				<PerformanceChart
					labels={performanceData.labels}
					fetchedData={performanceData.data}
					className={className}
					onSeriesClick={({ dataPointIndex }) => {
						dispatch({
							type: REPORTS_ACTION_TYPES.SET_FILTERS_MANUALLY,
							payload: parseChartClickedData(performanceData.labelDates[dataPointIndex], filters),
						});
						navigate("/app/reports");
					}}
				/>
			</div>
		</div>
	);
};

function parseChartClickedData(time, filters) {
	const sortBy = filters.sortBy.value;

	const parsedDate = sortBy === "daily" && new Date(time);
	const parsedWeek = sortBy === "weekly" && time.split("-")[0];
	const parsedMonth = sortBy === "monthly" && time.split("-")[0];
	const parsedYear = (sortBy === "weekly" || sortBy === "monthly") && time.split("-")[1];

	return {
		...filters,
		date: parsedDate,
		week: Number(parsedWeek),
		month: Number(parsedMonth),
		year: Number(parsedYear),
		reportType: parsedDate ? { value: REPORT_PERIOD_TYPES.DAILY } : { value: REPORT_PERIOD_TYPES.PERIOD },
	};
}

export default PerformanceChartBox;
