import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";

import { useReportsState } from "context";
import { useNotifications } from "hooks";
import { LineLoader } from "components/ui";
import NotificationItem from "./NotificationItem";

import { REPORTS_ACTION_TYPES } from "reducers/const";

const NotificationsWrapper = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const { dispatch } = useReportsState();

	const parentRef = useRef();

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useNotifications({
		custom: {
			per_page: 50,
		},
		staleTime: 0,
	});

	const handleOnClick = (payload) => {
		dispatch({
			type: REPORTS_ACTION_TYPES.SET_FILTERS_MANUALLY,
			payload,
		});
		navigate("/app/reports");
	};

	const notifications = useMemo(() => {
		if (!data) {
			return [];
		}
		return data?.pages.map((page) => page.data).reduce((prev, curr) => [...prev, ...curr], []);
	}, [data]);

	const rowVirtualizer = useVirtual({
		size: notifications.length,
		estimateSize: useCallback(() => 80, []),
		parentRef,
	});

	useEffect(() => {
		const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

		if (!lastItem) {
			return;
		}

		if (lastItem.index >= notifications.length - 1 && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, fetchNextPage, notifications.length, isFetchingNextPage, rowVirtualizer.virtualItems]);

	return (
		<>
			{isFetchingNextPage && <LineLoader />}
			<div className='v__notifications' ref={parentRef}>
				<div
					className='relative'
					style={{
						height: `${rowVirtualizer.totalSize}px`,
					}}
				>
					{rowVirtualizer.virtualItems.map((virtualRow) => {
						const child = notifications[virtualRow.index];
						return (
							<div
								key={virtualRow.index}
								ref={virtualRow.measureRef}
								className='absolute top-0 left-0 w-full'
								style={{
									height: `${child}px`,
									transform: `translateY(${virtualRow.start}px)`,
								}}
							>
								<div className='v__notifications__item'>
									<NotificationItem notification={child} onClick={handleOnClick} />
								</div>
							</div>
						);
					})}
					{rowVirtualizer.virtualItems.length === 0 && <div className='text-center pad-xy-2'>{t("noData")}</div>}
				</div>
			</div>
		</>
	);
};

export default NotificationsWrapper;
