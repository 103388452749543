import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { FairplayForm } from ".";
import { useEffect, useState } from "react";
import { useDivisions } from "hooks/query/divisions";
import { useCreateFairplaySP, useFairplaySP, useUpdateFairplaySP } from "hooks";

function parseSelectedMembers(members) {
	if (!members) return {};

	const servicePartners = {};

	for (const member of members) {
		const member_sp = member.service_partners?.[0];
		if (!(member_sp?.id in servicePartners)) {
			servicePartners[member_sp.id] = { ...member_sp, members: {} };
		}

		servicePartners[member_sp.id].members[member.id] = { ...member, checked: true };
	}

	return servicePartners;
}

function parseSelectedSP(servicePartners) {
	if (!servicePartners) return {};

	const fetchedSPs = {};

	for (const sp of servicePartners) {
		if (!(sp?.id in fetchedSPs)) {
			fetchedSPs[sp.id] = { ...sp, checked: true };
		}
	}

	return fetchedSPs;
}

const fields = [
	"division_id",
	"del_delivered_multiplier",
	"del_delivered_early_multiplier",
	"del_delivered_late_multiplier",
	"del_not_delivered_multiplier",
	"positive_feedback_multiplier",
	"negative_feedback_multiplier",
	"working_days_multiplier",
	"eta_multiplier",

	"ticket_multiplier",
	"resolved_ticket_multiplier",
	"time_early_multiplier",
	"time_late_multiplier",
	"time_very_late_multiplier",
	"absence_multiplier",
	"safty_multiplier",
	"vis_multiplier",
	"key_multiplier",
	"van_multiplier",
	"uni_multiplier",
	"beha_multiplier",
	"no_debrief_multiplier",
	"due_multiplier",
	"bad_images_multiplier",
	"failed_ture_multiplier",
	"smart_multiplier",
];

const FairplaySPModal = ({ payload, onClose }) => {
	const { t } = useTranslation();

	const [step, setStep] = useState(-1);
	const [maxStep, setMaxStep] = useState(7);

	const { data: fairplay } = useFairplaySP(payload?.id, {
		custom: {
			includes: ["members", "members.servicePartners", "servicePartners", "owner", "formulas"],
		},
		enabled: Boolean(payload?.id),
	});

	const { data: divisions } = useDivisions({});

	useEffect(() => {
		setMaxStep(divisions?.data.length - 1);
	}, [divisions]);

	const { mutate: update, isLoading: isLoadingUpdate } = useUpdateFairplaySP({
		onSuccess: (res) => {
			onClose();
		},
	});

	const { mutate: create, isLoading: isLoadingCreate } = useCreateFairplaySP({
		onSuccess: (res) => {
			onClose();
		},
	});

	const fairplayData = fairplay?.data;

	const defaultValues = {
		title: fairplayData?.title,
		image_path: fairplayData?.image_path,
		members: parseSelectedMembers(fairplayData?.members),
		service_partners: parseSelectedSP(fairplayData?.servicePartners),
		owner_id: Boolean(payload) ? { value: fairplayData?.owner_id, label: fairplayData?.owner?.full_name } : null,
	};

	if (Boolean(fairplayData)) {
		const formulasData = [];
		fairplayData.formulas.forEach((formula) => {
			const divisionToBeAdded = {};
			fields.forEach((field) => {
				divisionToBeAdded[field] = formula[field];
			});
			formulasData.push(divisionToBeAdded);
		});

		defaultValues.formulas = formulasData;
	}

	const handleOnSubmit = (data) => {
		if (step === maxStep) {
			if (Boolean(payload)) {
				update({ id: payload.id, data });
			} else {
				create(data);
			}
		} else {
			setStep((prev) => prev + 1);
		}
	};

	const handleOnBack = () => setStep((prev) => prev - 1);

	const shouldRender = (payload && fairplay) || !payload;
	const isLoading = isLoadingUpdate || isLoadingCreate;

	return (
		<Modal onClose={onClose}>
			<Card
				className='v__modal v__card--overflow-able'
				header={(!Boolean(payload) ? t("createFairplay") : t("editFairplay")) + " - Step " + (step + 2) + "/9"}
				relativeLoading={isLoading}
				dismissible={onClose}
			>
				{shouldRender && (
					<FairplayForm
						divisions={divisions}
						step={step}
						maxStep={maxStep}
						fields={fields}
						onSubmit={handleOnSubmit}
						onBack={handleOnBack}
						defaultValues={defaultValues}
						setStep={setStep}
						onCancel={onClose}
					/>
				)}
			</Card>
		</Modal>
	);
};

export default FairplaySPModal;
