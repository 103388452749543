import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "../hooks";

const AppContext = createContext();

const AppProvider = ({ children }) => {
	const [theme, setTheme] = useLocalStorage("theme", "dark");
	const [fluidContainer, setFluidContainer] = useLocalStorage("fluidContainer", false);
	const [sidebar, setSidebar] = useLocalStorage("sidebar", true);

	const [localSidebar, setLocalSidebar] = useState(sidebar);

	const toggleTheme = () =>
		setTheme((prev) => {
			const newValue = prev === "dark" ? "light" : "dark";
			return newValue;
		});

	const toggleContainer = () => setFluidContainer((prev) => !prev);

	const toggleSidebar = () =>
		setSidebar((prev) => {
			setLocalSidebar(!prev);
			return !prev;
		});

	const toggleLocalSidebar = () => setLocalSidebar((prev) => !prev);

	useEffect(() => {
		if (theme === "dark") {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
		document.body.dataset.theme = theme;
	}, [theme]);

	return (
		<AppContext.Provider
			value={{
				theme,
				setTheme,
				fluidContainer,
				setFluidContainer,
				sidebar,
				setSidebar,
				localSidebar,
				setLocalSidebar,
				toggleTheme,
				toggleContainer,
				toggleSidebar,
				toggleLocalSidebar,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export const useApp = () => {
	const context = useContext(AppContext);

	if (context === undefined) {
		throw new Error("useApp must be used within a AppProvider");
	}

	return context;
};

export default AppProvider;
