import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuth } from "context";
import { useCardsStatuses, useCompanies, useDepoStaff, useGroups, useServicePartners } from "hooks";
import cn from "classnames";

import { FormSelect } from "../../components/forms";
import { Button, FormDatePicker } from "../../components";

const CardEditForm = ({ onSubmit, onCancel, defaultValues: { owners, ...otherDefaultValues } }) => {
	const { t } = useTranslation();

	const { userCan } = useAuth();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({ defaultValues: otherDefaultValues });

	const [ddrNames, setDDRNames] = useState(() => {
		if (owners !== null) {
			return owners;
		}

		return [{}];
	});

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
				{userCan("manage_cards") && (
					<>
						<Controller
							render={({ field }) => (
								<FormSelect
									groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12")}
									label={t("depo_owner")}
									isAsync
									useFetch={useCompanies}
									isGrouped
									isClearable
									queryConfig={{ custom: { type: "delivery_daily", includes: ["depos"] } }}
									groupParse={{ label: "title", options: "depos" }}
									error={errors?.depo_id && t(`${errors?.depo_id?.type}`)}
									{...field}
								/>
							)}
							control={control}
							name='depo_id'
							defaultValue={null}
							rules={{ required: false }}
						/>

						<Controller
							render={({ field }) => (
								<FormSelect
									groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12")}
									label={t("group_owner")}
									isAsync
									useFetch={useGroups}
									isGrouped
									isClearable
									queryConfig={{ custom: { page: 0, per_page: 25 } }}
									error={errors?.depo_id && t(`${errors?.depo_id?.type}`)}
									{...field}
								/>
							)}
							control={control}
							name='group_id'
							defaultValue={null}
							rules={{ required: false }}
						/>

						<Controller
							render={({ field }) => (
								<FormSelect
									groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
										"v__form__group--incorrect": errors?.service_partner_id,
									})}
									label={t("service_partner_owner")}
									isAsync
									asyncSearch
									useFetch={useServicePartners}
									isGrouped
									queryConfig={{
										custom: {
											page: 0,
											per_page: 10,
										},
									}}
									optionsParse={{ value: "id", label: "title" }}
									error={errors?.service_partner_id && t(`${errors?.service_partner_id?.type}`)}
									{...field}
								/>
							)}
							control={control}
							name='service_partner_id'
							defaultValue={null}
							rules={{ required: false }}
						/>
					</>
				)}
				<Controller
					render={({ field }) => (
						<FormSelect
							groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
								"v__form__group--incorrect": errors?.status,
								"v__grid__item v__grid__item--col-12": !userCan("manage_cards"),
							})}
							label={t("status")}
							queryConfig={{
								custom: {},
							}}
						    isAsync	
							useFetch={useCardsStatuses}
							isGrouped
							error={errors?.status && t(`${errors?.status?.type}`)}
							{...field}
						/>
					)}
					control={control}
					name='status'
					defaultValue={null}
					rules={{ required: true }}
				/>

				<GenerateUserForms t={t} control={control} ddrNames={ddrNames} errors={errors} />
				<div className='v__form__group v__grid__item v__grid__item--col-12'>
					<div className='flex justify-center items-center mt-5'>
						<Button className='float-left !mr-5' bg status='accent' onClick={() => setDDRNames((prev) => [...prev, {}])}>
							{t("add_ddr_name")}
						</Button>
						{ddrNames.length > 1 && (
							<Button bg status='darker' onClick={() => setDDRNames((prev) => prev.slice(0, -1))}>
								{t("remove_ddr_name")}
							</Button>
						)}
					</div>
				</div>

				<div className='v__form__footer v__grid__item v__grid__item--col-12'>
					<Button bg status='darker' onClick={onCancel}>
						{t("cancel")}
					</Button>
					<Button bg status='accent' type='submit'>
						{t("save")}
					</Button>
				</div>
			</form>
		</>
	);
};

const GenerateUserForms = (props) => {
	if (props.ddrNames === undefined || props.ddrNames.length === 0) {
		props.ddrNames.push({ user_id: undefined });
	}

	return props.ddrNames.map((value, index) => {
		return (
			<div className='v__grid__item v__grid__item--col-12 mt-2 !p-0' key={"wrapper" + index}>
				<div className='v__grid__item v__grid__item--col-12 mb-5 mt-2 !p-0 !m-0'></div>
				<div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
					<hr />
				</div>
				<Controller
					render={({ field }) => (
						<FormSelect
							groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
								"v__form__group--incorrect": props.errors?.user_id,
							})}
							label={props.t("card_owner")}
							isAsync
							asyncSearch
							useFetch={useDepoStaff}
							isGrouped
							queryConfig={{
								custom: {
									page: 0,
									per_page: 10,
								},
							}}
							groupParse={{ label: "title", options: "staff" }}
							optionsParse={{ value: "id", label: "full_name" }}
							error={props.errors?.user_id && props.t(`${props.errors?.user_id?.type}`)}
							{...field}
						/>
					)}
					control={props.control}
					name={"users[" + index + "][user_id]"}
					defaultValue={value?.user !== undefined ? { value: value.user.id, label: value.user.full_name } : ""}
					rules={{ required: false }}
				/>

				<Controller
					render={({ field: { value, ...restField } }) => (
						<FormDatePicker
							groupClassName={cn(
								"flex float-left w-6/12 v__grid__item v__grid__item--col-6 v__grid__item--col-sm-6",
								props.errors?.from_date && "v__form__group--incorrect"
							)}
							label={""}
							error={props.errors?.from_date && props.t(`${props.errors?.from_date?.type}`)}
							maxDate={false}
							selected={value}
							{...restField}
						/>
					)}
					control={props.control}
					name={"users[" + index + "][from_date]"}
					defaultValue={value.from_date !== undefined ? new Date(value.from_date) : new Date("2015-01-01")}
					rules={{ required: true }}
				/>
				<Controller
					render={({ field: { value, ...restField } }) => (
						<FormDatePicker
							groupClassName={cn("flex  w-6/12 v__grid__item v__grid__item--col-6 v__grid__item--col-sm-6", props.errors?.to_date && "v__form__group--incorrect")}
							label={""}
							error={props.errors?.to_date && props.t(`${props.errors?.to_date?.type}`)}
							maxDate={false}
							selected={value}
							{...restField}
						/>
					)}
					control={props.control}
					name={"users[" + index + "][to_date]"}
					defaultValue={value.to_date !== undefined ? new Date(value.to_date) : new Date("2050-01-01")}
					rules={{ required: true }}
				/>
			</div>
		);
	});
};

export default CardEditForm;
