import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../components";
import { useAuth } from "context";

const InvoiceManagerEditForm = ({ onSubmit, onCancel, payload, defaultValues: { ...otherDefaultValues } }) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({ defaultValues: otherDefaultValues });
    

    const { user } = useAuth();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>

            { /*<GenerateSNumberFields user={user} snumbers={payload?.unknown_snumbers} t={t} errors={errors} control={control} /> */}

            <div className='v__grid__item v__grid__item--col-12 mb-2 mt-2'>
                <hr />
            </div>
            <label htmlFor={"group-options"} className={`v__form__group__label text-xl my-2 pl-3`}>
                <span>{t("unrecognizedAdjustments")}</span>
            </label>
            <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                <hr />
            </div>

            <GenerateAdjustmentFields user={user} getValues={getValues} adjustments={payload?.invoice_manager.adjustments} t={t} errors={errors} control={control} />
            <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                <Button bg status='darker' onClick={onCancel}>
                    {t("cancel")}
                </Button>
                <Button bg status='accent' type='submit'>
                    {t("save")}
                </Button>
            </div>
        </form>
    );
};

const GenerateAdjustmentFields = ({ adjustments, t, errors, control, getValues }) => {
    return adjustments.map((adjustment) => {
        return (
            <>
                <div className='v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12 mt-5 md:mt-2 invoice__manager__adjustments'>
                    <p className='text-sm'>Date: {adjustment.date}</p>
                    <p className='text-sm'>Price: {adjustment.price}</p>
                    <p className='text-sm'>Notes: {adjustment.notes}</p>
                </div>
                { /*
                <Controller
                    render={({ field }) => (
                        <FormSelect
                            groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12 mt-5 md:mt-2 ", {
                                "v__form__group--incorrect": errors?.adjustments,
                            })}
                            label={t("chooseDriver")}
                            isAsync
                            asyncSearch
                            isMulti
                            useFetch={useDDR}
                            isGrouped
                            getValues={getValues}
                            isClearable
                            queryConfig={{
                                custom: {
                                    page: 0,
                                    per_page: 10,
                                },
                            }}
                            optionsParse={{ value: "id", label: "ddr_name" }}
                            {...field}
                        />
                    )}
                    control={control}
                    name={"adjustments[" + adjustment.id + "]"}
                    key={"adjustment_" + adjustment.id}
                    rules={{}}
                />
                */ }

                <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                    <hr />
                </div>
            </>
        );
    });
};

// const GenerateSNumberFields = ({ snumbers, t, errors, control, user }) => {
//     return snumbers.map((snumber) => {
//         return (
//             <Controller
//                 render={({ field }) => (
//                     <FormSelect
//                         groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
//                             "v__form__group--incorrect": errors?.s_number,
//                         })}
//                         label={t("chooseSNumber", { value: snumber })}
//                         isAsync
//                         asyncSearch
//                         useFetch={useDDR}
//                         isGrouped
//                         queryConfig={{
//                             custom: {
//                                 page: 0,
//                                 per_page: 10,
//                             },
//                         }}
//                         optionsParse={{ value: "id", label: "ddr_name" }}
//                         {...field}
//                     />
//                 )}
//                 control={control}
//                 name={"snumbers[" + snumber + "]"}
//                 defaultValue={null}
//                 key={"snumber_" + snumber}
//                 rules={{}}
//             />
//         );
//     });
// };

export default InvoiceManagerEditForm;
