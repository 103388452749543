import { useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { useWindowSize } from "../../hooks";
import { useApp } from "../../context";
import { Badge } from "../ui";
import { Tip } from "..";
import { useTranslation } from "react-i18next";

const SwitchGroup = ({
	loading,
	options = [],
	onChange,
	activeOption,
	readjustPosition,
	id = null,
	hint = null,
	dark = false,
	small = false,
	xSmall = false,
	className,
}) => {
	const { t } = useTranslation();

	const switchGroupSelectorRef = useRef();
	const { width } = useWindowSize();

	const { localSidebar, sidebar } = useApp();

	const adjustSelectionIndicator = () => {
		const optionsList = switchGroupSelectorRef.current?.parentNode?.children;
		if (optionsList) {
			for (const item of optionsList) {
				if (item.classList.contains("v__switch__group__item--active")) {
					switchGroupSelectorRef.current.style.left = `${item.offsetLeft}px`;
					switchGroupSelectorRef.current.style.width = `${item.offsetWidth}px`;
				}
			}
		}
	};

	useEffect(() => {
		adjustSelectionIndicator();
		return () => {
			adjustSelectionIndicator();
		};
	}, [activeOption, options, width, readjustPosition]);

	useLayoutEffect(() => {
		adjustSelectionIndicator();
		return () => {
			adjustSelectionIndicator();
		};
	}, [localSidebar, sidebar]);

	return (
		<div
			className={cn(
				"v__switch__group",
				{
					disabled: loading,
					"v__switch__group--dark": dark,
					"v__switch__group--sm": small,
					"v__switch__group--xs": xSmall,
				},
				className
			)}
		>
			{hint && <Tip message={t("performanceTip")} icon='help_outline' className='pr-2' />}

			<div className='v__switch__group__container'>
				<div className={cn("v__switch__group__selector", !activeOption?.value && "hide")} ref={switchGroupSelectorRef} />
				{options.map((item) => (
					<label
						htmlFor={id ? `${id}_${item.value}_type` : `${item.value}_type`}
						key={item.value}
						className={`v__switch__group__item ${activeOption?.value === item.value ? "v__switch__group__item--active" : ""}`}
					>
						<input
							type='radio'
							name='type'
							id={id ? `${id}_${item.value}_type` : `${item.value}_type`}
							value={item.value}
							tabIndex={0}
							onChange={() => onChange?.(item)}
							checked={activeOption?.value === item.value}
						/>
						<div className='v__switch__group__item__label'>
							{item.label}
							{item.secondaryLabel && (
								<Badge type='warning' className='v__switch__group__item__label__secondary'>
									{item.secondaryLabel}
								</Badge>
							)}
						</div>
					</label>
				))}
			</div>
		</div>
	);
};

SwitchGroup.propTypes = {
	loading: PropTypes.bool,
	options: PropTypes.array,
	onSelect: PropTypes.func,
	activeOption: PropTypes.object,
	hint: PropTypes.string,
	id: PropTypes.string,
	small: PropTypes.bool,
	xSmall: PropTypes.bool,
	dark: PropTypes.bool,
};

export default SwitchGroup;
