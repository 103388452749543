import { useCallback, useState } from "react";
import { ActionsCell, Button, Icon, StatisticsTable } from "components";
import { useDataTableGenerate } from "hooks";
import { useInvoicesProfitPreview } from "hooks/query/invoices";
import { InvoiceManagerPriceEditModal, InvoiceManagerProfitEditModal } from "modules/invoice-manager";

const InvoiceShowProfitTable = ({ week, year, user_id, tour_category_id, type, amount }) => {

    const [tourModal, setTourModal] = useState({
        state: false,
        payload: null,
    });

    const [changePriceModal, setChangePriceModal] = useState({
        state: false,
        payload: null,
    });



    const closeModal = () => setTourModal({ state: false, payload: null });
    const closeChangePriceModal = () => setChangePriceModal({ state: false, payload: null });

    const columns = useCallback((columns) => {
        return columns.map((col) => {

            const { title, accessor, disableSortBy, field, type, ...rest } = col;

            const columnProps = {
                Header: title,
                accessor,
                disableSortBy,
                id: field,
                sortDescFirst: true,
                icon: type,
                ...rest,
            };


            if (col.accessor === "profit_actions") {
                return {
                    ...columnProps,
                    Cell: ({ value, row }) => {
                        if (row.original.id === "TOT" || row.original.id === "AVG") {
                            return null;
                        }

                        return (
                            <>
                                <ActionsCell>
                                    <Button small bg status='warning' onClick={() => setChangePriceModal({ payload: row.original, state: true })}>
                                        <Icon style={{ "fontFamily": "Material Icons Outlined" }} >account_balance</Icon>
                                    </Button>

                                    <Button small bg status='warning' onClick={() => setTourModal({ payload: row.original, state: true })}>
                                        <Icon style={{ "fontFamily": "Material Icons Outlined" }} >edit</Icon>
                                    </Button>
                                </ActionsCell>
                            </>
                        );
                    },
                };
            }


            return columnProps;
        });
    }, []);

    //how to fire the query when the step is the right one
    const { tableProps, hasOverall, query: { refetch } } = useDataTableGenerate(useInvoicesProfitPreview, columns, {
        user_id,
        tour_category_id,
        week,
        year,
        type,
        amount,
    });

    return (
        <>
            {changePriceModal.state && <InvoiceManagerPriceEditModal payload={changePriceModal.payload} refetch={refetch} onClose={closeChangePriceModal} />}
            {tourModal.state && <InvoiceManagerProfitEditModal payload={tourModal.payload} refetch={refetch} onClose={closeModal} />}

            <StatisticsTable {...tableProps} hasOverallDataRows={hasOverall} scrollToTopOnChange dark />
        </>
    );

};

export default InvoiceShowProfitTable;
