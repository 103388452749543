import { useTranslation } from "react-i18next";
import { REPORT_GROUP_BY_TYPES } from "reducers/const";

export function ReportsPrintFilterState({ columnPreset, columns, state, queryParams }) {
	const { t } = useTranslation();
	return (
		<table className='screen-hide print-only-table'>
			<thead>
				<tr>
					<th>{t("preset")}</th>
					<th>{t("time")}</th>
					<th>{t("groupBy")}</th>
					<th>{t("filters")}</th>
					<th>{t("advancedFilters")}</th>
					<th>{t("sortedColumns")}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{columnPreset?.value?.replace("_", " ")}</td>
					<td>{parsePrintTime(queryParams, t)}</td>
					<td>{parsePrintGroupBy(queryParams?.groupBy, t)}</td>
					<td>{parsePrintFilters(state, t)}</td>
					<td>{parsePrintReportsAdvancedFilteredColumns(columns, queryParams?.filter)}</td>
					<td>{parsePrintReportsSortedColumns(columns, queryParams?.sortBy, t)}</td>
				</tr>
			</tbody>
		</table>
	);
}

export function ReportsPrintViewPageFilterState({ columnPreset, columns, queryParams }) {
	const { t } = useTranslation();
	return (
		<table className='screen-hide print-only-table'>
			<thead>
				<tr>
					<th>{t("preset")}</th>
					<th>{t("time")}</th>
					<th>{t("groupBy")}</th>
					<th>{t("advancedFilters")}</th>
					<th>{t("sortedColumns")}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{columnPreset?.value?.replace("_", " ")}</td>
					<td>{parsePrintTime(queryParams, t)}</td>
					<td>{parsePrintGroupBy(queryParams?.groupBy, t)}</td>
					<td>{parsePrintReportsAdvancedFilteredColumns(columns, queryParams?.filter)}</td>
					<td>{parsePrintReportsSortedColumns(columns, queryParams?.sortBy, t)}</td>
				</tr>
			</tbody>
		</table>
	);
}

export function ReportsPrintQuicKViewFilterState({ viewing, queryParams, columnPreset, columns }) {
	const { t } = useTranslation();
	return (
		<table className='screen-hide print-only-table'>
			<thead>
				<tr>
					<th>{t("viewing")}</th>
					<th>{t("preset")}</th>
					<th>{t("time")}</th>
					<th>{t("advancedFilters")}</th>
					<th>{t("sortedColumns")}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{viewing}</td>
					<td>{columnPreset?.value?.replace("_", " ")}</td>
					<td>{parsePrintTime(queryParams, t)}</td>
					<td>{parsePrintReportsAdvancedFilteredColumns(columns, queryParams?.filter)}</td>
					<td>{parsePrintReportsSortedColumns(columns, queryParams?.sortBy, t)}</td>
				</tr>
			</tbody>
		</table>
	);
}

export function PrintAccountType({ currentAccount, accountType }) {
	const { t } = useTranslation();

	const data = {
		isGroup: accountType === "member_group_id",
		isDepo: accountType === "depo_id",
		isSp: accountType === "service_partner_id",
		isUser: accountType === "user_id",
	};

	return (
		<div className='profile__print screen-hide'>
			<div className='profile__print__user'>
				<div className='profile__print__user__division'>{currentAccount?.current_division?.icon}</div>
				<div className='profile__print__user__info'>
					<h2 className='print:text-2xl'>{currentAccount?.title || currentAccount?.full_name}</h2>
					<p>
						{t("rate")} : {currentAccount?.current_division?.rate}
					</p>
				</div>
				{data.isUser && (
					<div className='user__connections'>
						<div className='user__connections__item'>
							<small>{t("servicePartner")}</small>
							<p>{currentAccount?.service_partners?.map((x) => x?.title).join(", ")}</p>
						</div>
						<div className='user__connections__item'>
							<small>{t("depos")}</small>
							<p>{currentAccount?.joined_depos?.map((x) => x?.title).join(", ")}</p>
						</div>
						<div className='user__connections__item'>
							<small>{t("group")}</small>
							<p>{currentAccount?.group?.title}</p>
						</div>
					</div>
				)}
				{data.isDepo && (
					<div className='staff__members'>
						<div className='staff__members__item'>
							<small>{t("servicePartners")}</small>
							<div>
								{currentAccount?.servicePartners
									?.slice(0, 5)
									?.map((x) => x?.title)
									.join(", ")}
								<div className='staff__members__left'>
									+ {currentAccount?.servicePartners?.length > 5 ? currentAccount?.servicePartners?.length - 5 : 0}{" "}
									{t("more")}
								</div>
							</div>
						</div>
					</div>
				)}
				{data.isSp && (
					<div className='staff__members'>
						<div className='staff__members__item'>
							<small>{t("drivers")}</small>
							<div>
								{currentAccount?.staff
									?.slice(0, 5)
									?.map((x) => x?.full_name)
									.join(", ")}
								<div className='staff__members__left'>
									+ {currentAccount?.staff?.length > 5 ? currentAccount?.staff?.length - 5 : 0} {t("more")}
								</div>
							</div>
						</div>
					</div>
				)}
				{data.isGroup && (
					<div className='staff__members'>
						<div className='staff__members__item'>
							<small>{t("drivers")}</small>
							<div>
								{currentAccount?.membersLimited?.map((x) => x.full_name).join(", ")}
								<div className='staff__members__left'>
									+{" "}
									{currentAccount?.members_count > 5
										? currentAccount?.members_count - currentAccount?.membersLimited?.length
										: 0}{" "}
									{t("more")}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className='profile__print__divisions'>
				<table className='veniway__report__table'>
					<thead>
						<tr>
							<th>{t("year")}</th>
							<th>{t("division")}</th>
						</tr>
					</thead>
					<tbody>
						{currentAccount?.divisions.map((item) => (
							<tr key={item.year}>
								<td>{item.year}</td>
								<td>
									{item.icon}
									<span>{item.name}</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

function parsePrintGroupBy(groupBy, t) {
	if (Object.values(REPORT_GROUP_BY_TYPES).find((x) => x === groupBy)) {
		return t(`${Object.values(REPORT_GROUP_BY_TYPES).find((x) => x === groupBy)}`);
	}
}

function parsePrintFilters(state, t) {
	const depo = state?.depo_id?.label;
	const sp = state?.service_partner_id?.label;
	const group = state?.member_group_id?.label;
	const user = state?.user_id?.label;

	return [
		{ value: depo, key: "depo" },
		{ value: sp, key: "sp" },
		{ value: group, key: "group" },
		{ value: user, key: "driver" },
	]
		.filter((x) => x.value)
		.map(({ value, key }) => `${t(key)}: ${value}`)
		.join("; ");
}

function parsePrintTime(params, t) {
	return (
		<>
			{params.week && `${t("week")} `}
			{params.month && `${t("month")} `}
			{[new Date(params.date)?.toLocaleDateString("en-US"), params.week, params.month].filter(Boolean)}
			{params.year && `${[params.week, params.month].filter(Boolean).length > 0 ? ` | ` : ""}${params.year}`}
		</>
	);
}

function parsePrintReportsAdvancedFilteredColumns(columns, filters) {
	if (!filters) return "";
	if (typeof filters !== "object") return "";

	return Object.entries(filters)
		.filter(([, a]) => a.changed)
		.map(([key, val]) => {
			return `${columns.find((y) => y?.id === key)?.Header}: (${val.min} - ${val.max})`;
		})
		.join("; ");
}

function parsePrintReportsSortedColumns(columns, sortByParams = [], t) {
	if (typeof sortByParams !== "string") return "";

	const explodedSortByParams = sortByParams.split(",");
	return explodedSortByParams
		.map((x) => {
			const [dir, key] = x.split(/(\+|-)/).filter(Boolean);
			const directions = {
				"-": "desc",
				"+": "asc",
			};
			return `${columns.find((y) => y?.id === key)?.Header}: ${t(directions[dir])}`;
		})
		.join("; ");
}
