import { PcnApi } from "api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "utils";

export const pcnKeys = {
	all: () => ["pcn"],
	byId: (id) => [...pcnKeys.all(), id],
	byConfig: (config) => [...pcnKeys.all(), config],
};


export const usePcns = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(pcnKeys.byConfig(custom), () => PcnApi.getPcns(custom), restConfig);
};

export const usePcn = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(pcnKeys.byId(id), () => PcnApi.getPcn(id, custom), restConfig);
};


export const useCreatePcn = (config) => {
	const queryClient = useQueryClient();
	return useMutation(PcnApi.createPcn, {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(pcnKeys.all());
		}, config?.onSuccess),
	});
};


export const useUpdatePcn = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => PcnApi.updatePcn(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(pcnKeys.all());
		}, config?.onSuccess),
	});
};


