import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { FormDropzone, FormInput, FormSelect } from "../../components/forms";
import { useDepoStaff } from "../../hooks";
import { validateFile } from "../../utils";
import { Button, Icon } from "../../components";
import ComingSoonSvg from "components/ui/graphics/ComingSoonSVG";
import { StepServicePartnerList } from "..";

const MAX_IMG_SIZE = process.env.REACT_APP_MAX_IMAGE_UPLOAD_SIZE_MB;

const SPList = ({ servicePartners, onRemove }) => {
	const { t } = useTranslation();

	if (Object.keys(servicePartners).length === 0) {
		return (
			<div
				className='v__list-selection__result__group text-center flex flex-col items-center  
                                         h-full justify-center '
			>
				<ComingSoonSvg classes='v__coming-soon__graphic !w-44 ' withAnimation={true} />
				<span>{t("nothingSelected")}</span>
			</div>
		);
	}

	return Object.entries(servicePartners).map((entry) => {
		const key = entry[0];
		const item = entry[1];

		if (!Boolean(item.checked)) {
			return null;
		}
		return (
			<div className='v__list-selection__result__group' key={key}>
				<div className='v__list-selection__result__group__title'>
					<span>{item.title ?? t("others")}</span>
					{/*
					<Badge type='outline' size='md'>
						{Object.values(item.members).filter((option) => option.checked).length}
						THIS WAS COOMMENTED{{item?.max_choices ? ` / ${item.max_choices}` : ""} }
                    </Badge>
                    */}
					<Button small round bg status='darker' hoverStatus='danger' onClick={() => onRemove?.({ sp: item, value: entry })}>
						<Icon>delete</Icon>
					</Button>
				</div>
			</div>
		);
	});
};

const GroupForm = ({ onSubmit, onCancel, defaultValues: { service_partners, image_path, ...otherDefaultValues } }) => {
	const { t } = useTranslation();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({ defaultValues: otherDefaultValues });

	const [list, setList] = useState(service_partners ?? {});

	const handleOnSelectSP = (v) => {
		setList((prev) => {
			return {
				...prev,
				[v.value.sp.id]: {
					id: v.value.sp.id,
					title: v.value.sp.title,
					checked: v.value.checked,
				},
			};
		});
	};

	const handleOnRemoveSP = (v) => {
		setList((prev) => {
			return {
				...prev,
				[v.sp.id]: {
					checked: false,
				},
			};
		});
	};

	useEffect(() => {
		setValue("service_partners", list);
	}, [list, setValue]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
			<Controller
				render={({ field }) => (
					<FormSelect
						groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
							"v__form__group--incorrect": errors?.owner_id,
						})}
						label={t("owner")}
					    isAsync	
						asyncSearch
						useFetch={useDepoStaff}
						isGrouped
						queryConfig={{
							custom: {
								page: 0,
								per_page: 10,
							},
						}}
						groupParse={{ label: "title", options: "staff" }}
						optionsParse={{ value: "id", label: "full_name" }}
						error={errors?.owner_id && t(`${errors?.owner_id?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='owner_id'
				defaultValue={null}
				rules={{ required: true }}
			/>
			<Controller
				render={({ field }) => (
					<FormInput
						groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
							"v__form__group--incorrect": errors?.title,
						})}
						label={t("title")}
						error={errors?.title && t(`${errors?.title?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='title'
				defaultValue=''
				rules={{ required: true }}
			/>
			<Controller
				render={({ field: { onChange, ...rest } }) => (
					<FormDropzone
						groupClassName={cn("v__grid__item v__grid__item--col-12", {
							"v__form__group--incorrect": errors?.image_path,
						})}
						label={t("image")}
						prevFile={image_path}
						accept='image/png, image/jpeg'
						error={errors.image_path?.message}
						onChange={(e) => onChange(e.target.files[0])}
						{...rest}
					/>
				)}
				control={control}
				name='image_path'
				defaultValue=''
				rules={{ validate: (v) => validateFile(v) || t("maxFileSize", { sizeInMb: MAX_IMG_SIZE }) }}
			/>
			<div
				className={cn("v__form__group v__grid__item v__grid__item--col-12", {
					// "v__form__group--incorrect": optionsError,
				})}
			>
				<label htmlFor={"group-options"} className={`v__form__group__label`}>
					<span>{t("groupChoices")}</span>
				</label>
				<div className='v__list-selection'>
					<div className='v__list-options pt-2'>
						<StepServicePartnerList onSelect={handleOnSelectSP} checked={list} />
					</div>
					<div className='v__list-selection__result'>
						<SPList servicePartners={list} onRemove={handleOnRemoveSP} />
					</div>
				</div>
			</div>
			<div className='v__form__footer v__grid__item v__grid__item--col-12'>
				<Button bg status='darker' onClick={onCancel}>
					{t("cancel")}
				</Button>
				<Button bg status='accent' type='submit'>
					{t("save")}
					{/* {payload ? lang?.save_changes : lang?.add_group} */}
				</Button>
			</div>
		</form>
	);
};

export default GroupForm;
