import { useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import cn from "classnames";

import { Button, Card, Icon } from "../../components";
import { FormInput, FormSelect } from "components/forms";
import { useRegister, useRefNumberFinder } from "../../hooks";
import { Avatar, Badge, Spinner } from "components/ui";
import { useAuth } from "context";
import toast from "react-hot-toast";

const lowerCharReq = /(?=.*[a-z])/g;
const upperCharReq = /(?=.*[A-Z])/g;
const digitReq = /(?=.*\d)/g;
const lengthReq = /[a-zA-Z0-9]{8,}/g;

const selectOptions = [
	{ value: "+359", label: "+359" },
	{ value: "+44", label: "+44" },
];

const Register = () => {
	const { t } = useTranslation();
	const { setToken } = useAuth();

	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm();

	const watchPass = watch("password");

	const [matchedAccount, setMatchedAccount] = useState();
	const [storedRefNumber, setStoredRefNumber] = useState();

	const { mutate: finder, isLoading: isLoadingSnumber } = useRefNumberFinder({
		onSuccess: (data) => {
			setMatchedAccount(data.data);
		},
		onError: (err) => {
			toast.error(err?.response?.data?.errors[0]);
		},
	});

	const { mutate: register, isLoading: isLoadingRegister } = useRegister({
		onSuccess: (res) => setToken(res.access_token),
	});

	const onSubmit = (data) => {
		if (!matchedAccount) {
			setStoredRefNumber(data.ref_number);
			return finder(data.ref_number);
		}
		const parsedData = { ...data, phone_code: data.phone_code?.value, ref_number: storedRefNumber };
		register(parsedData);
	};

	const isLoading = isLoadingSnumber || isLoadingRegister;

	return (
		<>
			<Helmet>
				<title>Veniway | {t("login")}</title>
			</Helmet>
			<Card className='auth__form__card'>
				<h1 className='v__heading'>{t("register")}</h1>
				<form onSubmit={handleSubmit(onSubmit)} className='v__grid v__auth__form'>
					{!matchedAccount && (
						<Controller
							render={({ field }) => (
								<FormInput
									label={t("ref_number")}
									groupClassName={cn("v__grid__item v__grid__item--col-12", {
										"v__form__group--incorrect": errors?.ref_number,
									})}
									type='text'
									error={errors?.ref_number && t(`${errors?.ref_number?.type}`)}
									{...field}
								/>
							)}
							control={control}
							name='ref_number'
							defaultValue=''
							rules={{ required: true }}
						/>
					)}

					{matchedAccount && <RegisterForm {...{ matchedAccount, control, errors, watchPass }} />}

					<div className='v__auth__form__footer v__grid__item v__grid__item--col-12'>
						<Button
							bg
							status='accent'
							type='submit'
							className='v__submit__btn'
							iconRight={isLoading ? <Spinner size={16} singleColor /> : null}
						>
							{matchedAccount ? t("register") : t("findPerson")}
						</Button>
						<span>
							{t("alreadyAccount")} <Link to='/login'>{t("login")}</Link>
						</span>
					</div>
				</form>
			</Card>
		</>
	);
};

function RegisterForm({ matchedAccount, control, errors, watchPass }) {
	const { t } = useTranslation();

	const [visiblePass, setVisiblePass] = useState(false);

	return (
		<>
			<div className='w-100'>
				<div className='v__grid__item v__grid__item--col-12'>
					<div className='snumber__matched no-hover'>
						<div className='snumber__matched__container'>
							<Avatar user={matchedAccount} />
							<span className='snumber__matched__name'>{matchedAccount?.full_name}</span>
							<Badge type='warning'>{Boolean(matchedAccount?.bot) ? t("unclaimed") : t("claimed")}</Badge>
						</div>
						<hr />
						<div className='snumber__matched__relations'>
							<small>{t("depos")}:</small>
							{matchedAccount?.joined_depos?.length > 0 ? (
								<Badge type='outline'>{matchedAccount?.joined_depos?.[0]?.title}</Badge>
							) : (
								<Badge type='danger'>{t("none")}</Badge>
							)}
						</div>
						<div className='snumber__matched__relations'>
							<small>{t("servicePartners")}:</small>
							{matchedAccount?.service_partners?.length > 0 ? (
								<Badge type='outline'>{matchedAccount?.service_partners?.[0]?.title}</Badge>
							) : (
								<Badge type='danger'>None</Badge>
							)}
						</div>
						<div className='snumber__matched__relations'>
							<small>{t("ddr_name")}:</small>
							<Badge type='outline'>{matchedAccount?.bot_name}</Badge>
						</div>
					</div>
				</div>
			</div>

			<Controller
				render={({ field }) => (
					<FormInput
						label={t("email")}
						groupClassName={cn("v__grid__item v__grid__item--col-12", {
							"v__form__group--incorrect": errors?.email,
						})}
						type='email'
						error={errors?.email && t(`${errors?.email?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='email'
				defaultValue=''
				rules={{ required: true }}
			/>

			<div className='v__grid__item v__grid__item--col-12 mb-4'>
				<div className='flex space-x-4 items-center'>
					<Controller
						render={({ field }) => (
							<FormSelect options={selectOptions} preSelect label={t("phone")} groupClassName='w-24' {...field} />
						)}
						control={control}
						name='phone_code'
						defaultValue={selectOptions[0]}
						rules={{ required: true }}
					/>

					<Controller
						render={({ field }) => <FormInput groupClassName='grow' {...field} />}
						control={control}
						name='phone'
						defaultValue=''
						rules={{ required: true }}
					/>
				</div>
			</div>

			<Controller
				render={({ field }) => (
					<FormInput
						label={t("password")}
						groupClassName={cn("v__grid__item v__grid__item--col-12", {
							"v__form__group--incorrect": errors?.password,
						})}
						type={visiblePass ? "text" : "password"}
						error={errors?.password && t(`${errors?.password?.type}`)}
						{...field}
					/>
				)}
				control={control}
				name='password'
				defaultValue=''
				rules={{ required: true }}
			/>
			<div className='v__grid__item v__grid__item--col-12 mb-4'>
				<ul className='v__pass__req'>
					<p>Password Requirements:</p>
					<li>
						{watchPass?.match(digitReq)?.length > 0 ? (
							<Icon className='text-green'>done</Icon>
						) : (
							<Icon className='text-red'>close</Icon>
						)}{" "}
						Min 1 digit
					</li>
					<li>
						{watchPass?.match(lowerCharReq)?.length > 0 ? (
							<Icon className='text-green'>done</Icon>
						) : (
							<Icon className='text-red'>close</Icon>
						)}{" "}
						Min 1 lowercase character
					</li>
					<li>
						{watchPass?.match(upperCharReq)?.length > 0 ? (
							<Icon className='text-green'>done</Icon>
						) : (
							<Icon className='text-red'>close</Icon>
						)}{" "}
						Min 1 UPPERcase character
					</li>
					<li>
						{watchPass?.match(lengthReq)?.length > 0 ? (
							<Icon className='text-green'>done</Icon>
						) : (
							<Icon className='text-red'>close</Icon>
						)}{" "}
						Min 8 characters
					</li>
				</ul>
			</div>

			<Controller
				render={({ field }) => (
					<div
						className={cn("v__form__group v__grid__item v__grid__item--col-12", {
							"v__form__group--incorrect": errors?.password_confirmation,
						})}
					>
						<label htmlFor='password_confirmation' className='v__form__group__label'>
							<span>{t("password_confirmation")}</span>
						</label>
						<div className='v__input__group'>
							<input className='v__input' type={visiblePass ? "text" : "password"} id='password_confirmation' {...field} />
							<Button
								spongy={false}
								round
								small
								className='v__input__group__add-on'
								onClick={() => setVisiblePass(!visiblePass)}
							>
								{visiblePass ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
							</Button>
						</div>
						{errors?.password_confirmation && (
							<small className='v__form__group__hint'>{t(`${errors?.password_confirmation?.type}`)}</small>
						)}
					</div>
				)}
				control={control}
				name='password_confirmation'
				defaultValue=''
				rules={{
					required: Boolean(watchPass),
					validate: {
						sameAsPass: (value) => value === watchPass,
					},
				}}
			/>
		</>
	);
}

export default Register;
