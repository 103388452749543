import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useApp } from "../../../context";
import { useWindowSize } from "../../../hooks";

import { Button, Icon } from "../../../components";
import { VeniwayFullLogo, VeniwayIconLogo } from "../../../components/ui";

const TopBarBrand = () => {
    const { t } = useTranslation();

    // REFACTOR:
    const { width } = useWindowSize();
    const isDesktop = width > 1200;

    const { localSidebar, toggleSidebar, toggleLocalSidebar } = useApp();

    const handleToggle = () => {
        if (!isDesktop) {
            toggleLocalSidebar();
        } else {
            toggleSidebar();
        }
    };

    const LogoLink = ({ alwaysFull = false }) => (
        <Link to='/'>
            <span style={{ fontSize: "0px" }}>Veniway {t("dashboard")}</span>
            {alwaysFull && !isDesktop ? <VeniwayFullLogo /> : !alwaysFull && isDesktop ? <VeniwayFullLogo /> : <VeniwayIconLogo />}
        </Link>
    );

    const Brand = ({ alwaysFull = false }) => {
        return (
            <>
                <div className='v__topbar__brand__name' title={`Veniway ${t("dashboard")}`}>
                    <LogoLink alwaysFull={alwaysFull} />
                </div>
                <Button round title='Toggle Sidebar' className='v__topbar__brand__trigger' onClick={handleToggle}>
                    <Icon>menu</Icon>
                </Button>
            </>
        );
    };

    return (
        <div className='v__topbar__brand'>
            <Brand />
            {!isDesktop && localSidebar && (
                <div className={cn("v__topbar__sidebar__overlap")}>
                    <Brand alwaysFull />
                </div>
            )}
        </div>
    );
};

export default TopBarBrand;
