import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { InvoiceManagerEditForm, InvoiceManagerForm, InvoiceManagerView } from ".";
import { useCreateInvoiceManager, useInvoiceManager, useUpdateInvoiceManager } from "hooks/query/invoiceManager";

const InvoiceManagerModal = ({ payload, onClose, view }) => {
    const { t } = useTranslation();

    const { data: invoice } = useInvoiceManager(payload?.id, {
        enabled: Boolean(payload?.id),
    });

    const { mutate: create, isLoading } = useCreateInvoiceManager({
        onSuccess: (res) => {
            onClose();
        },
    });

    const { mutate: update } = useUpdateInvoiceManager({
        onSuccess: (res) => {
            onClose();
        },

    });

    const handleOnSubmit = (data) => {
        create(data);
    };


    const handleOnUpdate = (data) => {
        update({ id: payload.id, data });
    };

    const invoiceData = invoice?.invoice_manager;
    let defaultValues = {};
    defaultValues["adjustments"] = [];


    if (invoiceData && invoiceData?.adjustments) {
        invoiceData.adjustments.forEach((item) => {
            defaultValues["adjustments"][item.id] = [];
            if (item.ddrs) {
                item.ddrs.forEach((ddr) => {
                    defaultValues["adjustments"][item.id].push(ddr);
                });

            }
        });
    }



    return (
        <Modal onClose={onClose}>
            <Card
                className='v__modal v__card--overflow-able'
                header={!Boolean(payload) ? t("createInvoiceManager") : t("fillExtraFields")}
                relativeLoading={isLoading}
                dismissible={onClose}
            >
                {!Boolean(payload) && <InvoiceManagerForm onSubmit={handleOnSubmit} onCancel={onClose} />}
                {
                    Boolean(invoice) && !view && <InvoiceManagerEditForm payload={invoice} onSubmit={handleOnUpdate} defaultValues={defaultValues} onCancel={onClose} />
                }

                {Boolean(invoice) && view && <InvoiceManagerView payload={invoice} onCancel={onClose} />}
            </Card>
        </Modal>
    );
};

export default InvoiceManagerModal;
