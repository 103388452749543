import axios from "axios";

export const Headers = {
	FORMDATA: { "Content-Type": "multipart/form-data" },
};

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export const bearerInterceptor = (config) => {
	const token = localStorage[`${process.env.REACT_APP_TOKEN_KEY}`];
	if (token) {
		const parsedToken = token.includes('"') ? JSON.parse(token) : token;
		config.headers.Authorization = `Bearer ${parsedToken}`;
	}
	return config;
};

export const unauthorizedInterceptor = (err) => {
	if (err?.response?.status === 401) {
		localStorage.clear();
		return window.location.replace("/login");
	}
	return Promise.reject(err);
};

instance.interceptors.request.use(bearerInterceptor);
instance.interceptors.response.use(null, unauthorizedInterceptor);

export default instance;
