import cn from "classnames";

const AuthProviding = ({ className, ...rest }) => {
	return (
		<svg width='864' height='789' viewBox='0 0 864 789' className={cn("auth__graphic", className)} {...rest}>
			<defs>
				<linearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='gray' stopOpacity='0.251' />
					<stop offset='0.54' stopColor='gray' stopOpacity='0.122' />
					<stop offset='1' stopColor='gray' stopOpacity='0.102' />
				</linearGradient>
			</defs>
			<g transform='translate(-485 -169)'>
				<rect className='a' width='393' height='543' transform='translate(723.5 222)' />
				<rect className='b' width='383' height='531' transform='translate(728.5 226)' />
				<rect className='c' width='186' height='44' transform='translate(827.5 212)' />
				<path
					className='c'
					d='M604.72,54.5a26,26,0,1,0,26,26A26,26,0,0,0,604.72,54.5Zm0,41a15,15,0,1,1,15-15A15,15,0,0,1,604.72,95.5Z'
					transform='translate(315.78 114.5)'
				/>
				<rect className='d' width='76' height='76' transform='translate(749.5 315)' />
				<rect className='c' width='76' height='76' transform='translate(749.5 476)' />
				<rect className='c' width='76' height='76' transform='translate(749.5 637)' />
				<rect className='e' width='104.17' height='7.27' transform='translate(875.5 338.46)' />
				<rect className='e' width='172' height='7.27' transform='translate(875.5 349.37)' />
				<rect className='e' width='159.89' height='7.27' transform='translate(875.5 360.27)' />
				<rect className='e' width='104.17' height='7.27' transform='translate(875.5 499.46)' />
				<rect className='e' width='172' height='7.27' transform='translate(875.5 510.37)' />
				<rect className='e' width='159.89' height='7.27' transform='translate(875.5 521.27)' />
				<rect className='e' width='104.17' height='7.27' transform='translate(875.5 660.46)' />
				<rect className='e' width='172' height='7.27' transform='translate(875.5 671.37)' />
				<rect className='e' width='159.89' height='7.27' transform='translate(875.5 682.27)' />
				<path className='b' d='M293.5,182l12,11,17-25,6,5-23,30-17-19Z' transform='translate(479 167)' />
				<path className='b' d='M293.5,344l12,11,17-25,6,5-23,30-17-19Z' transform='translate(479 167)' />
				<path className='b' d='M293.5,506l12,11,17-25,6,5-23,30-17-19Z' transform='translate(479 167)' />
				<line className='f' x2='6' transform='translate(1130 518)' />
				<line className='g' x2='132.69' transform='translate(1147.54 518)' />
				<path className='f' d='M807,351h6v-6' transform='translate(479 167)' />
				<line className='h' y1='108.82' transform='translate(1292 391.73)' />
				<line className='f' y1='6' transform='translate(1292 380)' />
				<path className='i' d='M231.5,182.5H97.5v126' transform='translate(479 167)' />
				<path className='i' d='M308.5,611.5v72h200' transform='translate(479 167)' />
				<path className='j' d='M754,176V71h85V176Z' transform='translate(479 167)' />
				<rect className='c' width='62' height='4' transform='translate(1244 270)' />
				<rect className='c' width='62' height='4' transform='translate(1244 286)' />
				<rect className='k' width='166' height='167' transform='translate(1024 791)' />
				<rect className='b' width='146' height='128' transform='translate(1035 796)' />
				<path
					className='e'
					d='M865.22,768.5c-40.35,0-73-10.29-73-23-40.35,0-73,8.95-73,20v48h146Z'
					transform='translate(315.78 114.5)'
				/>
				<circle className='c' cx='15' cy='15' r='15' transform='translate(1142 809)' />
				<rect className='b' width='192' height='136' transform='translate(485 491)' />
				<path
					className='e'
					d='M269.17,410.84a37.294,37.294,0,0,0-4.8.32l4.8,36.68-36.91-2.46c-.05.81-.09,1.63-.09,2.46a37,37,0,1,0,37-37Z'
					transform='translate(315.78 114.5)'
				/>
				<path className='c' d='M256.37,404.16a37,37,0,0,0-32.11,34.22l36.91,2.46Z' transform='translate(315.78 114.5)' />
			</g>
		</svg>
	);
};

export default AuthProviding;
