import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useReportPigments } from "../../hooks";
import { Badge, BadgeList, Spinner } from "../../components/ui";
import { generateFontColorDependingOnBg } from "../../utils";
import SettingsItem from "./SettingsItem";
import ReportPigmentsModal from "./ReportPigmentsModal";

const ReportsSection = () => {
	const { t } = useTranslation();

	const [reportPigmentsModal, setReportPigmentsModal] = useState({
		state: false,
		payload: null,
	});

	const closeModal = () => setReportPigmentsModal({ state: false, payload: null });

	const { data: pigments, isFetching, isFetched } = useReportPigments();

	const parseSavedPigmentValues = (color, pigment) => {
		if (color.min === color.max) {
			return `${color.min}${pigment === "percent" ? "%" : ""}`;
		} else {
			return `${color.min}${pigment === "percent" ? "%" : ""} - ${color.max}${pigment === "percent" ? "%" : ""}`;
		}
	};

	return (
		<SettingsItem label={t("choosePigments")} labelHint={t("choosePigmentsHint")} overflow={false}>
			{isFetching && !isFetched && (
				<div className='text-center pad-xy-2'>
					<Spinner size={40} />
				</div>
			)}
			{pigments?.columns.map((pigment) => {
				return (
					<div key={pigment.field} className='v__report__pigments__item'>
						<div
							className='v__report__pigments__item__info'
							onClick={() =>
								setReportPigmentsModal({
									state: true,
									payload: {
										column: pigment,
										data: pigments?.data?.find((entry) => entry.field === pigment.field),
									},
								})
							}
						>
							<p>{pigment.title}</p>
							<small>{pigment.description}</small>
						</div>
						<div className='v__report__pigments__item__data'>
							<BadgeList>
								{pigments?.data
									?.find((entry) => entry.field === pigment.field)
									?.colors.map((color) => (
										<Badge
											key={color.id}
											onClick={() =>
												setReportPigmentsModal({
													state: true,
													payload: {
														column: pigment,
														data: pigments?.data?.find((entry) => entry.field === pigment.field),
													},
												})
											}
											size='mid'
											style={{
												background: color.color,
												color: generateFontColorDependingOnBg(color.color),
											}}
										>
											{parseSavedPigmentValues(color, pigment.number_type)}
										</Badge>
									))}
							</BadgeList>
							{pigments?.data?.find((entry) => entry.field === pigment.accessor)?.colors.length === 0 && (
								<div className='text-red'>{t("noRulesApplied")}</div>
							)}
						</div>
					</div>
				);
			})}
			{reportPigmentsModal.state && <ReportPigmentsModal payload={reportPigmentsModal.payload} onClose={closeModal} />}
		</SettingsItem>
	);
};

export default ReportsSection;
