import { useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "../../utils";
import { TourApi } from "../../api";
import { reportKeys } from "./reports";

export const tourKeys = {
    all: () => ["tours"],
    defaultConditions: () => ["toursDefault"],
    byId: (id) => [...tourKeys.all(), id],
    byConfig: (config) => [...tourKeys.all(), config],
    byConfigDefault: (config) => [...tourKeys.defaultConditions(), config],
};

export const useTours = (config) => {
    const { custom, ...restConfig } = config;
    return useQuery(tourKeys.byConfig(custom), () => TourApi.getTours(custom), restConfig);
};

export const useTour = (id, config) => {
    const { custom, ...restConfig } = config;
    // return useQuery(tourKeys.byId(id), () => TourApi.getTour(id, custom), restConfig);
    return useQuery(tourKeys.byId(id), () => TourApi.getTour(id, custom), {
        ...restConfig,
        cacheTime: 0, // Disable caching
    });
};

export const useTourDefaultConditions = (config) => {
    const { custom, ...restConfig } = config;
    return useQuery(tourKeys.byConfigDefault(custom), () => TourApi.getDefaultTourConditions(custom), restConfig);
};

export const useUpdateToursPaymentOptions = (config) => {
    const queryClient = useQueryClient();
    return useMutation(({ data }) => TourApi.updateTourPaymentOptions(data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(tourKeys.defaultConditions());
        }, config?.onSuccess),
    });
};

export const useUpdateTour = (config) => {
    const queryClient = useQueryClient();
    return useMutation(({ id, data }) => TourApi.updateTour(id, data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(tourKeys.all());
            queryClient.invalidateQueries(reportKeys.all());
        }, config?.onSuccess),
    });
};

export const useTourDelete = (config) => {
    const queryClient = useQueryClient();
    return useMutation(async (data) => TourApi.deleteTour(data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(tourKeys.all());
        }, config?.onSuccess),
    });
};
