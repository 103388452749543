import { useTranslation } from "react-i18next";
import { Card, Modal } from "components";
import { useCard, useCreateFuel, useUpdateCard } from "hooks";
import { CardEditForm, CardUploadForm } from ".";

const CardModal = ({ payload, onClose }) => {
	const { t } = useTranslation();

	const { data: card } = useCard(payload?.id, {
		custom: {
			includes: ["owners", "owners.user", "servicePartner", "depo", "group"],
		},

		enabled: Boolean(payload?.id),
	});

	const { mutate: update } = useUpdateCard({
		onSuccess: (res) => {
			onClose();
		},
	});

	const { mutate: create, isLoading: isLoadingCreate } = useCreateFuel({
		onSuccess: (res) => {
			onClose();
		},
	});

	const cardData = card?.data;

	const defaultValues = {
		owners: Boolean(cardData) && Boolean(cardData.owners) ? cardData.owners : null,
		depo_id: Boolean(cardData) && Boolean(cardData.depo) ? { value: cardData?.depo?.id, label: cardData?.depo?.title } : null,
		group_id: Boolean(cardData) && Boolean(cardData.group) ? { value: cardData?.group?.id, label: cardData?.group?.title } : null,
		service_partner_id:
			Boolean(cardData) && Boolean(cardData.servicePartner)
				? { value: cardData?.servicePartner?.id, label: cardData?.servicePartner?.title }
				: null,

		status: Boolean(cardData) ? { value: cardData?.status, label: cardData?.statusTranslated } : null,
	};

	const handleOnSubmit = (data) => {
		if (Boolean(payload)) {
			update({ id: payload.id, data });
			return;
		}
		create(data);
	};

	const shouldRender = payload && card;

	return (
		<Modal onClose={onClose}>
			<Card
				className='v__modal v__card--overflow-able'
				header={!Boolean(payload) ? t("uploadFuelFile") : t("editCard") + cardData?.card_number}
				relativeLoading={isLoadingCreate}
				dismissible={onClose}
			>
				{!Boolean(payload) && <CardUploadForm onSubmit={handleOnSubmit} onCancel={onClose} />}
				{shouldRender && <CardEditForm defaultValues={defaultValues} onSubmit={handleOnSubmit} onCancel={onClose} />}
			</Card>
		</Modal>
	);
};

export default CardModal;
