import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { validateFile } from "../../utils";

import { FormDropzone, FormInput, FormSelect } from "../../components/forms";
import { useDepoStaff } from "../../hooks";
import { Button, FormDatePicker } from "../../components";

const MAX_IMG_SIZE = process.env.REACT_APP_MAX_IMAGE_UPLOAD_SIZE_MB;

const PcnForm = ({ onSubmit, onCancel, defaultValues: { image_path, date, due_week, status, ...otherDefaultValues } }) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: otherDefaultValues });

    const statusOptions = [
        { value: "pending", label: "Pending Payment" },
        { value: "partially_paid", label: "Partially Paid" },
        { value: "paid", label: "Paid" },
        // 													{ value: "preinvoice", label: "Preinvoice" },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
            <Controller
                render={({ field }) => (
                    <FormInput
                        groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.number,
                        })}
                        label={t("number")}
                        error={errors?.number && t(`${errors?.number?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='number'
                defaultValue=''
                rules={{ required: true }}
            />
            <Controller
                render={({ field }) => (
                    <FormInput
                        groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.van_number,
                        })}
                        label={t("van_number")}
                        error={errors?.van_number && t(`${errors?.van_number?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='van_number'
                defaultValue=''
                rules={{ required: true }}
            />

            <Controller
                render={({ field }) => (
                    <FormSelect
                        groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.user_id,
                        })}
                        label={t("driver")}
                        isAsync
                        asyncSearch
                        useFetch={useDepoStaff}
                        isGrouped
                        queryConfig={{
                            custom: {
                                page: 0,
                                per_page: 10,
                            },
                        }}
                        groupParse={{ label: "title", options: "staff" }}
                        optionsParse={{ value: "id", label: "full_name" }}
                        error={errors?.user_id && t(`${errors?.user_id?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='user_id'
                defaultValue={null}
                rules={{ required: false }}
            />

            <Controller
                render={({ field }) => (
                    <FormInput
                        groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.amount,
                        })}
                        label={t("amount")}
                        error={errors?.amount && t(`${errors?.amount?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='amount'
                defaultValue=''
                rules={{ required: true }}
            />
            <Controller
                render={({ field }) => (
                    <FormInput
                        groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.amount_for_driver,
                        })}
                        label={t("for_driver")}
                        error={errors?.amount_for_driver && t(`${errors?.amount_for_driver?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='amount_for_driver'
                defaultValue=''
                rules={{ required: true }}
            />
            <Controller
                render={({ field }) => (
                    <FormInput
                        groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.admin_fee,
                        })}
                        label={t("admin_fee")}
                        error={errors?.admin_fee && t(`${errors?.admin_fee?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='admin_fee'
                defaultValue=''
                rules={{ required: true }}
            />

            <Controller
                render={({ field }) => (
                    <FormSelect
                        groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-4", {
                            "v__form__group--incorrect": errors?.status,
                        })}
                        label={t("status")}
                        options={statusOptions}
                        error={errors?.type && t(`${errors?.type?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='status'
                defaultValue={(status != undefined) ? statusOptions.find(option => option.value === status) : statusOptions[0]}
                rules={{ required: true }}
            />
            <Controller
                render={({ field: { value, ...restField } }) => (
                    <FormDatePicker
                        groupClassName={cn(
                            "v__grid__item v__grid__item--col-4",
                            errors?.date && "v__form__group--incorrect"
                        )}
                        label={t("offence_date")}
                        error={errors?.date && t(`${errors?.date?.type}`)}
                        maxDate={false}
                        selected={value}
                        {...restField}
                    />
                )}
                control={control}
                name={"date"}
                defaultValue={(date !== undefined) ? new Date(date) : new Date()}
                rules={{ required: false }}
            />
            <Controller
                render={({ field }) => (
                    <FormInput
                        groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.due_week,
                        })}
                        label={t("due_week")}
                        error={errors?.due_week && t(`${errors?.due_week?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                defaultValue={(due_week !== undefined) ? due_week : null}
                name='due_week'
                rules={{ required: false }}
            />

            <Controller
                render={({ field: { onChange, ...rest } }) => (
                    <FormDropzone
                        groupClassName={cn("v__grid__item v__grid__item--col-12", {
                            "v__form__group--incorrect": errors?.image_path,
                        })}
                        label={t("image")}
                        prevFile={(image_path !== null) ? image_path : null}
                        accept='image/png, image/jpeg'
                        error={errors.image_path?.message}
                        onChange={(e) => onChange(e.target.files[0])}
                        {...rest}
                    />
                )}
                control={control}
                name='image_path'
                defaultValue=''
                rules={{ validate: (v) => validateFile(v) || t("maxFileSize", { sizeInMb: MAX_IMG_SIZE }) }}
            />




            <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                <Button bg status='darker' onClick={onCancel}>
                    {t("cancel")}
                </Button>
                <Button bg status='accent' type='submit'>
                    {t("save")}
                    {/* {payload ? lang?.save_changes : lang?.add_group} */}
                </Button>
            </div>
        </form>
    );
};

export default PcnForm;
