import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Avatar } from "../../ui";

const MembersCell = ({ cell }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const count = cell.row.original.members_count;

	return (
		<div className='v__table__body__row__cell__members'>
			{cell.value.map((member) => (
				<Avatar user={member} title={member.full_name} key={Math.random()} onClick={() => navigate(`/app/users/${member.id}`)} />
			))}
			{count - cell.value.length > 0 && (
				<div className='v__table__body__row__cell__members__left'>
					+ {count - cell.value.length} {t("more")}
				</div>
			)}
		</div>
	);
};

export default MembersCell;
