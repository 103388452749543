import { INVOICE_GROUP_BY_TYPES, REPORTS_ACTION_TYPES, REPORT_GROUP_BY_TYPES, REPORT_PERIOD_TYPES, REPORT_SELECTION_TYPES } from "./const";
import { getISOWeek } from "date-fns";

const YEARLY_PERIODS = [REPORT_PERIOD_TYPES.YEARLY_DAY, REPORT_PERIOD_TYPES.YEARLY_WEEK, REPORT_PERIOD_TYPES.YEARLY_MONTH];

export const initialReportsState = {
    reportType: { value: REPORT_PERIOD_TYPES.DAILY },
    selected: {
        all: false,
        year: new Date().getFullYear(),
        month: null,
        week: null,
        date: null,
    },
    groupBy: { value: REPORT_GROUP_BY_TYPES.USER_ID },
    depo_id: null,
    service_partner_id: null,
    member_group_id: null,
    user_id: null,
    filter: [],
    fileProvider: null,
    requestParams: {
        groupBy: REPORT_GROUP_BY_TYPES.USER_ID,
    },
};

export const initialInvoicesState = {
    reportType: { value: REPORT_PERIOD_TYPES.PERIOD },
    selected: {
        all: false,
        year: new Date().getFullYear(),
        month: null,
        week: getISOWeek(new Date()),
        date: null,
    },
    groupBy: { value: INVOICE_GROUP_BY_TYPES.INVOICE_ID },
    depo_id: null,
    service_partner_id: null,
    member_group_id: null,
    user_id: null,
    filter: [],
    fileProvider: null,
    requestParams: {
        groupBy: INVOICE_GROUP_BY_TYPES.INVOICE_ID,
    },
};


const isDaily = (v) => (v?.value ? v.value === REPORT_PERIOD_TYPES.DAILY : v === REPORT_PERIOD_TYPES.DAILY);
const isPeriod = (v) => (v?.value ? v.value === REPORT_PERIOD_TYPES.PERIOD : v === REPORT_PERIOD_TYPES.PERIOD);
const isYearly = (v) => (v?.value ? YEARLY_PERIODS.includes(v.value) : YEARLY_PERIODS.includes(v));
const isYearlyDay = (v) => (v?.value ? v.value === REPORT_PERIOD_TYPES.YEARLY_DAY : v === REPORT_PERIOD_TYPES.YEARLY_DAY);
const isYearlyWeek = (v) => (v?.value ? v.value === REPORT_PERIOD_TYPES.YEARLY_WEEK : v === REPORT_PERIOD_TYPES.YEARLY_WEEK);
const isYearlyMonth = (v) => (v?.value ? v.value === REPORT_PERIOD_TYPES.YEARLY_MONTH : v === REPORT_PERIOD_TYPES.YEARLY_MONTH);

const isUserSelected = (state) => Boolean(state.user_id);

const reportsReducer = (state, action) => {
    switch (action.type) {
        case REPORTS_ACTION_TYPES.REPORT_TYPE_SET: {
            return {
                ...state,
                reportType: action.payload,
                selected: determineSelectedOnTypeSwitch(state, action),
                requestParams: {
                    ...state.requestParams,
                    ...determineTimePeriodOnTypeSwitch(state, action),
                    filter: null,
                },
            };
        }
        case REPORTS_ACTION_TYPES.TIME_SELECTION_SET: {
            return determineSelection(state, action);
        }
        case REPORTS_ACTION_TYPES.GROUP_BY_SET: {
            return {
                ...state,
                reportType: isYearly(state.reportType) ? { value: REPORT_PERIOD_TYPES.PERIOD } : state.reportType,
                groupBy: action.payload,
                depo_id: null,
                service_partner_id: null,
                member_group_id: null,
                user_id: null,
                requestParams: {
                    ...state.requestParams,
                    groupBy: action.payload.value,
                    // TODO: determine timing for group change
                    depo_id: null,
                    service_partner_id: null,
                    member_group_id: null,
                    user_id: null,
                    filter: null,
                },
            };
        }
        case REPORTS_ACTION_TYPES.DEPO_SET: {
            return {
                ...state,
                depo_id: action.payload,
                service_partner_id: null,
                member_group_id: null,
                user_id: null,
                requestParams: {
                    ...state.requestParams,
                    depo_id: action.payload?.value ?? null,
                    service_partner_id: null,
                    member_group_id: null,
                    user_id: null,
                    filter: null,
                },
            };
        }
        case REPORTS_ACTION_TYPES.SP_SET: {
            return {
                ...state,
                service_partner_id: action.payload,
                member_group_id: null,
                user_id: null,
                requestParams: {
                    ...state.requestParams,
                    service_partner_id: action.payload?.value ?? null,
                    member_group_id: null,
                    user_id: null,
                    filter: null,
                },
            };
        }
        case REPORTS_ACTION_TYPES.GROUP_SET: {
            return {
                ...state,
                member_group_id: action.payload,
                user_id: null,
                requestParams: {
                    ...state.requestParams,
                    member_group_id: action.payload?.value ?? null,
                    user_id: null,
                    filter: null,
                },
            };
        }
        case REPORTS_ACTION_TYPES.USER_SET: {
            return {
                ...state,
                reportType: determineReportType(state, action),
                user_id: action.payload,
                requestParams: {
                    ...state.requestParams,
                    ...determineParamsOnUserSet(state, action),
                    groupBy: determineGroupByForUser(state, action),
                    user_id: action.payload?.value ?? null,
                    filter: null,
                },
            };
        }
        case REPORTS_ACTION_TYPES.ADVANCED_FILTER_OPTIONS_SET: {
            return {
                ...state,
                filter: action.payload,
            };
        }
        case REPORTS_ACTION_TYPES.ADVANCED_FILTER_VALUES_SET: {
            return {
                ...state,
                requestParams: {
                    ...state.requestParams,
                    filter: Boolean(action.payload) ? { ...state.requestParams.filter, ...action.payload } : action.payload,
                },
            };
        }
        case REPORTS_ACTION_TYPES.SET_FILTERS_MANUALLY: {
            return {
                ...state,
                reportType: action.payload?.date ? { value: REPORT_PERIOD_TYPES.DAILY } : { value: REPORT_PERIOD_TYPES.PERIOD },
                depo_id: action.payload?.depo_id?.value ? action.payload?.depo_id : null,
                service_partner_id: action.payload?.service_partner_id?.value ? action.payload?.service_partner_id : null,
                member_group_id: action.payload?.member_group_id?.value ? action.payload?.member_group_id : null,
                user_id: action.payload?.user_id?.value ? action.payload?.user_id : null,
                selected: {
                    ...state.selected,
                    date: action.payload?.date ? new Date(action.payload.date) : null,
                    week: action.payload?.week || null,
                    month: action.payload?.month || null,
                    year: action.payload?.year || null,
                    all: false,
                },
                requestParams: {
                    ...state.requestParams,
                    date: action.payload?.date ? new Date(action.payload.date).toLocaleDateString("en-US") : null,
                    week: action.payload?.week || null,
                    month: action.payload?.month || null,
                    year: action.payload?.year || null,
                    depo_id: action.payload?.depo_id?.value ? action.payload?.depo_id?.value : null,
                    service_partner_id: action.payload?.service_partner_id?.value ? action.payload?.service_partner_id?.value : null,
                    member_group_id: action.payload?.member_group_id?.value ? action.payload?.member_group_id?.value : null,
                    user_id: action.payload?.user_id?.value ? action.payload?.user_id?.value : null,
                },
            };
        }
        default: {
            throw new Error(`Invalid action type ${action.type}`);
        }
    }
};

function determineSelectedOnTypeSwitch(state, action) {
    if (isUserSelected(state) && isYearly(action.payload)) {
        return {
            ...state.selected,
            all: false,
            year: new Date().getFullYear(),
        };
    }
    return state.selected;
}

function determineTimePeriodOnTypeSwitch(state, action) {
    if (isUserSelected(state)) {
        if (isYearlyDay(action.payload)) {
            return {
                ...state.requestParams,
                groupBy: REPORT_GROUP_BY_TYPES.DAY,
                date: null,
                year: state.selected.year ?? new Date().getFullYear(),
                month: null,
                week: null,
            };
        }
        if (isYearlyWeek(action.payload)) {
            return {
                ...state.requestParams,
                groupBy: REPORT_GROUP_BY_TYPES.WEEK,
                date: null,
                year: state.selected.year ?? new Date().getFullYear(),
                month: null,
                week: null,
            };
        }
        if (isYearlyMonth(action.payload)) {
            return {
                ...state.requestParams,
                groupBy: REPORT_GROUP_BY_TYPES.MONTH,
                date: null,
                year: state.selected.year ?? new Date().getFullYear(),
                month: null,
                week: null,
            };
        }
        if (isPeriod(action.payload)) {
            return {
                ...state.requestParams,
                groupBy: determineGroupByOnSwitchFromYearly(state),
                date: null,
                year: state.selected.year ?? new Date().getFullYear(),
                month: state.selected.month,
                week: state.selected.week,
            };
        }
    } else {
        if (isDaily(action.payload)) {
            return {
                ...state.requestParams,
                date: new Date(state.selected.date)?.toLocaleDateString("en-US"),
                year: null,
                month: null,
                week: null,
            };
        }
        if (isPeriod(action.payload)) {
            return {
                ...state.requestParams,
                date: null,
                year: state.selected.year,
                month: state.selected.month,
                week: state.selected.week,
            };
        }
    }
}

function determineGroupByOnSwitchFromYearly(state) {
    if (Boolean(state.selected.year)) {
        if (Boolean(state.selected.month)) {
            return REPORT_GROUP_BY_TYPES.WEEK;
        }
        if (Boolean(state.selected.week)) {
            return REPORT_GROUP_BY_TYPES.DAY;
        }
        return REPORT_GROUP_BY_TYPES.MONTH;
    }
    if (state.selected.all) {
        return REPORT_GROUP_BY_TYPES.YEAR;
    }
}

function determineGroupByForUser(state, action) {
    if (Boolean(action.payload)) {
        if (state.groupBy.value == "invoices.id") {
            return INVOICE_GROUP_BY_TYPES.INVOICE_ID;
        }
        if (isDaily(state.reportType)) {
            return REPORT_GROUP_BY_TYPES.DAY;
        }
        if (isPeriod(state.reportType) && Boolean(state.selected.year)) {
            if (Boolean(state.selected.month)) {
                return REPORT_GROUP_BY_TYPES.WEEK;
            }
            if (Boolean(state.selected.week)) {
                return REPORT_GROUP_BY_TYPES.DAY;
            }
            return REPORT_GROUP_BY_TYPES.MONTH;
        }
        if (isPeriod(state.reportType) && state.selected.all) {
            return REPORT_GROUP_BY_TYPES.YEAR;
        }
        if (isYearlyDay(state.reportType)) {
            return REPORT_GROUP_BY_TYPES.DAY;
        }
        if (isYearlyWeek(state.reportType)) {
            return REPORT_GROUP_BY_TYPES.WEEK;
        }
        if (isYearlyMonth(state.reportType)) {
            return REPORT_GROUP_BY_TYPES.MONTH;
        }
    }
    return REPORT_GROUP_BY_TYPES.USER_ID;
}

function determineReportType(state, action) {
    if (Boolean(action.payload) && isDaily(state.reportType)) {
        return { value: REPORT_PERIOD_TYPES.YEARLY_DAY };
    }
    if (!Boolean(action.payload) && isYearly(state.reportType)) {
        return { value: REPORT_PERIOD_TYPES.PERIOD };
    }
    return state.reportType;
}

function determineParamsOnUserSet(state, action) {
    if (Boolean(action.payload) && isDaily(state.reportType)) {
        return {
            ...state.requestParams,
            date: null,
            year: state.selected.year,
            month: null,
            week: null,
        };
    }
    if (!Boolean(action.payload) && isYearly(state.reportType)) {
        return {
            ...state.requestParams,
            date: null,
            year: state.selected.year,
            month: state.selected.month,
            week: state.selected.week,
        };
    }
}

function determineSelection(state, action) {
    switch (action.payload.type) {
        case REPORT_SELECTION_TYPES.ALL: {
            return {
                ...state,
                reportType: { value: REPORT_PERIOD_TYPES.PERIOD },
                selected: {
                    ...state.selected,
                    all: true,
                    year: null,
                    month: null,
                    week: null,
                },
                requestParams: {
                    ...state.requestParams,
                    groupBy: isUserSelected(state) ? REPORT_GROUP_BY_TYPES.YEAR : state.groupBy.value,
                    date: null,
                    year: null,
                    month: null,
                    week: null,
                },
            };
        }
        case REPORT_SELECTION_TYPES.YEAR: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    all: false,
                    year: action.payload.payload,
                    month: null,
                    week: null,
                },
                requestParams: {
                    ...state.requestParams,
                    groupBy: isUserSelected(state) ? REPORT_GROUP_BY_TYPES.MONTH : state.groupBy.value,
                    date: null,
                    year: action.payload.payload,
                    month: null,
                    week: null,
                },
            };
        }
        case REPORT_SELECTION_TYPES.MONTH: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    all: false,
                    year: state.selected.year ?? new Date().getFullYear(),
                    month: action.payload.payload,
                    week: null,
                },
                requestParams: {
                    ...state.requestParams,
                    groupBy: isUserSelected(state) ? REPORT_GROUP_BY_TYPES.WEEK : state.groupBy.value,
                    date: null,
                    year: state.selected.year ?? new Date().getFullYear(),
                    month: action.payload.payload,
                    week: null,
                },
            };
        }
        case REPORT_SELECTION_TYPES.WEEK: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    all: false,
                    year: state.selected.year ?? new Date().getFullYear(),
                    week: action.payload.payload,
                    month: null,
                },
                requestParams: {
                    ...state.requestParams,
                    groupBy: isUserSelected(state) ? REPORT_GROUP_BY_TYPES.DAY : state.groupBy.value,
                    date: null,
                    year: state.selected.year ?? new Date().getFullYear(),
                    week: action.payload.payload,
                    month: null,
                },
            };
        }
        case REPORT_SELECTION_TYPES.DATE: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    date: action.payload.payload,
                },
                requestParams: {
                    ...state.requestParams,
                    date: new Date(action.payload.payload).toLocaleDateString("en-US"),
                    year: null,
                    week: null,
                    month: null,
                },
            };
        }
        default: {
            throw new Error(`Invalid action type ${action.type}`);
        }
    }
}

export default reportsReducer;
