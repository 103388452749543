import { ErrorBoundary } from "react-error-boundary";
import { Error } from "components/Error";

const PageWrapper = ({ children, ...rest }) => {
	return (
		<div className='v__page' {...rest}>
			<ErrorBoundary FallbackComponent={Error}>{children}</ErrorBoundary>
		</div>
	);
};

export default PageWrapper;
