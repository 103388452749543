import { format } from "date-fns";
import axios from "./axios";

const api = {
    getPcns(params) {
        return axios
            .get(`/pcn`, {
                params,
            })
            .then(({ data }) => data);
    },
    getPcn(id, params) {
        return axios
            .get(`/pcn/${encodeURI(id)}`, {
                params,
            })
            .then(({ data }) => data);
    },
    createPcn(data) {
        return axios.post("/pcn", formatPcnData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },
    updatePcn(id, data) {
        return axios.post(`/pcn/${encodeURI(id)}/update`, formatPcnData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },
};

function formatPcnData(data) {

    console.log(data);
    const formattedData = new FormData();
    formattedData.append("user_id", data.user_id?.value ?? "");
    formattedData.append("number", data.number);
    formattedData.append("van_number", data.van_number);
    formattedData.append("amount", data.amount);
    formattedData.append("amount_for_driver", data.amount_for_driver);
    formattedData.append("admin_fee", data.admin_fee);
    formattedData.append("date", format(new Date(data["date"]), "dd-MM-yyyy"));
    formattedData.append("due_week", data.due_week);
    formattedData.append("status", data.status?.value ?? "");

	if (data.image_path) {
		formattedData.append("image_path", data.image_path);
	}


    return formattedData;
}

export default api;
