import axios from "./axios";

const api = {
	getServicePartners(params) {
		return axios
			.get(`/service-partners`, {
				params,
			})
			.then(({ data }) => data);
	},
	getServicePartner(id, params) {
		return axios
			.get(`/service-partners/${encodeURI(id)}`, {
				params,
			})
			.then(({ data }) => data);
	},

	createServicePartner(data) {
		return axios.post("/service-partners", formatSpData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
	updateServicePartner(id, data) {
		return axios
			.post(`/service-partners/${encodeURI(id)}/update`, formatSpData(data), { headers: Headers.FORMDATA })
			.then(({ data }) => data);
	},

	getServicePartnerStaff(params) {
		return axios
			.get(`/service-partners/staff`, {
				params,
			})
			.then(({ data }) => data);
	},
	getServicePartnerStaffById(id, params) {
		return axios
			.get(`/service-partners/staff/${id}`, {
				params,
			})
			.then(({ data }) => data);
	},
};

function formatSpData(data) {
	const formattedData = new FormData();
	formattedData.append("title", data.title);
	formattedData.append("import_title", data.import_title);
	formattedData.append("description", data.description);

	for (const sp of Object.values(data.service_partners)) {
		for (const user of Object.values(sp.members)) {
			formattedData.append(`users[]`, user.id);
		}
	}

	return formattedData;
}

export default api;
