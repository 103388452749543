import { forwardRef } from "react";
import Slider, { Range } from "rc-slider";
import PropTypes from "prop-types";
import cn from "classnames";

import { roundBigNumbers } from "utils";

const Handle = Slider.Handle;

const SliderHandle = (props) => {
	const { value, dragging, index, ...restProps } = props;
	return (
		<Handle key={index} value={value} {...restProps}>
			<div className='v__slider__tooltip'>{roundBigNumbers(value)}</div>
		</Handle>
	);
};

const FormRange = forwardRef(({ groupClassName, withLabel = true, name, id = name, label, children, ...rest }, ref) => {
	return (
		<div className={cn(`v__form__group`, groupClassName)}>
			<label htmlFor={id} className={cn("v__form__group__label", !withLabel && "v__form__group__label--invisible")}>
				<span>{label}</span>
			</label>
			<Range className='v__slider' id={id} name={name} handle={SliderHandle} pushable {...rest} ref={ref} />
			{children}
		</div>
	);
});

FormRange.propTypes = {
	groupClassName: PropTypes.string,
	id: PropTypes.string.isRequired,
	name: PropTypes.string,
	onChange: PropTypes.func,
	label: PropTypes.any.isRequired,
	withLabel: PropTypes.bool,
};

export default FormRange;
