import { SwitchGroup } from "components/forms";

const PerformanceSortBySwitch = ({ filters, onFilter, sortByOptions, ...rest }) => {
	return (
		<SwitchGroup
			activeOption={filters.sortBy}
			onChange={(option) => {
				onFilter({ sortBy: option });
			}}
			options={sortByOptions}
			dark={true}
			{...rest}
		/>
	);
};

export default PerformanceSortBySwitch;
