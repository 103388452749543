import { useEffect, useState } from "react";
import { debounce } from "utils";

export const useWindowSize = (delay = 500) => {
	const [size, setSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = debounce(() => {
			setSize({ width: window.innerWidth, height: window.innerHeight });
		}, delay);
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [delay]);

	return size;
};

export default useWindowSize;
