import axios from "./axios";
import { format } from "date-fns";

const api = {
    getInvoices(params) {
        return axios
            .get(`/invoices`, {
                params,
            })
            .then(({ data }) => data);
    },

    createInvoice(data) {
        return axios.post("/invoices/create", formatData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    getInvoiceProfitPreview(params) {
        return axios
            .get(`/invoices/previewProfit`, {
                params,
            })
            .then(({ data }) => data);
    },

    getInvoiceAdjustmentsPreview(params) {
        return axios
            .get(`/invoices/previewAdjustment`, {
                params,
            })
            .then(({ data }) => data);
    },

    payInvoice(id, data) {
        return axios
            .post(`/invoices/${encodeURI(id)}/pay`, formatPayData(data), { headers: Headers.FORMDATA })
            .then(({ data }) => data);
    },


    deleteInvoice(id) {
        return axios.delete(`/invoices/${encodeURI(id)}`).then(({ data }) => data);
    },
};

function formatPayData(data) {
    const formattedData = new FormData();

    formattedData.append("paid", data.paid ?? "");
    formattedData.append("status", data?.status?.value ?? "");


    return formattedData;
}

function formatData(data) {
    const formattedData = new FormData();
    let i = 0;
    let v = 0;


    formattedData.append("amount", data.amount ?? "");
    formattedData.append("bonus", data.bonus ?? "");
    formattedData.append("charge", data.charge ?? "");
    formattedData.append("van", data.van ?? "");
    formattedData.append("week", data.week ?? "");
    formattedData.append("year", data.year ?? "");
    formattedData.append("user_id", data?.driver_id?.value ?? "");
    formattedData.append("tour_category_id", data?.tour_category?.value ?? "");
    formattedData.append("fairplay_id", data?.fairplay?.value ?? "");
    formattedData.append("paymentType", data?.paymentType ?? "");
    formattedData.append("type", data?.type?.value ?? "");
    formattedData.append("extraFuel", data?.extraFuel ?? false);
    formattedData.append("reg_number", data?.reg_number ?? false);
    formattedData.append("vanOverallPrice", data?.vanOverallPrice ?? false);
    formattedData.append("fuelOverallPrice", data?.fuelOverallPrice ?? false);
    formattedData.append("room_rent", data?.room_rent ?? false);
    formattedData.append("personal_van", data?.personal_van ?? false);
    formattedData.append("personal_fuel", data?.personal_fuel ?? false);
    formattedData.append("paid_date", format(new Date(data["paid_date"]), "dd-MM-yyyy"));

    for (const ddr of Object.values(data.avanses)) {
        formattedData.append(`avanses[${i}][description]`, ddr["description"]);
        formattedData.append(`avanses[${i}][amount]`, ddr["amount"]);
        formattedData.append(`avanses[${i}][date]`, format(new Date(ddr["date"]), "dd-MM-yyyy"));
        i++;
    }

    for (const other of Object.values(data.others)) {
        formattedData.append(`others[${v}][description]`, other["description"]);
        formattedData.append(`others[${v}][amount]`, other["amount"]);
        formattedData.append(`others[${v}][date]`, format(new Date(other["date"]), "dd-MM-yyyy"));
        v++;
    }




    data.fuelPerDay?.map((item, index) => {
        formattedData.append("fuelPerDay[" + index + "][amount]", item.amount);
        formattedData.append("fuelPerDay[" + index + "][reg]", item.reg ?? "");
        return 1;
    });


    return formattedData;
}


export default api;
