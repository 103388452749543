import axios from "./axios";

const api = {
	getDepo(id, params) {
		return axios
			.get(`/depos/${encodeURI(id)}`, {
				params,
			})
			.then(({ data }) => data);
	},
};

export default api;
