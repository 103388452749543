import { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import LineLoader from "./ui/loaders/LineLoader";
import Button from "./Button";
import Icon from "./Icon";

const Card = forwardRef(
	({ children, header, dismissible, headerHint, loading, relativeLoading = false, className, headerClassName, ...rest }, ref) => {
		return (
			<>
				{loading && <LineLoader />}
				<div className={classnames(`v__card`, className)} {...rest} ref={ref}>
					{header && (
						<div className={classnames(`v__card__header`, headerClassName)}>
							{relativeLoading && <LineLoader />}
							<div className={`v__card__header__container`}>
								<span>
									{header}
									{headerHint && (
										<>
											<Icon>info</Icon>
											<div className='v__card__header__container__hint'>{headerHint}</div>
										</>
									)}
								</span>
								{dismissible && (
									<Button
										bg
										status='darker'
										label='close modal'
										className='v__card__header__container__dismissible'
										onClick={dismissible}
									>
										<Icon>clear</Icon>
									</Button>
								)}
							</div>
						</div>
					)}
					<div className={`v__card__body ${loading && "disabled"}`}>{children}</div>
				</div>
			</>
		);
	}
);

Card.propTypes = {
	header: PropTypes.any,
	dismissible: PropTypes.func,
	loading: PropTypes.bool,
	relativeLoading: PropTypes.bool,
};

export default Card;
