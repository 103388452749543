import { InvoicesApi } from "api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "utils";
import { reportKeys } from "./reports";

export const invoicesKeys = {
	all: () => ["invoicesKeys"],
	byId: (id) => [...invoicesKeys.all(), id],
	byConfig: (config) => [...invoicesKeys.all(), config],
};

export const invoicesProfitPreviewKeys = {
	all: () => ["invoicesProfitPreviewKeys"],
	byConfig: (config) => [...invoicesProfitPreviewKeys.all(), config],
};

export const invoicesAdjustmentsPreviewKeys = {
	all: () => ["invoicesAdjustmentsPreviewKeys"],
	byConfig: (config) => [...invoicesAdjustmentsPreviewKeys.all(), config],
};

export const useInvoices = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(invoicesKeys.byConfig(custom), () => InvoicesApi.getInvoices(custom), restConfig);
};

export const useCreateInvoice = (config) => {
	const queryClient = useQueryClient();
	return useMutation(InvoicesApi.createInvoice, {
		...config,
		onSuccess: callAll(() => {
			queryClient.resetQueries(invoicesKeys.all());
			queryClient.invalidateQueries(invoicesKeys.all());
            console.log('invalidating');
		}, config?.onSuccess),
	});
};

export const usePayInvoice = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => InvoicesApi.payInvoice(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};


export const useDeleteInvoice = (config) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => InvoicesApi.deleteInvoice(data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(invoicesKeys.all());
		}, config?.onSuccess),
	});
};

export const useInvoicesAdjustmentsPreview = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(invoicesAdjustmentsPreviewKeys.byConfig(custom), () => InvoicesApi.getInvoiceAdjustmentsPreview(custom), restConfig);
};

export const useInvoicesProfitPreview = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(invoicesProfitPreviewKeys.byConfig(custom), () => InvoicesApi.getInvoiceProfitPreview(custom), restConfig);
};
