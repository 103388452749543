import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useDataTableGenerate, useDeleteFairplaySP, useFairplaysSP, useMedia, useMediaQueries } from "hooks";
import { Button, Icon, ActionsCell, Table, MembersCell } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { FairplayModal, FairplaySPModal } from "modules";

const FairplaySPTable = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const [modal, setModal] = useState({
		state: false,
		payload: null,
	});

	const closeModal = () => setModal({ state: false, payload: null });

	const { mutate, isLoading } = useDeleteFairplaySP();

	const columns = useCallback(
		(columns) => {
			return columns.map((col) => {
				if (col.accessor === "membersLimited") {
					return {
						...col,
						Cell: ({ cell }) => <MembersCell cell={cell} />,
					};
				}

				if (col.accessor === "actions") {
					return {
						...col,
						Cell: ({ cell }) => (
							<ActionsCell>
								{cell.row.original.can_edit && (
									<Button small bg status='warning' onClick={() => setModal({ payload: cell.row.original, state: true })}>
										<Icon>edit</Icon>
									</Button>
								)}

								<Button small bg status='accent' onClick={() => navigate(`/app/reports/sp_fbr/${cell.row.original?.id}`)}>
									<Icon>visibility</Icon>
								</Button>

								{cell.row.original.can_edit && (
									<Button small bg status='danger' onClick={() => mutate(cell.row.original?.id)}>
										<Icon>delete</Icon>
									</Button>
								)}
							</ActionsCell>
						),
					};
				}
				return null;
			});
		},
		[mutate, navigate]
	);

	const { tableProps } = useDataTableGenerate(useFairplaysSP, columns, {
		includes: ["owner", "membersLimited", "membersLimited.media"],
	});

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | SP | Fairplay</title>
			</Helmet>
			<PageHeader title={t("sp_fairplay")}>
				<div className='v__table__extras__actions'>
					{/* {userCan("add_groups") && ( */}
					<Button small bg status='danger' round={!isMedium} onClick={() => setModal({ state: true, payload: null })}>
						{isMedium ? t("addNew") : <Icon>add</Icon>}
					</Button>
					{/* )} */}
				</div>
			</PageHeader>
			<PageContent>
				<Table {...tableProps} loading={isLoading} scrollToTopOnChange />
			</PageContent>
			{modal.state && <FairplaySPModal payload={modal.payload} onClose={closeModal} />}
		</PageWrapper>
	);
};

export default FairplaySPTable;
