import toast from "react-hot-toast";
import cn from "classnames";

import { Icon } from "components";
import { useTranslation } from "react-i18next";

function Toast({ t }) {
	return (
		<div
			className={cn(
				"flex items-center w-full max-w-xs p-4 rounded-lg shadow transition-opacity",
				t.type === "success" && "text-white bg-green-700",
				t.type === "error" && "text-white bg-red-700",
				t.visible ? "opacity-100" : "opacity-0"
			)}
		>
			<div
				className={cn(
					"inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg",
					t.type === "success" && "text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200",
					t.type === "error" && "text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-200"
				)}
			>
				{t.type === "success" && <Icon>done</Icon>}
				{t.type === "error" && <Icon>close</Icon>}
			</div>
			<div className='ml-3 text-sm'>{t.message}</div>
			<button
				type='button'
				className='ml-auto rounded-lg p-2 inline-grid place-content-center h-8 w-8'
				aria-label='Close'
				onClick={() => toast.dismiss(t.id)}
			>
				<span className='sr-only'>Close</span>
				<Icon className='text-base'>close</Icon>
			</button>
		</div>
	);
}

function ToastPageReload({ t: toast }) {
	const { t } = useTranslation();
	return (
		<div
			className={cn(
				"flex items-center w-full max-w-xs p-4 rounded-lg shadow transition-opacity text-white bg-sky-700",
				toast.visible ? "opacity-100" : "opacity-0"
			)}
		>
			<div
				className={cn(
					"inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg",
					"text-sky-700 bg-sky-200 dark:bg-sky-700 dark:text-sky-200"
				)}
			>
				<Icon>info</Icon>
			</div>
			<div className='ml-3 text-sm'>{t("newVersionAvailable")}</div>
			<button
				type='button'
				className='ml-auto rounded-lg p-2 inline-grid place-content-center h-8 w-8'
				aria-label='Close and reload page'
				onClick={() => window.location.reload()}
			>
				<span className='sr-only'>Close</span>
				<Icon className='text-base'>close</Icon>
			</button>
		</div>
	);
}

Toast.Reload = ToastPageReload;

export default Toast;
