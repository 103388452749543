import cn from "classnames";
import {useTranslation} from "react-i18next";

import {Button, Icon} from "components";

const NotificationItem = ({notification, onClick}) => {
    const {t} = useTranslation();
    const nType = notification.data.type;
    const nDate = notification.data.date;


    const parseCorrectTypeStyles = (type) => {
        switch (type) {
            case "user-created":
                return {
                    icon: "cake",
                    bg: "accent",
                    title: `${t("welcome")} 🎉`,
                };
            case "uploaded_delivery_daily":
            case "uploaded_feedback":
            case "uploaded_snumber":
                return {
                    icon: "cloud_done",
                    bg: "success",
                    title: t("success_upload"),
                };
            case "uploaded_fail_delivery_daily":
            case "uploaded_fail_feedback":
            case "uploaded_fail_snumber":
                return {
                    icon: "cloud_off",
                    bg: "danger",
                    title: t("fail_upload"),
                };
            case "file_ready":
                return {
                    icon: "file_copy",
                    bg: "accent",
                    title: `${t("file_ready")}`,
                };
            default:
                return {
                    icon: "event_note",
                    bg: "darker",
                    title: t("notification"),
                };
        }
    };

    const handleOnClick = () => {
        onClick?.({
            date: new Date(nDate),
        });
    };
    //

    return (
        <div className={cn("v__notifications__item__content", {unread: !notification.read})}>
            <div className='v__notifications__item__content__header'>
                <Icon
                    className={cn(`icon-${parseCorrectTypeStyles(nType).bg}`)}>{parseCorrectTypeStyles(nType).icon}</Icon>
                <div className='v__notifications__item__content__header__title'>
                    <p>{parseCorrectTypeStyles(nType).title}</p>
                    <span>{notification.date}</span>
                </div>
            </div>
            <div className='v__notifications__item__content__body'>
                <div
                    className='v__notifications__item__content__body__msg'
                    dangerouslySetInnerHTML={{__html: notification.data.message}}
                />
                {nType === "uploaded_delivery_daily" && (
                    <Button small bg status='warning' iconLeft={<Icon>arrow_forward</Icon>} onClick={handleOnClick}>
                        {t("view")}
                    </Button>
                )}

                {nType === "file_ready" && (
                    <a className='mr-2' href={notification.data.link} target='_blank' rel='noopener noreferrer'
                       download>
                        <Button small bg status='success' iconLeft={<Icon>download</Icon>}>
                            Download CSV
                        </Button>
                    </a>
                )}
            </div>
        </div>
    );
};

export default NotificationItem;
