import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import SettingsItem from "./SettingsItem";
import { emailRegEx, validateFile } from "../../utils";
import { useAuth } from "../../context";
import { Button, Icon } from "../../components";
import { FormDropzone, FormInput } from "../../components/forms";
import { useUpdateProfile } from "../../hooks";
import { Spinner } from "../../components/ui";
import { AUTH_ACTION_TYPES } from "../../reducers";

const MAX_IMG_SIZE = process.env.REACT_APP_MAX_IMAGE_UPLOAD_SIZE_MB;

const ProfileSection = () => {
	const { t } = useTranslation();
	const { user, dispatch } = useAuth();

	const {
		handleSubmit,
		watch,
		control,
		resetField,
		formState: { errors },
	} = useForm({
		defaultValues: {
			first_name: user.first_name,
			last_name: user.last_name,
			email: user.email,
		},
	});

	const watchPass = watch("new_password", "");

	const [visiblePass, setVisiblePass] = useState(false);

	const { mutate, isLoading } = useUpdateProfile({
		onSuccess: ({ data }) => {
			resetField("avatar");
			resetField("new_password");
			resetField("password_confirmation");
			dispatch({
				type: AUTH_ACTION_TYPES.USER_UPDATE,
				payload: data,
			});
		},
	});

	const onSubmit = (data) => mutate(data);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<SettingsItem label={t("avatar")} labelFor='avatar'>
				<Controller
					render={({ field: { onChange, ...rest } }) => (
						<FormDropzone
							groupClassName={errors.avatar && "v__form__group--incorrect"}
							prevFile={user}
							accept='image/png, image/jpeg, image/gif'
							error={errors.avatar?.message}
							onChange={(e) => onChange(e.target.files[0])}
							{...rest}
						/>
					)}
					control={control}
					name='avatar'
					defaultValue=''
					rules={{ validate: (v) => validateFile(v) || t("maxFileSize", { sizeInMb: MAX_IMG_SIZE }) }}
				/>
			</SettingsItem>
			<SettingsItem label={t("fields.firstName")} labelFor='first_name'>
				<Controller
					render={({ field }) => (
						<FormInput
							groupClassName={errors.first_name && "v__form__group--incorrect"}
							withLabel={false}
							error={errors.first_name?.message}
							{...field}
						/>
					)}
					control={control}
					name='first_name'
					defaultValue=''
					rules={{
						required: { value: true, message: t("required") },
						minLength: { value: 2, message: t("minLength", { value: 2 }) },
						maxLength: { value: 50, message: t("maxLength", { value: 50 }) },
					}}
				/>
			</SettingsItem>
			<SettingsItem label={t("lastName")} labelFor='last_name'>
				<Controller
					render={({ field }) => (
						<FormInput
							groupClassName={errors.last_name && "v__form__group--incorrect"}
							withLabel={false}
							error={errors.last_name?.message}
							{...field}
						/>
					)}
					control={control}
					name='last_name'
					defaultValue=''
					rules={{
						required: { value: true, message: t("required") },
						minLength: { value: 2, message: t("minLength", { value: 2 }) },
						maxLength: { value: 50, message: t("maxLength", { value: 50 }) },
					}}
				/>
			</SettingsItem>
			<SettingsItem label={t("fields.email")} labelFor='email'>
				<Controller
					render={({ field }) => (
						<FormInput
							groupClassName={errors.email && "v__form__group--incorrect"}
							withLabel={false}
							type='email'
							error={errors.email?.message}
							{...field}
						/>
					)}
					control={control}
					name='email'
					defaultValue=''
					rules={{
						required: { value: true, message: t("required") },
						pattern: { value: emailRegEx, message: t("pattern") },
					}}
				/>
			</SettingsItem>
			<SettingsItem label={t("newPassword")} labelFor='new_password'>
				<Controller
					render={({ field }) => (
						<FormInput
							groupClassName={errors.new_password && "v__form__group--incorrect"}
							withLabel={false}
							type={visiblePass ? "text" : "password"}
							error={errors.new_password?.message}
							{...field}
						/>
					)}
					control={control}
					name='new_password'
					defaultValue=''
					rules={{
						minLength: { value: 6, message: t("minLength", { value: 6 }) },
					}}
				/>
			</SettingsItem>
			<SettingsItem label={t("confirmPassword")} labelFor='password_confirmation'>
				<Controller
					render={({ field }) => (
						<div className={cn("v__form__group", errors.password_confirmation && "v__form__group--incorrect")}>
							<div className='v__input__group'>
								<input type={visiblePass ? "text" : "password"} className='v__input' {...field} />
								<Button
									spongy={false}
									small
									round
									hoverStatus='accent'
									className='v__input__group__add-on'
									onClick={() => setVisiblePass((prev) => !prev)}
								>
									{visiblePass ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
								</Button>
							</div>
							{errors?.password_confirmation && (
								<small className='v__form__group__hint'>{errors?.password_confirmation?.message}</small>
							)}
						</div>
					)}
					control={control}
					name='password_confirmation'
					defaultValue=''
					rules={{
						required: { value: watchPass?.length > 0, message: t("required") },
						validate: (v) => v === watchPass || t("notMatching"),
					}}
				/>
			</SettingsItem>
			<SettingsItem>
				<Button
					bg
					status='accent'
					type='submit'
					className='v__submit__btn'
					iconRight={isLoading ? <Spinner size={16} singleColor /> : null}
				>
					{t("save")}
				</Button>
			</SettingsItem>
		</form>
	);
};

export default ProfileSection;
