import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";

import { useAuth } from "../../context";
import { useServicePartnerStaffById } from "../../hooks";
import { FormInput } from "../../components/forms";
import UserCardItem from "./UserCardItem";
import { LineLoader } from "../../components/ui";

const MIN_SEARCH_LENGTH = 2;

const StaffList = () => {
	const { t } = useTranslation();
	const { userCurrentRole } = useAuth();

	const parentRef = useRef();

	const [search, setSearch] = useState("");

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useServicePartnerStaffById({
		custom: {
			sp_id: userCurrentRole.service_partner_id,
			per_page: 20,
			includes: ["roles", "media"],
			search,
		},
		enabled: Boolean(userCurrentRole.service_partner_id),
		getNextPageParam: ({ meta }) => {
			const nextPage = meta.current_page + 1;
			const lastPage = meta.last_page;
			return nextPage <= lastPage ? nextPage : undefined;
		},
	});

	const staff = useMemo(() => {
		if (!data) {
			return [];
		}
		return data?.pages.map((page) => page.data).reduce((prev, curr) => [...prev, ...curr], []);
	}, [data]);

	const rowVirtualizer = useVirtual({
		size: staff.length,
		estimateSize: useCallback(() => 50, []),
		parentRef,
	});

	useEffect(() => {
		const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

		if (!lastItem) {
			return;
		}

		if (lastItem.index >= staff.length - 1 && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, fetchNextPage, staff.length, isFetchingNextPage, rowVirtualizer.virtualItems]);

	const hasSearched = search.length < MIN_SEARCH_LENGTH && search.length !== 0;

	return (
		<div className='v__card v__staff body-no-pad'>
			{isFetchingNextPage && <LineLoader />}
			<div className='v__card__header'>
				<div className='v__card__header__container'>
					<span>{t("drivers")}</span>
				</div>
			</div>
			<FormInput
				groupClassName='px-1'
				name='search'
				withLabel={false}
				placeholder={t("search")}
				value={search}
				onChange={({ target }) => setSearch(target.value)}
				error={hasSearched && t("minLength", { value: MIN_SEARCH_LENGTH })}
			/>
			<div className='v__card__body v__staff__container' ref={parentRef}>
				<div
					style={{
						height: `${rowVirtualizer.totalSize}px`,
					}}>
					{rowVirtualizer.virtualItems.map((virtualRow) => {
						const child = staff[virtualRow.index];
						return (
							<div
								key={virtualRow.index}
								ref={virtualRow.measureRef}
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: `${child}px`,
									transform: `translateY(${virtualRow.start}px)`,
								}}>
								<div className='v__staff__item px-1'>
									<UserCardItem member={child} />
								</div>
							</div>
						);
					})}
					{rowVirtualizer.virtualItems.length === 0 && <div className='text-center pad-xy-2'>{t("noData")}</div>}
				</div>
			</div>
		</div>
	);
};

export default StaffList;
