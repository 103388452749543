import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import {  FormInput } from "../../components/forms";
import { Button, FormDatePicker } from "../../components";


const PcnForm = ({ onSubmit, onCancel, defaultValues: {from_date, to_date, ...otherDefaultValues } }) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: otherDefaultValues });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
            <Controller
                render={({ field }) => (
                    <FormInput
                        groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
                            "v__form__group--incorrect": errors?.title,
                        })}
                        label={t("title")}
                        error={errors?.ttile && t(`${errors?.title?.type}`)}
                        {...field}
                    />
                )}
                control={control}
                name='title'
                defaultValue=''
                rules={{ required: true }}
            />
            <Controller
                render={({ field: { value, ...restField } }) => (
                    <FormDatePicker
                        groupClassName={cn(
                            "v__grid__item v__grid__item--col-12",
                            errors?.from_date && "v__form__group--incorrect"
                        )}
                        label={t("from_date")}
                        error={errors?.from_date && t(`${errors?.from_date?.type}`)}
                        maxDate={false}
                        selected={value}
                        {...restField}
                    />
                )}
                control={control}
                name={"from_date"}
                defaultValue={(from_date !== undefined) ? new Date(from_date) : new Date()}
                rules={{ required: false }}
            />

            <Controller
                render={({ field: { value, ...restField } }) => (
                    <FormDatePicker
                        groupClassName={cn(
                            "v__grid__item v__grid__item--col-12",
                            errors?.to_date && "v__form__group--incorrect"
                        )}
                        label={t("from_date")}
                        error={errors?.to_date && t(`${errors?.to_date?.type}`)}
                        maxDate={false}
                        selected={value}
                        {...restField}
                    />
                )}
                control={control}
                name={"to_date"}
                defaultValue={(to_date !== undefined) ? new Date(to_date) : new Date()}
                rules={{ required: false }}
            />




            <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                <Button bg status='darker' onClick={onCancel}>
                    {t("cancel")}
                </Button>
                <Button bg status='accent' type='submit'>
                    {t("save")}
                    {/* {payload ? lang?.save_changes : lang?.add_group} */}
                </Button>
            </div>
        </form>
    );
};

export default PcnForm;
