import { format } from "date-fns";
import axios from "./axios";

const api = {
	getCards(params) {
		return axios
			.get(`/cards`, {
				params,
			})
			.then(({ data }) => data);
	},

	getCardsStatuses(params) {
		return axios
			.get(`/cards/statuses/all`, {
				params,
			})
			.then(({ data }) => data);
	},

	getCardbyId(id, params) {
		return axios
			.get(`/cards/${id}`, {
				params,
			})
			.then(({ data }) => data);
	},

	createFuel(data) {
		return axios.post("cards/uploadFuelGenie", formatData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},

	updateCard(id, data) {
		return axios.post(`/cards/${encodeURI(id)}/update`, formatData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
};

function formatData(data) {
	const formattedData = new FormData();

	if (data?.image_path) {
		formattedData.append("file", data.image_path);
	}

	if (data?.extra_fuel_value) {
		formattedData.append("extra_fuel_value", data.extra_fuel_value);
	}


	if (data?.depo_id) {
		formattedData.append("depo_id", data.depo_id?.value ?? "");
	}

	if (data?.group_id) {
		formattedData.append("group_id", data.group_id?.value ?? "");
	}

	if (data?.service_partner_id) {
		formattedData.append("service_partner_id", data.service_partner_id?.value ?? "");
	}

	if (data?.users) {
		var i = 0;
		for (const user of Object.values(data.users)) {
			formattedData.append(`users[${i}][user_id]`, user["user_id"].value);
			formattedData.append(`users[${i}][from_date]`, format(new Date(user["from_date"]), "dd-MM-yyyy"));
			formattedData.append(`users[${i}][to_date]`, format(new Date(user["to_date"]), "dd-MM-yyyy"));
			i++;
		}
	}

	return formattedData;
}

export default api;
