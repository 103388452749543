export const AUTH_ACTION_TYPES = {
	LOGOUT: "LOGOUT",
	STATUS_SET: "STATUS_SET",
	TOKEN_SET: "TOKEN_SET",
	USER_SET: "USER_SET",
	USER_UPDATE: "USER_UPDATE",
};

export const USER_STATUS_TYPES = {
	ONLINE: "ONLINE",
	OFFLINE: "OFFLINE",
	AWAY: "AWAY",
	FAILED: "FAILED",
	UNAVAILABLE: "UNAVAILABLE",
};

export const initialAuthState = {
	token: null,
	isAuthenticated: false,
	status: "unavailable",
	user: null,
	userRoles: [],
	userServicePartners: [],
};

const authReducer = (state, action) => {
	switch (action.type) {
		case AUTH_ACTION_TYPES.USER_SET: {
			const currRole = action.payload?.roles?.find((el) => el?.name === "Depo")
				? action.payload.roles.find((el) => el.name === "Depo")
				: action.payload.roles.find((el) => el.service_partner_id !== null)
				? action.payload.roles.find((el) => el.service_partner_id !== null)
				: action.payload.roles[0];

			return {
				...state,
				isAuthenticated: true,
				user: action.payload,
				userRoles: action.payload.roles,
				userServicePartners: action.payload.service_partners,
				userCurrentRole: currRole,
				permissions: currRole.permissions.map((v) => v.value && v.name),
				unreadNotifications: action.payload.unreadNotifications,
			};
		}
		case AUTH_ACTION_TYPES.USER_UPDATE: {
			return {
				...state,
				user: action.payload,
			};
		}
		case AUTH_ACTION_TYPES.STATUS_SET: {
			return {
				...state,
				status: action.payload,
			};
		}
		case AUTH_ACTION_TYPES.TOKEN_SET: {
			return {
				...state,
				token: action.payload,
			};
		}
		case AUTH_ACTION_TYPES.LOGOUT: {
			return {
				...initialAuthState,
			};
		}
		default: {
			throw new Error(`Invalid action type ${action.type}`);
		}
	}
};

export default authReducer;
