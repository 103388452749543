import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { Step, Stepper } from "react-form-stepper";
import { useEffect, useState } from "react";
import { Button, Card, DateWeekMonthPicker, FormDatePicker } from "components";
import { Controller, useForm } from "react-hook-form";
import { FormCheckbox, FormInput, FormSelect } from "components/forms";
import { useDepoStaff } from "hooks";
import cn from "classnames";
import { REPORT_PERIOD_TYPES } from "reducers/const";
import { useFairplays } from "hooks/query/fairplayManagement";
import { useNavigate } from "react-router-dom";
import { InvoiceShowProfitTable, InvoiceShowFuelTable, InvoiceShowAdjustmentsTable, InvoiceShowFairplayTable } from "modules";

import { useCreateInvoice } from "hooks/query/invoices";
import RadioButtonGroup from "react-custom-radio-buttons-group/dist/components/RadioButtonGroup";
import { useReportsState } from "context";
import { getISOWeek } from "date-fns";
import { useToursCategories } from "hooks/query/toursCategories";
import { useDeleteInvoiceManagerTourPrices } from "hooks/query/invoiceManager";

const InvoiceCreate = () => {
    const { t } = useTranslation();


    const { state } = useReportsState();

    // const [selected, setSelected] = useState({ year: new Date().getFullYear(), week: getISOWeek(new Date()) });
    const [selected, setSelected] = useState({ year: state.selected.year, week: (state.selected.week) ?? getISOWeek(new Date()) });
    const [extraFuelFields, setExtraFuelFields] = useState(false);
    const [paymentType, setPaymentType] = useState("");
    const reportType = { value: REPORT_PERIOD_TYPES.PERIOD };

    let years = [];

    for (let i = 2018; i <= new Date().getFullYear(); i++) {
        years.push(new Date().setFullYear(i));
    }

    const [step, setStep] = useState(0);
    const [avansesCount, setAvansesCount] = useState(1);
    const [othersCount, setOthersCount] = useState(1);
    const [fuelOverall, setFuelOverall] = useState(0);


    const maxStep = 2;

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    const watchFuel = watch("fuelOverallPrice");

    useEffect(() => {
        // if (watchFuel > fuelOverall) {
        setValue("personal_fuel", fuelOverall + watchFuel * -1);
        // }
    }, [watchFuel, fuelOverall, setValue]);

    const watchTourID = watch("tour_category");
    const watchUserID = watch("driver_id");
    const watchFairplayID = watch("fairplay");
    const watchAmount = watch("amount");

    const { mutate: deleteInvoiceCustomPrices } = useDeleteInvoiceManagerTourPrices({
        onSuccess: (res) => {
        },
    });


    const navigate = useNavigate();

    const handleOnBack = () => setStep((prev) => prev - 1);

    const { mutateAsync: create } = useCreateInvoice();

    const handleOnSubmitForm = (data, e) => {
        const submitter = e.nativeEvent.submitter.name

        if (submitter === "nextStep") {
            if (step === 0) {
                deleteInvoiceCustomPrices({});
            }
            if (step === maxStep) {
                let dataForSend = { ...data, week: selected.week, year: selected.year, paymentType: paymentType, extraFuel: extraFuelFields };
                create(dataForSend, {
                    onSettled: () => {
                        navigate(`/app/invoices/advanced/statistics`);
                    },
                });
                // return;
            } else {
                setStep((prev) => prev + 1);
            }

        }

    };

    const onDateChange = (data) => {
        setSelected({ ...selected, [data.type]: data.payload });
    };

    const onDailyFuelChange = (data) => {
        setExtraFuelFields(data)
    };


    const onRadioChange = (data) => {
        setPaymentType(data.target.value);
    };



    return (
        <PageWrapper>
            <Helmet>
                <title>Veniway | Invoices</title>
            </Helmet>
            <PageHeader title={`${t("invoices")}${step >= 1 ? ' | ' + watchUserID.label + ' - Week ' + selected.week + ' - Year ' + selected.year : ''}`} />
            <PageContent>
                <div className='w-full h-15'>
                    <Stepper
                        activeStep={step}
                        styleConfig={{
                            activeBgColor: "#2b7cff",
                            activeTextColor: "#fff",
                            inactiveBgColor: "#444",
                            inactiveTextColor: "#fff",
                            completedBgColor: "#2b7cff",
                            completedTextColor: "#fff",
                            size: "2em",
                        }}
                    >
                        <Step label='General Information' />
                        <Step label='Transport' inactiveBgColor='#000' />
                        <Step label='Finish' />
                    </Stepper>
                </div>


                <form onSubmit={handleSubmit(handleOnSubmitForm)}>
                    {step === 0 && (
                        <>
                            <Card className={cn("body-no-pad v__card--overflow-able print:hidden")}>
                                <DateWeekMonthPicker
                                    selected={selected}
                                    onChange={onDateChange}
                                    reportType={reportType}
                                    files={years}
                                    withoutAll={true}
                                />
                            </Card>

                            <Card className='v__modal v__card--overflow-able' header={t("generalInformation")}>
                                <div className='v__grid mt-5'>
                                    <Controller
                                        render={({ field }) => (
                                            <FormSelect
                                                groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-4", {
                                                    "v__form__group--incorrect": errors?.driver_id,
                                                })}
                                                label={t("driver")}
                                                isAsync
                                                asyncSearch
                                                useFetch={useDepoStaff}
                                                isGrouped
                                                queryConfig={{
                                                    custom: {
                                                        page: 0,
                                                        per_page: 20,
                                                    },
                                                }}
                                                groupParse={{ label: "title", options: "staff" }}
                                                optionsParse={{ value: "id", label: "full_name" }}
                                                error={errors?.driver_id && t(`${errors?.driver_id?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='driver_id'
                                        defaultValue={null}
                                        rules={{ required: true }}
                                    />
                                    <Controller
                                        render={({ field }) => (
                                            <FormSelect
                                                groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-4", {
                                                    "v__form__group--incorrect": errors?.fairplay,
                                                })}
                                                label={t("fairplay")}
                                                isAsync
                                                asyncSearch
                                                useFetch={useFairplays}
                                                isGrouped
                                                queryConfig={{
                                                    custom: {
                                                        page: 0,
                                                        per_page: 20,
                                                    },
                                                }}
                                                optionsParse={{ value: "id", label: "title" }}
                                                error={errors?.fairplay && t(`${errors?.fairplay?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='fairplay'
                                        defaultValue={null}
                                        rules={{ required: true }}
                                    />
                                    <Controller
                                        render={({ field }) => (
                                            <FormSelect
                                                groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-4", {
                                                    "v__form__group--incorrect": errors?.tour_category,
                                                })}
                                                label={t("tour_category")}
                                                isAsync
                                                asyncSearch
                                                useFetch={useToursCategories}
                                                isGrouped
                                                queryConfig={{
                                                    custom: {
                                                        page: 0,
                                                        per_page: 20,
                                                    },
                                                }}
                                                optionsParse={{ value: "id", label: "title" }}
                                                error={errors?.tour_category && t(`${errors?.tour_category?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='tour_category'
                                        defaultValue={null}
                                        rules={{ required: true }}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <FormSelect
                                                groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-4", {
                                                    "v__form__group--incorrect": errors?.type,
                                                })}
                                                label={t("type")}
                                                options={[
                                                    { value: "invoice", label: "Invoice" },
                                                    // 													{ value: "preinvoice", label: "Preinvoice" },
                                                ]}
                                                error={errors?.type && t(`${errors?.type?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='type'
                                        defaultValue={{ value: "invoice", label: "Invoice" }}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </Card>
                            <Card className='v__modal v__card--overflow-able' header={t("Payment Type")}>
                                <div className='mt-3'>
                                    <RadioButtonGroup
                                        className='v__grid__item v__grid__item--col-5 v__grid__item--col-sm-12 '
                                        onChange={onRadioChange}
                                        hide={false}
                                        defaultValue={t("tourFormulas")}
                                        values={[t("tourFormulas"), t("percent"), t("perWorkingDay"), t("raw")]}
                                    />
                                    {paymentType !== t("tourFormulas") && paymentType !== t("raw") && (
                                        <div className='v__grid '>
                                            <Controller
                                                render={({ field }) => (
                                                    <FormInput
                                                        groupClassName={cn("v__grid__item v__grid__item--col-5 v__grid__item--col-sm-12", {
                                                            "v__form__group--incorrect": errors?.amount,
                                                        })}
                                                        error={errors?.amount && t(`${errors?.title?.amount}`)}
                                                        {...field}
                                                    />
                                                )}
                                                control={control}
                                                name='amount'
                                                defaultValue=''
                                                rules={{ required: false }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <Card className='v__modal v__card--overflow-able' header={t("fuel")}>
                                <InvoiceShowFuelTable user_id={watchUserID.value} setValue={setValue} setGrossFuel={setFuelOverall} year={selected.year} week={selected.week} />
                            </Card>

                            <Card className='v__modal v__card--overflow-able' header={t("fuelDetails")}>
                                <div className='v__grid '>
                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.fuelOverallPrice,
                                                })}
                                                label={t("fuelOverallPrice")}
                                                disabled={extraFuelFields}
                                                error={errors?.fuelOverallPrice && t(`${errors?.fuelOverallPrice?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='fuelOverallPrice'
                                        defaultValue={fuelOverall}
                                        rules={{ required: true }}
                                    />

                                </div>
                            </Card>


                            <Card className='v__modal v__card--overflow-able' header={t("vanDetails")}>
                                <div className='v__grid '>

                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.vanOverallPrice,
                                                })}
                                                label={t("vanOverallPrice")}
                                                disabled={extraFuelFields}
                                                error={errors?.vanOverallPrice && t(`${errors?.vanOverallPrice?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='vanOverallPrice'
                                        defaultValue={0}
                                        rules={{ required: true }}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.reg_number,
                                                })}
                                                label={t("regNumber")}
                                                error={errors?.van && t(`${errors?.reg_number?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='reg_number'
                                        rules={{ required: false }}
                                    />

                                    <FormCheckbox
                                        groupClassName={"v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12 mt-7"}
                                        onChange={onDailyFuelChange}
                                        withLabel={false}
                                        checkboxLabel={t("selectToFillVanForTheWholeWeek")}
                                    />



                                </div>
                            </Card>
                            {extraFuelFields === true && (
                                <FuelExtraFields shouldShow={extraFuelFields} errors={errors} control={control} />
                            )}

                        </>
                    )}

                    {step === 2 && (
                        <>
                            <Card className='v__modal v__card--overflow-able' header={t("driver_income")}>
                                <InvoiceShowProfitTable
                                    user_id={watchUserID.value}
                                    tour_category_id={watchTourID.value}
                                    amount={watchAmount}
                                    week={selected.week}
                                    year={selected.year}
                                    type={paymentType}
                                />
                            </Card>
                            <Card className='v__modal v__card--overflow-able' header={t("adjustments")}>
                                <InvoiceShowAdjustmentsTable user_id={watchUserID.value} week={selected.week} year={selected.year} />
                            </Card>
                            <Card className='v__modal v__card--overflow-able' header={t("fairplay")}>
                                <InvoiceShowFairplayTable fairplay_id={watchFairplayID?.value} user_id={watchUserID.value} groupBy={"day_group"} week={selected.week} year={selected.year} />
                            </Card>

                            <Card className='v__modal v__card--overflow-able' header={t("Details")}>
                                <div className='v__grid '>
                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.bonus,
                                                })}
                                                label={t("Bonus")}
                                                error={errors?.bonus && t(`${errors?.bonus?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='bonus'
                                        defaultValue={0}
                                        rules={{ required: true }}
                                    />
                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.charge,
                                                })}
                                                label={t("credit")}
                                                helperMessage={t("minus_required")}
                                                error={errors?.charge && t(`${errors?.charge?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='charge'
                                        defaultValue={0}
                                        rules={{ required: true }}
                                    />
                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.room_rent,
                                                })}
                                                label={t("room_rent")}
                                                helperMessage={t("minus_required")}
                                                error={errors?.room_rent && t(`${errors?.room_rent?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='room_rent'
                                        defaultValue={0}
                                        rules={{ required: true }}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.personal_van,
                                                })}
                                                label={t("personal_van")}
                                                helperMessage={t("minus_required")}
                                                error={errors?.personal_van && t(`${errors?.personal_van?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='personal_van'
                                        defaultValue={0}
                                    />
                                    <Controller
                                        render={({ field }) => (
                                            <FormInput
                                                groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                                    "v__form__group--incorrect": errors?.personal_fuel,
                                                })}
                                                label={t("personal_fuel")}
                                                helperMessage={t("minus_required")}
                                                error={errors?.personal_fuel && t(`${errors?.personal_fuel?.type}`)}
                                                {...field}
                                            />
                                        )}
                                        control={control}
                                        name='personal_fuel'
                                        defaultValue={0}
                                    />
                                    <Controller
                                        render={({ field: { value, ...restField } }) => (
                                            <FormDatePicker
                                                groupClassName={cn(
                                                    "v__grid__item v__grid__item--col-6",
                                                    errors?.paid_date && "v__form__group--incorrect"
                                                )}
                                                label={t("paid_date")}
                                                error={errors?.paid_date && t(`${errors?.paid_date?.type}`)}
                                                maxDate={false}
                                                selected={value}
                                                {...restField}
                                            />
                                        )}
                                        control={control}
                                        name={"paid_date"}
                                        defaultValue={new Date()}
                                        rules={{ required: false }}
                                    />
                                </div>

                            </Card>
                            <Card className='v__modal v__card--overflow-able' header={t("avanses")}>
                                <div className='v__grid '>
                                    <GenerateAvanses control={control} errors={errors} avansesCount={avansesCount} />
                                    <div className='v__form__group v__grid__item v__grid__item--col-12'>
                                        <div className='flex justify-center items-center mt-5'>

                                            <Button className='float-left !mr-5' bg status='accent' onClick={() => setAvansesCount(prevCount => prevCount + 1)}>
                                                {t("add_ddr_name")}
                                            </Button>


                                        </div>
                                    </div>
                                </div>

                            </Card>

                            <Card className='v__modal v__card--overflow-able' header={t("others")}>
                                <div className='v__grid '>
                                    <GenerateOthers control={control} errors={errors} othersCount={othersCount} />
                                    <div className='v__form__group v__grid__item v__grid__item--col-12'>
                                        <div className='flex justify-center items-center mt-5'>

                                            <Button className='float-left !mr-5' bg status='accent' onClick={() => setOthersCount(prevCount => prevCount + 1)}>
                                                {t("add_ddr_name")}
                                            </Button>


                                        </div>
                                    </div>
                                </div>

                            </Card>



                        </>
                    )}
                    <div className='v__form__footer v__grid__item v__grid__item--col-12 !justify-center'>
                        {step > 0 && (
                            <Button bg status='danger' className='!mr-5' onClick={handleOnBack}>
                                {t("back")}
                            </Button>
                        )}
                        <Button bg status='accent' name='nextStep' className='!ml-0' type='submit'>
                            {step === maxStep ? t("save") : t("continue")}
                        </Button>
                    </div>
                </form>
            </PageContent>
        </PageWrapper >
    );
};


const GenerateAvanses = ({ errors, control, avansesCount }) => {
    const components = [];

    const { t } = useTranslation();

    for (let i = 0; i < avansesCount; i++) {
        components.push(
            <>
                <Controller
                    render={({ field }) => (
                        <FormInput
                            groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                "v__form__group--incorrect": errors?.description,
                            })}
                            label={t("description")}
                            error={errors?.description && t(`${errors?.description?.type}`)}
                            {...field}
                        />
                    )}
                    control={control}
                    name={"avanses[" + i + "][description]"}
                    rules={{ required: false }}
                />
                <Controller
                    render={({ field }) => (
                        <FormInput
                            groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                "v__form__group--incorrect": errors?.amount,
                            })}
                            helperMessage={t("minus_required")}
                            label={t("amount")}
                            error={errors?.amount && t(`${errors?.amount?.type}`)}
                            {...field}
                        />
                    )}
                    control={control}
                    name={"avanses[" + i + "][amount]"}
                    defaultValue={0}
                    rules={{ required: false }}
                />
                <Controller
                    render={({ field: { value, ...restField } }) => (
                        <FormDatePicker
                            groupClassName={cn(
                                "v__grid__item v__grid__item--col-4",
                                errors?.date && "v__form__group--incorrect"
                            )}
                            label={t("date")}
                            error={errors?.date && t(`${errors?.date?.type}`)}
                            maxDate={false}
                            selected={value}
                            {...restField}
                        />
                    )}
                    control={control}
                    name={"avanses[" + i + "][date]"}
                    defaultValue={new Date()}
                    rules={{ required: false }}
                />
            </>
        );
    }

    return components;
}

const GenerateOthers = ({ errors, control, othersCount }) => {
    const components = [];

    const { t } = useTranslation();

    for (let i = 0; i < othersCount; i++) {
        components.push(
            <>
                <Controller
                    render={({ field }) => (
                        <FormInput
                            groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                "v__form__group--incorrect": errors?.description,
                            })}
                            label={t("description")}
                            error={errors?.description && t(`${errors?.description?.type}`)}
                            {...field}
                        />
                    )}
                    control={control}
                    name={"others[" + i + "][description]"}
                    rules={{ required: false }}
                />
                <Controller
                    render={({ field }) => (
                        <FormInput
                            groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                "v__form__group--incorrect": errors?.amount,
                            })}
                            helperMessage={t("minus_required")}
                            label={t("amount")}
                            error={errors?.amount && t(`${errors?.amount?.type}`)}
                            {...field}
                        />
                    )}
                    control={control}
                    name={"others[" + i + "][amount]"}
                    defaultValue={0}
                    rules={{ required: false }}
                />
                <Controller
                    render={({ field: { value, ...restField } }) => (
                        <FormDatePicker
                            groupClassName={cn(
                                "v__grid__item v__grid__item--col-4",
                                errors?.date && "v__form__group--incorrect"
                            )}
                            label={t("date")}
                            error={errors?.date && t(`${errors?.date?.type}`)}
                            maxDate={false}
                            selected={value}
                            {...restField}
                        />
                    )}
                    control={control}
                    name={"others[" + i + "][date]"}
                    defaultValue={new Date()}
                    rules={{ required: false }}
                />
            </>
        );
    }

    return components;
}


const FuelExtraFields = ({ shouldShow = false, errors, control }) => {

    const { t } = useTranslation();
    const components = [];
    if (shouldShow) {
        for (var i = 1; i <= 7; i++) {
            const dayTranslation = "day_" + i;
            components.push(
                <>

                    <Card className='v__modal v__card--overflow-able' header={t(dayTranslation)}>

                        <div className='v__grid '>
                            <Controller
                                render={({ field }) => (
                                    <FormInput
                                        groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                            "v__form__group--incorrect": errors?.vanOverallPrice,
                                        })}
                                        label={"Amount"}
                                        error={errors?.fuelPerDay && t(`${errors?.fuelPerDay?.type}`)}
                                        {...field}
                                    />
                                )}
                                control={control}
                                name={"fuelPerDay[" + i + "][amount"}
                                defaultValue={0}
                                rules={{ required: false }}
                            />
                            <Controller
                                render={({ field }) => (
                                    <FormInput
                                        groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                            "v__form__group--incorrect": errors?.vanOverallPrice,
                                        })}
                                        label={"Registration Number"}
                                        error={errors?.fuelPerDay && t(`${errors?.fuelPerDay?.type}`)}
                                        {...field}
                                    />
                                )}
                                control={control}
                                name={"fuelPerDay[" + i + "][reg]"}
                                defaultValue={""}
                                rules={{ required: false }}
                            />
                        </div>
                    </Card>
                </>

            );
        }
    }

    return components;
}

export default InvoiceCreate;
