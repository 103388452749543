import { useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "utils";
import { FairplayManagementApi } from "../../api";
import { ReportsApi } from "../../api";

export const fairplayKeys = {
    all: () => ["fairplayKeys"],
    byId: (id) => [...fairplayKeys.all(), id],
    byConfig: (config) => [...fairplayKeys.all(), config],
};

export const fairplayStatisticsKeys = {
    all: () => ["fairplayKeys"],
    byConfig: (config) => [...fairplayStatisticsKeys.all(), config],
};


export const useFairplays = (config) => {
    const { custom, ...restConfig } = config;
    return useQuery(fairplayKeys.byConfig(custom), () => FairplayManagementApi.getFairplays(custom), restConfig);
};

export const useFairplay = (id, config) => {
    const { custom, ...restConfig } = config;
    return useQuery(fairplayKeys.byId(id), () => FairplayManagementApi.getFairplay(id, custom), restConfig);
};

export const useCreateFairplay = (config) => {
    const queryClient = useQueryClient();
    return useMutation(FairplayManagementApi.createFairplay, {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(fairplayKeys.all());
        }, config?.onSuccess),
    });
};

export const useUpdateFairplay = (config) => {
    const queryClient = useQueryClient();
    return useMutation(({ id, data }) => FairplayManagementApi.updateFairplay(id, data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(fairplayKeys.all());
        }, config?.onSuccess),
    });
};

export const useDeleteFairplay = (config) => {
    const queryClient = useQueryClient();
    return useMutation(async (data) => FairplayManagementApi.deleteFairplay(data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(fairplayKeys.all());
        }, config?.onSuccess),
    });
};

export const useFairplayStatistics = (config) => {
    const { custom, ...restConfig } = config;
    return useQuery(fairplayStatisticsKeys.byConfig(custom), () => ReportsApi.getFBR(custom), restConfig);
};

