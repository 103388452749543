import { useCallback, useEffect, useState } from "react";
import { ActionsCell, Button, Icon, StatisticsTable } from "components";
import { useDataTableGenerate } from "hooks";
import { useFuels } from "hooks/query/fuel";
import { FuelModal } from "modules/fuel";

const InvoiceShowFuelTable = ({ week, year, user_id, setValue, setGrossFuel   }) => {

    const [fuelEditModal, setFuelEditModal] = useState({
        state: false,
        payload: null,
    });

    const closeModal = () => {
        setFuelEditModal({ state: false, view: false, payload: null });
    };


    const columns = useCallback((columns) => {
        return columns.map((col) => {

            const { title, accessor, disableSortBy, field, type, ...rest } = col;

            const columnProps = {
                Header: title,
                accessor,
                disableSortBy,
                id: field,
                sortDescFirst: true,
                icon: type,
                ...rest,
            };


            if (col.accessor === "fuel_actions") {
                return {
                    ...columnProps,
                    Cell: ({ value, row }) => {
                        if (row.original.id === "TOT" || row.original.id === "AVG") {
                            return null;
                        }
                        return (
                            <ActionsCell>
                                <Button small bg status='warning' onClick={() => setFuelEditModal({ payload: row.original, state: true })}>
                                    <Icon style={{ "fontFamily": "Material Icons Outlined" }} >edit</Icon>
                                </Button>
                            </ActionsCell>
                        );
                    },
                };
            }


            return columnProps;
        });
    }, []);


    //how to fire the query when the step is the right one
    const { tableProps, hasOverall } = useDataTableGenerate(useFuels, columns, {
        user_id,
        week,
        year,
        groupBy: "fuels.id",
    });

    useEffect(() => {
        if (hasOverall) {
            setValue('fuelOverallPrice', tableProps.data?.[0].gross * -1);
            setGrossFuel(tableProps.data?.[0].gross * -1);
        }
    }, [hasOverall, setGrossFuel, setValue])


    return (
        <>
            {fuelEditModal.state && <FuelModal payload={fuelEditModal.payload} view={fuelEditModal.view} onClose={closeModal} />}
            <StatisticsTable {...tableProps} hasOverallDataRows={hasOverall} scrollToTopOnChange dark />
        </>
    );


};

export default InvoiceShowFuelTable;
