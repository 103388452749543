import React from "react";

export const ExcelLogo = (props) => {
	return (
		<svg className='excel__logo' viewBox='0 0 76.765 76.765' {...props}>
			<path
				style={{ fill: "#21a366" }}
				d='M36.787,2H11.2A3.2,3.2,0,0,0,8,5.2V18.792L36.787,35.585,51.18,41.659l14.393-6.074V18.792Z'
				transform='translate(11.191 2.798)'
			/>
			<path style={{ fill: "#107c41" }} d='M8,9H36.787V25.792H8Z' transform='translate(11.191 12.59)' />
			<path
				style={{ fill: "#33c481" }}
				d='M45.589,2H20V18.792H48.787V5.2a3.2,3.2,0,0,0-3.2-3.2Z'
				transform='translate(27.978 2.798)'
			/>
			<path
				style={{ fill: "#185c37" }}
				d='M36.787,16H8V46.387a3.2,3.2,0,0,0,3.2,3.2H62.376a3.2,3.2,0,0,0,3.2-3.2V32.792Z'
				transform='translate(11.191 22.383)'
			/>
			<path style={{ fill: "#107c41" }} d='M20,16H48.787V32.792H20Z' transform='translate(27.978 22.383)' />
			<path
				style={{ opacity: 0.1 }}
				d='M28.791,7H8V52.579H28.791a3.207,3.207,0,0,0,3.2-3.2V10.2a3.207,3.207,0,0,0-3.2-3.2Z'
				transform='translate(11.191 9.792)'
			/>
			<path
				style={{ opacity: 0.2 }}
				d='M26.392,8H8V53.579H26.392a3.207,3.207,0,0,0,3.2-3.2V11.2A3.207,3.207,0,0,0,26.392,8Z'
				transform='translate(11.191 11.191)'
			/>
			<path
				style={{ opacity: 0.2 }}
				d='M26.392,8H8V48.782H26.392a3.207,3.207,0,0,0,3.2-3.2V11.2A3.207,3.207,0,0,0,26.392,8Z'
				transform='translate(11.191 11.191)'
			/>
			<path
				style={{ opacity: 0.2 }}
				d='M23.994,8H8V48.782H23.994a3.207,3.207,0,0,0,3.2-3.2V11.2A3.207,3.207,0,0,0,23.994,8Z'
				transform='translate(11.191 11.191)'
			/>
			<path
				style={{ fill: "#107c41" }}
				d='M3.2,8H35.185a3.2,3.2,0,0,1,3.2,3.2V43.185a3.2,3.2,0,0,1-3.2,3.2H3.2a3.2,3.2,0,0,1-3.2-3.2V11.2A3.2,3.2,0,0,1,3.2,8Z'
				transform='translate(0 11.191)'
			/>
			<path
				style={{ fill: "#fff" }}
				d='M3.533,34.989,11.3,22.961,4.186,11H9.907l3.881,7.645q.535,1.087.736,1.622h.05q.384-.868.8-1.689L19.527,11h5.251L17.485,22.894l7.477,12.1H19.373l-4.484-8.4a7.01,7.01,0,0,1-.535-1.123h-.067a5.3,5.3,0,0,1-.518,1.087L9.156,34.989Z'
				transform='translate(4.942 15.388)'
			/>
			<path style={{ fill: "none" }} d='M0,0H76.765V76.765H0Z' />
		</svg>
	);
};
