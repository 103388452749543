import { useMutation, useQuery, useQueryClient } from "react-query";
import { FuelApi } from "../../api";
import { callAll } from "../../utils";

export const fuelKeys = {
	all: () => ["fuel"],
	byId: (id) => [...fuelKeys.all(), id],
	byConfig: (config) => [...fuelKeys.all(), config],
};

export const useFuels = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(fuelKeys.byConfig(custom), () => FuelApi.getFuels(custom), restConfig);
};

export const useFuel = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(fuelKeys.byId(id), () => FuelApi.getFuelById(id, custom), restConfig);
};

export const useUploadedDates = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(fuelKeys.byConfig({ type: "dates", ...custom }), () => FuelApi.getUploadedDates(custom), restConfig);
};

export const useUpdateFuel = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => FuelApi.updateFuel(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(fuelKeys.all());
		}, config?.onSuccess),
	});
};
