import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { FormInput } from "../../components/forms";
import { Button } from "../../components";
import { useAuth } from "context";
import { useEffect, useState } from "react";

const TourForm = ({ onSubmit, onCancel, onBack, defaultValues: { ...otherDefaultValues }, step, fullPrice, maxStep, defaultConditions }) => {
    const { t } = useTranslation();

    const { userCan } = useAuth();

    const {
        control,
        handleSubmit,
        watch,
        reset,
        getValues,
        formState: { errors },
    } = useForm({ defaultValues: otherDefaultValues });

    const progressValue = step > 0 ? (step * 100) / maxStep / 100 : 0;

    const watchFullPrice = watch("full_price", "");



    useEffect(() => {
        if (watchFullPrice !== fullPrice) {
            reset({ ...getValues(), conditions: {} });
        }
    }, [watchFullPrice, getValues, reset, fullPrice]);

    return (
        <>
            <div
                className='h-4 w-full bg-v-accent transition-transform origin-left rounded mb-2'
                style={{ transform: `scaleX(${progressValue})` }}
            />
            {step >= 0 && (
                <div className='dark:bg-v-dark bg-v-light dark:text-white flex items-center justify-center space-x-4 text-black p-4 rounded mb-4'>
                    <p className='mb-0 leading-none'>{t(otherDefaultValues?.title)}</p>
                </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
                {step > 1 && (
                    <GenerateDivisionsForms
                        fullPrice={watchFullPrice}
                        defaultFullPrice={fullPrice}
                        step={step}
                        t={t}
                        control={control}
                        conditions={defaultConditions}
                        values={otherDefaultValues.conditions}
                        errors={errors}
                    />
                )}
                {step === 1 && (
                    <>
                        <div className='v__grid__item v__grid__item--col-12 mb-2 mt-2'>
                            <hr />
                        </div>
                        <label htmlFor={"group-options"} className={`v__form__group__label m-auto text-xl my-2 pl-3`}>
                            <span className='!m-0'>{t("preinvoice_settings")}</span>
                        </label>
                        <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                            <hr />
                        </div>

                        <Controller
                            render={({ field }) => (
                                <FormInput
                                    groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                        "v__form__group--incorrect": errors?.price_per_parcel,
                                    })}
                                    label={t("price_per_parcel")}
                                    error={errors?.price_per_parcel && t(`${errors?.price_per_parcel?.type}`)}
                                    {...field}
                                />
                            )}
                            control={control}
                            name='price_per_parcel'
                            defaultValue={"0"}
                            rules={{ required: true }}
                        />
                        <Controller
                            render={({ field }) => (
                                <FormInput
                                    groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                        "v__form__group--incorrect": errors?.van_per_day,
                                    })}
                                    label={t("van_per_day")}
                                    error={errors?.van_per_day && t(`${errors?.van_per_day?.type}`)}
                                    {...field}
                                />
                            )}
                            control={control}
                            name='van_per_day'
                            defaultValue={"0"}
                            rules={{ required: true }}
                        />
                        <Controller
                            render={({ field }) => (
                                <FormInput
                                    groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
                                        "v__form__group--incorrect": errors?.fuel_per_day,
                                    })}
                                    label={t("fuel_per_day")}
                                    error={errors?.fuel_per_day && t(`${errors?.fuel_per_day?.type}`)}
                                    {...field}
                                />
                            )}
                            control={control}
                            name='fuel_per_day'
                            defaultValue={"0"}
                            rules={{ required: true }}
                        />

                        {userCan("tours_advanced_edit") && (
                            <>
                                <div className='v__grid__item v__grid__item--col-12 mb-2 mt-2'>
                                    <hr />
                                </div>
                                <label htmlFor={"group-options"} className={`v__form__group__label m-auto text-xl my-2 pl-3`}>
                                    <span className='!m-0'>{t("preset_conditions")}</span>
                                </label>
                                <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                                    <hr />
                                </div>
                                <Controller
                                    render={({ field }) => (
                                        <FormInput
                                            groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-4", {
                                                "v__form__group--incorrect": errors?.full_price,
                                            })}
                                            label={t("full_price")}
                                            error={errors?.full_price && t(`${errors?.full_price?.type}`)}
                                            {...field}
                                        />
                                    )}
                                    control={control}
                                    name='full_price'
                                    defaultValue={0}
                                    rules={{ required: false }}
                                />
                            </>
                        )}
                    </>
                )}
                <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                    <Button bg status='darker' onClick={onCancel}>
                        {t("cancel")}
                    </Button>
                    {step > 1 && (
                        <Button bg status='darker' onClick={onBack}>
                            {t("back")}
                        </Button>
                    )}

                    <Button bg status='accent' type='submit'>
                        {step === maxStep ? t("save") : t("continue")}
                        {/* {payload ? lang?.save_changes : lang?.add_group} */}
                    </Button>
                </div>
            </form>
        </>
    );
};

const GenerateDivisionsForms = (props) => {
    if (props.conditions === undefined) {
        return null;
    }

    var slicedConditions = props.conditions.data.slice((props.step - 2) * 13 + 1, (props.step - 1) * 13 + 1);

    return slicedConditions.map((condition, index) => {
        let field_name = condition.title;
        let field_slug = condition.field;
        let defaultValue = 0;
        let payment_type = condition.paymentOption?.type;

        if (field_slug === "full-price") {
            return null;
        }


        if (props.values[condition.field] !== undefined) {
            defaultValue = props.values[condition.field];
        }

        if (props.defaultFullPrice != props.fullPrice) {

            if (payment_type !== undefined) {
                if (payment_type === "full_paid") {
                    defaultValue = props.fullPrice;
                }
                if (payment_type === "half_paid") {
                    defaultValue = props.fullPrice / 2;
                }
            }

        }

        //ako ima paymentOptions vzemi type
        //po type promenqi defaultValue-to

        return (
            <>
                {index === 0 && (
                    <>
                        <div className='v__grid__item v__grid__item--col-12 mb-2 mt-2'>
                            <hr />
                        </div>
                        <label htmlFor={"group-options"} className={`v__form__group__label m-auto text-xl my-2 pl-3`}>
                            <span className='!m-0'>NEW INVOICE SETTINGS</span>
                        </label>
                        <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                            <hr />
                        </div>
                    </>
                )}

                {index === 3 && (
                    <>
                        <div className='v__grid__item v__grid__item--col-12 mb-2 mt-2'>
                            <hr />
                        </div>
                        <label htmlFor={"group-options"} className={`v__form__group__label m-auto text-xl my-2 pl-3`}>
                            <span className='!m-0'>OLD INVOICE SETTINGS</span>
                        </label>
                        <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                            <hr />
                        </div>
                    </>
                )}
                <Controller
                    render={({ field }) => (
                        <FormInput
                            groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                "v__form__group--incorrect": props.errors?.field,
                            })}
                            label={props.t(field_name)}
                            error={props.errors?.field && "error"}
                            {...field}
                        />
                    )}
                    control={props.control}
                    name={"conditions." + field_slug}
                    defaultValue={defaultValue}
                    key={field_slug}
                    rules={{}}
                />
            </>
        );
    });
};

export default TourForm;
