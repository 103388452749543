import { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Button, Card, Modal } from "components";
import { FormRange } from "components/forms";

const ReportsAdvancedFilters = ({ state, onClose, onReset, onChange }) => {
	const { t } = useTranslation();

	const [filters, setFilters] = useState(() => {
		if (state.filter.lenght > 0) {
			return [...state.filter].reduce((prev, curr) => ({ ...prev, [curr.accessor]: curr }));
		}
		return {};
	});

	return (
		<Modal onClose={onClose}>
			<Card className='v__modal v__modal--lg v__card--overflow-able' header={t("filters")} dismissible={onClose}>
				<div className='v__grid v__range__grid'>
					{state.filter.map((item) => {
						const value = [filters[item.accessor]?.min ?? item.min, filters[item.accessor]?.max ?? item.max];
						return (
							<FormRange
								key={item.accessor}
								groupClassName={cn("v__grid__item v__grid__item--col-3 v__grid__item--col-md-6 v__grid__item--col-sm-12", {
									disabled: item.min === item.max,
								})}
								id={item.accessor}
								label={item.description ?? ""}
								onChange={(val) => {
									setFilters((prev) => ({
										...prev,
										[item.accessor]: {
											...prev[item.accessor],
											min: val[0],
											max: val[1],
											changed: !(val[0] === item.min && val[1] === item.max),
										},
									}));
								}}
								step={item.iterator}
								min={item.min}
								max={item.max}
								parseHandleValues
								value={value}
							/>
						);
					})}

					<div className='v__form__footer v__grid__item v__grid__item--col-12'>
						<Button bg status='darker' onClick={onClose}>
							{t("cancel")}
						</Button>
						<Button bg status='warning' onClick={onReset}>
							{t("clearFilters")}
						</Button>
						<Button bg status='accent' onClick={() => onChange?.(filters)}>
							{t("apply")}
						</Button>
					</div>
				</div>
			</Card>
		</Modal>
	);
};

export default ReportsAdvancedFilters;
