import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "utils";
import { UsersApi } from "../../api";
import { reportKeys } from "./reports";

export const usersKeys = {
	all: () => ["users"],
	byId: (id) => [...usersKeys.all(), id],
	byConfig: (config) => [...usersKeys.all(), config],
	byInfiniteConfig: (config) => [...usersKeys.byConfig(config), "infinite"],
};

export const useDepoStaff = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(usersKeys.byConfig(custom), () => UsersApi.getDepoStaff(custom), restConfig);
};

export const useInfiniteDepoStaff = (config) => {
	const { custom, ...restConfig } = config;
	return useInfiniteQuery(
		usersKeys.byInfiniteConfig(custom),
		({ pageParam = 1 }) => UsersApi.getDepoStaff({ page: pageParam, ...custom }),
		restConfig
	);
};

export const useUsers = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(usersKeys.byConfig(custom), () => UsersApi.getUsers(custom), restConfig);
};

export const useUser = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(usersKeys.byId(id), () => UsersApi.getUser(id, custom), restConfig);
};

export const useCreateUser = (config) => {
	const queryClient = useQueryClient();
	return useMutation(UsersApi.createUser, {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(usersKeys.all());
			queryClient.invalidateQueries(usersKeys.byId());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};

export const useUpdateUser = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => UsersApi.updateUser(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(usersKeys.all());
			queryClient.invalidateQueries(usersKeys.byId());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};
