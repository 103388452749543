import { useTranslation } from "react-i18next";

const DivisionCell = ({ cell }) => {
	const { t } = useTranslation();

	return (
		<div className='v__table__body__row__cell__division'>
			<div className='v__table__body__row__cell__division__icon'>{cell.value?.icon}</div>
			<p>
				<small className='sub-text'>{t("rate")}: </small> <span>{cell.value?.rate}</span>
			</p>
		</div>
	);
};

export default DivisionCell;
