import { instanceOf } from "prop-types";
import axios from "./axios";

const api = {
    getTours(params) {
        return axios
            .get(`/routes`, {
                params,
            })
            .then(({ data }) => data);
    },
    getTour(id, params) {
        return axios.get(`/routes/${encodeURI(id)}`, { params }).then(({ data }) => data);
    },

    getDefaultTourConditions(params) {
        return axios
            .get(`/routes/default-conditions`, {
                params,
            })
            .then(({ data }) => data);
    },

    updateTourPaymentOptions(data) {
        return axios.post(`/routes/pay-options`, formatTourPaymentOptions(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    updateTour(id, data) {
        return axios.post(`/routes/${encodeURI(id)}/update`, data, { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    deleteTour(id) {
        return axios.delete(`/routes/${id}`).then(({ data }) => data);
    },
};

function formatTourPaymentOptions(data) {
    const formattedData = new FormData();

    formattedData.append(`category_id`, data.category_id);
    if (Boolean(data.tours)) {
        for (const tour_key of Object.keys(data.tours)) {
            if (!Boolean(data.tours[tour_key])) {
                continue;
            }
            console.log( typeof data.tours[tour_key]);
            if (typeof data.tours[tour_key] === 'string') {
                formattedData.append(`tours[${tour_key}][type]`, data.tours[tour_key]);
                formattedData.append(`tours[${tour_key}][route_id]`, tour_key);
                continue;
            }
            formattedData.append(`tours[${tour_key}][type]`, data.tours[tour_key].value);
            formattedData.append(`tours[${tour_key}][route_id]`, tour_key);
        }
    }

    return formattedData;
}

export default api;
