import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ViewContextProvider from "context/ViewContext";

import { useGroup, useWindowSize } from "hooks";

import { Button, Icon, PerformanceChartBox, PerformanceSortBySwitch } from "components";
import { Avatar, TabCard, TabCardHeader, TabCardPanel } from "components/ui";

import PageWrapper from "components/util/PageWrapper";
import PageHeader from "components/util/PageHeader";
import PageContent from "components/util/PageContent";

import ViewAllStats from "components/util/views_widgets/ViewAllStats";
import ViewAllReports from "components/util/views_widgets/ViewAllReports";

const GroupsView = () => {
	const { width } = useWindowSize();
	const { id } = useParams();
	const { t } = useTranslation();

	const { data: currentUser } = useGroup(id, {
		custom: {
			includes: ["owner", "owner.media", "workingYears", "divisions"],
		},
	});

	const userOptions = [
		{
			label: t("overview"),
			value: "overview",
		},
		{
			label: t("reports"),
			value: "reports",
		},
	];

	const HeaderDetails = () => {
		return (
			<div className='v__page__header__content__title__user'>
				<Avatar user={currentUser?.data} />
				<p>{currentUser?.data?.title}</p>
			</div>
		);
	};

	return (
		<>
			<PageWrapper>
				<Helmet>
					<title>{`Veniway | ${currentUser?.data?.title ?? ""}`}</title>
				</Helmet>
				<PageHeader title={<HeaderDetails />}>
					<div className='v__table__extras__actions'>
						<Button
							small
							bg
							round={width < 800}
							status='darker'
							iconLeft={width < 800 ? null : <Icon>print</Icon>}
							onClick={() => {
								window.print();
							}}
							title='Print results'
						>
							{width < 800 ? <Icon>print</Icon> : t("print")}
						</Button>
					</div>
				</PageHeader>
				<PageContent>
					<ViewContextProvider>
						<TabCard>
							<TabCardHeader options={userOptions} initialActive={0} />
							<TabCardPanel overflow={false}>
								<ViewAllStats currentAccount={currentUser?.data} accountType='group_id' />
								<hr className='print-hide' />
								{currentUser?.data && (
									<PerformanceChartBox
										filtersComponent={({ isLarge, ...props }) =>
											isLarge && <PerformanceSortBySwitch {...props} small />
										}
										bodyFiltersComponent={({ isLarge, ...props }) =>
											!isLarge && <PerformanceSortBySwitch {...props} small />
										}
										filterValues={{ member_group_id: { value: currentUser?.data?.id } }}
										className='pt-4'
									/>
								)}
							</TabCardPanel>
							<TabCardPanel overflow={false}>
								<ViewAllReports currentAccount={currentUser?.data} accountType='member_group_id' />
							</TabCardPanel>
						</TabCard>
					</ViewContextProvider>
				</PageContent>
			</PageWrapper>
		</>
	);
};

export default GroupsView;
