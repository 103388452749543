import axios, { Headers } from "./axios";


const api = {
	login(data) {
		data.client_secret = process.env.REACT_APP_CLIENT_SECRET;
		data.grant_type = process.env.REACT_APP_GRANT_TYPE;
		data.client_id = process.env.REACT_APP_CLIENT_ID;

		return axios.post("/oauth/token", data).then(({ data }) => data);
	},
	register(data) {
		return axios.post("/register", data).then(({ data }) => data);
	},
	findSNumber(snumber) {
		return axios.get(`/users/snumber/${encodeURI(snumber)}`).then(({ data }) => data);
	},

	findRefNumber(refNumber) {
		return axios.get(`/users/refNumber/${encodeURI(refNumber)}`).then(({ data }) => data);
	},

	getProfile(includes) {
		return axios
			.get("/profile", {
				params: {
					includes,
				},
			})
			.then(({ data }) => data?.data);
	},
	updateProfile(data) {
		const formData = new FormData();

		Object.entries(data).forEach(([key, value]) => {
			if (Boolean(value)) {
				formData.append(key, value);
			}
		});

		return axios.post("/profile", formData, { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
};

export default api;
