import { Button, Icon } from "components";

const PerformancePagination = ({ onPrev, onNext, current }) => {
	return (
		<div className='v__performance__nav'>
			<Button small bg round hoverStatus='accent' onClick={onPrev}>
				<Icon>arrow_back</Icon>
			</Button>
			<div className='v__performance__nav__current'>{current}</div>
			<Button small bg round hoverStatus='accent' onClick={onNext}>
				<Icon>arrow_forward</Icon>
			</Button>
		</div>
	);
};

export default PerformancePagination;
