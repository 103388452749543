import axios from "./axios";

const api = {
	getGroups(params) {
		return axios
			.get(`/groups`, {
				params,
			})
			.then(({ data }) => data);
	},
	getGroupById(id, params) {
		return axios
			.get(`/groups/${id}`, {
				params,
			})
			.then(({ data }) => data);
	},
	createGroup(data) {
		return axios.post("/groups", formatGroupData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},
	updateGroup(id, data) {
		return axios.post(`/groups/${encodeURI(id)}/update`, formatGroupData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
	},

	deleteGroup(id) {
		return axios.delete(`/groups/${id}`).then(({ data }) => data);
	},
};

function formatGroupData(data) {
	const formattedData = new FormData();
	formattedData.append("owner_id", data.owner_id?.value ?? "");
	formattedData.append("title", data.title);
	if (data.image_path) {
		formattedData.append("image_path", data.image_path);
	}

	for (const sp of Object.values(data.service_partners)) {
		formattedData.append(`service_partners[]`, sp.id);
	}

	return formattedData;
}

export default api;
