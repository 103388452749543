import { forwardRef } from "react";
import { format } from "date-fns";
import { LineLoader } from "../ui";

export const DatePickerBtn = forwardRef(({ loading = false, onClick, value }, ref) => {
	return (
		<>
			{loading && <LineLoader />}
			<input
				className='v__input hover:cursor-pointer'
				onClick={onClick}
				value={format(new Date(value), "dd LLL, yyyy")}
				type='text'
				readOnly
				ref={ref}
			/>
		</>
	);
});
