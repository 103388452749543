import { NotificationsApi } from "api";
import { useInfiniteQuery, useQuery } from "react-query";

export const notificationKeys = {
	all: () => ["notifications"],
	new: () => [...notificationKeys.all(), "new"],
	byConfig: (config) => [...notificationKeys.all(), config],
};

export const useNewNotifications = (config) => {
	return useQuery(notificationKeys.new(), () => NotificationsApi.getNewNotifications(), config);
};

export const useNotifications = (config) => {
	const { custom, ...restConfig } = config;
	return useInfiniteQuery(
		notificationKeys.byConfig(custom),
		({ pageParam = 1 }) => NotificationsApi.getNotifications({ page: pageParam, ...custom }),
		{
			getNextPageParam: ({ meta }) => {
				const nextPage = meta.current_page + 1;
				const lastPage = meta.last_page;
				return nextPage <= lastPage ? nextPage : undefined;
			},
			...restConfig,
		}
	);
};
