import PropTypes from "prop-types";
import cn from "classnames";

const Icon = ({ className, children, ...rest }) => {
	return (
		<i className={cn("material-icons-outlined", className)} {...rest}>
			{children}
		</i>
	);
};

Icon.propTypes = {
	className: PropTypes.string,
};

export default Icon;
