import { useQuery } from "react-query";
import { DivisionsApi } from "../../api";

export const divisionsKeys = {
	all: () => ["divisions"],
	byConfig: (config) => [...divisionsKeys.all(), config],
};

export const useDivisions = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(divisionsKeys.byConfig(custom), () => DivisionsApi.getDivisions(custom), restConfig);
};
