import { forwardRef, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { MultiVirtualAsyncSelect, VirtualAsyncSelect, VirtualSelect } from "..";

const componentPicker = {
	defaultAsync: (isAsync, isMulti) => isAsync && !isMulti,
	default: (isAsync, isMulti) => !isAsync && !isMulti,
	multiAsync: (isAsync, isMulti) => isAsync && isMulti,
};

const FormSelect = forwardRef(
	({ groupClassName, name, id = name, label, withLabel = true, isAsync, isMulti, error, children, ...rest }, ref) => {

		const componentType = useMemo(() => {
			return {
				defaultAsync: VirtualAsyncSelect,
				default: VirtualSelect,
				multiAsync: MultiVirtualAsyncSelect,
			};
		}, []);

		const [Component, setComponent] = useState(componentType.default);

		useEffect(() => {
			Object.entries(componentPicker).forEach(([componentKey, componentCheck]) => {
				if (componentCheck(isAsync, isMulti)) {
					setComponent(componentType[componentKey]);
					return;
				}
			});
		}, [isAsync, isMulti, componentType]);

		if (!Component) {
			return null;
		}

		return (
			<div className={cn(`v__form__group`, groupClassName)}>
				<label htmlFor={id} className='v__form__group__label'>
					{withLabel && <span>{label}</span>}
					<Component {...rest} ref={ref} name={name} />
				</label>
				{error && <small className='v__form__group__hint'>{error}</small>}
				{children}
			</div>
		);
	}
);

FormSelect.propTypes = {
	groupClassName: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	withLabel: PropTypes.bool,
	isAsync: PropTypes.bool,
};

export default FormSelect;
