import { useTranslation } from "react-i18next";
import { Card, Modal } from "components";
import { useDepoStaff, useSnumbers } from "hooks";
import cn from "classnames";

import { Controller, useForm } from "react-hook-form";
import { FormInput, FormSelect } from "../../components/forms";
import { Button } from "../../components";
import { useUpdateInvoiceManagerTourPrice } from "hooks/query/invoiceManager";
import { useParams } from "react-router-dom";


const InvoiceManagerPriceEditModal = ({ payload, onClose, refetch }) => {
    const { t } = useTranslation();

    const { mutate: update } = useUpdateInvoiceManagerTourPrice({
        onSuccess: (res) => {
            onClose();

            if (refetch) {
                refetch();
            }
        },
    });

    const { id } = useParams();

    const onSubmit = (data) => {
        let dataToSend = {
            date: payload?.date,
            tour_name: payload?.route,
            price: data?.price
        };


        update({ data: dataToSend });
    };


    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({});


    return (
        <Modal onClose={onClose}>
            <Card className='v__modal v__card--overflow-able' header={t("editProfits") + " - " + payload.date + " - " + payload.route} dismissible={onClose}>
                <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>

                    <Controller
                        render={({ field }) => (
                            <FormInput
                                groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
                                    "v__form__group--incorrect": errors?.amount,
                                })}
                                helperMessage={t("set_daily_price")}
                                label={t("amount")}
                                error={errors?.amount && t(`${errors?.amount?.type}`)}
                                {...field}
                            />
                        )}
                        control={control}
                        name={"price"}
                        defaultValue={payload?.amount}
                        rules={{ required: true }}
                    />

                    <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                        <Button bg status='darker' onClick={onClose}>
                            {t("cancel")}
                        </Button>
                        <Button bg status='accent' type='submit'>
                            {t("save")}
                        </Button>
                    </div>
                </form>
            </Card>
        </Modal>
    );
};

export default InvoiceManagerPriceEditModal;
