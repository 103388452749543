import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { useGroup, useCreateGroup, useUpdateGroup } from "hooks";

import { GroupForm } from ".";

function parseSelectedSP(servicePartners) {
	if (!servicePartners) return {};

	const fetchedSPs = {};

	for (const sp of servicePartners) {
		if (!(sp?.id in fetchedSPs)) {
			fetchedSPs[sp.id] = { ...sp, checked: true };
		}
	}

	return fetchedSPs;
}

const GroupModal = ({ payload, onClose }) => {
	const { t } = useTranslation();
	const { data: group } = useGroup(payload?.id, {
		custom: {
			includes: ["servicePartners", "owner"],
		},
		enabled: Boolean(payload?.id),
	});

	const { mutate: update, isLoading: isLoadingUpdate } = useUpdateGroup({
		onSuccess: (res) => {
			onClose();
		},
	});

	const { mutate: create, isLoading: isLoadingCreate } = useCreateGroup({
		onSuccess: (res) => {
			onClose();
		},
	});

	const groupData = group?.data;

	const defaultValues = {
		title: groupData?.title,
		image_path: groupData?.image_path,
		service_partners: parseSelectedSP(groupData?.servicePartners),
		owner_id: Boolean(payload) ? { value: groupData?.owner_id, label: groupData?.owner?.full_name } : null,
	};

	const handleOnSubmit = (data) => {
		if (Boolean(payload)) {
			update({ id: payload.id, data });
		} else {
			create(data);
		}
	};

	const shouldRender = (payload && group) || !payload;
	const isLoading = isLoadingUpdate || isLoadingCreate;

	return (
		<Modal onClose={onClose}>
			<Card
				className='v__modal v__card--overflow-able'
				header={t("choosePigments")}
				relativeLoading={isLoading}
				dismissible={onClose}
			>
				{shouldRender && <GroupForm onSubmit={handleOnSubmit} defaultValues={defaultValues} onCancel={onClose} />}
			</Card>
		</Modal>
	);
};

export default GroupModal;
