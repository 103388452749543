import React from "react";
import cn from "classnames";
import Container from "./Container";

const PageContent = ({ className, children, ...rest }) => {
	return (
		<div className={cn("v__page__content", className)} {...rest}>
			<Container>
				<div className='v__page__content__content'>{children}</div>
			</Container>
		</div>
	);
};

export default PageContent;
