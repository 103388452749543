import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { useCreateUser, useUpdateUser, useUser } from "hooks";
import { UsersForm } from ".";

const UsersModal = ({ payload, onClose }) => {
	const { t } = useTranslation();
	const { data: user } = useUser(payload?.id, {
		custom: {
			includes: ["joinedDepos", "depos", "depos.depo", "servicePartners", 'snumberRanges', 'snumberRanges.snumber'],
		},
		enabled: Boolean(payload?.id),
	});

	const { mutate: update, isLoading: isLoadingUpdate } = useUpdateUser({
		onSuccess: (res) => {
			onClose();
		},
	});

	const { mutate: create, isLoading: isLoadingCreate } = useCreateUser({
		onSuccess: (res) => {
			onClose();
		},
	});

	const userData = user?.data;

	//change those ->load user
	const defaultValues = {
		first_name: userData?.first_name,
		last_name: userData?.last_name,
		service_partner_id:
			Boolean(userData) && Boolean(userData.service_partners)
				? { value: userData?.service_partners[0].id, label: userData?.service_partners[0].title }
				: null,
		depo_ids: Boolean(userData) && Boolean(userData.joined_depos) ? userData.joined_depos : [],
		snumbersPassed: Boolean(userData) && Boolean(userData.snumberRanges) ? userData.snumberRanges : null,
	};

	

	const handleOnSubmit = (data) => {
		if (Boolean(payload)) {
			update({ id: payload.id, data });
		} else {
			create(data);
		}
	};

	const shouldRender = (payload && user) || !payload;
	const isLoading = isLoadingUpdate || isLoadingCreate;

	return (
		<Modal onClose={onClose}>
			<Card className='v__modal v__card--overflow-able' header={t("manageUsers")} relativeLoading={isLoading} dismissible={onClose}>
				{shouldRender && <UsersForm onSubmit={handleOnSubmit} defaultValues={defaultValues} onCancel={onClose} />}
			</Card>
		</Modal>
	);
};

export default UsersModal;
