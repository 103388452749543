import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { IssuesApi } from "api";
import { callAll } from "utils";
import { reportKeys } from "./reports";

export const issuesKeys = {
	all: () => ["issues"],
	byConfig: (config) => [...issuesKeys.all(), config],
};

export const useIssues = (config) => {
	const { custom, ...restConfig } = config;
	return useInfiniteQuery(issuesKeys.byConfig(custom), ({ pageParam = 1 }) => IssuesApi.getIssues({ page: pageParam, ...custom }), {
		getNextPageParam: ({ meta }) => {
			const nextPage = meta.current_page + 1;
			const lastPage = meta.last_page;
			return nextPage <= lastPage ? nextPage : undefined;
		},
		...restConfig,
	});
};

export const useIssueCreate = (config) => {
	const queryClient = useQueryClient();
	return useMutation(IssuesApi.createIssue, {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(issuesKeys.all());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};

export const useIssueDelete = (config) => {
	const queryClient = useQueryClient();
	return useMutation(IssuesApi.deleteIssue, {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(issuesKeys.all());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};
