import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { FormDropzone, FormInput, FormSelect } from "../../components/forms";
import { useDepoStaff } from "../../hooks";
import { validateFile } from "../../utils";
import { StepServicePartnerList, StepStaffList } from "..";
import { Button, Icon } from "../../components";
import { Badge, Tab, Tabs } from "../../components/ui";
import ComingSoonSvg from "components/ui/graphics/ComingSoonSVG";

const MAX_IMG_SIZE = process.env.REACT_APP_MAX_IMAGE_UPLOAD_SIZE_MB;

const FairplayForm = ({
	onSubmit,
	onCancel,
	onBack,
	defaultValues: { members, service_partners, image_path, ...otherDefaultValues },
	step,
	maxStep,
	divisions,
	fields,
	setStep,
}) => {
	const { t } = useTranslation();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
		getValues,
		reset,
	} = useForm({ defaultValues: otherDefaultValues });

	const [list, setList] = useState(members ?? {});
	const [listSP, setListSP] = useState(service_partners ?? {});
	const [choices, setChoices] = useState({});
	const [mode, setMode] = useState("chooseDriver");

	const onSetDefaultDivisions = () => {
		const allFields = getValues();
		const firstDivision = allFields.formulas[0];
		const divisionsToBeAdded = [];

		for (let i = 0; i <= maxStep; i++) {
			divisionsToBeAdded.push({ ...firstDivision, division_id: divisions.data[i].id });
		}

		allFields.formulas = divisionsToBeAdded;
		reset(allFields);
		setStep((prev) => prev + 1);
	};

	const handleOnSelectDriver = (v) => {
		setList((prev) => {
			return {
				...prev,
				[v.chain.sp.id]: {
					...v.chain.sp,
					members: {
						...prev[v.chain.sp.id]?.members,
						[v.value.member.id]: {
							...v.value.member,
							checked: v.value.checked,
						},
					},
				},
			};
		});
	};

	const handleOnSelectSP = (v) => {
		setListSP((prev) => {
			return {
				...prev,
				[v.value.sp.id]: {
					id: v.value.sp.id,
					title: v.value.sp.title,
					checked: v.value.checked,
				},
			};
		});
	};

	const handleOnRemoveSP = (v) => {
		setListSP((prev) => {
			return {
				...prev,
				[v.sp.id]: {
					checked: false,
				},
			};
		});
	};

	const handleOnRemove = (v) => {
		setList((prev) => ({
			...prev,
			[v.sp.id]: {
				...prev[v.sp.id],
				members: {
					...prev[v.sp.id].members,
					[v.value.id]: {
						...prev[v.sp.id].members[v.value.id],
						checked: false,
					},
				},
			},
		}));
	};

	useEffect(() => {
		setValue("members", list);
		setChoices(() => extractMemberSelections(list));
	}, [list, setValue]);

	useEffect(() => {
		setValue("service_partners", listSP);
	}, [listSP, setValue]);

	const currentDivision = divisions?.data?.[step];
	const progressValue = step > 0 ? (step * 100) / maxStep / 100 : 0;

	return (
		<>
			<div
				className='h-4 w-full bg-v-accent transition-transform origin-left rounded mb-2'
				style={{ transform: `scaleX(${progressValue})` }}
			/>
			{step >= 0 && (
				<div className='dark:bg-v-dark bg-v-light dark:text-white flex items-center justify-center space-x-4 text-black p-4 rounded mb-4'>
					<p className='text-xl mb-0 h-9'>{currentDivision?.icon}</p>
					<p className='mb-0 leading-none'>{t(currentDivision?.name)}</p>
				</div>
			)}
			<form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
				<GenerateDivisionsForms fields={fields} step={step} t={t} control={control} divisions={divisions} errors={errors} />
				{step === -1 && (
					<>
						<Controller
							render={({ field }) => (
								<FormSelect
									groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
										"v__form__group--incorrect": errors?.owner_id,
									})}
									label={t("owner")}
									isAsync
									asyncSearch
									useFetch={useDepoStaff}
									isGrouped
									queryConfig={{
										custom: {
											page: 0,
											per_page: 10,
										},
									}}
									groupParse={{ label: "title", options: "staff" }}
									optionsParse={{ value: "id", label: "full_name" }}
									error={errors?.owner_id && t(`${errors?.owner_id?.type}`)}
									{...field}
								/>
							)}
							control={control}
							name='owner_id'
							defaultValue={null}
							rules={{ required: true }}
						/>
						<Controller
							render={({ field }) => (
								<FormInput
									groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
										"v__form__group--incorrect": errors?.title,
									})}
									label={t("title")}
									error={errors?.title && t(`${errors?.title?.type}`)}
									{...field}
								/>
							)}
							control={control}
							name='title'
							defaultValue=''
							rules={{ required: true }}
						/>
						<Controller
							render={({ field: { onChange, ...rest } }) => (
								<FormDropzone
									groupClassName={cn("v__grid__item v__grid__item--col-12", {
										"v__form__group--incorrect": errors?.image_path,
									})}
									label={t("image")}
									prevFile={image_path}
									accept='image/png, image/jpeg'
									error={errors.image_path?.message}
									onChange={(e) => onChange(e.target.files[0])}
									{...rest}
								/>
							)}
							control={control}
							name='image_path'
							defaultValue=''
							rules={{ validate: (v) => validateFile(v) || t("maxFileSize", { sizeInMb: MAX_IMG_SIZE }) }}
						/>
						<div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
							<hr />
						</div>
						<div
							className={cn("v__form__group v__grid__item v__grid__item--col-12", {
								// "v__form__group--incorrect": optionsError,
							})}
						>
							<Tabs
								tabsContainerClassName='v__grid__item v__grid__item--col-12 v__report__add__tabs'
								onTabSelect={(tab) => setMode(tab.tabValue)}
							>
								<Tab
									key={0}
									tabComponent={TabItem({
										icon: <Icon>people_alt</Icon>,
										label: t("drivers"),
										value: "chooseDriver",
									})}
									tabValue={"chooseDriver"}
								/>
								<Tab
									key={1}
									tabComponent={TabItem({
										icon: <Icon>work_outline</Icon>,
										label: t("service_partners"),
										value: "chooseSP",
									})}
									tabValue={"chooseSP"}
								/>
							</Tabs>
							<label htmlFor={"group-options"} className={`v__form__group__label my-2 pl-3`}>
								<span>{mode === "chooseSP" ? t("pickSP") : t("pickDrivers")}</span>
							</label>

							{mode === "chooseSP" && (
								<div className='v__list-selection'>
									<div className='v__list-options pt-2'>
										<StepServicePartnerList onSelect={handleOnSelectSP} checked={listSP} />
									</div>
									<div className='v__list-selection__result'>
										<SPList servicePartners={listSP} onRemove={handleOnRemoveSP} />
									</div>
								</div>
							)}

							{mode === "chooseDriver" && (
								<div className='v__list-selection'>
									<div className='v__list-options pt-2'>
										<StepStaffList.Base onSelect={handleOnSelectDriver} checked={choices} />
									</div>
									<div className='v__list-selection__result'>
										<MembersList members={list} onRemove={handleOnRemove} />
									</div>
								</div>
							)}
						</div>
					</>
				)}
				<div className='v__form__footer v__grid__item v__grid__item--col-12'>
					<Button bg status='darker' onClick={onCancel}>
						{t("cancel")}
					</Button>
					{step >= 0 && (
						<Button bg status='darker' onClick={onBack}>
							{t("back")}
						</Button>
					)}
					{step === 0 && (
						<Button bg status='accent' onClick={onSetDefaultDivisions}>
							{t("continueAndSame")}
						</Button>
					)}

					<Button bg status='accent' type='submit'>
						{step === maxStep ? t("save") : t("continue")}
						{/* {payload ? lang?.save_changes : lang?.add_group} */}
					</Button>
				</div>
			</form>
		</>
	);
};

const SPList = ({ servicePartners, onRemove }) => {
	const { t } = useTranslation();

	if (Object.keys(servicePartners).length === 0) {
		return (
			<div
				className='v__list-selection__result__group text-center flex flex-col items-center  
                                         h-full justify-center '
			>
				<ComingSoonSvg classes='v__coming-soon__graphic !w-44 ' withAnimation={true} />
				<span>{t("nothingSelected")}</span>
			</div>
		);
	}

	return Object.entries(servicePartners).map((entry) => {
		const key = entry[0];
		const item = entry[1];

		if (!Boolean(item.checked)) {
			return null;
		}
		return (
			<div className='v__list-selection__result__group' key={key}>
				<div className='v__list-selection__result__group__title'>
					<span>{item.title ?? t("others")}</span>
					{/*
					<Badge type='outline' size='md'>
						{Object.values(item.members).filter((option) => option.checked).length}
						THIS WAS COOMMENTED{{item?.max_choices ? ` / ${item.max_choices}` : ""} }
                    </Badge>
                    */}
					<Button small round bg status='darker' hoverStatus='danger' onClick={() => onRemove?.({ sp: item, value: entry })}>
						<Icon>delete</Icon>
					</Button>
				</div>
			</div>
		);
	});
};

const MembersList = ({ members, onRemove }) => {
	const { t } = useTranslation();
	const [collapsedChoiceGroups, setCollapsedChoiceGroups] = useState({});

	if (Object.keys(members).length === 0) {
		return (
			<div
				className='v__list-selection__result__group text-center flex flex-col items-center  
                                         h-full justify-center '
			>
				<ComingSoonSvg classes='v__coming-soon__graphic !w-44 ' withAnimation={true} />
				<span>{t("nothingSelected")}</span>
			</div>
		);
	}

	return Object.entries(members).map((entry) => {
		const key = entry[0];
		const item = entry[1];
		if (Object.values(item.members).filter((option) => option.checked).length === 0) {
			return null;
		}
		return (
			<div className='v__list-selection__result__group' key={key}>
				<div
					className='v__list-selection__result__group__title'
					onClick={() => setCollapsedChoiceGroups((prev) => ({ ...prev, [item.id]: !prev[item.id] }))}
				>
					{collapsedChoiceGroups[item.id] ? <Icon>expand_more</Icon> : <Icon>expand_less</Icon>}

					<span>{item.title ?? t("others")}</span>

					<Badge type='outline' size='md'>
						{Object.values(item.members).filter((option) => option.checked).length}
						{/* {item?.max_choices ? ` / ${item.max_choices}` : ""} */}
					</Badge>
				</div>
				{!collapsedChoiceGroups[item.id] && (
					<div className='v__list-selection__result__group__body'>
						{Object.values(item.members)
							.filter((option) => option.checked)
							.map((entry) => (
								<div key={Math.random()} className='v__list-selection__result__group__body__item'>
									<span>{entry.full_name}</span>
									<Button
										small
										round
										bg
										status='darker'
										hoverStatus='danger'
										onClick={() => onRemove?.({ sp: item, value: entry })}
									>
										<Icon>delete</Icon>
									</Button>
								</div>
							))}
					</div>
				)}
			</div>
		);
	});
};

const GenerateDivisionsForms = (props) => {
	if (props.divisions === undefined) {
		return null;
	}

	return props.divisions?.data.map((division, index) => {
		if (index !== props.step) {
			return null;
		}
		return props.fields.map((field_name) => {
			return (
				<Controller
					render={({ field }) => (
						<FormInput
							groupClassName={cn("v__grid__item v__grid__item--col-4 v__grid__item--col-sm-12", {
								"v__form__group--incorrect": props.errors?.title,
								hidden: field_name === "division_id",
							})}
							label={field_name === "division_id" ? props.t(division.name) : props.t(field_name)}
							type={field_name === "division_id" ? "hidden" : null}
							error={props.errors?.title && "error"}
							{...field}
						/>
					)}
					control={props.control}
					name={"formulas[" + index + "][" + field_name + "]"}
					defaultValue={field_name === "division_id" ? division.id : 0}
					key={field_name}
					rules={{}}
				/>
			);
		});
	});
};

const extractMemberSelections = (obj) => {
	return Object.values(obj).reduce((prev, curr) => {
		const sanitize = Object.entries(curr.members).reduce((p, c) => ({ ...p, [c[0]]: c[1].checked }), {});
		return { ...prev, ...sanitize };
	}, {});
};

const TabItem = ({ label, icon }) => (
	<div className='v__tabs__list__item__label'>
		{icon}
		{label}
	</div>
);

export default FairplayForm;
