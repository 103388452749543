import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { useAuth } from "context";

import { Button } from "../../components";
import { Spinner } from "components/ui";

const OPTIMISTIC_SCROLLBAR_WIDTH = 12;

const InvoiceManagerEditForm = ({ onCancel, payload: { invoice_manager } }) => {
	const { t } = useTranslation();

	const { token } = useAuth();

	const documentContainerRef = useRef();
	const [numPages, setNumPages] = useState(0);

	return (
		<>
			<div ref={documentContainerRef}>
				<Document
					file={{
						url: `${process.env.REACT_APP_API_URL}/invoicemanager/${invoice_manager?.id}/pdf`,
						httpHeaders: {
							Accept: "application/json, text/plain, */*",
							Authorization: `Bearer ${token}`,
						},
					}}
					loading={
						<div className='w-full flex justify-center'>
							<Spinner size={40} />
						</div>
					}
					noData={t("noData")}
					error={t("errorTODO")}
					onLoadSuccess={({ numPages }) => setNumPages(numPages)}
					className='max-h-[70vh] overflow-y-auto space-y-2 '
				>
					{[...Array(numPages).keys()].map((x) => (
						<Page
							key={`pdfPage${x + 1}`}
							pageNumber={x + 1}
							width={documentContainerRef.current?.getBoundingClientRect()?.width - OPTIMISTIC_SCROLLBAR_WIDTH || 0}
						/>
					))}
				</Document>
			</div>
			<div className='v__form__footer v__grid__item v__grid__item--col-12'>
				<Button bg status='darker' onClick={onCancel}>
					{t("cancel")}
				</Button>
			</div>
		</>
	);
};

export default InvoiceManagerEditForm;
