import { useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "utils";
import { SPFairplayManagementApi } from "../../api";
import { ReportsApi } from "../../api";

export const fairplaySPKeys = {
    all: () => ["fairplaySPKeys"],
    byId: (id) => [...fairplaySPKeys.all(), id],
    byConfig: (config) => [...fairplaySPKeys.all(), config],
};

export const fairplaySPStatisticsKeys = {
    all: () => ["fairplaySPKeys"],
    byConfig: (config) => [...fairplaySPStatisticsKeys.all(), config],
};


export const useFairplaysSP = (config) => {
    const { custom, ...restConfig } = config;
    return useQuery(fairplaySPKeys.byConfig(custom), () => SPFairplayManagementApi.getSPFairplays(custom), restConfig);
};

export const useFairplaySP = (id, config) => {
    const { custom, ...restConfig } = config;
    return useQuery(fairplaySPKeys.byId(id), () => SPFairplayManagementApi.getSPFairplay(id, custom), restConfig);
};

export const useCreateFairplaySP = (config) => {
    const queryClient = useQueryClient();
    return useMutation(SPFairplayManagementApi.createSPFairplay, {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(fairplaySPKeys.all());
        }, config?.onSuccess),
    });
};

export const useUpdateFairplaySP = (config) => {
    const queryClient = useQueryClient();
    return useMutation(({ id, data }) => SPFairplayManagementApi.updateSPFairplay(id, data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(fairplaySPKeys.all());
        }, config?.onSuccess),
    });
};

export const useDeleteFairplaySP = (config) => {
    const queryClient = useQueryClient();
    return useMutation(async (data) => SPFairplayManagementApi.deleteSPFairplay(data), {
        ...config,
        onSuccess: callAll(() => {
            queryClient.invalidateQueries(fairplaySPKeys.all());
        }, config?.onSuccess),
    });
};

export const useFairplayStatisticsSP = (config) => {
    const { custom, ...restConfig } = config;
    return useQuery(fairplaySPStatisticsKeys.byConfig(custom), () => ReportsApi.getSPFBR(custom), restConfig);
};

