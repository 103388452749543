import { useQuery } from "react-query";
import { DepoApi } from "../../api";

export const depoKeys = {
	all: () => ["depo"],
	byId: (id) => [...depoKeys.all(), id],
};

export const useDepo = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(depoKeys.byId(id), () => DepoApi.getDepo(id, custom), restConfig);
};
