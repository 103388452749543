import { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import cn from "classnames";

import { mergeRefs } from "../../utils";
import { Avatar, CSVLogo } from "../ui";
import { Button, Icon } from "..";
import { ExcelLogo } from "components/ui/logos/ExcelLogo";
import { useWindowSize } from "hooks";

const FormDropzone = forwardRef(
	(
		{
			groupClassName,
			withLabel = true,
			name,
			id = name,
			label = "",
			fileForDownload,
			prevFile,
			icon,
			error,
			children,
			multiple,
			accept,
			onChange,
			value,
			...rest
		},
		ref
	) => {
		const { t } = useTranslation();

		const { width } = useWindowSize();
		const isMobile = width < 800;

		const [files, setFiles] = useState([]);

		const { getRootProps, getInputProps, inputRef, acceptedFiles, isDragAccept, isDragReject } = useDropzone({
			multiple,
			accept,
			...rest,
		});

		useEffect(() => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		}, [acceptedFiles, setFiles]);

		useEffect(() => {
			if (!value) {
				setFiles([]);
			}
		}, [value]);

		const validateDrop = useMemo(() => {
			let dragStatus;
			if (isDragAccept && !isDragReject) {
				dragStatus = "v__img__dropzone--valid";
			} else if (!isDragAccept && isDragReject) {
				dragStatus = "v__img__dropzone--invalid";
			} else {
				dragStatus = "";
			}
			return dragStatus;
		}, [isDragAccept, isDragReject]);

		const thumbs = files.map(function (file, i) {
			var returnComp = {};
			switch (file.type) {
				case "text/csv":
					returnComp.icon = <CSVLogo className='w-20 h-20' />;
					break;
				case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
					returnComp.icon = <ExcelLogo className='w-20 h-20' />;
					break;
				case "application/vnd.ms-excel":
					returnComp.icon = <ExcelLogo className='w-20 h-20' />;
					break;
				default:
					returnComp.icon = (
						<img loading='lazy' key={file.name} src={file.preview} alt='new avatar' className='v__img__dropzone__new__img' />
					);
					break;
			}

			returnComp.fileName = file.path.substring(0, 40);

			return returnComp;
		});

		return (
			<div className={cn(`v__form__group`, groupClassName)}>
				<label
					htmlFor={id}
					className={`v__form__group__label v__form__group__label--with-action ${
						!withLabel ? "v__form__group__label--invisible" : ""
					}`}
				>
					<span>{label}</span>
				</label>

				<div
					{...getRootProps({
						className: `v__img__dropzone ${validateDrop}`,
					})}
				>
					<input {...getInputProps({ onChange, id })} ref={mergeRefs([ref, inputRef])} />
					<div className='v__img__dropzone__prev'>
						{!Boolean(fileForDownload) && (
							<Avatar
								user={prevFile?.avatar ? prevFile : null}
								imgUrl={typeof prevFile === "string" ? prevFile : null}
								className='v__img__dropzone__prev__img'
								icon={Boolean(icon) ? icon : undefined}
							/>
						)}

						{Boolean(fileForDownload) && (
							<a
								href={fileForDownload}
								onClick={(e) => {
									e.stopPropagation();
								}}
								target='_blank'
								rel='noopener noreferrer'
								download
								className='inline-block mx-auto mb-4'
							>
								<Button bg status='warning' iconLeft={<Icon>download</Icon>}>
									Download File
								</Button>
							</a>
						)}

						{thumbs.length === 0 && <p className='v__img__dropzone__prev__instructions'>{t("dragNDrop")}</p>}
					</div>

					{thumbs.length > 0 && <Icon>arrow_forward</Icon>}
					{thumbs.length > 0 && (
						<div className='v__img__dropzone__new'>
							<div className='v__img__dropzone__new__container'>
								{thumbs.map((option) => option.icon)}
								<Button round small bg spongy={false} status='danger' onClick={() => setFiles([])}>
									<Icon>clear</Icon>
								</Button>
							</div>
						</div>
					)}

					{thumbs.length > 0 && !isMobile && (
						<p className='v__img__dropzone__prev__instructions'>{thumbs.map((option) => option.fileName)}</p>
					)}
				</div>

				{validateDrop === "v__img__dropzone--invalid" && (
					<div className='dropzone__invalid__msg'>
						{t("uploadOnly")} <strong>{accept.replace(/image\//g, ".").replace(/,/g, " / ")}</strong>
					</div>
				)}
				{error && <small className='v__form__group__hint'>{error}</small>}
				{children}
			</div>
		);
	}
);

FormDropzone.propTypes = {
	groupClassName: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	withLabel: PropTypes.bool,
	accept: PropTypes.string,
	multiple: PropTypes.bool,
};

export default FormDropzone;
