import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useMedia, useMediaQueries, useReports, useReportsTableColumnHiding, useReportsTableGenerate } from "hooks";

import { Button, Card, Icon, Modal, NoDataRow, ReportsTable, VirtualSelect } from "components";
import { SwitchGroup } from "components/forms";
import { Badge, LineLoader } from "components/ui";
import ReportsControls from "./ReportsControls";

import { parseClickedRowData, parsePreset } from "utils";

import { REPORT_COLUMN_PRESETS, REPORT_GROUP_BY_TYPES } from "reducers/const";
import { ReportsPrintQuicKViewFilterState } from "./ReportsPrintFilterState";
import { REPORT_FEEDBACK_CELL_ACCESSORS, REPORT_POINTS_CELL_ACCESSORS } from "./const";

// pass id to a outside the table, there the comments will render (it must be positioned relatively)
const quickViewCommentContainerId = "quickViewReportsContainerId";

const QuickViewReportsModal = ({ payload, onClose, primaryDataType = "reports", initialParams }) => {
	const { presets, columnPreset: initialPreset, ...restPayload } = payload;
	const { LARGE } = useMediaQueries();
	const isLarge = useMedia(LARGE);
    let hidePresets = false;

    if (primaryDataType == "invoices") {
        hidePresets = true;
    }

	const { t } = useTranslation();

	// state for current column preset
	const [columnPreset, setColumnPreset] = useState(() => initialPreset);
	const dataType = columnPreset?.value === REPORT_COLUMN_PRESETS.FEEDBACK ? "feedback" : primaryDataType;
	//state for stack
	const [stack, setStack] = useState(() => [restPayload]);
	const removeFromStack = useCallback(() => setStack((prev) => prev.slice(0, prev.length - 1)), []);
	const addToStack = useCallback((data) => setStack((prev) => prev.concat(data)), []);


	// handling the clock on a non Link cell (open Quick View)
	const onRowClick = useCallback(
		(rowData) => {
			const isFeedbackCell = REPORT_FEEDBACK_CELL_ACCESSORS.includes(rowData.cell.column.id);
			const isPointCell = REPORT_POINTS_CELL_ACCESSORS.includes(rowData.cell.column.id);

			//make points table unclickable
			if (rowData.queryParams.points) {
				return;
			}
			if (isPointCell) {
				return addToStack(parseClickedRowData({ ...rowData, points: true }));
			}
			if (isFeedbackCell) {
				return addToStack(parseClickedRowData(rowData, "feedback"));
			}

			addToStack(parseClickedRowData(rowData));
		},
		[addToStack]
	);
	const currentConfig = stack[stack.length - 1];
	const currentRow = currentConfig?.row ?? {};
	const currentParams = currentConfig?.requestParams ?? {};
	currentParams.fairplay_id = initialParams?.fairplay_id;
	currentParams.groupBy = currentParams.points === true ? undefined : currentParams.groupBy;
	const currentDataType = currentConfig?.dataType;

	useEffect(() => {
		// update the report preset switch when drilling
		if (currentDataType === "feedback") {
			return setColumnPreset(() => presets.find((x) => x.value === "feedback"));
		}

		if (primaryDataType === "invoices") {
			return setColumnPreset(() => presets.find((x) => x.value === "salary"));
		}

		// if going back in history reset to initial column preset
		setColumnPreset(() => initialPreset);
	}, [currentDataType, presets, initialPreset, primaryDataType]);

	// const userId = currentParams.user_id;
	// const { data: workingYears } = useUser(userId, {
	// 	custom: {
	// 		includes: ["workingYears"],
	// 	},
	// 	enabled: Boolean(userId),
	// 	select: (data) => {
	// 		const years = data.data.workingYears?.sort();
	// 		const allYears = new Set([...years, new Date().getFullYear()]);
	// 		return [...allYears].map((x) => ({ value: x, label: x }));
	// 	},
	// });

	const workingYears = (() => {
		const returnValue = [];
		for (let i = 2022; i >= 2018; i--) {
			returnValue.push({ value: i, label: i });
		}
		return returnValue;
	})();

	// generate Reports Table
	const {
		state: { setQueryParams },
		query: { data },
		tableProps,
	} = useReportsTableGenerate({
		useFetch: useReports,
		config: currentParams,
		dataType,
		commentContainerId: quickViewCommentContainerId,
		onRowClick,
	});

	// load saved hidden columns for reports page
	const { onColumnHide, storedHiddenColumns } = useReportsTableColumnHiding({ columnPreset });

	// handle reports preset (change to page 1 if switching from reports to feedback)
	const onPresetChange = useCallback(
		(preset) => {
			setColumnPreset((prev) => {
				if (parsePreset(prev?.value, primaryDataType) !== parsePreset(preset.value, primaryDataType)) {
					setQueryParams((params) => ({ ...params, page: 1, sortBy: undefined }));
					return preset;
				}
				return preset;
			});
		},
		[setQueryParams, primaryDataType]
	);

	function getViewingNames() {
		if (currentParams.user_id) {
			return [currentRow.depo_name, currentRow.service_partner_name, currentRow.group_name, currentRow.full_name];
		}
		if (currentParams.member_group_id) {
			return [currentRow.group_name];
		}
		if (currentParams.service_partner_id) {
			return [currentRow.service_partner_name];
		}
		if (currentParams.depo_id) {
			return [currentRow.depo_name];
		}
		if (currentParams.card_id) {
			return [currentRow.card_number];
		}
		if (currentParams.invoice_id) {
			return [currentRow.invoice_number, currentRow.full_name];
		}

        return ["undefined"];
	}

	const rowData = (
		<>
			<span className='mr-2'>{getViewingNames().filter(Boolean).join(" / ")}</span>
			<Badge type='warning' size='md'>
				{currentParams.week && `${t("week")} `}
				{currentParams.month && `${t("month")} `}
				{[currentParams.date, currentParams.week, currentParams.month].filter(Boolean)}
				{currentParams.year &&
					`${[currentParams.week, currentParams.month].filter(Boolean).length > 0 ? ` | ` : ""}${currentParams.year}`}
			</Badge>
		</>
	);

	if (!data || !columnPreset) {
		return (
			<Modal onClose={onClose}>
				<Card className='v__modal v__modal--lg v__card--overflow-able' header={rowData} dismissible={onClose}>
					<LineLoader />
					<NoDataRow asDiv />
				</Card>
			</Modal>
		);
	}

	const tableExtraProps = {
		onColumnHide,
		tableHiddenColumns: storedHiddenColumns?.[columnPreset.value] ?? columnPreset.columns,
		resetPageIndex: JSON.stringify({ currentDataType, state: currentParams }), // RESET PAGE
		rowHighlighting: currentDataType !== REPORT_COLUMN_PRESETS.FEEDBACK,
		dark: true,
	};

	const additionalGroupByOptions = isLarge
		? [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: t("yearly"),
					secondaryLabel: t("daily"),
				},
				{
					value: REPORT_GROUP_BY_TYPES.WEEK,
					label: t("yearly"),
					secondaryLabel: t("weekly"),
				},
				{
					value: REPORT_GROUP_BY_TYPES.MONTH,
					label: t("yearly"),
					secondaryLabel: t("monthly"),
				},
		  ]
		: [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: `${t("yearly")} - ${t("daily")}`,
				},
				{
					value: REPORT_GROUP_BY_TYPES.WEEK,
					label: `${t("yearly")} - ${t("weekly")}`,
				},
				{
					value: REPORT_GROUP_BY_TYPES.MONTH,
					label: `${t("yearly")} - ${t("monthly")}`,
				},
		  ];

	const activeUserYearlyGroupByOption =
		!currentParams.date && !currentParams.week && !currentParams.month
			? additionalGroupByOptions?.find((x) => x.value === currentParams?.groupBy)
			: null;

	const handleUserYearlyGroupByChange = (option) => {
		addToStack({
			...currentConfig,
			requestParams: {
				...currentConfig.requestParams,
				groupBy: option.value,
				points: undefined,
				date: undefined,
				week: undefined,
				month: undefined,
			},
		});
	};

	const groupGroupByOptions = isLarge
		? [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: t("day"),
					secondaryLabel: t("group"),
				},

				{
					value: REPORT_GROUP_BY_TYPES.USER_ID,
					label: t("driver"),
					secondaryLabel: t("group"),
				},
				{
					value: REPORT_GROUP_BY_TYPES.SP_NAME,
					label: t("servicePartner"),
					secondaryLabel: t("group"),
				},
		  ]
		: [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: `${t("day")} - ${t("group")}`,
				},

				{
					value: REPORT_GROUP_BY_TYPES.USER_ID,
					label: `${t("driver")} - ${t("group")}`,
				},
				{
					value: REPORT_GROUP_BY_TYPES.SP_NAME,
					label: `${t("servicePartner")} - ${t("group")}`,
				},
		  ];

	const spGroupByOptions = isLarge
		? [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: t("day"),
					secondaryLabel: t("group"),
				},

				{
					value: REPORT_GROUP_BY_TYPES.USER_ID,
					label: t("driver"),
					secondaryLabel: t("group"),
				},
		  ]
		: [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: `${t("day")} - ${t("group")}`,
				},

				{
					value: REPORT_GROUP_BY_TYPES.USER_ID,
					label: `${t("driver")} - ${t("group")}`,
				},
		  ];

	const depoGroupByOptions = isLarge
		? [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: t("day"),
					secondaryLabel: t("group"),
				},
				{
					value: REPORT_GROUP_BY_TYPES.USER_ID,
					label: t("driver"),
					secondaryLabel: t("group"),
				},
				{
					value: REPORT_GROUP_BY_TYPES.SP_NAME,
					label: t("servicePartner"),
					secondaryLabel: t("group"),
				},
		  ]
		: [
				{
					value: REPORT_GROUP_BY_TYPES.DAY,
					label: `${t("day")} - ${t("group")}`,
				},

				{
					value: REPORT_GROUP_BY_TYPES.USER_ID,
					label: `${t("driver")} - ${t("group")}`,
				},
				{
					value: REPORT_GROUP_BY_TYPES.SP_NAME,
					label: `${t("servicePartner")} - ${t("group")}`,
				},
		  ];

	const activeGroupGroupByOption = groupGroupByOptions?.find((x) => x.value === currentParams?.groupBy);
	const activeSpGroupByOption = spGroupByOptions?.find((x) => x.value === currentParams?.groupBy);
	const activeDepoGroupByOption = depoGroupByOptions?.find((x) => x.value === currentParams?.groupBy);

	const handleGroupByChange = (option) => {
		addToStack({
			points: undefined,
			...currentConfig,
			requestParams: {
				...currentConfig.requestParams,
				groupBy: option.value,
				points: undefined,
			},
		});
	};

	return (
		<Modal onClose={onClose}>
			<Card className='v__modal v__modal--lg v__card--overflow-able' header={rowData} dismissible={onClose}>
				<div className='flex items-center justify-end lg:flex-row flex-col space-y-2 lg:space-y-0 pb-2 relative print:hidden'>
					{currentDataType !== REPORT_COLUMN_PRESETS.FEEDBACK && primaryDataType !== "invoices" &&
						(isLarge ? (
							<SwitchGroup
								id='userYearlyGroupBySwitch'
								activeOption={activeUserYearlyGroupByOption}
								onChange={handleUserYearlyGroupByChange}
								options={additionalGroupByOptions}
							/>
						) : (
							// <VirtualSelect
							// 	className='w-full'
							// 	id='userYearlyGroupBySwitch'
							// 	value={activeUserYearlyGroupByOption}
							// 	onChange={handleUserYearlyGroupByChange}
							// 	options={additionalGroupByOptions}
							// />
							<SwitchGroup
								id='userYearlyGroupBySwitch'
								activeOption={activeUserYearlyGroupByOption}
								onChange={handleUserYearlyGroupByChange}
								options={additionalGroupByOptions}
							/>
						))}

					<div className='flex flex-col space-y-2 lg:space-y-0 lg:flex-row items-center justify-end grow w-full lg:w-auto'>
						{currentParams.member_group_id &&
							!currentParams.user_id &&
							!currentParams.service_partner_id &&
							!currentParams.depo_id &&
							currentDataType !== REPORT_COLUMN_PRESETS.FEEDBACK &&
							(isLarge ? (
								<SwitchGroup
									id='groupGroupBySwitch'
									activeOption={activeGroupGroupByOption}
									onChange={handleGroupByChange}
									options={groupGroupByOptions}
									className={"!mr-2"}
								/>
							) : (
								<VirtualSelect
									className='w-full'
									id='groupGroupBySwitch'
									value={activeGroupGroupByOption}
									onChange={handleGroupByChange}
									options={groupGroupByOptions}
								/>
							))}
						{currentParams.service_partner_id &&
							!currentParams.user_id &&
							!currentParams.member_group_id &&
							!currentParams.depo_id &&
							currentDataType !== REPORT_COLUMN_PRESETS.FEEDBACK &&
							(isLarge ? (
								<SwitchGroup
									id='spGroupBySwitch'
									activeOption={activeSpGroupByOption}
									onChange={handleGroupByChange}
									options={spGroupByOptions}
									className={"!mr-2"}
								/>
							) : (
								<VirtualSelect
									className='w-full'
									id='groupGroupBySwitch'
									value={activeSpGroupByOption}
									onChange={handleGroupByChange}
									options={spGroupByOptions}
								/>
							))}
						{currentParams.depo_id &&
							!currentParams.user_id &&
							!currentParams.member_group_id &&
							!currentParams.service_partner_id &&
							currentDataType !== REPORT_COLUMN_PRESETS.FEEDBACK &&
							(isLarge ? (
								<SwitchGroup
									id='depoGroupBySwitch'
									activeOption={activeDepoGroupByOption}
									onChange={handleGroupByChange}
									options={depoGroupByOptions}
									className={"!mr-2"}
								/>
							) : (
								<VirtualSelect
									className='w-full'
									id='groupGroupBySwitch'
									value={activeDepoGroupByOption}
									onChange={handleGroupByChange}
									options={depoGroupByOptions}
								/>
							))}

						{currentDataType !== REPORT_COLUMN_PRESETS.FEEDBACK && (
							<VirtualSelect
								className='w-full lg:w-28'
								value={workingYears?.find((x) => x.value === currentParams?.year)}
								onChange={(option) => {
									addToStack({
										...currentConfig,
										requestParams: {
											...currentConfig.requestParams,
											points: undefined,
											year: option.value,
										},
									});
								}}
								options={workingYears ?? []}
							/>
						)}
						{stack.length > 1 && (
							<div className='flex justify-center order-1 lg:-order-1 mr-2'>
								<Button bg status='warning' iconLeft={<Icon>undo</Icon>} onClick={removeFromStack}>
									{t("goBack")}
								</Button>
							</div>
						)}
					</div>
				</div>
				<ReportsPrintQuicKViewFilterState
					viewing={getViewingNames().filter(Boolean).join(" / ")}
					columnPreset={columnPreset}
					hidePresets={hidePresets}
					columns={tableProps?.columns}
					queryParams={currentParams}
				/>
				<ReportsTable
					{...tableProps}
					{...tableExtraProps}
					controlComponent={(props) => (
						<ReportsControls
							{...props}
							presets={presets}
                            hidePresets={hidePresets}
							columnPreset={columnPreset}
							onPresetChange={onPresetChange}
							feedbackOnly={currentDataType === REPORT_COLUMN_PRESETS.FEEDBACK}
							showPrintBtn
							dark
						/>
					)}
				/>
			</Card>
		</Modal>
	);
};

export default QuickViewReportsModal;
