import { useEffect, useLayoutEffect } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import cn from "classnames";

import { useUpdateEffect } from "hooks";
import { Card, Icon } from "components";

import TablePagination from "./TablePagination";
import NoDataRow from "./NoDataRow";
import { getCss } from "utils";

// TODO: think of a way to remove this, the table shouldn't know what is being rendered
import { REPORT_FEEDBACK_CELL_ACCESSORS, REPORT_POINTS_CELL_ACCESSORS, REPORT_REDIRECT_CELL_ACCESSORS } from "pages";

const ReportsTable = ({
	columns,
	data,
	fetchData,
	loading,
	serverPageCount,
	serverTotalResults,
	initialPageSize = 15,
	scrollToTopOnChange,
	paginationComponent,
	dark,
	resetPageIndex,
	onColumnHide,
	tableHiddenColumns = [],
	// Important for comment cell
	commentContainerId,
	// report specific props
	cardClassName,
	hasOverallDataRows,
	controlComponent,
	rowHighlighting,
	filteredColumns,
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		allColumns,
		getToggleHideAllColumnsProps,
		toggleHideAllColumns,
		setHiddenColumns,
		state: { pageIndex, pageSize, sortBy, hiddenColumns },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: initialPageSize, sortBy: [], hiddenColumns: tableHiddenColumns },
			manualPagination: true,
			manualSortBy: true,
			pageCount: serverPageCount,
			autoResetPage: false,
			autoResetHiddenColumns: false,
			autoResetSortBy: false,
		},
		useSortBy,
		usePagination
	);

	useEffect(() => {
		gotoPage(0);
	}, [gotoPage, resetPageIndex]);

	useEffect(() => {
		fetchData?.({ pageIndex, pageSize, sortBy });
	}, [fetchData, pageIndex, pageSize, sortBy]);

	useLayoutEffect(() => {
		setHiddenColumns(tableHiddenColumns);
	}, [setHiddenColumns, tableHiddenColumns]);

	useUpdateEffect(() => {
		onColumnHide?.({ hiddenColumns });
	}, [hiddenColumns.join(",")]);

	const controlProps = {
		columns,
		allColumns,
		getToggleHideAllColumnsProps,
		toggleHideAllColumns,
		setHiddenColumns,
		hiddenColumns,
	};

	const paginationProps = {
		gotoPage,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		pageCount,
		pageIndex,
		pageOptions,
		pageSize,
		setPageSize,
		serverTotalResults,
		scrollToTopOnChange,
		lessPerPage: false,
		dark,
	};

	return (
		<>
			{controlComponent?.(controlProps)}

			<div className='relative' id={commentContainerId} />

			<Card loading={loading} className={cn("v__table__container body-no-pad print:!overflow-hidden relative", cardClassName)}>
				<table className='v__report__table' {...getTableProps()}>
					<thead className='v__report__table__header'>
						{headerGroups.map((headerGroup) => renderHeader(headerGroup, filteredColumns))}
					</thead>
					<tbody
						className={cn("v__report__table__body", {
							"v__report__table__body--with-avg": hasOverallDataRows,
						})}
						{...getTableBodyProps()}
					>
						{data?.length === 0 && <NoDataRow />}
						{page.map((row, index) => {
							prepareRow(row);
							if (hasOverallDataRows && (index === 0 || index === 1)) {
								return renderOverallRow(row, rowHighlighting);
							}
							return renderRow(row, rowHighlighting);
						})}
					</tbody>
				</table>
			</Card>

			{paginationComponent?.(paginationProps) ?? <TablePagination {...paginationProps} />}
		</>
	);
};

const parseCellValue = (val) => {
	if (typeof val === "string" && val?.includes("%")) {
		return Number(val.split("%")[0]);
	}
	return val;
};

const setCorrectCellStyles = (cell) => {
	if ((cell.column.id === "negative_feedback_shorten" && cell.value) || (cell.column.id === "overall_points" && cell.value <= 0)) {
		return {
			background: getCss("--danger"),
		};
	}
	if ((cell.column.id === "positive_feedback_shorten" && cell.value) || (cell.column.id === "overall_points" && cell.value > 0)) {
		return {
			background: getCss("--success"),
		};
	}

	if (Boolean(cell.column.pigments?.length) && cell.value) {
		const pigment = cell.column.pigments?.filter(
			(item) => parseCellValue(cell.value) >= Number(item.min) && parseCellValue(cell.value) <= Number(item.max)
		)[0];
		if (pigment) {
			return {
				background: pigment.color,
			};
		} else {
			return null;
		}
	}
};

const renderHeader = (headerGroup, filteredColumns) => {
	return (
		<tr {...headerGroup.getHeaderGroupProps()} className='v__report__table__header__row'>
			{headerGroup.headers.map((column) => (
				<th
					{...column.getHeaderProps(column.getSortByToggleProps())}
					className={cn("v__report__table__header__row__cell", {
						"v__report__table__header__row__cell--desc": column.isSorted && column.isSortedDesc,
						"v__report__table__header__row__cell--asc": column.isSorted && !column.isSortedDesc,
						"v__report__table__header__row__cell--sticky": column.sticky,
						"v__report__table__header__row__cell--filtered": filteredColumns
							? Object.keys(filteredColumns).includes(column.id)
							: false,
					})}
					title={column.description}
				>
					<span>
						{column.icon === "golden_five" ? (
							<Icon>military_tech</Icon>
						) : column.icon === "scores" ? (
							<Icon>grade</Icon>
						) : column.icon === "rating" ? (
							<Icon className='text-gold'>emoji_events</Icon>
						) : null}{" "}
						{column.render("Header")}
					</span>
				</th>
			))}
		</tr>
	);
};

const renderRow = (row, rowHighlighting) => (
	<tr
		{...row.getRowProps()}
		className={cn("v__report__table__body__row", {
			"v__report__table__body__row--hover": rowHighlighting,
		})}
	>
		{row.cells.map((cell) => {
			const isLinkRedirect = REPORT_REDIRECT_CELL_ACCESSORS.includes(cell.column.id);
			const isFeedbackCell = REPORT_FEEDBACK_CELL_ACCESSORS.includes(cell.column.id);
			const isPointCell = REPORT_POINTS_CELL_ACCESSORS.includes(cell.column.id);
			return (
				<td
					{...cell.getCellProps()}
					className={cn("v__report__table__body__row__cell", {
						"v__report__table__body__row__cell--limited":
							cell.column.id === "full_comment" ||
							cell.column.id === "address" ||
							cell.column.id === "miv" ||
							cell.column.id === "full_name",
						"v__report__table__body__row__cell--clickable": cell.column.onClick || cell.column.id === "full_comment",
						"v__report__table__body__row__cell--sticky": cell.column.sticky,
						"v__report__table__body__row__cell--division": cell.column.id === "division_icon",
						"v__report__table__body__row__cell--colorful": setCorrectCellStyles(cell),
						"v__report__table__body__row__cell--clickable-redirect relative": isLinkRedirect,
						"v__report__table__body__row__cell--clickable-action relative": isFeedbackCell,
						"v__report__table__body__row__cell--clickable-points relative": isPointCell,
					})}
					style={isLinkRedirect ? { padding: 0 } : {}}
					onClick={Boolean(rowHighlighting) && Boolean(cell.column.onClick) ? () => cell.column.onClick?.(cell) : undefined}
					title={cell.column.description}
				>
					{setCorrectCellStyles(cell) ? (
						<span>
							{cell.render("Cell")}
							<div className='cell__pigment' style={setCorrectCellStyles(cell)} />
						</span>
					) : (
						cell.render("Cell")
					)}
				</td>
			);
		})}
	</tr>
);

const renderOverallRow = (row, rowHighlighting) => {
	return (
		<tr
			{...row.getRowProps()}
			className={cn("v__report__table__body__row", {
				"v__report__table__body__row--hover": rowHighlighting,
			})}
		>
			{row.cells.map((cell) => (
				<td
					{...cell.getCellProps()}
					className={cn("v__report__table__body__row__cell", {
						"v__report__table__body__row__cell--sticky": cell.column.sticky,
						"v__report__table__body__row__cell--division": cell.column.id === "division_icon",
						"v__report__table__body__row__cell--colorful": setCorrectCellStyles(cell),
					})}
					title={cell.column.description}
				>
					{setCorrectCellStyles(cell) ? (
						<span>
							{cell.render("Cell")}
							<div className='cell__pigment' style={setCorrectCellStyles(cell)} />
						</span>
					) : (
						cell.render("Cell")
					)}
				</td>
			))}
		</tr>
	);
};

export default ReportsTable;
