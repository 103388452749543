import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

import { useUploadedDates } from "hooks";

import PageContent from "components/util/PageContent";
import PageHeader from "components/util/PageHeader";
import PageWrapper from "components/util/PageWrapper";
import { FuelFilters } from ".";
import ReportsPeriodPicker from "pages/Reports/ReportsPeriodPicker";

function FuelWrapper() {
	const { data: files } = useUploadedDates({});

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Fuel</title>
			</Helmet>
			<PageHeader>
				<FuelFilters title={"fuel"} />
			</PageHeader>
			<PageContent>
				<ReportsPeriodPicker files={files} />
				<Outlet />
			</PageContent>
		</PageWrapper>
	);
}

export default FuelWrapper;
