import Container from "./Container";
import Clock from "../ui/Clock";

const PageHeader = ({ title, children, clock = false }) => {
	return (
		<div className='v__page__header'>
			<Container>
				<div className='v__page__header__content'>
					{title && <div className='v__page__header__content__title'>{title}</div>}
					{children}
					{clock && <Clock />}
				</div>
			</Container>
		</div>
	);
};

export default PageHeader;
