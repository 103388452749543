import { useContext, createContext, useMemo, useState } from "react";

export const ViewContext = createContext();

const ViewContextProvider = ({ children }) => {
	const [reportHistory, setReportHistory] = useState([]);
	const [overviewYearViewingOption, setOverviewYearViewingOption] = useState({value: new Date().getFullYear()});

	const reportHistoryValue = useMemo(() => ({ reportHistory, setReportHistory }), [reportHistory, setReportHistory]);
	const overviewValue = useMemo(
		() => ({ overviewYearViewingOption, setOverviewYearViewingOption }),
		[overviewYearViewingOption, setOverviewYearViewingOption]
	);

	return (
		<ViewContext.Provider
			value={{
				reportHistoryValue,
				overviewValue,
			}}
		>
			{children}
		</ViewContext.Provider>
	);
};

export const useProfileView = () => {
	const context = useContext(ViewContext);

	if (context === undefined) {
		throw new Error("useProfileView must be used within a ViewContextProvider");
	}

	return context;
};

export default ViewContextProvider;
