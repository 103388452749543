import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";
import cn from "classnames";

import { useInfiniteDepoStaff } from "../../hooks";
import UserCardItem from "./UserCardItem";
import { LineLoader } from "../../components/ui";

const ListItem = ({ onSelect, child, checked, staff }) => {
	const getSp = (driver) => {
		const target = driver.member.id;
		return staff.find((item) => {
			return item?.staffIds?.includes(target);
		});
	};

	const handleOnSelect = Boolean(onSelect) ? (value) => onSelect?.({ ...value, chain: { sp: getSp(value) } }) : undefined;

	return (
		<div className='v__staff__item px-1'>
			{child.type === "header" ? (
				<p className='dark:text-slate-300 text-slate-700 text-sm my-1'>{child.title}</p>
			) : (
				<UserCardItem member={child} onSelect={handleOnSelect} checked={checked?.[child.id]} />
			)}
		</div>
	);
};

const DepoStaffList = ({ onSelect, checked, search = "", className }) => {
	const { t } = useTranslation();
	const parentRef = useRef();

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isFetched } = useInfiniteDepoStaff({
		custom: {
			per_page: 2,
			includes: ["roles", "media"],
			search,
		},
		enabled: Boolean(search.length > 2),
		getNextPageParam: ({ meta }) => {
			const nextPage = meta.current_page + 1;
			const lastPage = meta.last_page;
			return nextPage <= lastPage ? nextPage : undefined;
		},
	});

	const staff = useMemo(() => {
		if (!data) {
			return [];
		}

		return data?.pages
			.map((page) => page.data)
			.reduce((prev, curr) => {
				const flatGroups = curr
					.map((v) => [
						{
							id: v.id,
							title: v.title,
							type: "header",
							staffIds: [...v.staff.reduce((p, c) => [...p, c.id], [])],
						},
						...v.staff,
					])
					.reduce((p, c) => [...p, ...c], []);
				return [...prev, ...flatGroups];
			}, []);
	}, [data]);

	const rowVirtualizer = useVirtual({
		size: staff.length,
		estimateSize: useCallback(() => 50, []),
		parentRef,
	});

	useEffect(() => {
		const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

		if (!lastItem) {
			return;
		}

		if (lastItem.index >= staff.length - 1 && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, fetchNextPage, staff.length, isFetchingNextPage, rowVirtualizer.virtualItems]);

	return (
		<div className={cn("h-full", className)}>
			{(isFetchingNextPage || (isFetching && !isFetched)) && <LineLoader />}
			<div className='v__staff__parent' ref={parentRef}>
				<div
					style={{
						height: `${rowVirtualizer.totalSize}px`,
					}}
				>
					{rowVirtualizer.virtualItems.map((virtualRow) => {
						const child = staff[virtualRow.index];
						return (
							<div
								key={virtualRow.index}
								ref={virtualRow.measureRef}
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: `${child}px`,
									transform: `translateY(${virtualRow.start}px)`,
								}}
								className='px-3'
							>
								<ListItem onSelect={onSelect} child={child} checked={checked} staff={staff} />
							</div>
						);
					})}
					{rowVirtualizer.virtualItems.length === 0 && <div className='text-center pad-xy-2'>{t("noData")}</div>}
				</div>
			</div>
		</div>
	);
};

export default DepoStaffList;
