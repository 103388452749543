import cn from "classnames";
import { useWindowSize } from "../../hooks";

const SettingsItem = ({ overflow = true, label, labelHint, labelFor, mobileHint = true, children }) => {
	const { width } = useWindowSize();

	return (
		<div className='v__grid v__settings__item'>
			<div className='v__grid__item v__grid__item--col-3 v__grid__item--col-md-4 v__grid__item--col-sm-12'>
				<label htmlFor={labelFor} className='v__settings__item__desc__label'>
					{label}
				</label>
				{mobileHint
					? labelHint && <span className='v__settings__item__desc__hint'>{labelHint}</span>
					: width > 1200 && labelHint && <span className='v__settings__item__desc__hint'>{labelHint}</span>}
			</div>
			<div
				className={cn(
					"v__settings__item__input v__grid__item v__grid__item--col-4 v__grid__item--col-md-7 v__grid__item--col-sm-12",
					{
						"no-overflow": !overflow,
					}
				)}>
				{children}
			</div>
		</div>
	);
};

export default SettingsItem;
