import { Outlet } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { FullPageLogoLoader } from "../components/ui";
import { useAuth } from "../context";
import { useProfile } from "../hooks";
import { BackToTop, Sidebar, TopBar } from "./components";

const UserLayout = () => {
	const { token, setLoggedUser } = useAuth();

	const { data: profile } = useProfile(token, {
		onSuccess: setLoggedUser,
	});

	if (!profile) {
		return (
			<div className='v__wrapper'>
				<CSSTransition in timeout={1000} unmountOnExit classNames='v__page__loader__container'>
					<FullPageLogoLoader />
				</CSSTransition>
			</div>
		);
	}

	return (
		<div className='v__wrapper'>
			<TopBar />
			<Sidebar />

			<main className='v__content__wrapper'>
				<Outlet />
			</main>
			<BackToTop />
		</div>
	);
};

export default UserLayout;
