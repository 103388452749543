import { useCallback, useReducer, useEffect, createContext, useContext } from "react";
import { useQueryClient } from "react-query";

import { useLocalStorage } from "hooks";
import { AUTH_ACTION_TYPES, authReducer, initialAuthState } from "../reducers";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const queryClient = useQueryClient();

	const [storageKey, setStorageKey] = useLocalStorage(process.env.REACT_APP_STORAGE_KEY);
	const [storageToken, setStorageToken, removeStorageToken] = useLocalStorage(process.env.REACT_APP_TOKEN_KEY);

	const [state, dispatch] = useReducer(authReducer, initialAuthState);

	const setLoggedUser = useCallback(
		(payload) => {
			dispatch({
				type: AUTH_ACTION_TYPES.USER_SET,
				payload,
			});
		},
		[dispatch]
	);

	const logout = useCallback(() => {
		removeStorageToken();
		dispatch({
			type: AUTH_ACTION_TYPES.LOGOUT,
		});
		queryClient.clear();
	}, [queryClient, removeStorageToken]);

	const userCan = useCallback(
		(...permissions) => {
			let isPermitted = false;
			for (const permission of permissions) {
				if (state.permissions.includes(permission)) {
					isPermitted = true;
				}
			}
			return isPermitted;
		},
		[state.permissions]
	);

	const setToken = useCallback((token) => setStorageToken(token), [setStorageToken]);

	useEffect(() => {
		dispatch({
			type: AUTH_ACTION_TYPES.TOKEN_SET,
			payload: storageToken,
		});
	}, [storageToken]);

	useEffect(() => {
		if (!storageKey) {
			logout();
			localStorage.clear();
			return setStorageKey(new Date().toString());
		}
	}, [storageKey, logout, setStorageKey]);

	return (
		<AuthContext.Provider
			value={{
				...state,
				dispatch,
				setToken,
				userCan,
				setLoggedUser,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error("useAuth must be used within a AuthProvider");
	}

	return context;
};

export default AuthProvider;
