import { Icon } from "components";
import { Link } from "react-router-dom";

import { Avatar } from "../../../components/ui";
import { useAuth } from "../../../context";

const TopBarUser = () => {
	const { user } = useAuth();

	return (
		<>
            
            {/*
			<div className='mr-auto santa' style={{ marginTop: "-14px", marginLeft: "-100px" }}>
				<img style={{ transform: "scalex(-1) rotate(-14deg)", width: "111px" }} src={"/santa.gif"} alt='my-gif' />
			</div>
            */}

			<Link className='v__topbar__user shrink-0' to={`/app/fairplay`}>
				<div className='v__topbar__user__avatar'>
					<Icon>account_balance_wallet</Icon>
				</div>
				<span className='v__topbar__user__name'>FBR {user?.wallet?.amount}</span>
			</Link>
			<Link className='v__topbar__user shrink-0' to={`/app/users/${user?.id}`}>
				<Avatar user={user} className='v__topbar__user__avatar' />
				<span className='v__topbar__user__name'>{user?.first_name ?? "User"}</span>
			</Link>
		</>
	);
};

export default TopBarUser;
