import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useReportsState } from "context";

import { useCompanies, useDepoStaff, useGroups, useMedia, useMediaQueries, useServicePartners } from "hooks";

import { Button, Collapsible, Icon } from "components";
import { FormSelect, SwitchGroup } from "components/forms";

import ReportsAdvancedFilters from "./ReportsAdvancedFilters";

import { REPORTS_ACTION_TYPES, REPORT_GROUP_BY_TYPES, REPORT_PERIOD_TYPES } from "reducers/const";

const groupByOptions = (t) => [
    {
        value: REPORT_GROUP_BY_TYPES.DEPO_ID,
        label: t("depos"),
    },
    {
        value: REPORT_GROUP_BY_TYPES.GROUP_ID,
        label: t("groups"),
    },
    {
        value: REPORT_GROUP_BY_TYPES.SP_NAME,
        label: t("servicePartners"),
    },
    {
        value: REPORT_GROUP_BY_TYPES.ROUTE_ID,
        label: t("tour"),
    },

    {
        value: REPORT_GROUP_BY_TYPES.USER_ID,
        label: t("drivers"),
    },
];


const reportTypeOptions = (t, user) => {
    if (Boolean(user)) {
        return [
            {
                value: REPORT_PERIOD_TYPES.PERIOD,
                label: t("period"),
            },
            {
                value: REPORT_PERIOD_TYPES.YEARLY_DAY,
                label: t("yearlyBy", { value: t("daily") }),
            },
            {
                value: REPORT_PERIOD_TYPES.YEARLY_WEEK,
                label: t("yearlyBy", { value: t("weekly") }),
            },
            {
                value: REPORT_PERIOD_TYPES.YEARLY_MONTH,
                label: t("yearlyBy", { value: t("monthly") }),
            },
        ];
    }
    return [
        {
            value: REPORT_PERIOD_TYPES.DAILY,
            label: t("daily"),
        },
        {
            value: REPORT_PERIOD_TYPES.PERIOD,
            label: t("period"),
        },
    ];
};

const ReportsFilters = ({ title }) => {
    const { t } = useTranslation();
    const { fairplayId } = useParams();

    const { XXLARGE, LARGE, MEDIUM } = useMediaQueries();
    const isXXL = useMedia(XXLARGE);
    const isLarge = useMedia(LARGE);
    const isMedium = useMedia(MEDIUM);

    const { state, dispatch } = useReportsState();

    const [filtersToggled, setFiltersToggled] = useState(!isLarge);
    const [groupFiltersToggled, setGroupFiltersToggled] = useState(!isLarge);
    const [reportColFiltersModal, setReportColFiltersModal] = useState(false);

    const advancedFiltersCount = state.requestParams.filter ? Object.keys(state.requestParams.filter).length : 0;

    return (
        <>
            <div className='v__page__header__content__title'>
                {isXXL ? (
                    <SwitchGroup
                        dark
                        small
                        options={groupByOptions(t)}
                        onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.GROUP_BY_SET, payload: option })}
                        activeOption={state.groupBy}
                    />
                ) : (
                    title
                )}
            </div>
            <div className='v__table__extras__actions'>
                {isLarge && (
                    <SwitchGroup
                        dark
                        small
                        hint={t("radioTip")}
                        options={reportTypeOptions(t, state.user_id)}
                        onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.REPORT_TYPE_SET, payload: option })}
                        activeOption={state.reportType}
                    />
                )}

                {!isXXL && (
                    <Button small bg round={!isMedium} status='accent' onClick={() => setGroupFiltersToggled((prev) => !prev)}>
                        {isMedium ? t("groupBy") : <Icon>settings_applications</Icon>}
                    </Button>
                )}

                <Button small bg round={!isMedium} status='accent' onClick={() => setFiltersToggled((prev) => !prev)}>
                    {isMedium ? t("filters") : <Icon>filter_alt</Icon>}
                </Button>

                <Button
                    small
                    bg
                    className='adv-filters'
                    status='warning'
                    round={!isMedium}
                    overflow
                    onClick={() => setReportColFiltersModal(true)}
                >
                    {Boolean(advancedFiltersCount) && <div className='btn-indicator'>{advancedFiltersCount}</div>}
                    {isMedium ? t("advancedFilters") : <Icon>filter_list</Icon>}
                </Button>

                <Button small bg round={!isMedium} status='darker' iconLeft={isMedium && <Icon>print</Icon>} onClick={() => window.print()}>
                    {isMedium ? t("print") : <Icon>print</Icon>}
                </Button>
            </div>

            <div className='v__table__extras__filters'>
                {!isXXL && (
                    <Collapsible closed={groupFiltersToggled} allowOverflow={true} className='body-no-pad no-mar'>
                        <div className='v__table__extras__views'>
                            <div className='v__table__extras__views__item v__table__extras__views__item--type'>
                                {!isLarge && (
                                    <FormSelect
                                        label={t("reportType")}
                                        isSearchable={false}
                                        value={
                                            state.reportType?.label
                                                ? state.reportType
                                                : reportTypeOptions(t, state.user_id).find((v) => v.value === state.reportType.value)
                                        }
                                        options={reportTypeOptions(t, state.user_id)}
                                        onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.REPORT_TYPE_SET, payload: option })}
                                    />
                                )}
                            </div>
                            <div className='v__table__extras__views__item v__table__extras__views__item--group'>
                                <FormSelect
                                    label={t("groupBy")}
                                    isSearchable={false}
                                    value={
                                        state.groupBy?.label
                                            ? state.groupBy
                                            : groupByOptions(t).find((v) => v.value === state.groupBy.value)
                                    }
                                    options={groupByOptions(t)}
                                    onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.GROUP_BY_SET, payload: option })}
                                />
                            </div>
                        </div>
                    </Collapsible>
                )}
                <Collapsible closed={filtersToggled} allowOverflow={true} className='body-no-pad no-mar'>
                    <div className='v__grid'>
                        <FormSelect
                            groupClassName='v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12'
                            label={t("depo")}
                            isAsync
                            useFetch={useCompanies}
                            isGrouped
                            isClearable
                            queryConfig={{ custom: { type: "delivery_daily", includes: ["depos"] } }}
                            groupParse={{ label: "title", options: "depos" }}
                            value={state.depo_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.DEPO_SET, payload: option })}
                        />

                        <FormSelect
                            groupClassName={cn("v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12", {
                                disabled: state.groupBy?.value === "depo_id",
                            })}
                            label={t("servicePartner")}
                            isAsync
                            asyncSearch
                            isClearable
                            useFetch={useServicePartners}
                            queryConfig={{ custom: { fairplay_id: fairplayId, depo_id: state.depo_id?.value, page: 0, per_page: 25 } }}
                            value={state.service_partner_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.SP_SET, payload: option })}
                        />

                        <FormSelect
                            groupClassName='v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12'
                            label={t("group")}
                            isAsync
                            asyncSearch
                            isClearable
                            useFetch={useGroups}
                            queryConfig={{ custom: { page: 0, per_page: 25 } }}
                            value={state.member_group_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.GROUP_SET, payload: option })}
                        />

                        <FormSelect
                            groupClassName={cn("v__grid__item v__grid__item--col-md-6 v__grid__item--col-sm-12", {
                                disabled: state.groupBy?.value !== "user_id",
                            })}
                            label={t("staff")}
                            isAsync
                            asyncSearch
                            isClearable
                            useFetch={useDepoStaff}
                            isGrouped
                            queryConfig={{
                                custom: {
                                    depo_id: state.depo_id?.value,
                                    service_partner_id: state.service_partner_id?.value,
                                    member_group_id: state.member_group_id?.value,
                                    page: 0,
                                    per_page: 10,
                                },
                            }}
                            groupParse={{ label: "title", options: "staff" }}
                            optionsParse={{ value: "id", label: "full_name" }}
                            value={state.user_id}
                            onChange={(option) => dispatch({ type: REPORTS_ACTION_TYPES.USER_SET, payload: option })}
                        />
                    </div>
                </Collapsible>
            </div>

            {reportColFiltersModal && (
                <ReportsAdvancedFilters
                    state={state}
                    onClose={() => setReportColFiltersModal(false)}
                    onReset={() => {
                        dispatch({
                            type: REPORTS_ACTION_TYPES.ADVANCED_FILTER_VALUES_SET,
                            payload: null,
                        });
                        setReportColFiltersModal(false);
                    }}
                    onChange={(filter) => {
                        dispatch({
                            type: REPORTS_ACTION_TYPES.ADVANCED_FILTER_VALUES_SET,
                            payload: filter,
                        });
                        setReportColFiltersModal(false);
                    }}
                />
            )}
        </>
    );
};

export default ReportsFilters;
