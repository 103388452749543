import { FUEL_GROUP_BY_TYPES, INVOICE_GROUP_BY_TYPES, REPORT_COLUMN_PRESETS, REPORT_GROUP_BY_TYPES } from "reducers/const";

export const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
export const emailRegEx =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

export const toMB = (fileSize) => Number(fileSize) / 1024 / 1024;
export const toBytes = (fileSize) => Number(fileSize) * 1024 * 1024;

export const validateFile = (file, sizeInMb = process.env.REACT_APP_MAX_IMAGE_UPLOAD_SIZE_MB) => {
    const size = toBytes(sizeInMb);

    if (!file || !file.size) return true;

    if (file.size <= size) {
        return true;
    }

    return false;
};

export const generateFontColorDependingOnBg = (val) => {
    let r, g, b;

    if (typeof val === "string") {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(val);
        if (result) {
            r = parseInt(result[1], 16);
            g = parseInt(result[2], 16);
            b = parseInt(result[3], 16);
        }
    } else {
        r = val.r;
        g = val.g;
        b = val.b;
    }

    const lightness = Math.round((parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000);

    if (lightness > 125) {
        return "black";
    } else {
        return "white";
    }
};

export const isWeekend = (date) => {
    const parsedDate = new Date(date);
    return parsedDate.getDay() === 0 || parsedDate.getDay() === 6;
};

export const parseSelectedMonth = (date) => {
    const month = date.toLocaleDateString(undefined, { month: "long" });
    const year = date.getFullYear();
    return `${month} ${year}`;
};

export const getWeekNumber = (date) => {
    const tdt = new Date(date.valueOf());
    const dayn = (date.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

export const getDateOfWeek = (week, year) => {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const weekStart = simple;
    if (dow <= 4) weekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else weekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return weekStart;
};

export const getDateOfMonth = (month, year) => {
    return new Date(year, month, 1);
};

export const getWeekdayName = (date) => {
    const dayNumber = new Date(date).getDay();
    return days[dayNumber];
};

export const roundBigNumbers = (v) => {
    if (v < -1000) {
        return (v / 1000).toFixed(1) + "k";
    } else if (v > 999 && v < 1000000) {
        return (v / 1000).toFixed(1) + "k";
    } else if (v > 1000000) {
        return (v / 1000000).toFixed(1) + "M";
    } else if (v < 1000) {
        return v;
    }
};

export const callAll =
    (...fns) =>
        (...args) =>
            fns.forEach((fn) => typeof fn === "function" && fn(...args));

export const objIsEmpty = (obj) => Object.keys(obj).length === 0;

export const getCss = (variable) => window.getComputedStyle(document.querySelector(":root")).getPropertyValue(variable);

export const setModalStyles = () => {
    const viewportWidth = document.documentElement.clientWidth;
    const windowWidth = window.innerWidth;
    const scrollBarWidth = windowWidth - viewportWidth;

    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.body.style.overflow = "hidden";
};

export const unsetModalStyles = () => {
    document.body.style.paddingRight = `0px`;
    document.body.style.overflow = "auto";
};

export const debounce = (func, wait = 500) => {
    let timeout;
    return (...args) => {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const mergeRefs = (refs) => (value) => {
    refs.forEach((ref) => {
        if (typeof ref === "function") {
            ref(value);
        } else if (ref != null) {
            ref.current = value;
        }
    });
};

export const parseTableSortBy = (sortBy) => (sortBy.length > 0 ? sortBy.map((v) => `${v.desc ? "-" : "+"}${v.id}`).join(",") : sortBy);
export const parseReportTableAdvancedFilters = (filters) => {
    if (!filters) return;
    const filtersToArr = Object.entries(filters);
    return filtersToArr.map((v) => `${v[0]},${v[1].min},${v[1].max}`);
};

export const arraysMatch = (a, b) => {
    for (const v of new Set([...a, ...b])) if (a.filter((e) => e === v).length !== b.filter((e) => e === v).length) return false;
    return true;
};

export const parseAndPreSelectPreset = (presets, key) => {
    if (!presets) return null;
    const preset = presets.find((x) => x.value === key);
    return { value: preset.value, columns: preset.columns };
};

export const getTablePresetsHiddenColumns = (data) => {
    const columnPresets = {};
    for (const key of Object.keys(data)) {
        if (key.includes("hidden_column")) {
            columnPresets[key] = data?.[key];
        }
    }
    return columnPresets;
};

export const getHiddenColumnsByKey = (presets, key) => (key ? presets?.[key] ?? [] : []);

export const objContainsAllKeys = (obj, ...keys) => {
    let exists = false;
    keys.forEach((x) => (exists = Boolean(x in obj)));
    return exists;
};

export const objContainsAnyKeyWithValue = (obj, ...keys) => {
    let exists = false;
    keys.forEach((x) => {
        if (Boolean(x in obj) && Boolean(obj[x])) {
            exists = true;
            return;
        }
    });
    return exists;
};

export const parsePreset = (x, primaryDataType = "reports") => (x !== "feedback" ? primaryDataType : "feedback");

const REPORT_LEVEL_TARGET = Object.freeze({
    FEEDBACK: "FEEDBACK",
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    YEARLY: "YEARLY",
    ALL: "ALL",
    USER_ALL: "USER_ALL",
});

// VIEW PAGE REPORTS ROW DRILLING
export const parseClickedViewPageRowData = (payload, accountType, id) => {
    const { cell, queryParams } = payload;
    const row = cell.row.original;

    return {
        row,
        requestParams: {
            groupBy: parseViewPagePeriodTypeAndGroupBy(queryParams).groupBy,
            [accountType]: id,
            date: pickViewPageDate(row, queryParams),
            week: parseViewPagePeriodTypeAndGroupBy(queryParams).target === REPORT_LEVEL_TARGET.WEEKLY ? row.week : undefined,
            month: parseViewPagePeriodTypeAndGroupBy(queryParams).target === REPORT_LEVEL_TARGET.MONTHLY ? row.month : undefined,
            year: pickViewPageYear(row, queryParams),
        },
    };
};

function parseViewPagePeriodTypeAndGroupBy(params) {
    if (Boolean(params.user_id)) {
        if (params.groupBy === REPORT_GROUP_BY_TYPES.WEEK) {
            return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.DAY };
        }
        if (params.groupBy === REPORT_GROUP_BY_TYPES.MONTH) {
            return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.DAY };
        }
        if (params.groupBy === REPORT_GROUP_BY_TYPES.YEAR) {
            return { target: REPORT_LEVEL_TARGET.YEARLY, groupBy: REPORT_GROUP_BY_TYPES.DAY };
        }
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.DAY) {
        return { target: REPORT_LEVEL_TARGET.DAILY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.WEEK) {
        return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.MONTH) {
        return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.YEAR) {
        return { target: REPORT_LEVEL_TARGET.YEARLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
    }
}

function pickViewPageDate(row, params) {
    const target = parseViewPagePeriodTypeAndGroupBy(params).target;
    if (target === REPORT_LEVEL_TARGET.DAILY) {
        if (Boolean(row.date)) {
            return new Date(row.date).toLocaleDateString("en-US");
        }
        return params.date;
    }
    return;
}

function pickViewPageYear(row, params) {
    const target = parseViewPagePeriodTypeAndGroupBy(params).target;
    if (target !== REPORT_LEVEL_TARGET.DAILY && Boolean(row.year)) {
        return row.year;
    }
    return;
}

// QUICK VIEW MODAL ON REPORTS PAGE
export const parseClickedRowData = (payload, columnPresetOtherThanFeedbackValue = "reports") => {
    const { cell, queryParams } = payload;
    const row = cell.row.original;

    const isFeedback =
        (parsePeriodTypeAndGroupBy(queryParams).target === REPORT_LEVEL_TARGET.FEEDBACK &&
            queryParams.groupBy === REPORT_GROUP_BY_TYPES.USER_ID) ||
        (parsePeriodTypeAndGroupBy(queryParams).target === REPORT_LEVEL_TARGET.DAILY &&
            parsePeriodTypeAndGroupBy(queryParams).groupBy === undefined);

    const shouldSwitchDataType = isFeedback && payload?.points ? false : isFeedback;

    return {
        row,
        dataType: shouldSwitchDataType ? REPORT_COLUMN_PRESETS.FEEDBACK : columnPresetOtherThanFeedbackValue,
        requestParams: {
            groupBy: parsePeriodTypeAndGroupBy(queryParams).groupBy,
            member_group_id: pickGroupId(row, queryParams),
            depo_id: pickDepoId(row, queryParams),
            user_id: pickUserId(row, queryParams),
            card_id: pickCardId(row, queryParams),
            invoice_id: pickInvoiceId(row, queryParams),
            service_partner_id: pickSPId(row, queryParams),
            date: pickDate(row, queryParams),
            week: parsePeriodTypeAndGroupBy(queryParams).target === REPORT_LEVEL_TARGET.WEEKLY ? row.week : undefined,
            month: parsePeriodTypeAndGroupBy(queryParams).target === REPORT_LEVEL_TARGET.MONTHLY ? row.month : undefined,
            year: pickYear(row, queryParams),
            points: payload?.points,
        },
    };
};

function parsePeriodTypeAndGroupBy(params) {
    if (Boolean(params.user_id)) {
        if (params.groupBy === REPORT_GROUP_BY_TYPES.DAY) {
            return { target: REPORT_LEVEL_TARGET.DAILY, groupBy: undefined };
        }
        if (params.groupBy === REPORT_GROUP_BY_TYPES.WEEK) {
            return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.DAY };
        }
        if (params.groupBy === REPORT_GROUP_BY_TYPES.MONTH) {
            return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.WEEK };
        }
        if (Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.DAILY, groupBy: REPORT_GROUP_BY_TYPES.DAY };
        }
        if (Boolean(params.month)) {
            return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.WEEK };
        }
        if (Boolean(params.year)) {
            return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.MONTH };
        }
        if (!Boolean(params.date) && !Boolean(params.year) && !Boolean(params.month) && !Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.USER_ALL, groupBy: REPORT_GROUP_BY_TYPES.MONTH };
        }
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.USER_ID) {
        if (Boolean(params.date)) {
            return { target: REPORT_LEVEL_TARGET.FEEDBACK, groupBy: undefined };
        }
        if (Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.DAY };
        }
        if (Boolean(params.month)) {
            return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.WEEK };
        }
        if (Boolean(params.year)) {
            return { target: REPORT_LEVEL_TARGET.YEARLY, groupBy: REPORT_GROUP_BY_TYPES.MONTH };
        }
        if (!Boolean(params.date) && !Boolean(params.year) && !Boolean(params.month) && !Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.ALL, groupBy: REPORT_GROUP_BY_TYPES.YEAR };
        }

        if (params.dataType === "invoices") {
            return { target: undefined, groupBy: undefined };
        }
    }

    if (params.groupBy === FUEL_GROUP_BY_TYPES.CARD_ID) {
        if (Boolean(params.date)) {
            return { target: REPORT_LEVEL_TARGET.DAILY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (Boolean(params.month)) {
            return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (Boolean(params.year)) {
            return { target: REPORT_LEVEL_TARGET.YEARLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (!Boolean(params.date) && !Boolean(params.year) && !Boolean(params.month) && !Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.ALL, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
    }

    if (params.groupBy === INVOICE_GROUP_BY_TYPES.INVOICE_ID) {
        return { target: REPORT_LEVEL_TARGET.DAILY, groupBy: INVOICE_GROUP_BY_TYPES.DAY };
    }




    if (params.groupBy === FUEL_GROUP_BY_TYPES.STATEMENT_ID) {
        return { target: undefined, groupBy: undefined };
    }

    if (
        params.groupBy === REPORT_GROUP_BY_TYPES.DEPO_ID ||
        params.groupBy === REPORT_GROUP_BY_TYPES.SP_NAME ||
        params.groupBy === REPORT_GROUP_BY_TYPES.GROUP_ID
    ) {
        if (Boolean(params.date)) {
            return { target: REPORT_LEVEL_TARGET.DAILY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (Boolean(params.month)) {
            return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (Boolean(params.year)) {
            return { target: REPORT_LEVEL_TARGET.YEARLY, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
        if (!Boolean(params.date) && !Boolean(params.year) && !Boolean(params.month) && !Boolean(params.week)) {
            return { target: REPORT_LEVEL_TARGET.ALL, groupBy: REPORT_GROUP_BY_TYPES.USER_ID };
        }
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.DAY) {
        return { target: REPORT_LEVEL_TARGET.FEEDBACK, groupBy: undefined };
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.WEEK) {
        return { target: REPORT_LEVEL_TARGET.WEEKLY, groupBy: REPORT_GROUP_BY_TYPES.DAY };
        //to day
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.MONTH) {
        return { target: REPORT_LEVEL_TARGET.MONTHLY, groupBy: REPORT_GROUP_BY_TYPES.WEEK };
        //to week
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.YEAR) {
        return { target: REPORT_LEVEL_TARGET.YEARLY, groupBy: REPORT_GROUP_BY_TYPES.MONTH };
        //to month
    }
}

function pickGroupId(row, params) {
    if (params.groupBy === REPORT_GROUP_BY_TYPES.GROUP_ID) {
        if (Boolean(row?.member_group_id)) {
            return row.member_group_id;
        }
        return "null";
    }

    if (
        params.groupBy === REPORT_GROUP_BY_TYPES.DAY ||
        params.groupBy === REPORT_GROUP_BY_TYPES.WEEK ||
        params.groupBy === REPORT_GROUP_BY_TYPES.MONTH
    ) {
        return params?.member_group_id;
    }

    return;
}

function pickDepoId(row, params) {
    if (params.groupBy === REPORT_GROUP_BY_TYPES.DEPO_ID) {
        return row.depo_id;
    }
    if (
        (params.groupBy === REPORT_GROUP_BY_TYPES.GROUP_ID || params.groupBy === REPORT_GROUP_BY_TYPES.SP_NAME) &&
        Boolean(params.depo_id)
    ) {
        return params.depo_id;
    }

    if (
        params.groupBy === REPORT_GROUP_BY_TYPES.DAY ||
        params.groupBy === REPORT_GROUP_BY_TYPES.WEEK ||
        params.groupBy === REPORT_GROUP_BY_TYPES.MONTH
    ) {
        return params?.depo_id;
    }

    return;
}

function pickUserId(row, params) {
    if (params.groupBy === REPORT_GROUP_BY_TYPES.USER_ID && !Boolean(params.user_id)) {
        return row.user_id;
    }

    return params.user_id;
}

function pickCardId(row, params) {
    if (params.groupBy === FUEL_GROUP_BY_TYPES.CARD_ID && !Boolean(params.card_id)) {
        return row.card_id;
    }

    return params.card_id;
}

function pickInvoiceId(row, params) {
    if (params.groupBy === INVOICE_GROUP_BY_TYPES.INVOICE_ID && !Boolean(params.invoice_id)) {
        return row.invoice_id;
    }

    return params.invoice_id;
}


function pickSPId(row, params) {
    if (params.groupBy === REPORT_GROUP_BY_TYPES.GROUP_ID && Boolean(params.service_partner_id)) {
        return params.service_partner_id;
    }
    if (params.groupBy === REPORT_GROUP_BY_TYPES.SP_NAME) {
        return row?.service_partner_id;
    }

    if (
        params.groupBy === REPORT_GROUP_BY_TYPES.DAY ||
        params.groupBy === REPORT_GROUP_BY_TYPES.WEEK ||
        params.groupBy === REPORT_GROUP_BY_TYPES.MONTH
    ) {
        return params?.service_partner_id;
    }

    return;
}

function pickDate(row, params) {
    const target = parsePeriodTypeAndGroupBy(params).target;
    if (target === REPORT_LEVEL_TARGET.FEEDBACK || target === REPORT_LEVEL_TARGET.DAILY) {
        if (Boolean(row.date)) {
            return new Date(row.date).toLocaleDateString("en-US");
        }
        return params.date;
    }
    return;
}

function pickYear(row, params) {
    const target = parsePeriodTypeAndGroupBy(params).target;
    if (target === REPORT_LEVEL_TARGET.ALL) {
        return;
    }
    if (target !== REPORT_LEVEL_TARGET.FEEDBACK && target !== REPORT_LEVEL_TARGET.DAILY && Boolean(row.year)) {
        return row.year;
    }
    return;
}
