import { forwardRef } from "react";
import { CustomPicker } from "react-color";
import { Hue, Saturation } from "react-color/lib/components/common";
import cn from "classnames";
import { generateFontColorDependingOnBg } from "../../utils";

const ColorPicker = forwardRef(
	(
		{
			rgb = {
				r: 0,
				g: 0,
				b: 0,
				a: 1,
			},
			hex = "#000",
			hsl = {
				h: 0,
				s: 0,
				l: 0,
				a: 1,
			},
			hsv,
			onChange,
			small,
			position,
			...rest
		},
		ref
	) => {
		const HuePointer = () => <div className='v__color__picker__hue__pointer' />;
		const SaturationPointer = () => <div className='v__color__picker__saturation__pointer' />;

		return (
			<div
				className={cn("v__color__picker", {
					"v__color__picker--small": small,
				})}
				style={position}
				ref={ref}>
				<div className='v__color__picker__saturation'>
					<Saturation hsl={hsl} hsv={hsv} onChange={onChange} {...rest} pointer={SaturationPointer} />
				</div>
				<div className='v__color__picker__hue'>
					<Hue hsl={hsl} onChange={onChange} {...rest} pointer={HuePointer} />
				</div>
				<div className='v__color__picker__current' style={{ background: hex, color: generateFontColorDependingOnBg(rgb) }}>
					{hex}
				</div>
			</div>
		);
	}
);

export default CustomPicker(ColorPicker);
