import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Icon, VirtualSelect } from "..";
import { callAll } from "../../utils";

const TablePagination = ({
	gotoPage,
	previousPage,
	nextPage,
	canPreviousPage,
	canNextPage,
	pageCount,
	pageIndex,
	pageOptions,
	pageSize,
	setPageSize,
	serverTotalResults,
	scrollToTopOnChange,
	lessPerPage = true,
	dark,
}) => {
	const { t } = useTranslation();

	const handleOnClick = (...fns) => callAll(() => (scrollToTopOnChange ? window.scrollTo({ top: 0, behavior: "smooth" }) : null), ...fns);

	return (
		<div className='v__table__pagination'>
			<div className='v__table__pagination__page__results'>
				<span>{t("showing")}</span>
				<strong className='v__table__pagination__page__results__current' style={{ display: "none" }}>
					{lessPerPage
						? minOptions.find((item) => item.value === pageSize)?.value
						: options.find((item) => item.value === pageSize)?.value}
				</strong>
				<VirtualSelect
					className='print:hidden'
					small
					light={!dark}
					value={
						lessPerPage ? minOptions.find((item) => item.value === pageSize) : options.find((item) => item.value === pageSize)
					}
					menuPlacement='top'
					options={lessPerPage ? minOptions : options}
					onChange={handleOnClick(
						() => gotoPage(0),
						(option) => setPageSize(Number(option.value))
					)}
				/>
				<span className='pl-1'>{t("of")}</span>
				<strong>{serverTotalResults}</strong>
				<span>{t("entries")}</span>
			</div>
			<div className='v__table__pagination__pages'>
				<span>
					<span>{t("page")}</span>
					<strong>{pageIndex + 1}</strong>
					<span>{t("of")}</span>
					<strong>{pageOptions.length ?? pageOptions}</strong>
				</span>
				<div className='v__table__pagination__pages__group'>
					<Button
						small
						bg
						status={dark ? "darker" : "none"}
						className='v__table__pagination__pages__group__btn'
						onClick={handleOnClick(() => gotoPage(0))}
						disabled={!canPreviousPage}
					>
						{<Icon>first_page</Icon>}
					</Button>
					<Button
						small
						bg
						status={dark ? "darker" : "none"}
						className='v__table__pagination__pages__group__btn'
						onClick={handleOnClick(() => previousPage())}
						disabled={!canPreviousPage}
					>
						{<Icon>chevron_left</Icon>}
					</Button>
					<Button
						small
						bg
						status={dark ? "darker" : "none"}
						className='v__table__pagination__pages__group__btn'
						onClick={handleOnClick(() => nextPage())}
						disabled={!canNextPage}
					>
						{<Icon>chevron_right</Icon>}
					</Button>
					<Button
						small
						bg
						status={dark ? "darker" : "none"}
						className='v__table__pagination__pages__group__btn'
						onClick={handleOnClick(() => gotoPage(pageCount - 1))}
						disabled={!canNextPage}
					>
						{<Icon>last_page</Icon>}
					</Button>
				</div>
			</div>
		</div>
	);
};

const minOptions = [
	{
		label: "10",
		value: 10,
	},
	{
		label: "25",
		value: 25,
	},
	{
		label: "50",
		value: 50,
	},
	{
		label: "100",
		value: 100,
	},
];

const options = [
	{
		label: "15",
		value: 15,
	},
	{
		label: "25",
		value: 25,
	},
	{
		label: "50",
		value: 50,
	},
	{
		label: "100",
		value: 100,
	},
	{
		label: "150",
		value: 150,
	},
];

TablePagination.propTypes = {
	loading: PropTypes.bool,
	page: PropTypes.array,
	allResults: PropTypes.number,
	pageIndex: PropTypes.number,
	pageOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	dark: PropTypes.bool,
	pageSize: PropTypes.number,
	withScrollTopOnChange: PropTypes.bool,
	gotoPage: PropTypes.func,
	setPageSize: PropTypes.func,
	canPreviousPage: PropTypes.bool,
	previousPage: PropTypes.func,
	nextPage: PropTypes.func,
	canNextPage: PropTypes.bool,
	pageCount: PropTypes.number,
};

export default TablePagination;
