import { forwardRef, useEffect, useRef } from "react";
import cn from "classnames";

import { Calendar } from ".";
import { Portal } from "..";
import { getWeekdayName, isWeekend } from "../../utils";
import CalendarHorizontalHeader from "./CalendarHorizontalHeader";

const CalendarDayContents = forwardRef(({ day, date }, ref) => {
	return (
		<div className='react-datepicker__day__content' ref={ref}>
			<div
				className={cn(
					"react-datepicker__day__content__name",
					isWeekend(date) ? "react-datepicker__day__content__weekend" : "react-datepicker__day__content__weekday"
				)}
			>
				{getWeekdayName(date)}
			</div>
			<div className='react-datepicker__day__content__day'>{day}</div>
		</div>
	);
});

const CalendarHorizontal = ({ navRoot, selected, ...rest }) => {
	const selectedDate = new Date(selected);
	const selectedRef = useRef();

	useEffect(() => {
		const selectedItem = selectedRef.current;
		if (selectedItem) {
			selectedItem.scrollIntoView({
				block: "nearest",
				inline: "nearest",
			});
		}
	}, [selected]);

	if (!selected) {
		return null;
	}

	return (
		<Calendar
			calendarClassName='react-datepicker--horizontal'
			showWeekNumbers={false}
			fixedHeight={false}
			renderCustomHeader={(headerProps) => (
				<Portal element={navRoot}>
					<CalendarHorizontalHeader {...headerProps} />
				</Portal>
			)}
			renderDayContents={(day, date) => {
				if (selectedDate.toDateString() === new Date(date).toDateString()) {
					return <CalendarDayContents {...{ day, date }} ref={selectedRef} />;
				}
				return <CalendarDayContents {...{ day, date }} />;
			}}
			selected={selectedDate}
			{...rest}
		/>
	);
};

export default CalendarHorizontal;
