import { useTranslation } from "react-i18next";

import { useApp } from "../../context";
import { FormCheckbox } from "../../components/forms";
import SettingsItem from "./SettingsItem";

const AppearanceSection = () => {
	const { t } = useTranslation();

	const { fluidContainer, toggleContainer } = useApp();

	return (
		<SettingsItem label={t("containerBoundaries")} labelHint={t("containerBoundariesInfo")}>
			<FormCheckbox
				withLabel={false}
				name='fluid_container_check'
				checkboxLabel={t("toggleContainer")}
				checked={fluidContainer}
				onChange={toggleContainer}
			/>
		</SettingsItem>
	);
};

export default AppearanceSection;
