import { useEffect, useState } from "react";
import { Button, Icon } from "components";

const BackToTop = ({ onClick, ...rest }) => {
	const [scrolled, setScrolled] = useState(false);

	const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

	// TODO: can implement with Intersection observer for better performance

	useEffect(() => {
		const handleScroll = () => {
			if (window.pageYOffset > 299) {
				setScrolled(true);
			} else if (window.pageYOffset < 300) {
				setScrolled(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	if (scrolled) {
		return (
			<Button bg round status='accent' className='v__to-top' onClick={scrollToTop} {...rest}>
				<Icon>arrow_upward</Icon>
			</Button>
		);
	}
	return null;
};

export default BackToTop;
