import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useReportsState } from "context";
import {  useReportDates } from "hooks";

import PageContent from "components/util/PageContent";
import PageHeader from "components/util/PageHeader";
import PageWrapper from "components/util/PageWrapper";
import ReportsFilters from "./ReportsFilters";
import ReportsPeriodPicker from "./ReportsPeriodPicker";
import { REPORTS_ACTION_TYPES, REPORT_GROUP_BY_TYPES } from "reducers/const";

function SpFairplayWrapper() {
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const { state, dispatch } = useReportsState();

	const { data: files } = useReportDates({
		custom: {
			type: "delivery_daily",
		},
	});
    


	//TODO god help me
	//if the group by options is not in the group by options of reports
	//change it to a default one

	if (Object.values(REPORT_GROUP_BY_TYPES).indexOf(state.groupBy.value) < 0) {
		dispatch({ type: REPORTS_ACTION_TYPES.GROUP_BY_SET, payload: { value: REPORT_GROUP_BY_TYPES.USER_ID } });
	}

	const pageTitle = pathname.includes("reports/") ? t("fairplay") : t("reports");

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | {pageTitle}</title>
			</Helmet>
			<PageHeader>
				<ReportsFilters title={pageTitle} />
			</PageHeader>
			<PageContent>
				<ReportsPeriodPicker files={files} />
				<Outlet />
			</PageContent>
		</PageWrapper>
	);
}

export default SpFairplayWrapper;
