import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { useAuth, useReportsState } from "context";

import { reportKeys, useReportHiddenColumns } from "./query";
import useLocalStorage from "./useLocalStorage";

import { ActionsCell, Button, CommentCell, Icon, LinkCell } from "components";
import { REPORT_REDIRECT_CELL_ACCESSORS } from "pages";

import { getHiddenColumnsByKey, getTablePresetsHiddenColumns, objContainsAllKeys, parseTableSortBy } from "utils";
import { REPORTS_ACTION_TYPES, REPORT_COLUMN_PRESETS } from "reducers/const";

export function useReportFiles() {
    const queryClient = useQueryClient();

    const { state } = useReportsState();

    return queryClient.getQueryData(
        reportKeys.byConfig({
            type: "delivery_daily",
            depo_id: state.depo_id?.value,
        })
    );
}

export function useReportsTablePresetsGenerate(generate, view_sp_panel = false) {
    const { t } = useTranslation();
    const { data } = useReportHiddenColumns();

    return useMemo(() => {
        if (!data?.data) {
            return [];
        }
        let columns = generate?.(getTablePresetsHiddenColumns(data.data), getHiddenColumnsByKey, t);

        if (!view_sp_panel) {
            columns = columns.filter(preset => preset.value !== REPORT_COLUMN_PRESETS.SP_PANEL);
        }

        return columns;
    }, [generate, data, t]);
}

export function useReportsTableGenerate({
    useFetch,
    config,
    dataType,
    commentContainerId = "reportsCommentContainer",
    onRowClick,
    setFuelEditModal,
    setInvoicesPayModal,
    deleteInvoice,
}) {
    const [queryParams, setQueryParams] = useState(() => ({
        dataType,
        sortBy: [],
        page: 1,
        per_page: 15,
        ...config,
    }));

    useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            ...config,
            dataType,
            page: 1,
        }));
    }, [config, dataType]);

    const { t } = useTranslation();
    const enableCondition = objContainsAllKeys(queryParams, "date", "year", "week", "month");

    const { data, isFetching, isFetched, ...rest } = useFetch({
        custom: queryParams,
        enabled: enableCondition,
        dataType: queryParams.dataType,
    });

    const fetchedData = data?.data ?? [];
    const fetchedOverallData = data?.overall ?? [];
    const fetchedMeta = data?.meta ?? null;

    const { userCan } = useAuth();

    const memoColumns = useMemo(() => {
        if (!data) return [];


        return data.columns.map((column) => {
            const { title, accessor, disableSortBy, field, type, ...rest } = column;
            const columnProps = {
                Header: title,
                accessor,
                disableSortBy,
                id: field,
                sortDescFirst: true,
                icon: type,
                pigments: data?.fields_pigments?.find((x) => x.field === accessor)?.colors,
                ...rest,
            };
            const isLink = REPORT_REDIRECT_CELL_ACCESSORS.includes(field);

            if (accessor === "id") {
                return {
                    ...columnProps,
                    sticky: true,
                };
            }
            if (accessor === "full_name") {
                return {
                    ...columnProps,
                    sticky: queryParams.groupBy === "user_id",
                    Cell: ({ value, row }) => LinkCell({ value, to: `/app/users/${row.original.user_id}` }),
                };
            }
            if (accessor === "translated_field_key_name") {
                return {
                    ...columnProps,
                    sticky: queryParams.points === true,
                };
            }
            if (accessor === "full_month_year") {
                return {
                    ...columnProps,
                    sticky: queryParams.groupBy === "month_group",
                };
            }
            if (accessor === "full_week_year") {
                return {
                    ...columnProps,
                    sticky: queryParams.groupBy === "week_group",
                };
            }
            if (accessor === "full_day") {
                return {
                    ...columnProps,
                    sticky: queryParams.groupBy === "day_group",
                };
            }

            if (accessor === "service_partner_name") {
                return {
                    ...columnProps,
                    sticky: queryParams.groupBy === "service_partner_name",
                    Cell: ({ value, row }) => LinkCell({ value, to: `/app/partners/${row.original.service_partner_id}` }),
                };
            }
            if (accessor === "group_name") {
                return {
                    ...columnProps,
                    sticky: queryParams.groupBy === "member_group_id",
                    Cell: ({ value, row }) =>
                        value !== " OTHERS" ? LinkCell({ value, to: `/app/groups/${row.original.member_group_id}` }) : value,
                };
            }
            if (accessor === "depo_subname" || accessor === "depo_name") {
                return {
                    ...columnProps,
                    sticky: queryParams.groupBy === "depo_id",
                    Cell: ({ value, row }) => LinkCell({ value, to: `/app/depo/${row.original.depo_id}` }),
                };
            }

            if (accessor === "full_comment") {
                return {
                    ...columnProps,
                    Cell: ({ value }) => <CommentCell value={value} containerId={commentContainerId} />,
                };
            }

            if (accessor === "status") {
                return {
                    ...columnProps,
                    Cell: ({ value, row }) => {
                        return t(value);
                    },
                };
            }


            if (accessor === "fuel_actions") {
                return {
                    ...columnProps,
                    Cell: ({ value, row }) => {
                        if (row.original.id === "TOT" || row.original.id === "AVG") {
                            return null;
                        }
                        return (
                            <ActionsCell>
                                <Button small bg status='warning' onClick={() => setFuelEditModal({ payload: row.original, state: true })}>
                                    <Icon style={{ "fontFamily": "Material Icons Outlined" }} >edit</Icon>
                                </Button>
                            </ActionsCell>
                        );
                    },
                };
            }

            if (accessor === "invoice_actions") {
                return {
                    ...columnProps,
                    Cell: ({ value, row }) => {
                        if (row.original.id === "TOT" || row.original.id === "AVG") {
                            return null;
                        }

                        return (
                            <ActionsCell>

                                <>
                                    {userCan("sp_panel_view") && (
                                        <Button small bg status='warning' onClick={() => setInvoicesPayModal({ payload: row.original, state: true })}>
                                            <Icon style={{ "fontFamily": "Material Icons Outlined" }}>attach_money</Icon>
                                        </Button>
                                    )}

                                    < a
                                        className='mr-2'
                                        href={process.env.REACT_APP_API_URL + `/invoices/` + row.original?.invoice_id + `/pdf`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download
                                    >
                                        <Button small bg status='success' >
                                            PDF
                                        </Button>
                                    </a>

                                    {userCan("sp_panel_view") && (
                                        <Button small bg status='danger' onClick={() => deleteInvoice(row.original?.invoice_id)} className='mr-2 !w-auto'>
                                            <Icon style={{ "fontFamily": "Material Icons Outlined" }}>delete</Icon>
                                        </Button>
                                    )}

                                </>


                            </ActionsCell >
                        );
                    },
                };
            }



            if (!isLink && queryParams.dataType !== "feedback" && onRowClick) {
                return {
                    ...columnProps,
                    onClick: (cell) => onRowClick({ cell, queryParams }),
                };
            }

            return columnProps;
        });
    }, [data, onRowClick, setFuelEditModal, queryParams, commentContainerId, deleteInvoice]);

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
        setQueryParams((prev) => ({
            ...prev,
            sortBy: parseTableSortBy(sortBy),
            page: pageSize !== prev.per_page ? 1 : pageIndex + 1,
            per_page: pageSize,
        }));
    }, []);

    return {
        tableProps: {
            columns: memoColumns,
            data: Boolean(fetchedData.length) ? [...fetchedOverallData, ...fetchedData] : [],
            fetchData,
            loading: isFetching && !isFetched,
            hasOverallDataRows: Boolean(fetchedOverallData.length),
            serverPageCount: fetchedMeta?.last_page,
            serverTotalResults: fetchedMeta?.total,
            commentContainerId,
        },
        state: {
            queryParams,
            setQueryParams,
        },
        query: {
            data,
            isFetching,
            isFetched,
            ...rest,
        },
    };
}

export function useReportsTableFilteredColumns({ data, isFetched }) {
    const { dispatch } = useReportsState();

    const filterColumns = useReportsTableFilteredColumnsValue({ data });

    useEffect(() => {
        if (isFetched) {
            dispatch({
                type: REPORTS_ACTION_TYPES.ADVANCED_FILTER_OPTIONS_SET,
                payload: filterColumns,
            });
        }
    }, [filterColumns, dispatch, isFetched]);
}

export function useReportsTableFilteredColumnsValue({ data }) {
    return useMemo(() => {
        if (!data || !data?.filter_columns) return [];

        return data.filter_columns.map((col) => ({
            ...col,
            description: data?.columns.find((v) => v.accessor === col.accessor)?.description,
        }));
    }, [data]);
}

export function useReportsTableColumnHiding({ columnPreset, storageKey = "reports" }) {
    const { user } = useAuth();

    const [storedHiddenColumns, setStoredHiddenColumns] = useLocalStorage(`${user?.id}_hidden_columns_${storageKey}`);

    const onColumnHide = useCallback(
        ({ hiddenColumns }) => {
            if (Boolean(columnPreset)) {
                setStoredHiddenColumns((prev) => ({ ...prev, [columnPreset.value]: hiddenColumns }));
            }
        },
        [setStoredHiddenColumns, columnPreset]
    );

    return { onColumnHide, storedHiddenColumns };
}
