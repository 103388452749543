import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { useDataTableGenerate, useDebounce, useMedia, useMediaQueries, usePcns } from "hooks";
import { useAuth } from "context";

import { Button, Collapsible, Icon, ActionsCell, Table } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { FormInput } from "components/forms";
import { PcnModal } from "modules/pcn";

const MIN_SEARCH_LENGTH = 2;

const INITIAL_PARAMS_VALUES = {
    includes: ["user"],
    search: "",
};

const PcnFilters = ({ onFilter, filters, filtersClosed }) => {
    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState(() => filters?.search ?? "");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const [searchTermHolder] = useState(() => filters?.searchHolder ?? "");
    const debouncedSearchTermHolder = useDebounce(searchTermHolder, 500);

    useEffect(() => {
        onFilter?.({ search: debouncedSearchTerm });
    }, [onFilter, debouncedSearchTerm]);

    useEffect(() => {
        onFilter?.({ searchHolder: debouncedSearchTermHolder });
    }, [onFilter, debouncedSearchTermHolder]);

    const disable = searchTerm.length !== 0 && searchTerm.length <= MIN_SEARCH_LENGTH;

    if (!filters) {
        return null;
    }

    return (
        <div className='v__table__extras__filters'>
            <Collapsible closed={filtersClosed} allowOverflow={true} className='body-no-pad no-mar'>
                <div className='v__grid'>
                    <FormInput
                        groupClassName={cn(
                            "v__grid__item v__grid__item--col-4 v__grid__item--col-md-6 v__grid__item--col-sm-12",
                            disable && "v__form__group--incorrect"
                        )}
                        label={t("name")}
                        name='search'
                        error={disable && t("minLength", { value: MIN_SEARCH_LENGTH })}
                        value={searchTerm}
                        onChange={({ target }) => setSearchTerm(target.value)}
                    />

                </div>
            </Collapsible>
        </div>
    );
};

const Pcns = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { userCan } = useAuth();

    const { MEDIUM } = useMediaQueries();
    const isMedium = useMedia(MEDIUM);

    const [modal, setModal] = useState({
        state: false,
        payload: null,
    });

    const closeModal = () => setModal({ state: false, payload: null });

    const [filtersClosed, setFiltersClosed] = useState(true);
    const [filters, setFilters] = useState();

    const onFiltersChange = useCallback((newFilters) => setFilters((prev) => ({ ...prev, ...newFilters })), []);

    const columns = useCallback(
        (columns) => {
            return columns.map((col) => {
                if (col.accessor === "actions") {
                    return {
                        ...col,
                        Cell: ({ cell }) => (
                            <ActionsCell>
                                {userCan("view_user") && (
                                    <Button small bg status='accent' onClick={() => navigate(`${cell.row.original?.id}`)}>
                                        <Icon>visibility</Icon>
                                    </Button>
                                )}
                                {userCan("add_user") && cell.row.values.ref_number !== "No Permission" && (
                                    <Button small bg status='warning' onClick={() => setModal({ payload: cell.row.original, state: true })}>
                                        <Icon>edit</Icon>
                                    </Button>
                                )}
                            </ActionsCell>
                        ),
                    };
                }
                return null;
            });
        },
        [userCan, navigate]
    );

    const {
        tableProps,
        state: { setQueryParams },
    } = useDataTableGenerate(usePcns, columns, filters);

    useEffect(() => {
        const canSearchByName = filters?.search?.length > MIN_SEARCH_LENGTH;

        setQueryParams((prev) => ({
            ...prev,
            ...INITIAL_PARAMS_VALUES,
            page: 1,
            search: canSearchByName ? filters.search : "",
        }));
    }, [filters, setQueryParams]);

    return (
        <PageWrapper>
            <Helmet>
                <title>Veniway | PCN</title>
            </Helmet>
            <PageHeader title={t("pcn")}>
                <div className='v__table__extras__actions'>
                    {userCan("add_user") && (
                        <Button small bg status='danger' round={!isMedium} onClick={() => setModal({ state: true, payload: null })}>
                            {isMedium ? t("addNew") : <Icon>add</Icon>}
                        </Button>
                    )}
                    <Button small bg round={!isMedium} status='accent' onClick={() => setFiltersClosed((prev) => !prev)}>
                        {isMedium ? t("filters") : <Icon>filter_alt</Icon>}
                    </Button>
                </div>
                <PcnFilters filters={filters} onFilter={onFiltersChange} filtersClosed={filtersClosed} />
            </PageHeader>
            <PageContent>
                <Table {...tableProps} scrollToTopOnChange />
            </PageContent>

            {modal.state && <PcnModal payload={modal.payload} onClose={closeModal} />}
        </PageWrapper>
    );
};

export default Pcns;
