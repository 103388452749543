import { useMutation, useQuery, useQueryClient } from "react-query";
import { reportKeys } from ".";
import { GroupsApi } from "../../api";
import { callAll } from "../../utils";

export const groupsKeys = {
	all: () => ["groups"],
	byId: (id) => [...groupsKeys.all(), id],
	byConfig: (config) => [...groupsKeys.all(), config],
};

export const useGroups = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(groupsKeys.byConfig(custom), () => GroupsApi.getGroups(custom), restConfig);
};

export const useGroup = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(groupsKeys.byId(id), () => GroupsApi.getGroupById(id, custom), restConfig);
};

export const useCreateGroup = (config) => {
	const queryClient = useQueryClient();
	return useMutation(GroupsApi.createGroup, {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(groupsKeys.all());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};

export const useUpdateGroup = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => GroupsApi.updateGroup(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(groupsKeys.all());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};

export const useGroupDelete = (config) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => GroupsApi.deleteGroup(data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(groupsKeys.all());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};
