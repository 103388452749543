import { format } from "date-fns";
import axios from "./axios";

const api = {
    getInvoiceManagers(params) {
        return axios
            .get(`/invoicemanager`, {
                params,
            })
            .then(({ data }) => data);
    },

    getInvoiceManager(id, params) {
        return axios
            .get(`/invoicemanager/${encodeURI(id)}`, {
                params,
            })
            .then(({ data }) => data);
    },

    createInvoiceManager(data) {
        return axios.post("/invoicemanager/upload", formatData(data), { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    downloadInvoiceManagers() {
        return axios.post("/invoicemanager/download/all", {}, { headers: Headers.FORMDATA }).then(({ data }) => data);
    },

    updateInvoiceManager(id, data) {
        return axios
            .post(`/invoicemanager/${encodeURI(id)}/update`, formatUpdateData(data), { headers: Headers.FORMDATA })
            .then(({ data }) => data);
    },

    updateInvoiceManagerTour(id, data) {
        return axios
            .post(`/invoicemanager/${encodeURI(id)}/tourEdit`, data, { headers: Headers.FORMDATA })
            .then(({ data }) => data);
    },

    updateInvoiceManagerTourPrice(data) {
        return axios
            .post(`/invoices/changeTourPrice`, data, { headers: Headers.FORMDATA })
            .then(({ data }) => formatData(data));
    },
    deleteInvoiceManagerTourPrices(data) {
        return axios
            .post(`/invoices/deleteManualPrices`, data, { headers: Headers.FORMDATA })
            .then(({ data }) => formatData(data));
    },

    deleteInvoiceAdjustmentRelations(id, data) {
        return axios
            .post(`/invoicemanager/adjustment/${encodeURI(id)}/removeRelations`, data, { headers: Headers.FORMDATA })
            .then(({ data }) => data);
    },

    deleteInvoiceManager(id) {
        return axios.delete(`/invoicemanager/${encodeURI(id)}`).then(({ data }) => data);
    },

    getToursEdit(id, params) {
        return axios
            .get(`/invoicemanager/${encodeURI(id)}/tourEdit`, {
                params
            })
            .then(({ data }) => data);
    },


};

function formatUpdateData(data) {
    const formattedData = new FormData();

    if (Boolean(data.adjustments)) {
        for (const adjustment_key of Object.keys(data.adjustments)) {
            for (const snumber_data of data.adjustments[adjustment_key]["snumbers"]) {
                formattedData.append(`unknown_adjustments[${adjustment_key}][snumbers][]`, snumber_data.id);
            }
            for (const user_data of data.adjustments[adjustment_key]["user"]) {
                formattedData.append(`unknown_adjustments[${adjustment_key}][user][]`, user_data.id);
            }

        }
    }

    if (Boolean(data.snumbers)) {
        for (const snumber of Object.keys(data.snumbers)) {
            if (!Boolean(data.snumbers[snumber]?.id)) {
                continue;
            }
            formattedData.append(`unknown_snumbers[${snumber}]`, data.snumbers[snumber]?.id);
        }
    }

    return formattedData;
}

function formatData(data) {
    const formattedData = new FormData();
    if (data.image_path) {
        formattedData.append("file", data.image_path);
    }

    if (data.date) {
        formattedData.append(`date`, format(new Date(data.date), "dd-MM-yyyy"));
    }

    return formattedData;
}

export default api;
