import React, { useEffect, useRef } from "react";
import classnames from "classnames";

const Clock = ({ withSeconds = false, className }) => {
	const digitalClock = useRef();

	const rounder = (number) => {
		if (number < 10) {
			number = "0" + number;
		}
		return number;
	};

	const clock = () => {
		let time = new Date(),
			hours = time.getHours(),
			minutes = time.getMinutes(),
			seconds = time.getSeconds();
		if (digitalClock.current) {
			digitalClock.current.innerHTML = withSeconds
				? `${rounder(hours)}:${rounder(minutes)}:${rounder(seconds)}`
				: `${rounder(hours)}:${rounder(minutes)}`;
		}
	};

	useEffect(() => {
		const clockInterval = setInterval(clock, 1000);
		return () => clearInterval(clockInterval);
	});

	return (
		<div className={classnames("v__clock__container", className)}>
			{withSeconds ? (
				<div className='v__clock' ref={digitalClock}>
					{rounder(new Date().getHours())}:{rounder(new Date().getMinutes())}:{rounder(new Date().getSeconds())}
				</div>
			) : (
				<div className='v__clock' ref={digitalClock}>
					{rounder(new Date().getHours())}:{rounder(new Date().getMinutes())}
				</div>
			)}
		</div>
	);
};

export default React.memo(Clock);
