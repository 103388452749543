import { CSSTransition } from "react-transition-group";
import cn from "classnames";

const Collapse = ({ classNames, timeout = 300, unmountOnExit = true, children, ...rest }) => {
	return (
		<CSSTransition timeout={timeout} unmountOnExit={unmountOnExit} classNames={cn("collapse__animate", classNames)} {...rest}>
			{children}
		</CSSTransition>
	);
};

export default Collapse;
