import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "context";

import ViewContextProvider from "context/ViewContext";

import { useUser, useWindowSize } from "hooks";

import { Button, Icon, PerformanceChartBox, PerformanceSortBySwitch } from "components";
import { Avatar, TabCard, TabCardHeader, TabCardPanel } from "components/ui";

import PageWrapper from "components/util/PageWrapper";
import PageHeader from "components/util/PageHeader";
import PageContent from "components/util/PageContent";

import ViewAllStats from "components/util/views_widgets/ViewAllStats";
import ViewAllReports from "components/util/views_widgets/ViewAllReports";
import { TicketModal } from "modules";

const UsersView = () => {
	const { width } = useWindowSize();
	const { id } = useParams();
	const { t } = useTranslation();

	const { userCan } = useAuth();

	const [ticketModal, setTicketModal] = useState({ state: false, payload: null });
	const closeTicketModal = () => setTicketModal((prev) => ({ ...prev, state: false }));

	const { data: currentUser, isFetching } = useUser(id, {
		custom: {
			includes: ["servicePartners", "joinedDepos", "media", "workingYears", "divisions"],
		},
	});

	const userOptions = [
		{
			label: t("overview"),
			value: "overview",
		},
		{
			label: t("reports"),
			value: "reports",
		},
	];

	const HeaderDetails = () => {
		return (
			<div className='v__page__header__content__title__user'>
				<Avatar user={currentUser?.data} />
				<p>{currentUser?.data?.full_name}</p>
			</div>
		);
	};

	return (
		<PageWrapper>
			<Helmet>
				<title>{`Veniway | ${currentUser?.data?.full_name ?? ""}`}</title>
			</Helmet>
			<PageHeader title={<HeaderDetails />}>
				<div className='v__table__extras__actions'>
					{userCan("add_ticket") && (
						<Button
							small
							bg
							status='warning'
							round={width < 800}
							onClick={() => setTicketModal({ state: true, payload: currentUser?.data })}
						>
							{width < 800 ? <Icon>how_to_vote</Icon> : t("raiseIssue")}
						</Button>
					)}
					<Button
						small
						bg
						round={width < 800}
						status='darker'
						iconLeft={width < 800 ? null : <Icon>print</Icon>}
						onClick={() => {
							window.print();
						}}
						title='Print results'
					>
						{width < 800 ? <Icon>print</Icon> : t("print")}
					</Button>
				</div>
			</PageHeader>
			<PageContent>
				<ViewContextProvider>
					<TabCard loading={!ticketModal && isFetching > 0}>
						<TabCardHeader options={userOptions} initialActive={0} />
						<TabCardPanel overflow={false}>
							<ViewAllStats currentAccount={currentUser?.data} accountType='user_id' />
							<hr className='print-hide' />
							{currentUser?.data && (
								<PerformanceChartBox
									filtersComponent={({ isLarge, ...props }) => isLarge && <PerformanceSortBySwitch {...props} small />}
									bodyFiltersComponent={({ isLarge, ...props }) =>
										!isLarge && <PerformanceSortBySwitch {...props} small />
									}
									filterValues={{ user_id: { value: currentUser?.data?.id } }}
									className='pt-4'
								/>
							)}
						</TabCardPanel>
						<TabCardPanel overflow={false}>
							<ViewAllReports currentAccount={currentUser?.data} accountType='user_id' />
						</TabCardPanel>
					</TabCard>
				</ViewContextProvider>
				{ticketModal.state && <TicketModal payload={ticketModal.payload} onClose={closeTicketModal} />}
			</PageContent>
		</PageWrapper>
	);
};

export default UsersView;
