const VeniwayIconLogo = (props) => {
	return (
		<svg viewBox='0 0 241.855 231.86' {...props}>
			<g transform='translate(0 0)'>
				<path
					className='accent-wing'
					d='M52.489,107.185A25.67,25.67,0,0,1,28.318,81.573V31.115A90.185,90.185,0,0,1,94.449,77.221a78.742,78.742,0,0,1,3.323-8,115,115,0,0,1,11.35-18.349L108.3,49.7A116.825,116.825,0,0,0,32.14,4.591L1.53.21V81.573A52.458,52.458,0,0,0,25.7,125.681l1.676,1a91.155,91.155,0,0,1,25.112-19.5Z'
					transform='translate(2.969 0.408)'
				/>
				<path
					className='rest-wings'
					d='M236.886,200.545a108.034,108.034,0,0,0-40.579-67.808,52.546,52.546,0,0,0,40.9-51.164V.21L206.6,4.591a117.3,117.3,0,0,0-98.859,98.859v.676h-.176v1.323l-.323,2.323-3.176.412c-51.135,6.645-91.773,44.666-99.241,92.361L0,231.45H47.048a88.626,88.626,0,0,0,73.806-38.667,88.626,88.626,0,0,0,73.953,38.667h47.048ZM210.421,31.115V81.573a25.729,25.729,0,0,1-25.7,25.7H134.233a90.155,90.155,0,0,1,76.188-76.159ZM47.048,204.662H31.287c5.646-36.139,36.844-64.691,76.159-70.013V149C107.475,179.7,80.3,204.662,47.048,204.662Zm147.906,0c-33.4,0-60.574-24.965-60.574-55.663v-14.35c39.314,5.2,70.572,33.874,76.159,70.013Z'
					transform='translate(0 0.408)'
				/>
				<path
					className='accent-wing'
					d='M68.053,36.344h0s7.645-9.88,11.762-14.173A153.64,153.64,0,0,1,108.926,0a370.5,370.5,0,0,1-39.7,4C56,4,27.21,0,27.21,0A153.64,153.64,0,0,1,56.144,22.171c4.264,4.293,11.762,14.173,11.762,14.173h0Z'
					transform='translate(52.801 0)'
				/>
			</g>
		</svg>
	);
};

export default VeniwayIconLogo;
