import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { callAll } from "utils";
import { PartnersApi } from "../../api";
import { reportKeys } from "./reports";

export const partnersKeys = {
	all: () => ["partners"],
	byId: (id) => [...partnersKeys.all(), id],
	byConfig: (config) => [...partnersKeys.all(), config],
	byInfiniteConfig: (config) => [...partnersKeys.byConfig(config), "infinite"],
	staff: () => [...partnersKeys.all(), "staff"],
	staffById: (id) => [...partnersKeys.staff(), "staff", id],
	byStaffConfig: (config) => [...partnersKeys.staff(), config],
};

export const useServicePartners = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(partnersKeys.byConfig(custom), () => PartnersApi.getServicePartners(custom), restConfig);
};

export const useServicePartner = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(partnersKeys.byId(id), () => PartnersApi.getServicePartner(id, custom), restConfig);
};

export const useCreateServicePartner = (config) => {
	const queryClient = useQueryClient();
	return useMutation(PartnersApi.createServicePartner, {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(partnersKeys.all());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};

export const useUpdateServicePartner = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => PartnersApi.updateServicePartner(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(partnersKeys.all());
			queryClient.invalidateQueries(reportKeys.all());
		}, config?.onSuccess),
	});
};

export const useInfiniteServicePartners = (config) => {
	const { custom, ...restConfig } = config;
	return useInfiniteQuery(
		partnersKeys.byInfiniteConfig(custom),
		({ pageParam = 1 }) => PartnersApi.getServicePartners({ page: pageParam, ...custom }),
		restConfig
	);
};

export const useServicePartnerStaff = (config) => {
	const { custom, ...restConfig } = config;
	return useInfiniteQuery(
		partnersKeys.byStaffConfig(custom),
		({ pageParam = 1 }) => PartnersApi.getServicePartnerStaff({ page: pageParam, ...custom }),
		restConfig
	);
};

export const useServicePartnerStaffById = (config) => {
	const { custom, ...restConfig } = config;
	const { sp_id, ...params } = custom;
	return useInfiniteQuery(
		partnersKeys.byStaffConfig(custom),
		({ pageParam = 1 }) => PartnersApi.getServicePartnerStaffById(sp_id, { page: pageParam, ...params }),
		restConfig
	);
};
