import React, { forwardRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const Button = forwardRef(
	(
		{
			className,
			type = "button",
			label = "button",
			status = "none",
			hoverStatus = "none",
			alignLeft = false,
			small = false,
			xsmall = false,
			round = false,
			wide = false,
			outlined = false,
			bg = false,
			iconLeft = null,
			iconRight = null,
			spongy = true,
			hover = true,
			focus = true,
			overflow = false,
			children,
			...rest
		},
		ref
	) => (
		<button
			type={type}
			aria-label={label}
			className={classnames(
				"v__btn",
				{
					v__btn__round: round,
					v__btn__wide: wide,
					v__btn__space: !!iconLeft,
					v__btn__space__alt: !!iconRight,
					v__btn__spongy: spongy,
					v__btn__sm: small,
					v__btn__xsm: xsmall,
					v__btn__left: alignLeft,
					v__btn__overflow: overflow,
				},
				{
					[`v__btn__outlined--${status}`]: outlined,
					[`v__btn__bg--${status}`]: bg,
					[`v__btn__hover--${hoverStatus}`]: hover,
				},
				className
			)}
			{...rest}
			ref={ref}
		>
			{iconLeft}
			{children}
			{iconRight}
		</button>
	)
);

Button.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	status: PropTypes.string,
	hoverStatus: PropTypes.string,
	alignLeft: PropTypes.bool,
	small: PropTypes.bool,
	xsmall: PropTypes.bool,
	round: PropTypes.bool,
	wide: PropTypes.bool,
	outlined: PropTypes.bool,
	bg: PropTypes.bool,
	iconLeft: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
	iconRight: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
	spongy: PropTypes.bool,
	hover: PropTypes.bool,
	focus: PropTypes.bool,
};

export default Button;
