import axios from "./axios";

const api = {
	getNewNotifications() {
		return axios.get(`/notifications/new`).then(({ data }) => data);
	},
	getNotifications(params) {
		return axios.get(`/notifications`, { params }).then(({ data }) => data);
	},
};

export default api;
