import { useTranslation } from "react-i18next";

const NoDataRow = ({ asDiv = false }) => {
	const Wrapper = asDiv ? "div" : "tr";
	const Cell = asDiv ? "div" : "td";

	const cellProps = asDiv ? {} : { colSpan: "10000" };

	return (
		<Wrapper className='v__table__body__row v__table__body__row--empty'>
			<Cell className='v__table__body__row__cell' {...cellProps}>
				<NoDataRowSVG />
			</Cell>
		</Wrapper>
	);
};

export const NoDataRowSVG = () => {
	const { t } = useTranslation();

	return (
		<div className='v__table__body__row__cell__value'>
			<svg viewBox='0 0 770.077 748.189' className='mx-auto'>
				<g transform='translate(-553.766 -130.325)'>
					<g transform='matrix(0.966, -0.259, 0.259, 0.966, -699.568, 359.953)'>
						<rect
							className='backboard'
							width='437.02'
							height='603.82'
							transform='translate(1238.89 218.91)'
						/>
						<rect
							className='backboard-paper dim'
							width='411.5'
							height='570.52'
							transform='translate(1251.65 233.42)'
						/>
						<rect className='clip' width='199.84' height='47.27' transform='translate(1358.02 206.56)' />
						<path
							className='clip-round'
							d='M666.86,118a27.93,27.93,0,1,0,27.93,27.93A27.93,27.93,0,0,0,666.86,118Zm0,44.05a16.155,16.155,0,1,0,0-.05Z'
							transform='translate(791.08 42.41)'
						/>
					</g>
					<g transform='matrix(0.996, 0.087, -0.087, 0.996, -326.611, -87.15)'>
						<rect
							className='backboard'
							width='437.02'
							height='603.82'
							transform='translate(1238.89 218.91)'
						/>
						<rect
							className='backboard-paper'
							width='411.5'
							height='570.52'
							transform='translate(1251.65 233.42)'
						/>
						<rect className='clip' width='199.84' height='47.27' transform='translate(1358.02 206.56)' />
						<path
							className='clip-round'
							d='M666.86,118a27.93,27.93,0,1,0,27.93,27.93A27.93,27.93,0,0,0,666.86,118Zm0,44.05a16.155,16.155,0,1,0,0-.05Z'
							transform='translate(791.08 42.41)'
						/>
					</g>
				</g>
			</svg>
			<br />
			<br />
			<span>{t("noData")}</span>
		</div>
	);
};

export default NoDataRow;
