import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useDataTableGenerate, useDebounce, useMedia, useMediaQueries, useTourDelete } from "hooks";

import { Button, Icon, ActionsCell, Table, Collapsible } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { FormInput } from "components/forms";

import { useTours } from "hooks/query/tours";
import TourModal from "modules/tours/TourModal";
import { TourPaymentModal } from "modules/tours";
import { useAuth } from "context";
import { useParams } from "react-router-dom";
import { useTourCategory } from "hooks/query/toursCategories";

const MIN_SEARCH_LENGTH = 1;

const INITIAL_PARAMS_VALUES = {
    search: "",
};

const ToursFilters = ({ onFilter, filters, filtersClosed }) => {
    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState(() => filters?.search ?? "");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        onFilter?.({ search: debouncedSearchTerm });
    }, [onFilter, debouncedSearchTerm]);

    const disable = searchTerm.length !== 0 && searchTerm.length <= MIN_SEARCH_LENGTH;

    if (!filters) {
        return null;
    }

    return (
        <div className='v__table__extras__filters'>
            <Collapsible closed={filtersClosed} allowOverflow={true} className='body-no-pad no-mar'>
                <div className='v__grid'>
                    <FormInput
                        groupClassName={cn(
                            "v__grid__item v__grid__item--col-4 v__grid__item--col-md-6 v__grid__item--col-sm-12",
                            disable && "v__form__group--incorrect"
                        )}
                        label={t("title")}
                        name='search'
                        error={disable && t("minLength", { value: MIN_SEARCH_LENGTH })}
                        value={searchTerm}
                        onChange={({ target }) => setSearchTerm(target.value)}
                    />
                </div>
            </Collapsible>
        </div>
    );
};

const Tours = () => {
    const { t } = useTranslation();

    const { id } = useParams();

    const { data: tourCategory } = useTourCategory(id, {
        enabled: Boolean(id)
    });

    const { userCan } = useAuth();

    const { MEDIUM } = useMediaQueries();
    const isMedium = useMedia(MEDIUM);

    const [tourModal, setTourModal] = useState({
        state: false,
        payload: null,
    });

    const [paymentTypeModal, setPaymentTypeModal] = useState({
        state: false,
    });

    const [filtersClosed, setFiltersClosed] = useState(true);
    const [filters, setFilters] = useState();

    const onFiltersChange = useCallback((newFilters) => setFilters((prev) => ({ ...prev, ...newFilters })), []);

    const closeModal = () => setTourModal({ state: false, payload: null });
    const closePaymentModal = () => setPaymentTypeModal({ state: false });

    const { mutate, isLoading } = useTourDelete();

    const columns = useCallback(
        (columns) => {
            return columns.map((col) => {
                if (col.accessor === "actions") {
                    return {
                        ...col,
                        Cell: ({ cell }) => (
                            <ActionsCell>
                                <Button small bg status='warning' onClick={() => setTourModal({ payload: cell.row.original, state: true })}>
                                    <Icon>edit</Icon>
                                </Button>

                                {userCan("tours_advanced_edit") && (
                                    <Button small bg status='danger' onClick={() => mutate(cell.row.original?.id)}>
                                        <Icon>delete</Icon>
                                    </Button>
                                )}
                            </ActionsCell>
                        ),
                    };
                }
                return null;
            });
        },
        [mutate, userCan]
    );

    const {
        tableProps: { loading, ...otherProps },
        state: { setQueryParams },
    } = useDataTableGenerate(useTours, columns, { route_category_id: id });

    useEffect(() => {
        const canSearchByName = filters?.search?.length > MIN_SEARCH_LENGTH;

        setQueryParams((prev) => ({
            ...prev,
            ...INITIAL_PARAMS_VALUES,
            page: 1,
            search: canSearchByName ? filters.search : "",
        }));
    }, [filters, setQueryParams]);

    return (
        <PageWrapper>
            <Helmet>
                <title>Veniway | Tours</title>
            </Helmet>
            <PageHeader title={t("tours") + " - " + tourCategory?.data.title}>
                <div className='v__table__extras__actions'>
                    {/* {userCan("add_groups") && ( */}

                    {userCan("tours_advanced_edit") && (
                        <Button small bg status='warning' onClick={() => setPaymentTypeModal({ state: true, category_id: id })}>
                            <Icon>edit</Icon>
                            Global Settings
                        </Button>
                    )}

                    <Button small bg status='danger' round={!isMedium} onClick={() => setTourModal({ state: true, payload: null })}>
                        {isMedium ? t("addNew") : <Icon>add</Icon>}
                    </Button>
                    <Button small bg round={!isMedium} status='accent' onClick={() => setFiltersClosed((prev) => !prev)}>
                        {isMedium ? t("filters") : <Icon>filter_alt</Icon>}
                    </Button>
                </div>

                <ToursFilters filters={filters} onFilter={onFiltersChange} filtersClosed={filtersClosed} />
            </PageHeader>
            <PageContent>
                <Table {...otherProps} loading={loading || isLoading} scrollToTopOnChange />
            </PageContent>
            {tourModal.state && <TourModal payload={tourModal.payload} categoryId={id} onClose={closeModal} />}
            {paymentTypeModal.state && <TourPaymentModal category_id={id} onClose={closePaymentModal} />}
        </PageWrapper>
    );
};

export default Tours;
