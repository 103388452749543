import { t } from "i18next";
import { Button, Icon } from "..";
import { useMedia, useMediaQueries } from "../../hooks";

const CalendarNavigation = ({ children, onPrev, onNext, prevProps, nextProps, prevLabel, nextLabel }) => {
	const { LARGE } = useMediaQueries();
	const isLarge = useMedia(LARGE);
	return (
		<div className='v__calendar__full__nav'>
			<Button bg round={!isLarge} status='darker' onClick={onPrev} {...prevProps}>
				<Icon>arrow_back</Icon>
				{isLarge && <span>{prevLabel ?? t("month")}</span>}
			</Button>
			{children}
			<Button bg round={!isLarge} status='darker' onClick={onNext} {...nextProps}>
				{isLarge && <span>{nextLabel ?? t("month")}</span>}
				<Icon>arrow_forward</Icon>
			</Button>
		</div>
	);
};

export default CalendarNavigation;
