import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDepoStaff } from "hooks";
import cn from "classnames";

import { FormInput, FormSelect } from "../../components/forms";
import { Button } from "../../components";

const InvoicePayForm = ({ onSubmit, onCancel, defaultValues }) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
                <Controller
                    render={({ field }) => (
                        <FormInput
                            groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-6", {
                                "v__form__group--incorrect": errors?.paid,
                            })}
                            label={t("paid")}
                            error={errors?.paid && t(`${errors?.paid?.type}`)}
                            {...field}
                        />
                    )}
                    control={control}
                    name='paid'
                    defaultValue='0'
                    rules={{ required: true }}
                />

                <Controller
                    render={({ field }) => (
                        <FormSelect
                            groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-6", {
                                "v__form__group--incorrect": errors?.status,
                            })}
                            label={t("status")}
                            options={[
                                { value: "waiting_payment", label: "Waiting Payment" },
                                { value: "paid", label: "Paid" },
                                // 													{ value: "preinvoice", label: "Preinvoice" },
                            ]}
                            error={errors?.status && t(`${errors?.status?.type}`)}
                            {...field}
                        />
                    )}
                    control={control}
                    name='status'
                    defaultValue={{ value: "waiting_payment", label: "Waiting Payment" }}
                    rules={{ required: true }}
                />

                <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                    <Button bg status='darker' onClick={onCancel}>
                        {t("cancel")}
                    </Button>
                    <Button bg status='accent' name='invoicePaySubmit' type='submit'>
                        {t("save")}
                    </Button>
                </div>
            </form>
        </>
    );
};
//defaultValue={value?.user !== undefined ? { value: value.user.id, label: value.user.full_name } : ""}

export default InvoicePayForm;
