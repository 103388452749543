import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuth } from "../../context";

const PermissionRoute = ({ permissions, children }) => {
	const { userCan } = useAuth();

	if (!userCan(...permissions)) {
		return <Navigate replace to='/app' />;
	}

	return children;
};

PermissionRoute.propTypes = {
	permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PermissionRoute;
