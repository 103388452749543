import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useDataTableGenerate, useDebounce, useMedia, useMediaQueries } from "hooks";

import { Button, Icon, ActionsCell, Table, Collapsible } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { FormInput } from "components/forms";

import { TourCategoryModal, TourPaymentModal } from "modules/tours";
import { useAuth } from "context";
import { useTourCategoryDelete, useToursCategories } from "hooks/query/toursCategories";
import { useNavigate } from "react-router-dom";

const MIN_SEARCH_LENGTH = 1;


const ToursFilters = ({ onFilter, filters, filtersClosed }) => {
	const { t } = useTranslation();

	const [searchTerm, setSearchTerm] = useState(() => filters?.search ?? "");
	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	useEffect(() => {
		onFilter?.({ search: debouncedSearchTerm });
	}, [onFilter, debouncedSearchTerm]);

	const disable = searchTerm.length !== 0 && searchTerm.length <= MIN_SEARCH_LENGTH;

	if (!filters) {
		return null;
	}

	return (
		<div className='v__table__extras__filters'>
			<Collapsible closed={filtersClosed} allowOverflow={true} className='body-no-pad no-mar'>
				<div className='v__grid'>
					<FormInput
						groupClassName={cn(
							"v__grid__item v__grid__item--col-4 v__grid__item--col-md-6 v__grid__item--col-sm-12",
							disable && "v__form__group--incorrect"
						)}
						label={t("title")}
						name='search'
						error={disable && t("minLength", { value: MIN_SEARCH_LENGTH })}
						value={searchTerm}
						onChange={({ target }) => setSearchTerm(target.value)}
					/>
				</div>
			</Collapsible>
		</div>
	);
};

const ToursCategories = () => {
	const { t } = useTranslation();

	const { userCan } = useAuth();

	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const [tourModal, setTourModal] = useState({
		state: false,
		payload: null,
	});

	const [paymentTypeModal, setPaymentTypeModal] = useState({
		state: false,
	});

	const [filtersClosed, setFiltersClosed] = useState(true);
	const [filters, setFilters] = useState();

	const onFiltersChange = useCallback((newFilters) => setFilters((prev) => ({ ...prev, ...newFilters })), []);

	const closeModal = () => setTourModal({ state: false, payload: null });
	const closePaymentModal = () => setPaymentTypeModal({ state: false });
	const navigate = useNavigate();

	const { mutate, isLoading } = useTourCategoryDelete();

	const columns = useCallback(
		(columns) => {
			return columns.map((col) => {
				if (col.accessor === "actions") {
					return {
						...col,
						Cell: ({ cell }) => (
							<ActionsCell>
								<Button small bg status='accent' onClick={() => navigate(`/app/tours/${cell.row.original?.id}`)}>
									<Icon>visibility</Icon>
								</Button>


								<Button small bg status='warning' onClick={() => setTourModal({ payload: cell.row.original, state: true })}>
									<Icon>edit</Icon>
								</Button>

								{userCan("tours_advanced_edit") && (
									<Button small bg status='danger' onClick={() => mutate(cell.row.original?.id)}>
										<Icon>delete</Icon>
									</Button>
								)}
							</ActionsCell>
						),
					};
				}
				return null;
			});
		},
		[mutate, userCan]
	);

	const {
		tableProps: { loading, ...otherProps },
	} = useDataTableGenerate(useToursCategories, columns, filters);


	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Tours Categories</title>
			</Helmet>
			<PageHeader title={t("tours_categories")}>
				<div className='v__table__extras__actions'>

					<Button small bg status='danger' round={!isMedium} onClick={() => setTourModal({ state: true, payload: null })}>
						{isMedium ? t("addNew") : <Icon>add</Icon>}
					</Button>
				</div>

				<ToursFilters filters={filters} onFilter={onFiltersChange} filtersClosed={filtersClosed} />
			</PageHeader>
			<PageContent>
				<Table {...otherProps} loading={loading || isLoading} scrollToTopOnChange />
			</PageContent>
			{tourModal.state && <TourCategoryModal payload={tourModal.payload} onClose={closeModal} />}
			{paymentTypeModal.state && <TourPaymentModal onClose={closePaymentModal} />}
		</PageWrapper>
	);
};

export default ToursCategories;
