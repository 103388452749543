import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import {
	useCompanies,
	useDataTableGenerate,
	useDebounce,
	useDepoStaff,
	useGroups,
	useMedia,
	useMediaQueries,
	useServicePartners,
} from "hooks";

import { Button, Icon, ActionsCell, Table, Collapsible } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { FormInput, FormSelect } from "components/forms";
import { useAuth } from "context";
import { useCards } from "hooks/query/cards";
import { CardModal } from "modules/cards";

const MIN_SEARCH_LENGTH = 2;

const INITIAL_PARAMS_VALUES = {
	includes: ["userOwners"],
	lastMonthChart: ["overall_rating_daily_avg"],
	search: "",
	depo_id: null,
	member_group_id: null,
	service_partner_id: null,
	user_id: null,
};

const CardFilter = ({ onFilter, filters, filtersClosed }) => {
	const { t } = useTranslation();

	const { userCan } = useAuth();

	const [searchTerm, setSearchTerm] = useState(() => filters?.search ?? "");
	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	useEffect(() => {
		onFilter?.({ search: debouncedSearchTerm });
	}, [onFilter, debouncedSearchTerm]);

	const disable = searchTerm.length !== 0 && searchTerm.length <= MIN_SEARCH_LENGTH;

	if (!filters) {
		return null;
	}

	return (
		<div className='v__table__extras__filters'>
			<Collapsible closed={filtersClosed} allowOverflow={true} className='body-no-pad no-mar'>
				<div className='v__grid'>
					<FormInput
						groupClassName={cn(
							"v__grid__item v__grid__item--col-2 v__grid__item--col-md-6 v__grid__item--col-sm-12",
							disable && "v__form__group--incorrect"
						)}
						label={t("card_number")}
						name='search'
						error={disable && t("minLength", { value: MIN_SEARCH_LENGTH })}
						value={searchTerm}
						onChange={({ target }) => setSearchTerm(target.value)}
					/>

					{userCan("manage_cards") && (
						<>
							<FormSelect
								groupClassName={cn(
									"v__grid__item v__grid__item--col-3 v__grid__item--col-md-6 v__grid__item--col-sm-12",
									disable && "disabled"
								)}
								label={t("depo")}
							    isAsync	
								useFetch={useCompanies}
								isGrouped
								isClearable
								queryConfig={{ custom: { type: "delivery_daily", includes: ["depos"] } }}
								groupParse={{ label: "title", options: "depos" }}
								value={filters.depo_id}
								onChange={(option) =>
									onFilter?.({
										depo_id: option,
										service_partner_id: null,
									})
								}
							/>

							<FormSelect
								groupClassName='v__grid__item v__grid__item--col-3 v__grid__item--col-md-6 v__grid__item--col-sm-12'
								label={t("group")}
							    isAsync	
								asyncSearch
								isClearable
								useFetch={useGroups}
								queryConfig={{ custom: { page: 0, per_page: 25 } }}
								value={filters.member_group_id}
								onChange={(option) =>
									onFilter?.({
										member_group_id: option,
									})
								}
							/>

							<FormSelect
								groupClassName={cn(
									"v__grid__item v__grid__item--col-2 v__grid__item--col-md-6 v__grid__item--col-sm-12",
									disable && "disabled"
								)}
								label={t("servicePartner")}
							    isAsync	
								asyncSearch
								isClearable
								useFetch={useServicePartners}
								queryConfig={{ custom: { depo_id: filters.depo_id?.value, page: 0, per_page: 25 } }}
								value={filters.service_partner_id}
								onChange={(option) =>
									onFilter?.({
										service_partner_id: option,
									})
								}
							/>
						</>
					)}

					<FormSelect
						groupClassName={cn("v__grid__item v__grid__item--col-2 v__grid__item--col-md-6 v__grid__item--col-sm-12")}
						label={t("staff")}
					    isAsync	
						asyncSearch
						isClearable
						useFetch={useDepoStaff}
						isGrouped
						queryConfig={{
							custom: {
								depo_id: filters.depo_id?.value,
								service_partner_id: filters.service_partner_id?.value,
								member_group_id: filters.member_group_id?.value,
								page: 0,
								per_page: 10,
							},
						}}
						groupParse={{ label: "title", options: "staff" }}
						optionsParse={{ value: "id", label: "full_name" }}
						value={filters.user_id}
						onChange={(option) =>
							onFilter?.({
								user_id: option,
							})
						}
					/>
				</div>
			</Collapsible>
		</div>
	);
};

const Cards = () => {
	const { t } = useTranslation();
	const { userCan } = useAuth();

	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const [modal, setModal] = useState({
		state: false,
		payload: null,
		view: false,
	});

	const [filtersClosed, setFiltersClosed] = useState(true);
	const [filters, setFilters] = useState();

	const onFiltersChange = useCallback((newFilters) => setFilters((prev) => ({ ...prev, ...newFilters })), []);

	const csvDownloadPath = process.env.REACT_APP_API_URL + "/cards/allExportCSV";

	const closeModal = () => setModal({ state: false, view: false, payload: null });

	const columns = useCallback((columns) => {
		return columns.map((col) => {
			if (col.accessor === "actions") {
				return {
					...col,
					Cell: ({ cell }) => (
						<ActionsCell>
							<Button small bg status='warning' onClick={() => setModal({ payload: cell.row.original, state: true })}>
								<Icon>edit</Icon>
							</Button>

							<Button
								small
								bg
								status='accent'
								onClick={() => setModal({ payload: cell.row.original, state: true, view: true })}
							>
								<Icon>visibility</Icon>
							</Button>
						</ActionsCell>
					),
				};
			}
			return null;
		});
	}, []);

	const {
		tableProps,
		state: { setQueryParams },
	} = useDataTableGenerate(useCards, columns, filters);

	useEffect(() => {
		const canSearchByName = filters?.search?.length > MIN_SEARCH_LENGTH;

		setQueryParams((prev) => ({
			...prev,
			...INITIAL_PARAMS_VALUES,
			page: 1,
			search: canSearchByName ? filters.search : "",
			depo_id: filters?.depo_id?.value,
			service_partner_id: filters?.service_partner_id?.value,
			member_group_id: filters?.member_group_id?.value,
			user_id: filters?.user_id?.value,
		}));
	}, [filters, setQueryParams]);

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Cards</title>
			</Helmet>
			<PageHeader title={t("cards")}>
				<div className='v__table__extras__actions'>
					<Button small bg status='danger' round={!isMedium} onClick={() => setModal({ state: true, payload: null })}>
						{isMedium ? t("addNew") : <Icon>add</Icon>}
					</Button>
					<a className='mr-2' href={csvDownloadPath} target='_blank' rel='noopener noreferrer' download>
						{userCan("download_fuel") && (
							<Button small bg status='success' iconLeft={<Icon>download</Icon>}>
								Download CSV
							</Button>
						)}
					</a>
					<Button small bg round={!isMedium} status='accent' onClick={() => setFiltersClosed((prev) => !prev)}>
						{isMedium ? t("filters") : <Icon>filter_alt</Icon>}
					</Button>
				</div>

				<CardFilter filters={filters} onFilter={onFiltersChange} filtersClosed={filtersClosed} />
			</PageHeader>
			<PageContent>
				<Table {...tableProps} scrollToTopOnChange />
			</PageContent>
			{modal.state && <CardModal payload={modal.payload} view={modal.view} onClose={closeModal} />}
		</PageWrapper>
	);
};

export default Cards;
