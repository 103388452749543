import { REPORT_COLUMN_PRESETS } from "reducers/const";

export const REPORT_REDIRECT_CELL_ACCESSORS = ["depo_name", "depo_subname", "service_partner_name", "group_name", "full_name"];
export const REPORT_FEEDBACK_CELL_ACCESSORS = ["positive_feedback_sum", "negative_feedback_sum"];
export const REPORT_POINTS_CELL_ACCESSORS = ["fairplay_overall_sum", "fairplay_overall_daily_sum", "fairplay_overall_parcel_sum"];

export const generateReportsPresets = (presets, getHiddenColumnsByKey, t) => [
    {
        groupTranslationKey: "scoresReports",
        value: REPORT_COLUMN_PRESETS.GOLDEN_5,
        labelTranslationKey: "goldenFive",
        iconKey: "military_tech",
        columns: getHiddenColumnsByKey(presets, "golden_five_hidden_columns"),
        description: <p>{t("description")}</p>,
    },
    {
        groupTranslationKey: "scoresReports",
        value: REPORT_COLUMN_PRESETS.SCORES,
        labelTranslationKey: "scores",
        iconKey: "grade",
        columns: getHiddenColumnsByKey(presets, "scores_hidden_columns"),
    },
    {
        value: REPORT_COLUMN_PRESETS.FEEDBACK,
        labelTranslationKey: "feedback",
        iconKey: "thumbs_up_down",
        columns: [],
    },
    {
        value: REPORT_COLUMN_PRESETS.CUSTOM,
        labelTranslationKey: "custom",
        iconKey: "format_list_bulleted",
        columns: getHiddenColumnsByKey(presets, "default_hidden_columns"),
    },
];

export const generateFBRPresets = (presets, getHiddenColumnsByKey, t) => [
    {
        groupTranslationKey: "depoFormula",
        value: REPORT_COLUMN_PRESETS.GOLDEN_10,
        labelTranslationKey: "goldenTen",
        iconKey: "verified",
        columns: getHiddenColumnsByKey(presets, "golden_10_hidden_columns"),
        description: <p>{t("description")}</p>,
    },
    // {
    //     groupTranslationKey: "depoFormula",
    //     value: REPORT_COLUMN_PRESETS.POINTS,
    //     labelTranslationKey: "points",
    //     iconKey: "paid",
    //     columns: getHiddenColumnsByKey(presets, "points_hidden_columns"),
    // },
    // {
    //     groupTranslationKey: "depoFormula",
    //     value: REPORT_COLUMN_PRESETS.SP_PREINVOICE,
    //     labelTranslationKey: "preinvoice",
    //     iconKey: "paid",
    //     columns: getHiddenColumnsByKey(presets, "preinvoice_hidden_columns"),
    // },
    {
        value: REPORT_COLUMN_PRESETS.FEEDBACK,
        labelTranslationKey: "feedback",
        iconKey: "thumbs_up_down",
        columns: [],
        isInvisible: true,
    },
];

export const generateFuelPresets = (presets, getHiddenColumnsByKey, t) => [
    {
        value: REPORT_COLUMN_PRESETS.GOLDEN_10,
        labelTranslationKey: "custom",
        iconKey: "verified",
        columns: getHiddenColumnsByKey(presets, "golden_10_hidden_columns"),
        description: <p>{t("description")}</p>,
    },
];

export const generateInvoicePresets = (presets, getHiddenColumnsByKey, t) => [
    {
        value: REPORT_COLUMN_PRESETS.SP_PANEL,
        labelTranslationKey: "sp_panel",
        iconKey: "verified",
        columns: getHiddenColumnsByKey(presets, "sp_panel_hidden_columns"),
        description: <p>{t("description")}</p>,
    },

    {
        value: REPORT_COLUMN_PRESETS.SALARY,
        labelTranslationKey: "salary",
        iconKey: "verified",
        columns: getHiddenColumnsByKey(presets, "salary_hidden_columns"),
        description: <p>{t("description")}</p>,
    },
    {
        value: REPORT_COLUMN_PRESETS.STATISTICS,
        labelTranslationKey: "statistics",
        iconKey: "verified",
        columns: getHiddenColumnsByKey(presets, "statistics_hidden_columns"),
        description: <p>{t("description")}</p>,
    },
    {
        value: REPORT_COLUMN_PRESETS.PAYMENT,
        labelTranslationKey: "payment",
        iconKey: "verified",
        columns: getHiddenColumnsByKey(presets, "payment_hidden_columns"),
        description: <p>{t("description")}</p>,
    },



];


