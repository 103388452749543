import axios from "./axios";

const api = {
	getCompanies(params) {
		const { type, ...restParams } = params;
		return axios
			.get(`/companies/${type}`, {
				params: restParams,
			})
			.then(({ data }) => data);
	},
};

export default api;
