import { useMutation, useQuery, useQueryClient } from "react-query";
import { CardApi } from "../../api";
import { callAll } from "../../utils";
import { fuelKeys } from "./fuel";

export const cardsKeys = {
	all: () => ["cards"],
	byId: (id) => [...cardsKeys.all(), id],
	byConfig: (config) => [...cardsKeys.all(), config],
};

export const useCards = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(cardsKeys.byConfig(custom), () => CardApi.getCards(custom), restConfig);
};

export const useCardsStatuses = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(["card_statuses"], () => CardApi.getCardsStatuses(custom), restConfig);
};

export const useCard = (id, config) => {
	const { custom, ...restConfig } = config;
	return useQuery(cardsKeys.byId(id), () => CardApi.getCardbyId(id, custom), restConfig);
};

export const useUpdateCard = (config) => {
	const queryClient = useQueryClient();
	return useMutation(({ id, data }) => CardApi.updateCard(id, data), {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(cardsKeys.all());
			queryClient.invalidateQueries(fuelKeys.all());
		}, config?.onSuccess),
	});
};

export const useCreateFuel = (config) => {
	const queryClient = useQueryClient();
	return useMutation(CardApi.createFuel, {
		...config,
		onSuccess: callAll(() => {
			queryClient.invalidateQueries(cardsKeys.all());
			queryClient.invalidateQueries(fuelKeys.all());
		}, config?.onSuccess),
	});
};
