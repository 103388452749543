import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDepoStaff } from "hooks";
import cn from "classnames";

import { FormSelect } from "../../components/forms";
import { Button } from "../../components";

const FuelForm = ({ onSubmit, onCancel, defaultValues }) => {
	const { t } = useTranslation();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({ defaultValues: defaultValues });

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
				<Controller
					render={({ field }) => (
						<FormSelect
							groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
								"v__form__group--incorrect": errors?.user_id,
							})}
							label={t("fuel_owner")}
							isAsync
							asyncSearch
							useFetch={useDepoStaff}
							isGrouped
							queryConfig={{
								custom: {
									page: 0,
									per_page: 10,
								},
							}}
							groupParse={{ label: "title", options: "staff" }}
							optionsParse={{ value: "id", label: "full_name" }}
							error={errors?.user_id && t(`${errors?.user_id?.type}`)}
							{...field}
						/>
					)}
					control={control}
					name={"user_id"}
					rules={{ required: false }}
				/>

				<div className='v__form__footer v__grid__item v__grid__item--col-12'>
					<Button bg status='darker' onClick={onCancel}>
						{t("cancel")}
					</Button>
					<Button bg status='accent' name='modalSubmit' type='submit'>
						{t("save")}
					</Button>
				</div>
			</form>
		</>
	);
};
//defaultValue={value?.user !== undefined ? { value: value.user.id, label: value.user.full_name } : ""}

export default FuelForm;
