import cn from "classnames";
import { forwardRef, useRef } from "react";
import { Calendar } from ".";
import { useMedia, useMediaQueries } from "../../hooks";
import { mergeRefs } from "../../utils";
import { DatePickerBtn } from "./DatePickerBtn";

const FormDatePicker = forwardRef(
	(
		{
			groupClassName,
			className,
			withLabel = true,
			label,
			name,
			id = name,
			small,
			maxDate = new Date(),
			children,
			error,
			isLoading,
			...rest
		},
		ref
	) => {
		const { LARGE } = useMediaQueries();
		const isLarge = useMedia(LARGE);

		const pickerRef = useRef();

		// console.log(pickerRef);

		return (
			<div className={cn("v__form__group z-10", groupClassName)}>
				<label htmlFor={id} className={cn("v__form__group__label", !withLabel && "v__form__group__label--invisible")}>
					<span>{label}</span>
				</label>
				<div className='v__date__picker'>
					{/*
						<Button
							label='previous-day'
							className='v__date__picker__btn v__date__picker__btn--prev'
							onClick={() => {
								// const ms = pickedDate.getTime() - 86400000;
								// setPickedDate(() => new Date(ms));
							}}
						>
							<Icon>arrow_back</Icon>
						</Button>
					<Button
						label='next-day'
						className='v__date__picker__btn v__date__picker__btn--next'
						onClick={() => {
							// const ms = pickedDate.getTime() + 86400000;
							// setPickedDate(() => new Date(ms));
						}}
					>
						<Icon>arrow_forward</Icon>
                    </Button>
                             *
                             */}
					{/* TODO cursor pointer */}
					<Calendar
						customInput={<DatePickerBtn loading={isLoading} />}
						showWeekNumbers={isLarge}
						inline={false}
						fixedHeight={false}
						maxDate={maxDate}
						{...rest}
						ref={mergeRefs([ref, pickerRef])}
					/>
				</div>
				{error && <small className='v__form__group__hint'>{error}</small>}
				{children}{" "}
			</div>
		);
	}
);

export default FormDatePicker;
