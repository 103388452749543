import axios from "./axios";

const api = {
	getPerformance(params) {
		return axios
			.get(`/daily-deliveries/performance`, {
				params,
			})
			.then(({ data }) => data);
	},
};

export default api;
