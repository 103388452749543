import { parseSelectedMonth } from "../../utils";
import CalendarNavigation from "./CalendarNavigation";

const CalendarHorizontalHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
	const parsedDate = parseSelectedMonth(date);
	return (
		<CalendarNavigation
			onPrev={decreaseMonth}
			onNext={increaseMonth}
			prevProps={{ disabled: prevMonthButtonDisabled }}
			nextProps={{ disabled: nextMonthButtonDisabled }}>
			<div className='v__calendar__full__nav__label'>{parsedDate}</div>
		</CalendarNavigation>
	);
};

export default CalendarHorizontalHeader;
