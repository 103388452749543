import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useRoutes } from "react-router-dom";

import { AppProvider, AuthProvider } from "./context";
import { useAppRoutes, useOnline } from "./hooks";

import { Toast } from "components/Toast";

import * as serviceWorker from "./serviceWorker";

const AppContent = () => {
	const routes = useAppRoutes();
	const elements = useRoutes(routes);

	useOnline();

	const [waitingWorker, setWaitingWorker] = useState(null);
	const onSWUpdate = (registration) => setWaitingWorker(registration.waiting);

	useEffect(() => {
		serviceWorker.register({ onUpdate: onSWUpdate });
	}, []);

	useEffect(() => {
		if (waitingWorker) {
			toast.custom((t) => <Toast.Reload t={t} />, { duration: Infinity });
		}
	}, [waitingWorker]);

	useEffect(() => {
		window.scrollTo({ top: 0 });
	});

	return elements;
};

const App = () => (
	<AuthProvider>
		<AppProvider>
			<AppContent />
		</AppProvider>
	</AuthProvider>
);

export default App;
