const VeniwayFullLogo = () => {
	return (
		<svg viewBox='0 0 404.12 79.07'>
			<path
				className='accent-wing'
				d='M18.86,36.59a8.73,8.73,0,0,1-8.22-8.71V10.72A30.67,30.67,0,0,1,33.13,26.4a26.779,26.779,0,0,1,1.13-2.72,39.11,39.11,0,0,1,3.86-6.24l-.28-.4A39.73,39.73,0,0,0,11.94,1.7L1.53.21V27.88a17.84,17.84,0,0,0,8.22,15l.57.34a31,31,0,0,1,8.54-6.63Z'
			/>
			<path
				className='rest-wings'
				d='M80.56,68.34a36.74,36.74,0,0,0-13.8-23.06,17.87,17.87,0,0,0,13.91-17.4V.21L70.26,1.7A39.89,39.89,0,0,0,36.64,35.32v.23h-.06V36l-.11.79-1.08.14C18,39.19,4.18,52.12,1.64,68.34L0,78.85H16A30.14,30.14,0,0,0,41.1,65.7,30.14,30.14,0,0,0,66.25,78.85h16Zm-9-57.62V27.88a8.75,8.75,0,0,1-8.74,8.74H45.65a30.66,30.66,0,0,1,25.91-25.9ZM16,69.74H10.64c1.92-12.29,12.53-22,25.9-23.81v4.88C36.55,61.25,27.31,69.74,16,69.74Zm50.3,0c-11.36,0-20.6-8.49-20.6-18.93V45.93c13.37,1.77,24,11.52,25.9,23.81Z'
			/>
			<path
				className='accent-wing'
				d='M41.1,12.36h0s2.6-3.36,4-4.82A52.25,52.25,0,0,1,55,0,126,126,0,0,1,41.5,1.36C37,1.36,27.21,0,27.21,0a52.25,52.25,0,0,1,9.84,7.54c1.45,1.46,4,4.82,4,4.82h0Z'
			/>
			<path className='characters' d='M121.88,49.19l8.35-27h12.56l-13.66,38.5H114.49l-13.66-38.5h12.56Z' />
			<path
				className='characters'
				d='M183.91,43.6H157.2q.21,4.35,2.21,6.24a7.12,7.12,0,0,0,5.11,1.9,7,7,0,0,0,4.35-1.31,6.12,6.12,0,0,0,2.28-3.38h12.49a16.32,16.32,0,0,1-3.31,7.28,17.64,17.64,0,0,1-6.49,5,22.15,22.15,0,0,1-18.8-.55A17,17,0,0,1,148.17,52a21.3,21.3,0,0,1-2.48-10.49,21.49,21.49,0,0,1,2.45-10.52A16.78,16.78,0,0,1,155,24.11a22.66,22.66,0,0,1,20.15,0,16.58,16.58,0,0,1,6.73,6.59,19.62,19.62,0,0,1,2.38,9.77,16.388,16.388,0,0,1-.35,3.13ZM170.28,32.94a7.5,7.5,0,0,0-5.21-1.9A7.73,7.73,0,0,0,159.69,33a8.09,8.09,0,0,0-2.41,5.66h15Q172.38,34.84,170.28,32.94Z'
			/>
			<path
				className='characters'
				d='M225.35,26.21q3.9,4.42,3.9,12.14V60.71h-11.8v-21a8.49,8.49,0,0,0-2-6A7.07,7.07,0,0,0,210,31.53a7.37,7.37,0,0,0-5.69,2.28,9.21,9.21,0,0,0-2.1,6.49V60.71h-11.8V22.21h11.8v7a12.7,12.7,0,0,1,4.93-5.45,14.47,14.47,0,0,1,7.69-2,13.33,13.33,0,0,1,10.52,4.45Z'
			/>
			<path
				className='characters'
				d='M237.87,6.92a8.29,8.29,0,0,1,10.21,0A5.76,5.76,0,0,1,250,11.38a5.68,5.68,0,0,1-1.93,4.38,8.29,8.29,0,0,1-10.21,0,5.68,5.68,0,0,1-1.93-4.38,5.76,5.76,0,0,1,1.94-4.46Zm11,15.29v38.5H237V22.21Z'
			/>
			<path
				className='characters'
				d='M313.29,22.21l-9.87,38.5H290.24l-7-25.81-7,25.81H263.06l-9.87-38.5H265l5.11,27.74,7.18-27.74h12.56L297.21,50l5.11-27.81Z'
			/>
			<path
				className='characters'
				d='M339.79,23.73a12.05,12.05,0,0,1,4.69,5.45v-7h11.73v38.5H344.48v-7a12,12,0,0,1-4.69,5.45,13.94,13.94,0,0,1-7.59,2,15.6,15.6,0,0,1-8.52-2.38A16.28,16.28,0,0,1,317.74,52a23.78,23.78,0,0,1-2.17-10.49,23.73,23.73,0,0,1,2.17-10.52,16.31,16.31,0,0,1,5.93-6.83,15.59,15.59,0,0,1,8.52-2.38,14,14,0,0,1,7.6,1.95Zm-9.9,10.83a9.74,9.74,0,0,0-2.31,6.9,9.73,9.73,0,0,0,2.31,6.9,8.66,8.66,0,0,0,12.21-.07,9.61,9.61,0,0,0,2.38-6.83,9.59,9.59,0,0,0-2.38-6.87,8.729,8.729,0,0,0-12.22-.03Z'
			/>
			<path className='characters' d='M373.25,22.21l9.25,23.6,8.62-23.6h13l-24,56.86h-13l9-19.8L360.07,22.21Z' />
		</svg>
	);
};

export default VeniwayFullLogo;
