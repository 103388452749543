import { Children, cloneElement, forwardRef, isValidElement, useCallback, useEffect, useMemo, useRef } from "react";
import { useVirtual } from "react-virtual";
import Select, { components, createFilter } from "react-select";
import PropTypes from "prop-types";
import cn from "classnames";

import { mergeRefs } from "../../utils";

const flattenGroupedChildren = (children) => {
	return children.reduce((result, child) => {
		const {
			props: { children: nestedChildren = [] },
		} = child;
		const nestedChildrenArr = Array.isArray(nestedChildren) ? nestedChildren : [];
		const nestedChildObj =
			typeof nestedChildren === "object" && !Array.isArray(nestedChildren) ? nestedChildren : null;

		return [...result, cloneElement(child, { type: "group" }, []), ...nestedChildrenArr, nestedChildObj].filter(
			(v) => v
		);
	}, []);
};

const customStyles = {
	menuList: (provided) => ({
		...provided,
		padding: 0,
	}),
};

const MenuList = (props) => {
	const parentRef = useRef();

	const children = useMemo(() => {
		const children = Children.toArray(props.children);

		const head = children[0] || {};

		if (isValidElement(head)) {
			const { props: { data: { options = [] } = {} } = {} } = head;
			const groupedChildrenLength = options.length;
			const isGrouped = groupedChildrenLength > 0;
			const flattenedChildren = isGrouped && flattenGroupedChildren(children);

			return isGrouped ? flattenedChildren : children;
		} else {
			return [];
		}
	}, [props.children]);

	const rowVirtualizer = useVirtual({
		size: children.length,
		estimateSize: useCallback(() => 30, []),
		parentRef,
	});

	const selectedIndex = useMemo(
		() =>
			Math.max(
				children.findIndex((child) => child.props?.isSelected),
				0
			),
		[children]
	);

	useEffect(() => {
		if (selectedIndex) {
			rowVirtualizer.scrollToIndex(selectedIndex);
		}
		// Scroll to selected option only on mount
		//eslint-disable-next-line
	}, []);

	return (
		<components.MenuList {...props} innerRef={mergeRefs([props.innerRef, parentRef])}>
			<div
				style={{
					height: `${rowVirtualizer.totalSize}px`,
				}}
			>
				{rowVirtualizer.virtualItems.map((virtualRow) => {
					const child = children[virtualRow.index];
					return (
						<div
							key={virtualRow.index}
							ref={virtualRow.measureRef}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: `${child}px`,
								transform: `translateY(${virtualRow.start}px)`,
							}}
						>
							{child}
						</div>
					);
				})}
			</div>
		</components.MenuList>
	);
};

const VirtualSelect = forwardRef((props, ref) => {
	const { small, light, className, ...rest } = props;
	return (
		<Select
			className={cn(
				"v__select",
				{
					"v__select--sm": small && !light,
					"v__select--light": !small && light,
					"v__select--light--sm": small && light,
				},
				className
			)}
			classNamePrefix='v__select'
			components={{ MenuList }}
			styles={customStyles}
			filterOption={createFilter({ ignoreAccents: false })}
			menuShouldScrollIntoView
			tabSelectsValue={false}
			menuPlacement='auto'
			{...rest}
			ref={ref}
		/>
	);
});

VirtualSelect.propTypes = {
	small: PropTypes.bool,
	light: PropTypes.bool,
};

export default VirtualSelect;
