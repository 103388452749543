import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { Step, Stepper } from "react-form-stepper";
import { useEffect, useMemo, useState } from "react";
import { Button, Card } from "components";
import { Controller, useForm } from "react-hook-form";
import { FormSelect } from "components/forms";
import { useDepoStaff, useSnumbers } from "hooks";
import cn from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import { useInvoiceManager, useUpdateInvoiceManager } from "hooks/query/invoiceManager";
import { useAuth } from "context";
import { InvoiceManagerProfitEditModal, InvoiceManagerToursTable } from "modules/invoice-manager";


const maxStep = 1;

const InvoiceManagerEdit = () => {
    const { t } = useTranslation();


    const { id } = useParams();

    const { user } = useAuth();

    const { data: invoice } = useInvoiceManager(id, {
        enabled: Boolean(id),
    });

    const { mutate: update } = useUpdateInvoiceManager({
    });

    const [modal, setModal] = useState({
        state: false,
        payload: null,
    });

    const closeModal = () => {
        setModal({ state: false, view: false, payload: null });
    };


    const invoiceData = invoice?.invoice_manager;
    const defaultValues = useMemo(() => {

        const values = { adjustments: [] };

        if (invoiceData && invoiceData?.adjustments) {
            invoiceData.adjustments.forEach((item) => {
                values["adjustments"][item.id] = [];
                values["adjustments"][item.id]["user"] = [];
                values["adjustments"][item.id]["snumbers"] = [];

                if (item.snumbers) {
                    item.snumbers.forEach((snumber) => {
                        values["adjustments"][item.id]["snumbers"].push(snumber);
                    });
                }

                if (item.users) {
                    item.users.forEach((user) => {
                        values["adjustments"][item.id]["user"].push(user);
                    });
                }

            });
        }

        return values;

    }, [invoiceData]);



    const [step, setStep] = useState(0);

    const navigate = useNavigate();


    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues });

    useEffect(() => {
        if (defaultValues.adjustments.length > 0) {
            reset(defaultValues);
        }

    }, [defaultValues, reset]);


    const handleOnBack = () => setStep((prev) => prev - 1);

    const handleOnSubmit = (data) => {
        if (step === 0) {
            console.log(data);
            update({ id: id, data });
            //submit the adjustments and wait for response for next
        }
        if (step === maxStep) {
            navigate(`/app/invoice-manager`);
        }

        setStep((prev) => prev + 1);
    };

    return (
        <PageWrapper>
            <Helmet>
                <title>Veniway | Invoice Manager Edit</title>
            </Helmet>
            <PageHeader title={t("invoices")}></PageHeader>
            <PageContent>
                <div className='w-full h-15'>
                    <Stepper
                        activeStep={step}
                        styleConfig={{
                            activeBgColor: "#2b7cff",
                            activeTextColor: "#fff",
                            inactiveBgColor: "#444",
                            inactiveTextColor: "#fff",
                            completedBgColor: "#2b7cff",
                            completedTextColor: "#fff",
                            size: "2em",
                        }}
                    >
                        <Step label='Adjustments' />
                        <Step label='Tours' inactiveBgColor='#000' />
                    </Stepper>
                </div>


                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    {step === 0 && invoice && (
                        <>
                            {/*<GenerateSNumberFields user={user} snumbers={invoice?.unknown_snumbers} t={t} errors={errors} control={control} /> */}

                            <div className='v__grid__item v__grid__item--col-12 mb-2 mt-2'>
                                <hr />
                            </div>
                            <label htmlFor={"group-options"} className={`v__form__group__label text-xl my-2 pl-3`}>
                                <span>{t("unrecognizedAdjustments")}</span>
                            </label>
                            <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                                <hr />
                            </div>

                            <GenerateAdjustmentFields user={user} getValues={getValues} adjustments={invoice?.invoice_manager.adjustments} t={t} errors={errors} control={control} />

                        </>
                    )}
                    {step === 1 && (
                        <>
                            <InvoiceManagerToursTable
                                invoice_manager_id={id}
                                setModal={setModal}
                            />

                        </>
                    )}

                    {step === 2 && (
                        <>
                        </>
                    )}
                    <div className='v__form__footer v__grid__item v__grid__item--col-12 !justify-center'>
                        {step > 0 && (
                            <Button bg status='danger' className='!mr-5' onClick={handleOnBack}>
                                {t("back")}
                            </Button>
                        )}
                        <Button bg status='accent' className='!ml-0' type='submit'>
                            {step === maxStep ? t("save") : t("continue")}
                        </Button>
                    </div>
                </form>

                {modal.state && <InvoiceManagerProfitEditModal payload={modal.payload} view={modal.view} onClose={closeModal} />}
            </PageContent>
        </PageWrapper >
    );
};


const GenerateAdjustmentFields = ({ adjustments, t, errors, control, getValues }) => {


    return adjustments.map((adjustment) => {
        return (
            <div key={Math.random()}>

                <Card className='v__modal v__card--overflow-able' header={"Adjustment"}>
                    <div className='v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12 mt-5 md:mt-2 invoice__manager__adjustments'>
                        <p className='text-sm'>Date: {adjustment.date}</p>
                        <p className='text-sm'>Price: {adjustment.price}</p>
                        <p className='text-sm'>Notes: {adjustment.notes}</p>
                    </div>


                    <Controller
                        render={({ field }) => {
                            return (
                                <FormSelect
                                    groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12 mt-5 md:mt-2 ")}
                                    label={t("snumber")}
                                    isAsync
                                    asyncSearch
                                    isMulti
                                    isClearable
                                    getValues={getValues}
                                    useFetch={useSnumbers}
                                    queryConfig={{
                                        custom: {
                                            page: 0,
                                            per_page: 25,
                                        },
                                    }}
                                    optionsParse={{ value: "id", label: "combined" }}
                                    {...field}
                                />
                            );
                        }}
                        control={control}
                        name={"adjustments[" + adjustment.id + "][snumbers]"}
                        key={"adjustment_" + adjustment.id + "_snumbers"}
                        rules={{ required: false }}
                    />

                    <div className="v__grid__item v__grid__item--col-12 my-5">
                        <hr />
                        <p className="text-center text-white  my-3 font-bold">OR</p>
                        <hr />
                    </div>

                    <Controller
                        render={({ field }) => (
                            <FormSelect
                                groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12 mt-5 md:mt-2 ", {
                                    "v__form__group--incorrect": errors?.user_id,
                                })}
                                label={t("chooseDriver")}
                                isAsync
                                asyncSearch
                                useFetch={useDepoStaff}
                                isGrouped
                                isClearable
                                isMulti
                                getValues={getValues}
                                queryConfig={{
                                    custom: {
                                        page: 0,
                                        per_page: 10,
                                    },
                                }}
                                groupParse={{ label: "title", options: "staff" }}
                                optionsParse={{ value: "id", label: "full_name" }}
                                error={errors?.user_id && t(`${errors?.user_id?.type}`)}
                                {...field}
                            />
                        )}
                        control={control}
                        name={"adjustments[" + adjustment.id + "][user]"}
                        key={"adjustment_" + adjustment.id + "_user"}
                        rules={{}}
                    />





                </Card>

                <div className='v__grid__item v__grid__item--col-12 mb-5 mt-2'>
                    <hr />
                </div>
            </div>
        );
    });
};

// const GenerateSNumberFields = ({ snumbers, t, errors, control, user }) => {
//     if (snumbers === undefined) {
//         return null; // Return nothing if data is null or an empty array
//     }
//
//     return snumbers.map((snumber) => {
//         return (
//
//             <Card className='v__modal v__card--overflow-able' header={t("generalInformation")}>
//                 <Controller
//                     render={({ field }) => (
//                         <FormSelect
//                             groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
//                                 "v__form__group--incorrect": errors?.s_number,
//                             })}
//                             label={t("chooseSNumber", { value: snumber })}
//                             isAsync
//                             asyncSearch
//                             useFetch={useDDR}
//                             isGrouped
//                             queryConfig={{
//                                 custom: {
//                                     page: 0,
//                                     per_page: 10,
//                                 },
//                             }}
//                             optionsParse={{ value: "id", label: "ddr_name" }}
//                             {...field}
//                         />
//                     )}
//                     control={control}
//                     name={"snumbers[" + snumber + "]"}
//                     defaultValue={null}
//                     key={"snumber_" + snumber}
//                     rules={{}}
//                 />
//             </Card>
//         );
//     });
// };
//
export default InvoiceManagerEdit;
