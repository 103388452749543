import { SnumberApi } from "api";
import { useQuery } from "react-query";

export const SnumberKeys = {
	all: () => ["snumber"],
	byConfig: (config) => [...SnumberKeys.all(), config],
};

export const useSnumbers = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(SnumberKeys.byConfig(custom), () => SnumberApi.getSnumbers(custom), restConfig);
};
