import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { Icon } from "../../../components";

const SidebarNavItem = ({ route: { path, icon, label, end = false } }) => {
	return (
		<div className='v__sidebar__scrollable__nav__item'>
			<NavLink
				to={path}
				end={end}
				className={({ isActive }) =>
					cn("v__sidebar__scrollable__nav__item__link", isActive && "v__sidebar__scrollable__nav__item__link--active")
				}
			>
				<div className='v__sidebar__scrollable__nav__item__link__icon__container'>
					<div className='v__sidebar__scrollable__nav__item__link__icon'>{icon ?? <Icon>arrow_forward</Icon>}</div>
				</div>
				<span>{label}</span>
			</NavLink>
		</div>
	);
};

export default SidebarNavItem;

SidebarNavItem.propTypes = {
	route: PropTypes.object,
};
