import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Icon } from "components";

const FormInput = forwardRef(
    ({ groupClassName, className, withLabel = true, label, type = "text", name, id = name, small, children, error, helperMessage, ...rest }, ref) => {

        const [tipVisibility, setTipVisibility] = useState(false);

        return (
            <div className={cn(`v__form__group`, groupClassName)}>
                <label htmlFor={id} className={cn("v__form__group__label", !withLabel && "v__form__group__label--invisible")}>
                    <span>{label}</span>
                    {helperMessage && (
                        <>
                            <Icon className={["text-base", "ml-1"]} onMouseLeave={() => setTipVisibility(false)} onMouseEnter={() => setTipVisibility(true)}>
                                help_outline
                            </Icon>
                            {tipVisibility && (
                                <div
                                    className='v__card__header__container__hint'
                                    style={{ top: "25px", left: "60px" }}
                                    dangerouslySetInnerHTML={{
                                        __html: helperMessage
                                    }}
                                />
                            )}
                        </>
                    )}

                </label>
                <input className={cn("v__input", small && "v__input--sm")} type={type} id={id} name={name} ref={ref} {...rest} />
                {error && <small className='v__form__group__hint'>{error}</small>}
                {children}
            </div>
        );
    });

FormInput.propTypes = {
    groupClassName: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    small: PropTypes.bool,
    label: PropTypes.string,
    helperMessage: PropTypes.string,
    withLabel: PropTypes.bool,
};

export default FormInput;
