import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

const ConnectionsCell = ({ cell }) => {
	const v = cell.row.original;

	return (
		<>
			<p className='v__table__body__row__cell__main'>
				{v.service_partners.map((item) => {
					const key = uuidv4();
					return (
						<Link key={key} to={`/app/partners/${item.id}`} className='text-accent-darker'>
							{item.title}
						</Link>
					);
				})}
			</p>
			<small>
				{v.joined_depos
					.map((item) => {
						const key = uuidv4();

						return (
							<Link key={key} to={`/app/depo/${item.id}`} className='sub-text sub-text-hoverable'>
								{item.title}
							</Link>
						);
					})
					.reduce((acc, el) => (acc === null ? [el] : [...acc, ", ", el]), null)}
			</small>
		</>
	);
};

export default ConnectionsCell;
