import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useProfileView } from "context/ViewContext";

import {
	useDepo,
	useGroup,
	useMedia,
	useMediaQueries,
	useReports,
	useReportsTableColumnHiding,
	useReportsTableFilteredColumnsValue,
	useReportsTableGenerate,
	useReportsTablePresetsGenerate,
	useServicePartner,
	useUser,
} from "hooks";

import { Button, Card, Icon, ReportsTable, NoDataRow, VirtualSelect } from "components";
import { SwitchGroup } from "components/forms";

import { generateReportsPresets, ReportsControls } from "pages";
import ReportsAdvancedFilters from "pages/Reports/ReportsAdvancedFilters";

import { objContainsAnyKeyWithValue, parseAndPreSelectPreset, parseClickedViewPageRowData, parsePreset } from "utils";
import { REPORT_COLUMN_PRESETS, REPORT_GROUP_BY_TYPES } from "reducers/const";
import { Badge } from "components/ui";
import { PrintAccountType, ReportsPrintViewPageFilterState } from "pages/Reports/ReportsPrintFilterState";

const ViewAllReports = ({ currentAccount, accountType }) => {
	const { t } = useTranslation();

	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const {
		reportHistoryValue: { reportHistory, setReportHistory },
	} = useProfileView();
	const removeFromStack = useCallback(() => setReportHistory((prev) => prev.slice(0, prev.length - 1)), [setReportHistory]);
	const addToStack = useCallback((data) => setReportHistory((prev) => prev.concat(data)), [setReportHistory]);

	useEffect(() => {
		// Setting initial entry to the stack
		if (reportHistory.length === 0) {
			addToStack({
				requestParams: {
					groupBy: REPORT_GROUP_BY_TYPES.MONTH,
					[accountType]: currentAccount?.id,
					date: undefined,
					week: undefined,
					month: undefined,
					year: new Date().getFullYear(),
					filter: null,
				},
			});
		}
	}, [currentAccount, accountType, addToStack, reportHistory.length]);

	// generating and getting all the report presets (extracted function)
	const presets = useReportsTablePresetsGenerate(generateReportsPresets);

	//Advanced filters state
	const [reportColFiltersModal, setReportColFiltersModal] = useState(false);

	// state for current column preset
	const [columnPreset, setColumnPreset] = useState();
	const dataType = columnPreset?.value === REPORT_COLUMN_PRESETS.FEEDBACK ? "feedback" : "reports";

	// using useLayoutEffect to set state before paint
	useLayoutEffect(() => {
		if (Boolean(presets.length) && !columnPreset) {
			// choose preselected column tab from REPORT_COLUMN_PRESETS
			setColumnPreset(() => parseAndPreSelectPreset(presets, REPORT_COLUMN_PRESETS.GOLDEN_5));
		}
	}, [presets, columnPreset]);

	// handling the clock on a non Link cell (open Quick View)
	const onRowClick = useCallback(
		(rowData) => {
			if (currentAccount?.id) {
				addToStack(parseClickedViewPageRowData(rowData, accountType, currentAccount?.id));
			}
		},
		[addToStack, accountType, currentAccount?.id]
	);
	const currentConfig = reportHistory[reportHistory.length - 1];
	const currentParams = currentConfig?.requestParams ?? {};

	const accountId = currentParams?.[accountType];
	const useFetch = {
		user_id: useUser,
		service_partner_id: useServicePartner,
		member_group_id: useGroup,
		depo_id: useDepo,
	};
	const { data: workingYears } = useFetch[accountType](accountId, {
		custom: {
			includes: ["workingYears"],
		},
		enabled: Boolean(accountId),
		select: (data) => {
			const years = data.data.workingYears?.sort();
			const allYears = new Set([...years, new Date().getFullYear()]);
			return [...allYears].map((x) => ({ value: x, label: x }));
		},
	});

	// generate Reports Table
	const {
		state: { setQueryParams },
		query: { data },
		tableProps,
	} = useReportsTableGenerate({
		useFetch: useReports,
		config: currentParams,
		dataType,
		onRowClick,
	});
	// parse filtered columns (Advanced filters)
	const advancedFilters = useReportsTableFilteredColumnsValue({ data });

	// load saved hidden columns for reports page
	const { onColumnHide, storedHiddenColumns } = useReportsTableColumnHiding({ columnPreset });

	// handle reports preset (change to page 1 if switching from reports to feedback)
	const onPresetChange = useCallback(
		(preset) => {
			setColumnPreset((prev) => {
				if (parsePreset(prev?.value) !== parsePreset(preset.value)) {
					setQueryParams((params) => ({ ...params, page: 1 }));
					return preset;
				}
				return preset;
			});
		},
		[setQueryParams]
	);

	// TODO: Render a loader
	if (!data || !columnPreset)
		return (
			<Card loading>
				<NoDataRow asDiv />
			</Card>
		);

	const tableExtraProps = {
		onColumnHide,
		tableHiddenColumns: storedHiddenColumns?.[columnPreset.value] ?? columnPreset.columns,
		filteredColumns: currentParams.filter,
		resetPageIndex: JSON.stringify({ dataType, state: currentParams }), // RESET PAGE
		rowHighlighting:
			dataType !== REPORT_COLUMN_PRESETS.FEEDBACK &&
			currentParams.groupBy !== REPORT_GROUP_BY_TYPES.USER_ID &&
			currentParams.groupBy !== REPORT_GROUP_BY_TYPES.SP_NAME,
		dark: true,
	};

	const advancedFiltersCount = currentParams.filter ? Object.keys(currentParams.filter)?.length : 0;

	const handleOnSwitchChange = (option) =>
		addToStack({
			requestParams: {
				...currentParams,
				...option,
			},
		});

	const showAdditionalGroupBySwitch =
		accountType !== "user_id" && objContainsAnyKeyWithValue(currentParams, "date", "week", "month", "year");
	const showGroupByValue = !objContainsAnyKeyWithValue(currentParams, "date", "week", "month", "year");

	const groupByOptions = [
		!objContainsAnyKeyWithValue(currentParams, "date") && {
			value: REPORT_GROUP_BY_TYPES.DAY,
			label: t("day"),
			secondaryLabel: t("group"),
		},
		{ value: REPORT_GROUP_BY_TYPES.USER_ID, label: t("driver"), secondaryLabel: t("group") },
		accountType !== "service_partner_id" && {
			value: REPORT_GROUP_BY_TYPES.SP_NAME,
			label: t("servicePartner"),
			secondaryLabel: t("group"),
		},
	].filter(Boolean);

	return (
		<div>
			<div className='flex justify-between items-center pb-2 xxl:flex-row flex-col space-y-2 xxl:space-y-0 print:hidden'>
				<div className='flex items-center space-x-2 w-full xxl:w-auto'>
					<TimePeriodGroupBySwitch value={showGroupByValue && currentParams.groupBy} onChange={handleOnSwitchChange} />
					{[currentParams.date, currentParams.week, currentParams.month, currentParams.year].filter(Boolean).length > 0 && (
						<Badge type='warning' size='md'>
							{currentParams.week && `${t("week")} `}
							{currentParams.month && `${t("month")} `}
							{[currentParams.date, currentParams.week, currentParams.month].filter(Boolean)}
							{currentParams.year &&
								`${[currentParams.week, currentParams.month].filter(Boolean).length > 0 ? ` | ` : ""}${currentParams.year}`}
						</Badge>
					)}
				</div>
				<div className='flex items-center flex-col lg:flex-row lg:space-y-0 space-y-2 lg:justify-between xxl:justify-end space-x-2 w-full'>
					{showAdditionalGroupBySwitch && (
						<AdditionalGroupBySwitch value={currentParams.groupBy} onChange={handleOnSwitchChange} options={groupByOptions} />
					)}
					<div className='inline-flex w-full lg:w-auto items-center space-x-2'>
						<Button
							small
							bg
							className='adv-filters shrink-0'
							status='warning'
							round={!isMedium}
							overflow
							onClick={() => setReportColFiltersModal(true)}
						>
							{Boolean(advancedFiltersCount) && <div className='btn-indicator'>{advancedFiltersCount}</div>}
							{isMedium ? t("advancedFilters") : <Icon>filter_list</Icon>}
						</Button>
						<VirtualSelect
							className='w-full lg:w-32'
							value={workingYears?.find(
								(x) => x.value === currentParams?.year || x.value === new Date(currentParams?.date).getFullYear()
							)}
							onChange={(option) => {
								addToStack({
									requestParams: {
										...currentParams,
										year: option.value,
									},
								});
							}}
							placeholder={t("allYears")}
							options={workingYears ?? []}
						/>
					</div>
				</div>
			</div>
			{reportHistory.length > 1 && (
				<div className='flex w-full justify-center lg:justify-end print:hidden'>
					<Button bg status='warning' iconLeft={<Icon>undo</Icon>} onClick={removeFromStack}>
						{t("goBack")}
					</Button>
				</div>
			)}

			<div className='hidden print:block mb-2 space-y-2'>
				<PrintAccountType currentAccount={currentAccount} accountType={accountType} />
				<ReportsPrintViewPageFilterState columnPreset={columnPreset} queryParams={currentParams} columns={tableProps?.columns} />
			</div>

			<ReportsTable
				{...tableProps}
				{...tableExtraProps}
				controlComponent={(props) => (
					<ReportsControls {...props} presets={presets} columnPreset={columnPreset} onPresetChange={onPresetChange} dark />
				)}
			/>

			{reportColFiltersModal && (
				<ReportsAdvancedFilters
					state={{ filter: advancedFilters }}
					onClose={() => setReportColFiltersModal(false)}
					onReset={() => {
						addToStack({
							requestParams: {
								...currentParams,
								filter: null,
							},
						});
						setReportColFiltersModal(false);
					}}
					onChange={(filter) => {
						addToStack({
							requestParams: {
								...currentParams,
								filter: Boolean(currentParams.filter) ? { ...currentParams.filter, ...filter } : filter,
							},
						});
						setReportColFiltersModal(false);
					}}
				/>
			)}
		</div>
	);
};

function TimePeriodGroupBySwitch({ value, onChange }) {
	const { t } = useTranslation();
	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const timePeriodReportTypeOptions = [
		{ value: REPORT_GROUP_BY_TYPES.DAY, label: t("daily") },
		{ value: REPORT_GROUP_BY_TYPES.WEEK, label: t("weekly") },
		{ value: REPORT_GROUP_BY_TYPES.MONTH, label: t("monthly") },
		{ value: REPORT_GROUP_BY_TYPES.YEAR, label: t("yearly") },
	];

	const activeOption = timePeriodReportTypeOptions.find((x) => x.value === value);

	return isMedium ? (
		<SwitchGroup
			id='timePeriodGroupBySwitch'
			activeOption={activeOption}
			onChange={(option) =>
				onChange?.({
					groupBy: option.value,
					date: undefined,
					week: undefined,
					month: undefined,
					year: undefined,
				})
			}
			options={timePeriodReportTypeOptions}
			className='xxl:!w-auto !w-full'
		/>
	) : (
		<VirtualSelect
			id='timePeriodGroupBySwitch'
			className='w-full'
			value={activeOption}
			onChange={(option) =>
				onChange?.({
					groupBy: option.value,
					date: undefined,
					week: undefined,
					month: undefined,
					year: undefined,
				})
			}
			options={timePeriodReportTypeOptions}
			placeholder={t("groupBy")}
		/>
	);
}

function AdditionalGroupBySwitch({ value, onChange, options }) {
	const { t } = useTranslation();
	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const activeOption = options.find((x) => x.value === value);
	return isMedium ? (
		<SwitchGroup
			id='additionalGroupBySwitch'
			activeOption={activeOption}
			onChange={(option) =>
				onChange?.({
					groupBy: option.value,
				})
			}
			options={options}
			className='w-full lg:w-auto'
		/>
	) : (
		<VirtualSelect
			id='additionalGroupBySwitch'
			className='w-full'
			value={activeOption}
			onChange={(option) =>
				onChange?.({
					groupBy: option.value,
				})
			}
			options={options}
			placeholder={t("additionalGroupBy")}
		/>
	);
}

export default ViewAllReports;
