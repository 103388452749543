import { useEffect } from "react";

const useOnline = () => {
	// TODO: implement in root
	// const toastId = useRef(null);

	useEffect(() => {
		const handleDismiss = () => {
			// toast.dismiss(toastId.current);
			// toastId.current = null;
		};

		const handleShowToast = () => {
			// if (!toastId.current) {
			// 	console.log("%c ⚠ Currently Offline", "font-size: 0.9rem; font-weight: 700; color: #d53b49;");
			// 	return (toastId.current = toast.warning(
			// 		<ToastContent icon={<Icon>warning</Icon>} msg={currently_offline[storedLocale ?? "en"]} />,
			// 		{ autoClose: false, closeButton: false, closeOnClick: false, position: "top-center" }
			// 	));
			// }
		};

		window.addEventListener("online", handleDismiss);
		window.addEventListener("offline", handleShowToast);

		return () => {
			window.removeEventListener("online", handleDismiss);
			window.removeEventListener("offline", handleShowToast);
		};
	}, []);
};

export default useOnline;
