import { useCallback, useState } from "react";
import { LoadScript, GoogleMap, MarkerClusterer, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

import { useApp } from "../../context";
import { ComingSoon } from "../../components/ui";

const darkMapStyles = [
	{
		featureType: "all",
		elementType: "geometry",
		stylers: [
			{
				color: "#202c3e",
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.fill",
		stylers: [
			{
				gamma: 0.01,
			},
			{
				lightness: 20,
			},
			{
				weight: "1.39",
			},
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.stroke",
		stylers: [
			{
				weight: "0.96",
			},
			{
				saturation: "9",
			},
			{
				visibility: "on",
			},
			{
				color: "#000000",
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [
			{
				lightness: 30,
			},
			{
				saturation: "9",
			},
			{
				color: "#29446b",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				saturation: 20,
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				lightness: 20,
			},
			{
				saturation: -20,
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [
			{
				lightness: 10,
			},
			{
				saturation: -30,
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#193a55",
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry.stroke",
		stylers: [
			{
				saturation: 25,
			},
			{
				lightness: 25,
			},
			{
				weight: "0.01",
			},
		],
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [
			{
				lightness: -20,
			},
		],
	},
];

const lightMapStyles = [
	{
		featureType: "administrative",
		elementType: "all",
		stylers: [
			{
				saturation: "-100",
			},
		],
	},
	{
		featureType: "administrative.province",
		elementType: "all",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "all",
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: 65,
			},
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "all",
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: "50",
			},
			{
				visibility: "simplified",
			},
		],
	},
	{
		featureType: "road",
		elementType: "all",
		stylers: [
			{
				saturation: "-100",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "all",
		stylers: [
			{
				visibility: "simplified",
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "all",
		stylers: [
			{
				lightness: "30",
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "all",
		stylers: [
			{
				lightness: "40",
			},
		],
	},
	{
		featureType: "transit",
		elementType: "all",
		stylers: [
			{
				saturation: -100,
			},
			{
				visibility: "simplified",
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				hue: "#ffff00",
			},
			{
				lightness: -25,
			},
			{
				saturation: -97,
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels",
		stylers: [
			{
				lightness: -25,
			},
			{
				saturation: -100,
			},
		],
	},
];

const IncomeChartBox = () => {
	const { t } = useTranslation();

	const { theme } = useApp();
	// TODO: use Tip component
	// const [tipVisibility, setTipVisibility] = useState(false);

	const [, setMap] = useState(null);
	const [center] = useState({
		lat: 51.5074,
		lng: -0.125,
	});

	const mapOptions = {
		zoomControl: false,
		mapTypeControl: false,
		scaleControl: false,
		streetViewControl: false,
		rotateControl: false,
		fullscreenControl: true,
		clickableIcons: false,
		styles: theme === "dark" ? darkMapStyles : lightMapStyles,
	};

	const clusterOptions = {
		imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
	};

	const locations = [];

	function createKey(location) {
		return location.lat + location.lng;
	}

	const onLoad = useCallback((map) => {
		// const bounds = new window.google.maps.LatLngBounds();
		// map.fitBounds(bounds);
		setMap(() => map);
	}, []);

	const onUnmount = useCallback((map) => {
		setMap(() => map);
	}, []);

	return (
		<div className={`v__card v__delivery__map body-no-pad`}>
			<div className={`v__card__header`}>
				<div className={`v__card__header__container`}>
					<span>
						{t("deliveryMapPoint")}
						{/*<i className="material-icons-outlined" onMouseLeave={() => setTipVisibility(false)}*/}
						{/*   onMouseEnter={() => setTipVisibility(true)}>info</i>*/}
						{/*{tipVisibility && <div className="v__card__header__container__hint"*/}
						{/*                       dangerouslySetInnerHTML={{__html: lang?.income_tip}}/>}*/}
					</span>
				</div>
			</div>
			<div className='v__card__body'>
				{process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
					<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
						<GoogleMap
							mapContainerClassName='v__map'
							center={center}
							zoom={10}
							onLoad={onLoad}
							onUnmount={onUnmount}
							options={mapOptions}
						>
							<MarkerClusterer options={clusterOptions}>
								{(clusterer) =>
									locations.map((location) => (
										<Marker key={createKey(location)} position={location} clusterer={clusterer} />
									))
								}
							</MarkerClusterer>
						</GoogleMap>
					</LoadScript>
				) : (
					<ComingSoon />
				)}
			</div>
		</div>
	);
};

export default IncomeChartBox;
