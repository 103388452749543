import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Button, Icon, Portal } from "..";
import CalendarNavigation from "./CalendarNavigation";

import { REPORT_PERIOD_TYPES, REPORT_SELECTION_TYPES } from "reducers/const";

const monthKeys = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

const getWeeksInMonth = (month, year) => {
    let weeks = [];

    const firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate();

    let start = 1;
    let end = 7 - firstDate.getDay();
    while (start <= numDays) {
        weeks.push({ start: start, end: end });
        start = end + 1;
        end = end + 7;
        if (end > numDays) end = numDays;
    }
    return weeks;
};

const getCalendar = (specs = new Date().getFullYear()) => {
    let months = [];
    let week_number = 1;

    for (let i = 0; i < 12; i++) {
        const weeks = [];
        const month_weeks = getWeeksInMonth(i, specs);

        // eslint-disable-next-line no-loop-func
        month_weeks.forEach((week) => {
            const start = week["start"];
            const end = week["end"];

            if (end - start >= 3) {
                weeks.push({ week: week_number });
                week_number++;
            }
        });

        months.push({
            month_number: i + 1,
            name_key: monthKeys[i],
            weeks,
        });
    }

    return months;
};

const MonthWeekPicker = ({ navRoot, onChange, selected, files, reportType, withoutAll }) => {
    const { t } = useTranslation();

    const selectedTimeRef = useRef();
    const [times, setTimes] = useState([]);

    const yearsWithFiles = useMemo(() => {
        if (!files || files.length === 0) return [new Date().getFullYear()];
        const years = files.reduce((prev, curr) => {
            const extractedYear = new Date(curr).getFullYear();
            return [...prev, extractedYear];
        }, []);
        const allYears = [...years, new Date().getFullYear()];
        return [...new Set(allYears)];
    }, [files]);

    useEffect(() => {
        if (yearsWithFiles.length > 0) {
            setTimes(() => getCalendar(selected?.year));
        }
    }, [yearsWithFiles, selected?.year]);

    useEffect(() => {
        const selectedTime = selectedTimeRef.current;
        if (selectedTime) {
            selectedTime.scrollIntoView({
                block: "nearest",
                inline: "center",
            });
        }
    }, [files, selected]);

    let allYearsWithFiles = [...yearsWithFiles, REPORT_SELECTION_TYPES.ALL];

    if (withoutAll !== undefined && withoutAll) {
        allYearsWithFiles = [...yearsWithFiles];
    }

    return (
        <>
            <Portal element={navRoot}>
                <CalendarNavigation
                    onPrev={() => onChange?.({ type: REPORT_SELECTION_TYPES.YEAR, payload: selected?.year - 1 })}
                    onNext={() => onChange?.({ type: REPORT_SELECTION_TYPES.YEAR, payload: selected?.year + 1 })}
                    prevProps={{ disabled: selected?.year === yearsWithFiles[0] || !selected?.year }}
                    nextProps={{
                        disabled: selected?.year === yearsWithFiles[yearsWithFiles.length - 1] || !selected?.year,
                    }}
                    prevLabel={t("year")}
                    nextLabel={t("year")}
                >
                    <div className='v__calendar__full__nav__years'>
                        {allYearsWithFiles.map((option) => {
                            if (option === selected?.year || (option === REPORT_SELECTION_TYPES.ALL && selected.all)) {
                                return (
                                    <div
                                        key={option}
                                        className='v__calendar__full__nav__year v__calendar__full__nav__year--selected'
                                        onClick={() =>
                                            onChange?.(
                                                option === REPORT_SELECTION_TYPES.ALL
                                                    ? { type: REPORT_SELECTION_TYPES.ALL }
                                                    : { type: REPORT_SELECTION_TYPES.YEAR, payload: option }
                                            )
                                        }
                                        ref={selectedTimeRef}
                                    >
                                        {option}
                                    </div>
                                );
                            }
                            return (
                                <div
                                    key={option}
                                    className='v__calendar__full__nav__year'
                                    onClick={() =>
                                        onChange?.(
                                            option === REPORT_SELECTION_TYPES.ALL
                                                ? { type: REPORT_SELECTION_TYPES.ALL }
                                                : { type: REPORT_SELECTION_TYPES.YEAR, payload: option }
                                        )
                                    }
                                >
                                    {option}
                                </div>
                            );
                        })}
                    </div>
                </CalendarNavigation>
            </Portal>
            {reportType.value === REPORT_PERIOD_TYPES.PERIOD && Boolean(selected?.year) && (
                <div className='v__month__week__picker__container'>
                    <div className='v__month__week__picker'>
                        {times?.map((time) => {
                            const d = new Date();
                            const isDisabledMonth = selected?.year === d.getFullYear() && time.month_number > d.getMonth() + 1;
                            return (
                                <div
                                    className={cn("v__month__week__picker__month", {
                                        disabled: isDisabledMonth,
                                    })}
                                    key={time.month_number}
                                >
                                    <div
                                        className={cn(
                                            "v__month__week__picker__month__item",
                                            selected?.month === time.month_number && "v__month__week__picker__month__item--active"
                                        )}
                                    >
                                        {selected?.month === time.month_number ? (
                                            <div className='v__month__week__picker__month__item__label' ref={selectedTimeRef}>
                                                <Icon>calendar_today</Icon>
                                                <span>{t(`${time.name_key}`)}</span>
                                            </div>
                                        ) : (
                                            <div
                                                className='v__month__week__picker__month__item__label'
                                                onClick={() =>
                                                    onChange?.({
                                                        type: REPORT_SELECTION_TYPES.MONTH,
                                                        payload: time.month_number,
                                                    })
                                                }
                                            >
                                                <Icon>calendar_today</Icon>
                                                <span>{t(`${time.name_key}`)}</span>
                                            </div>
                                        )}
                                        <div className='v__month__week__picker__weeks'>
                                            {time.weeks.map(({ week }, i) => (
                                                <div
                                                    key={week}
                                                    className='v__month__week__picker__weeks__item'
                                                    onClick={() => onChange?.({ type: REPORT_SELECTION_TYPES.WEEK, payload: week })}
                                                >
                                                    {selected?.week === week ? (
                                                        <Button small bg status='accent' ref={selectedTimeRef}>
                                                            {week}
                                                        </Button>
                                                    ) : (
                                                        <Button small bg>
                                                            {week}
                                                        </Button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default MonthWeekPicker;
