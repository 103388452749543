import { ActionsCell, Button, Icon, StatisticsTable } from "components";
import { useDataTableGenerate } from "hooks";
import { useDeleteInvoiceAdjustmentRelations } from "hooks/query/invoiceManager";
import { useInvoicesAdjustmentsPreview } from "hooks/query/invoices";
import { useCallback } from "react";

const InvoiceShowAdjustmentsTable = ({ week, user_id, year }) => {


    const { mutate: deleteAdjustmentRelations } = useDeleteInvoiceAdjustmentRelations();


    const columns = useCallback((columns) => {
        return columns.map((col) => {

            const { title, accessor, disableSortBy, field, type, ...rest } = col;

            const columnProps = {
                Header: title,
                accessor,
                disableSortBy,
                id: field,
                sortDescFirst: true,
                icon: type,
                ...rest,
            };


            if (col.accessor === "adjustments_actions") {
                return {
                    ...columnProps,
                    Cell: ({ value, row }) => {
                        if (row.original.id === "TOT" || row.original.id === "AVG") {
                            return null;
                        }
                        return (
                            <ActionsCell>
                                <Button small bg status='danger' hoverStatus='danger' onClick={() => deleteAdjustmentRelations(row.original?.id)} >
                                    <Icon style={{ "fontFamily": "Material Icons Outlined" }} >delete</Icon>
                                </Button>

                            </ActionsCell>
                        );
                    },
                };
            }


            return columnProps;
        });
    }, [deleteAdjustmentRelations]);



    //how to fire the query when the step is the right one
    const { tableProps } = useDataTableGenerate(useInvoicesAdjustmentsPreview, columns, {
        user_id,
        week,
        year,
    });


    return (
        <StatisticsTable {...tableProps} scrollToTopOnChange dark />
    );

};

export default InvoiceShowAdjustmentsTable;
