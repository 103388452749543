import PropTypes from "prop-types";
import cn from "classnames";

import { Icon } from "..";

const Avatar = ({ user = null, imgUrl = null, className, ...rest }) => {
	const parseAvatarURL = (url) => {
		if (typeof url === "string") {
			return url;
		} else if (url?.encoded) {
			return url?.encoded;
		}
		return null;
	};

	return (
		<>
			{user || imgUrl ? (
				<img src={parseAvatarURL(user?.avatar) ?? imgUrl} loading='lazy' alt='' className={cn(className)} {...rest} />
			) : (
				<Icon className={cn(`v__icon__avatar`, className)} {...rest}>
					account_circle
				</Icon>
			)}
		</>
	);
};

Avatar.propTypes = {
	user: PropTypes.object,
	classes: PropTypes.string,
};

export default Avatar;
