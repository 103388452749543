import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import cn from "classnames";

import { useLogin } from "../../hooks";
import { useAuth } from "../../context";

import { Button, Card, Icon } from "../../components";
import { FormInput } from "../../components/forms";
import { Spinner } from "../../components/ui";

import { emailRegEx } from "../../utils";

const Login = () => {
	const { t } = useTranslation();

	const { setToken } = useAuth();

	const [visiblePass, setVisiblePass] = useState(false);

	const {
		control,
		handleSubmit,
		resetField,
		formState: { errors },
	} = useForm();

	const { mutate: login, isLoading } = useLogin({
		onSuccess: (res) => setToken(res.access_token),
		onError: (err) => {
			resetField("password");
			toast.error("Wrong username or password");
		},
	});

	const onSubmit = (data) => login(data);

	return (
		<>
			<Helmet>
				<title>Veniway | {t("login")}</title>
			</Helmet>
			<Card className='auth__form__card'>
				<h1 className='v__heading'>{t("login")}</h1>
				{/* {!!matchedAccount && !isRegistering && <p className='v__claimed__hint'>{lang?.account_already_claimed}</p>} */}
				<form onSubmit={handleSubmit(onSubmit)} className='v__grid v__auth__form'>
					<Controller
						render={({ field }) => (
							<FormInput
								label={t("email")}
								groupClassName={cn("v__grid__item v__grid__item--col-12", {
									"v__form__group--incorrect": errors?.username,
								})}
								type='email'
								error={errors?.username && t(`${errors?.username?.type}`)}
								{...field}
							/>
						)}
						control={control}
						name='username'
						defaultValue=''
						rules={{ required: true, pattern: emailRegEx }}
					/>
					<Controller
						render={({ field }) => (
							<div
								className={cn("v__form__group v__grid__item v__grid__item--col-12", {
									"v__form__group--incorrect": errors?.password,
								})}
							>
								<label htmlFor='password' className='v__form__group__label'>
									<span>{t("password")}</span>
								</label>
								<div className='v__input__group'>
									<input className='v__input' type={visiblePass ? "text" : "password"} id='password' {...field} />
									<Button
										spongy={false}
										round
										small
										className='v__input__group__add-on'
										onClick={() => setVisiblePass(!visiblePass)}
									>
										{visiblePass ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
									</Button>
								</div>
								{errors?.password && <small className='v__form__group__hint'>{t(`${errors?.password?.type}`)}</small>}
							</div>
						)}
						control={control}
						name='password'
						defaultValue=''
						rules={{ required: true }}
					/>

					<div className='v__auth__form__footer v__grid__item v__grid__item--col-12'>
						<Button
							bg
							status='accent'
							type='submit'
							className='v__submit__btn'
							iconRight={isLoading ? <Spinner size={16} singleColor /> : null}
						>
							{t("login")}
						</Button>
						<span>
							{t("noAccount")} <Link to='/register'>{t("register")}</Link>
						</span>
					</div>
				</form>
			</Card>
		</>
	);
};

export default Login;
