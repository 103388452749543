import { useQuery } from "react-query";
import { CompaniesApi } from "../../api";

export const companiesKeys = {
	all: () => ["companies"],
	byType: (type) => [...companiesKeys.all(), type],
	byIncludes: (includes) => [...companiesKeys.all(), includes],
	byTypeIncludes: (type, includes) => [...companiesKeys.byType(type), ...companiesKeys.byIncludes(includes)],
	byConfig: (config) => [...companiesKeys.all(), config],
};

export const useCompanies = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(companiesKeys.byConfig(custom), () => CompaniesApi.getCompanies(custom), restConfig);
};
