import { useEffect, useRef } from "react";
import FocusTrap from "focus-trap-react";

import { Portal } from "..";
import { useKeyPress } from "../../hooks";
import { setModalStyles, unsetModalStyles } from "../../utils";

const useModalClose = (cb = () => {}) => {
	const ref = useRef();
	const onClick = ({ target }) => {
		if (target === ref.current) {
			cb();
		}
	};
	return { onClick, ref };
};

const Modal = ({ onClose, withFocus = true, children }) => {
	useKeyPress(27, () => onClose?.());

	useEffect(() => {
		setModalStyles();
		return () => {
			unsetModalStyles();
		};
	}, []);

	const modalProps = useModalClose(onClose);

	return (
		<Portal>
			<FocusTrap active={withFocus}>
				<div className='v__modal__overlay'>
					<div className='v__modal__overlay__inner' {...modalProps}>
						{children}
					</div>
				</div>
			</FocusTrap>
		</Portal>
	);
};

export default Modal;
