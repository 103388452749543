import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { enGB } from "date-fns/locale";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import CalendarHeaderNavigation from "./CalendarHeaderNavigation";

const Calendar = forwardRef((props, ref) => {
	const { i18n } = useTranslation();

	registerLocale("en-US", enGB);

	return (
		<ReactDatePicker
			renderCustomHeader={(headerProps) => <CalendarHeaderNavigation {...headerProps} />}
			renderDayContents={(day) => <div className='react-datepicker__day__content'>{day}</div>}
			showPopperArrow={false}
			maxDate={props.maxDate}
			locale={i18n.language}
			inline
			showWeekNumbers
			fixedHeight
			{...props}
			ref={ref}
		/>
	);
});

export default Calendar;
