import cn from "classnames";

import { useApp } from "../../context";

const Container = ({ children, ...rest }) => {
	const { fluidContainer } = useApp();

	return (
		<div
			className={cn("container", {
				"container--fluid": fluidContainer,
			})}
			{...rest}>
			{children}
		</div>
	);
};

export default Container;
