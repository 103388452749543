import { useMutation, useQuery, useQueryClient } from "react-query";
import { ReportsApi } from "api";
import { callAll, parseReportTableAdvancedFilters } from "utils";

export const reportKeys = {
	all: () => ["reports"],
	settings: () => [...reportKeys.all(), "settings"],
	hiddenColumns: () => [...reportKeys.all(), "hiddenColumns"],
	pigments: () => [...reportKeys.settings(), "pigments"],
	byConfig: (config) => [...reportKeys.all(), config],
	byType: (type) => [...reportKeys.all(), type],
	byTypeAndConfig: (type, config) => [...reportKeys.byType(type), config],
};

export const useReportPigments = (config) => {
	return useQuery(reportKeys.pigments(), ReportsApi.getPigments, config);
};

export const useReportHiddenColumns = (config) => {
	return useQuery(reportKeys.hiddenColumns(), ReportsApi.getReportsHiddenColumns, config);
};

export const useStoreReportPigments = (config) => {
	const queryClient = useQueryClient();

	return useMutation(async (data) => ReportsApi.storePigments(data), {
		...config,
		onSuccess: callAll(() => queryClient.invalidateQueries(reportKeys.pigments()), config?.onSuccess),
	});
};

export const useReportDates = (config) => {
	const { custom, ...restConfig } = config;
	return useQuery(reportKeys.byConfig(custom), async () => ReportsApi.getReportDates(custom), restConfig);
};

export const useReports = (config) => {
	const {
		custom: { dataType, ...custom },
		...restConfig
	} = config;

	const apiRequestType = {
		reports: ReportsApi.getReports,
		feedback: ReportsApi.getFeedback,
		fbr: ReportsApi.getFBR,
		sp_fbr: ReportsApi.getSPFBR,
		fuel: ReportsApi.getFuel,
		invoices: ReportsApi.getInvoices,
	};

	const apiCall = apiRequestType[dataType] ?? ReportsApi.getReports;

	return useQuery(
		reportKeys.byTypeAndConfig(dataType, custom),
		() => apiCall({ ...custom, filter: parseReportTableAdvancedFilters(custom?.filter) }),
		restConfig
	);
};

export const useUploadReport = (config) => {
	const queryClient = useQueryClient();

	return useMutation(async (data) => ReportsApi.uploadReport(data), {
		...config,
		onSuccess: callAll(() => queryClient.invalidateQueries(reportKeys.all()), config?.onSuccess),
	});
};
