import cn from "classnames";
import { Icon } from "components";

const Tip = ({ message, icon, className }) => {
	return (
		<div className={cn("relative group", className)}>
			<Icon className='text-lg'>{icon}</Icon>
			<div className='v__card__header__container__hint group-hover:opacity-100 opacity-0'>{message}</div>
		</div>
	);
};

export default Tip;
