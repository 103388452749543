export const REPORTS_ACTION_TYPES = Object.freeze({
	TIME_SELECTION_SET: "TIME_SELECTION_SET",
	REPORT_TYPE_SET: "REPORT_TYPE_SET",
	GROUP_BY_SET: "GROUP_BY_SET",
	DEPO_SET: "DEPO_SET",
	SP_SET: "SP_SET",
	GROUP_SET: "GROUP_SET",
	USER_SET: "USER_SET",
	ADVANCED_FILTER_OPTIONS_SET: "ADVANCED_FILTER_OPTIONS_SET",
	ADVANCED_FILTER_VALUES_SET: "ADVANCED_FILTER_VALUES_SET",
	SET_FILTERS_MANUALLY: "SET_FILTERS_MANUALLY",
});

export const REPORT_PERIOD_TYPES = Object.freeze({
	DAILY: "daily",
	PERIOD: "period",
	YEARLY_DAY: "yearly/day_group",
	YEARLY_WEEK: "yearly/week_group",
	YEARLY_MONTH: "yearly/month_group",
});

export const REPORT_GROUP_BY_TYPES = Object.freeze({
	USER_ID: "user_id",
	SP_NAME: "service_partner_name",
	GROUP_ID: "member_group_id",
	ROUTE_ID: "route_id",
	DEPO_ID: "depo_id",
	DAY: "day_group",
	WEEK: "week_group",
	MONTH: "month_group",
	YEAR: "year",
});

export const FUEL_GROUP_BY_TYPES = Object.freeze({
	USER_ID: "user_id",
	SP_NAME: "service_partner_name",
	GROUP_ID: "member_group_id",
	DEPO_ID: "depo_id",
	CARD_ID: "cards.id",
	STATEMENT_ID: "fuels.id",
	DAY: "day_group",
	WEEK: "week_group",
	MONTH: "month_group",
	YEAR: "year",
});

export const INVOICE_GROUP_BY_TYPES = Object.freeze({
	USER_ID: "user_id",
	INVOICE_ID: "invoices.id",
	DATA_ID: "invoice_data.id",
	DAY: "day_group",
	WEEK: "week_group",
	MONTH: "month_group",
	YEAR: "year",
});




export const REPORT_SELECTION_TYPES = Object.freeze({
	ALL: "all",
	YEAR: "year",
	MONTH: "month",
	WEEK: "week",
	DATE: "date",
});

export const REPORT_COLUMN_PRESETS = Object.freeze({
	GOLDEN_5: "golden_5",
	GOLDEN_10: "golden_10",
	USER_PREINVOICE: "user_preinvoice",
	SP_PREINVOICE: "sp_preinvoice",
	DEPO_PREINVOICE: "depo_preinvoice",
	SCORES: "scores",
	POINTS: "points",
	FEEDBACK: "feedback",
	CUSTOM: "custom",
	SP_PANEL: "sp_panel",
	SALARY: "salary",
	STATISTICS: "statistics",
	PAYMENT: "payment",
});
