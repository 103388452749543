import { createContext, useCallback, useContext, useReducer, useState } from "react";
import { reportsReducer, initialReportsState } from "../reducers";

const ReportsContext = createContext();

const ReportsProvider = ({ children, initialState }) => {
	const [state, dispatch] = useReducer(reportsReducer, initialState ?? initialReportsState);

	const [reportsModal, setReportsModal] = useState({
		state: false,
		payload: null,
	});

	const closeReportsModal = useCallback(() => {
		setReportsModal({ state: false, payload: null });
	}, []);
	// const [fileProvider, setFileProvider] = useState(null);

	return (
		<ReportsContext.Provider
			value={{
				state,
				dispatch,
				reportsModal,
				setReportsModal,
				closeReportsModal,
			}}
		>
			{children}
		</ReportsContext.Provider>
	);
};

export const useReportsState = () => {
	const context = useContext(ReportsContext);

	if (context === undefined) {
		throw new Error("useReportsState must be used within a ReportsProvider");
	}

	return context;
};

export default ReportsProvider;
