import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { FormDropzone } from "../../components/forms";
import { validateFile } from "../../utils";
import { Button } from "../../components";

const MAX_IMG_SIZE = process.env.REACT_APP_MAX_IMAGE_UPLOAD_SIZE_MB;

const InvoiceManagerForm = ({ onSubmit, onCancel }) => {
	const { t } = useTranslation();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
				<Controller
					render={({ field: { onChange, ...rest } }) => (
						<FormDropzone
							groupClassName={cn("v__grid__item v__grid__item--col-12", {
								"v__form__group--incorrect": errors?.image_path,
							})}
							label={t("image")}
							accept='application/pdf, application/vnd.ms-excel, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
							error={errors?.file && t(`${errors?.file?.type}`)}
							onChange={(e) => onChange(e.target.files[0])}
							icon='description'
							{...rest}
						/>
					)}
					control={control}
					name='image_path'
					defaultValue=''
					rules={{ validate: (v) => validateFile(v) || t("maxFileSize", { sizeInMb: MAX_IMG_SIZE }) }}
				/>
				<div className='v__form__footer v__grid__item v__grid__item--col-12'>
					<Button bg status='darker' onClick={onCancel}>
						{t("cancel")}
					</Button>
					<Button bg status='accent' type='submit'>
						{t("upload")}
					</Button>
				</div>
			</form>
		</>
	);
};

export default InvoiceManagerForm;
