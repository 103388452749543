import { useTranslation } from "react-i18next";
import ComingSoonSvg from "./ComingSoonSVG";

const ComingSoon = () => {
	const { t } = useTranslation();

	return (
		<div className='v__coming-soon__container'>
			<span>{t("comingSoon")}</span>
			<ComingSoonSvg withAnimation={true} />
		</div>
	);
};

export default ComingSoon;
