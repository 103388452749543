import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { useCreateTourCategory, useTourCategory, useUpdateTourCategory } from "hooks/query/toursCategories";
import { TourCategoryForm } from ".";

const TourCategoryModal = ({ payload, onClose }) => {
    const { t } = useTranslation();

    const { data: tour } = useTourCategory(payload?.id, {
        enabled: Boolean(payload?.id),
    });

    const { mutate: update, isLoading: isLoadingUpdate } = useUpdateTourCategory({
        onSuccess: (res) => {
            onClose();
        },
    });

    const { mutate: create, isLoading: isLoadingCreate } = useCreateTourCategory({
        onSuccess: (res) => {
            onClose();
        },
    });



    const tourData = tour?.data;

    const defaultValues = {
        title: tourData?.title,
        from_date: tourData?.from_date,
        to_date: tourData?.to_date,
    };

    const handleOnSubmit = (data) => {
        if (payload) {
            update({ id: payload.id, data });
        }
        else {
            create(data);
        }

    };


    const shouldRender = (payload && tourData) || !payload;
    const isLoading = isLoadingUpdate;

    return (
        <Modal onClose={onClose}>
            <Card
                className='v__modal v__card--overflow-able'
                header={t("Tour Category Management")}
                relativeLoading={isLoading}
                dismissible={onClose}
            >
                {shouldRender && (
                    <TourCategoryForm
                        onSubmit={handleOnSubmit}
                        defaultValues={defaultValues}
                        onCancel={onClose}
                    />
                )}
            </Card>
        </Modal>
    );
};

export default TourCategoryModal;
