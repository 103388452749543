import { useParams } from "react-router-dom";
import { REPORT_COLUMN_PRESETS } from "reducers/const";
import Reports from "./Reports";

const Fairplay = () => {
	const { fairplayId } = useParams();
	return <Reports primaryDataType='fbr' initialConfig={{ fairplay_id: fairplayId }} initialPreset={REPORT_COLUMN_PRESETS.GOLDEN_10} />;
};

export default Fairplay;
