import { useAuth } from "context";
import { useAppRoutes } from "hooks";

import SidebarNavItem from "./SidebarNavItem";

const SidebarNav = () => {
	const { userCan } = useAuth();

	const routes = useAppRoutes();
	const sidebarRoutes = routes.find((v) => v.path === "app");

	return (
		<nav className='v__sidebar__scrollable__nav' role='navigation'>
			{sidebarRoutes.children
				.filter((v) => v?.path && v?.path !== "*" && !Boolean(v?.plainRoute))
				.map((route) => {
					if (!route?.permissions?.length) {
						return <SidebarNavItem key={route.path} route={route} />;
					}
					if (userCan(...route?.permissions)) {
						return <SidebarNavItem key={route.path} route={route} />;
					}
					return null;
				})}
		</nav>
	);
};

export default SidebarNav;
