import { useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import cn from "classnames";

import { Card, Icon } from "..";
import NoDataRow from "./NoDataRow";
import TablePagination from "./TablePagination";
import { getCss } from "utils";

const renderHeader = (headerGroup, filteredColumns) => {
    return (
        <tr {...headerGroup.getHeaderGroupProps()} className='v__report__table__header__row'>
            {headerGroup.headers.map((column) => (
                <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={cn("v__report__table__header__row__cell", {
                        "v__report__table__header__row__cell--desc": column.isSorted && column.isSortedDesc,
                        "v__report__table__header__row__cell--asc": column.isSorted && !column.isSortedDesc,
                        "v__report__table__header__row__cell--sticky": column.sticky,
                        "v__report__table__header__row__cell--filtered": filteredColumns
                            ? Object.keys(filteredColumns).includes(column.id)
                            : false,
                    })}
                    title={column.description}
                >
                    <span>
                        {column.icon === "golden_five" ? (
                            <Icon>military_tech</Icon>
                        ) : column.icon === "scores" ? (
                            <Icon>grade</Icon>
                        ) : column.icon === "rating" ? (
                            <Icon className='text-gold'>emoji_events</Icon>
                        ) : null}{" "}
                        {column.render("Header")}
                    </span>
                </th>
            ))}
        </tr>
    );
};

const renderRow = (row) => {
    return (
        <tr {...row.getRowProps()} className={cn("v__report__table__body__row")}>
            {row.cells.map((cell) => {
                return (
                    <td
                        {...cell.getCellProps()}
                        className={cn("v__report__table__body__row__cell", {
                            "v__report__table__body__row__cell--limited":
                                cell.column.id === "full_comment" ||
                                cell.column.id === "address" ||
                                cell.column.id === "miv" ||
                                cell.column.id === "full_name" ||
                                cell.column.id === "short_notes",
                            "v__report__table__body__row__cell--clickable": cell.column.onClick || cell.column.id === "full_comment",
                            "v__report__table__body__row__cell--sticky": cell.column.sticky,
                            "v__report__table__body__row__cell--division": cell.column.id === "division_icon",
                            "v__report__table__body__row__cell--colorful": setCorrectCellStyles(cell),
                        })}
                        title={cell.column.description}
                    >
                        {setCorrectCellStyles(cell) ? (
                            <span>
                                {cell.render("Cell")}
                                <div className='cell__pigment' style={setCorrectCellStyles(cell)} />
                            </span>
                        ) : (
                            cell.render("Cell")
                        )}
                    </td>
                );
            })}
        </tr>
    );
};

const parseCellValue = (val) => {
    if (typeof val === "string" && val?.includes("%")) {
        return Number(val.split("%")[0]);
    }
    return val;
};

const setCorrectCellStyles = (cell) => {
    if ((cell.column.id === "negative_feedback_shorten" && cell.value) || (cell.column.id === "overall_points" && cell.value <= 0)) {
        return {
            background: getCss("--danger"),
        };
    }
    if ((cell.column.id === "positive_feedback_shorten" && cell.value) || (cell.column.id === "overall_points" && cell.value > 0)) {
        return {
            background: getCss("--success"),
        };
    }

    if (Boolean(cell.column.pigments?.length) && cell.value) {
        const pigment = cell.column.pigments?.filter(
            (item) => parseCellValue(cell.value) >= Number(item.min) && parseCellValue(cell.value) <= Number(item.max)
        )[0];
        if (pigment) {
            return {
                background: pigment.color,
            };
        } else {
            return null;
        }
    }
};

const StatisticsTable = ({
    columns,
    data,
    fetchData,
    loading,
    paginationComponent,
    serverPageCount,
    serverTotalResults,
    initialPageSize = 10,
    cardClassName,
    scrollToTopOnChange,
    hasOverallDataRows,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: initialPageSize, sortBy: [] },
            manualPagination: true,
            manualSortBy: true,
            pageCount: serverPageCount,
            autoResetPage: false,
            autoResetSortBy: false,
        },
        useSortBy,
        usePagination
    );


    useEffect(() => {
        fetchData?.({ pageIndex, pageSize, sortBy });
    }, [fetchData, pageIndex, pageSize, sortBy]);

    const paginationProps = {
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        pageIndex,
        pageOptions,
        pageSize,
        setPageSize,
        serverTotalResults,
        scrollToTopOnChange,
    };

    return (
        <>
            <Card loading={loading} className={cn("v__table__container body-no-pad print:!overflow-hidden relative", cardClassName)}>
                <table className='v__report__table' {...getTableProps()}>
                    <thead className='v__report__table__header'>{headerGroups.map((headerGroup) => renderHeader(headerGroup))}</thead>
                    <tbody
                        className={cn("v__report__table__body", {
                            "v__report__table__body--with-avg": hasOverallDataRows,
                        })}
                        {...getTableBodyProps()}
                    >
                        {data.length === 0 && <NoDataRow />}
                        {page.map((row) => {
                            prepareRow(row);
                            return renderRow(row);
                        })}
                    </tbody>
                </table>
            </Card>

            {paginationComponent?.(paginationProps) ?? <TablePagination {...paginationProps} />}
        </>
    );
};

export default StatisticsTable;
