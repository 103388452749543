import { useCallback, useEffect, useMemo } from "react";

const useEventListener = (e, handler, config) => {
	const el = useMemo(() => config?.el ?? window, [config?.el]);
	const eventOptions = useMemo(() => config?.eventOptions ?? {}, [config?.eventOptions]);

	const callback = useCallback((e) => handler?.(e), [handler]);

	useEffect(() => {
		if (el) {
			el.addEventListener(e, callback, eventOptions);
			return () => el.removeEventListener(e, callback, eventOptions);
		}
	}, [e, config, callback, eventOptions, el]);
};

export default useEventListener;
