import { useEffect, useRef } from "react";

const useKeyPress = (key, onPress, combined = false) => {
	const onPressRef = useRef(onPress);

	useEffect(() => {
		onPressRef.current = onPress;
	});

	useEffect(() => {
		const handlePress = (e) => {
			if (combined && e.ctrlKey && e.altKey && e.keyCode === key) {
				return onPressRef.current(e);
			}
			if (!combined && e.keyCode === key) {
				return onPressRef.current(e);
			}
		};
		window.addEventListener("keydown", handlePress);
		return () => {
			window.removeEventListener("keydown", handlePress);
		};
	}, [key, combined]);
};

export default useKeyPress;
