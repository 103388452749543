import axios from "./axios";

const api = {
	getStatisticsById(params) {
		const { id, type, ...rest } = params;
		return axios
			.get(`/overall/${encodeURI(type)}/${encodeURI(id)}`, {
				params: rest,
			})
			.then(({ data }) => data);
	},
};

export default api;
