import { Navigate, Outlet } from "react-router-dom";

import { Footer } from "./components";
import { AuthProviding, VeniwayFullLogo, VeniwayIconLogo } from "../components/ui";
import { useAuth } from "../context";
import { Icon } from "../components";

const GuestLayout = () => {
	const { token } = useAuth();

	if (token) {
		return <Navigate replace to='/app' />;
	}

	return (
		<div className='v__wrapper'>
			<VeniwayIconLogo className='v__auth__logo__watermark' />
			<div className='v__auth__content__wrapper'>
				<div className='v__auth__brand'>
					<VeniwayFullLogo />
				</div>
				<div className='v__auth__form__container'>
					<Outlet />
				</div>
				<div className='v__auth__footer'>
					<Footer />
				</div>
			</div>
			<div className='v__auth__about__wrapper'>
				<div className='v__auth__about'>
					<AuthProviding />
					<ul className='v__auth__about__services'>
						<li>
							<Icon>analytics</Icon>
							Extensive Statistics
						</li>
						<li>
							<Icon>sync</Icon>
							Automated Workflow
						</li>
						<li>
							<Icon>touch_app</Icon>
							Intuitive UI
						</li>
						<li>Plus much more...</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default GuestLayout;
