import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useCompanies, useReportDates, useUploadReport } from "hooks";
import { Button, Card, FormDatePicker, Icon } from "components";
import { FormDropzone, FormSelect } from "components/forms";
import { Spinner, Tab, Tabs } from "components/ui";
import { useReportsState } from "context";
import { validateFile } from "utils";
import { format } from "date-fns";

const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE_MB;

const ReportAdd = () => {
	const { t } = useTranslation();

	const {
		handleSubmit,
		control,
		reset,
		watch,
		// setValue,
		formState: { errors },
	} = useForm();

	const { closeReportsModal } = useReportsState();

	const [activeReportType, setActiveReportType] = useState("delivery_daily");

	useEffect(() => {
		reset();
	}, [activeReportType, reset]);

	const watchCompany = watch("depo_id");
	const watchDate = watch("date");

	const reportOptions = useMemo(
		() => [
			{
				icon: <Icon>local_shipping</Icon>,
				label: t("dailyReport"),
				value: "delivery_daily",
			},
			// {
			//     icon: <Icon/>,
			//     label: lang?.invoices,
			//     value: 'delivery_invoice',
			// },
			// {
			//     icon: <Icon/>,
			//     label: lang?.fuel,
			//     value: 'fuel'
			// },
			{
				icon: <Icon>feedback</Icon>,
				label: t("feedback"),
				value: "feedback",
			},
			{
				icon: <Icon>fact_check</Icon>,
				label: t("snumber"),
				value: "snumber",
			},
		],
		[t]
	);
	const {
		data: files,
		// isFetching,
		// isFetched,
	} = useReportDates({
		custom: {
			type: "delivery_daily",
			depo_id: watchCompany?.value,
		},
		enabled: Boolean(watchCompany?.value),
	});

	const calendarDepoFiles = useMemo(() => {
		if (!files) return [];
		return files.data.filter((a) => a.for_all).map((b) => new Date(b.date));
	}, [files]);

	const { data: companies, isFetching } = useCompanies({
		custom: {
			type: activeReportType,
			includes: activeReportType !== "feedback" ? ["depos"] : undefined,
		},
	});

	const selectOptions = useMemo(() => {
        console.log(companies);
		if (!companies) return [];
		if (companies.data[1]?.depos?.length) {
			return companies.data.map((depo) => ({
				label: (
					<div className='v__select__group__separator'>
						<span className='v__select__group__separator__label'>{depo?.title}</span>
						<div className='v__select__group__separator__results'>{depo.depos.length}</div>
					</div>
				),
				options: depo.depos.map((v) => ({
					value: v?.id,
					label: v?.title,
					...v,
				})),
			}));
		}
		return companies.data.map((v) => ({ value: v?.id, label: v?.title, ...v }));
	}, [companies]);

	//  useEffect(() => {
	// 	const name = activeReportType !== "feedback" ? "depo_id" : "company_id"

	// 	const isGroupedSelectOptions = Boolean(selectOptions?.[0]?.options);

	// 	if(isGroupedSelectOptions){
	// 		setValue(name, selectOptions[0].options[0])
	// 	}else{
	// 		setValue(name, selectOptions[0])
	// 	}

	// }, [activeReportType, selectOptions, setValue])

	const { mutate: upload, isLoading } = useUploadReport({
		onSuccess: () => {
			// reset();
		},
	});

	const onSubmit = (data) => {
        console.log(data);
		const dataForSend = { ...data, type: activeReportType };

		upload(dataForSend);
	};

	const fileForDownload = files?.data?.find((x) => x.date === format(watchDate ?? new Date(), "yyyy-MM-dd"));

	return (
		<Card className='v__modal v__card--overflow-able' header={t("addReport")} dismissible={closeReportsModal}>
			<Tabs
				tabsContainerClassName='v__grid__item v__grid__item--col-12 v__report__add__tabs'
				onTabSelect={(tab) => setActiveReportType(tab.tabValue)}
			>
				{reportOptions.map((item, index) => (
					<Tab key={index} tabComponent={ReportTabItem(item)} tabValue={item.value} />
				))}
			</Tabs>

			<form className='v__grid' id='uploadForm' onSubmit={handleSubmit(onSubmit)}>
				<Controller
					render={({ field }) => (
						<FormSelect
							isLoading={isFetching}
							options={selectOptions}
							preSelect
							//TODO: set different translation depending on selected report type?
							label={t("company")}
							groupClassName={cn("v__grid__item v__grid__item--col-12", errors?.company_id && "v__form__group--incorrect")}
							error={errors?.company_id && t(`${errors?.company_id?.type}`)}
							{...field}
						/>
					)}
					control={control}
					name={activeReportType !== "feedback" ? "depo_id" : "company_id"}
					defaultValue={null}
					rules={{ required: true }}
				/>

				{activeReportType !== "feedback" && (
					<Controller
						render={({ field: { value, ...restField } }) => (
							<FormDatePicker
								groupClassName={cn("v__grid__item v__grid__item--col-12", errors?.date && "v__form__group--incorrect")}
								label={t("date")}
								error={errors?.date && t(`${errors?.date?.type}`)}
								highlightDates={calendarDepoFiles}
								selected={value}
								{...restField}
							/>
						)}
						control={control}
						name='date'
						defaultValue={new Date()}
						rules={{ required: true }}
					/>
				)}

				<Controller
					render={({ field: { onChange, ...rest } }) => (
						<FormDropzone
							groupClassName={cn("v__grid__item v__grid__item--col-12", errors?.files && "v__form__group--incorrect")}
							label={t("file")}
							// renderFileThumbnail={(files) => {
							// 	const fileType = "whatever";
							// 	// You can use switch
							// 	if ("type") {
							// 		return <SVG />;
							// 	}
							// 	// fallback if nothing matches
							// 	return <KUR />;
							// }}
							// prevFile={user}
							accept='application/pdf, application/vnd.ms-excel, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            multiple={true}
							error={errors?.files && t(`${errors?.files?.type}`)}
							onChange={(e) => onChange(e.target.files)}
							icon='description'
							fileForDownload={Boolean(fileForDownload) ? fileForDownload.file : undefined}
							{...rest}
						/>
					)}
					control={control}
					name='files'
					defaultValue=''
					rules={{ validate: (v) => validateFile(v) || t("maxFileSize", { sizeInMb: MAX_FILE_SIZE }) }}
				/>
			</form>
			<div className='v__form__footer v__grid__item v__grid__item--col-12'>
				<Button bg status='darker' onClick={closeReportsModal}>
					{t("cancel")}
				</Button>
				<Button
					bg
					status='accent'
					type='submit'
					form='uploadForm'
					// disabled={!otherState.allowedEdit}
					iconRight={isLoading ? <Spinner size={16} singleColor /> : null}
				>
					{t("upload")}
				</Button>
			</div>
		</Card>
	);
};

const ReportTabItem = ({ label, icon }) => (
	<div className='v__tabs__list__item__label'>
		{icon}
		{label}
	</div>
);

export default ReportAdd;
