import { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import cn from "classnames";
import { useWindowSize } from "../../hooks";
import { Fade } from "..";

const Tabs = ({
	additionalActions,
	initialActiveTab = 0,
	onTabSelect = null,
	tabsContainerClassName,
	tabClassName,
	animated = true,
	children,
}) => {
	const tabSelector = useRef();
	const selectedTab = useRef();
	const { width } = useWindowSize();
	const [activeTab, setActiveTab] = useState(initialActiveTab);

	const tabTitles = children.map((item, index) => {
		if (activeTab === index) {
			return (
				<div
					key={index}
					ref={selectedTab}
					onClick={() => {
						setActiveTab(() => index);
						if (onTabSelect) onTabSelect(children[index].props);
					}}
					className={`v__tabs__list__item v__tabs__list__item--active`}>
					{item.props.tabComponent ? item.props.tabComponent : item.props.tabTitle ? item.props.tabTitle : index}
				</div>
			);
		} else {
			return (
				<div
					key={index}
					tabIndex={0}
					onFocus={() => {
						setActiveTab(() => index);
						if (onTabSelect) onTabSelect(children[index].props);
					}}
					onClick={() => {
						setActiveTab(() => index);
						if (onTabSelect) onTabSelect(children[index].props);
					}}
					className={`v__tabs__list__item`}>
					{item.props.tabComponent ? item.props.tabComponent : item.props.tabTitle ? item.props.tabTitle : index}
				</div>
			);
		}
	});

	const tabContent = children.map((item, index) => (
		<Fade key={index} in={activeTab === index}>
			<div className={`v__tabs__content ${tabClassName ?? ""}`}>{item.props.children}</div>
		</Fade>
	));

	useEffect(() => {
		const tabList = tabSelector.current.parentNode.children;
		if (tabList) {
			for (const item of tabList) {
				if (item.classList.contains("v__tabs__list__item--active")) {
					tabSelector.current.style.left = `${item.offsetLeft}px`;
					tabSelector.current.style.width = `${item.offsetWidth}px`;
				}
			}
		}
		if (selectedTab.current) {
			selectedTab.current?.scrollIntoView();
		}
	}, [activeTab, width]);

	return (
		<div className={cn("v__tabs__container", !animated && "v__tabs__container--no-animations", tabsContainerClassName)}>
			<div className='v__tabs__headers'>
				<ScrollContainer className='v__tabs__list'>
					<div className='v__tab__selector' ref={tabSelector} />
					{tabTitles}
				</ScrollContainer>
				<div className='v__tabs__list__extra-action'>{additionalActions}</div>
			</div>
			{tabContent}
		</div>
	);
};

export default Tabs;
