import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context";

import { useDataTableGenerate, useGroupDelete, useGroups, useMedia, useMediaQueries } from "hooks";

import { Button, Icon, ActionsCell, AvatarCell, DivisionCell, RateCell, Table } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { GroupModal } from "modules";

const Groups = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { userCan } = useAuth();

	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const [groupModal, setGroupModal] = useState({
		state: false,
		payload: null,
	});

	const closeModal = () => setGroupModal({ state: false, payload: null });

	const { mutate, isLoading } = useGroupDelete();

	const columns = useCallback(
		(columns) => {
			return columns.map((col) => {
				if (col.accessor === "title") {
					return {
						...col,
						sticky: true,
						secondaryData: "owner.full_name",
						Cell: ({ cell }) => <AvatarCell cell={cell} />,
					};
				}
				if (col.accessor === "rateChart") {
					return {
						...col,
						Cell: ({ cell }) => <RateCell cell={cell} />,
					};
				}
				if (col.accessor === "current_division") {
					return {
						...col,
						Cell: ({ cell }) => <DivisionCell cell={cell} />,
					};
				}
				if (col.accessor === "actions") {
					return {
						...col,
						Cell: ({ cell }) => (
							<ActionsCell>
								<Button small bg status='accent' onClick={() => navigate(`${cell.row.original?.id}`)}>
									<Icon>visibility</Icon>
								</Button>
								{userCan("add_groups") && (
									<Button
										small
										bg
										status='warning'
										onClick={() => setGroupModal({ payload: cell.row.original, state: true })}
									>
										<Icon>edit</Icon>
									</Button>
								)}

								{userCan("add_groups") && (
									<Button small bg status='danger' onClick={() => mutate(cell.row.original?.id)}>
										<Icon>delete</Icon>
									</Button>
								)}
							</ActionsCell>
						),
					};
				}
				return null;
			});
		},
		[userCan, mutate, navigate]
	);

	const {
		tableProps: { loading, ...otherProps },
	} = useDataTableGenerate(useGroups, columns, {
		includes: ["owner"],
		lastMonthChart: ["overall_rating_daily_avg"],
	});

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Groups</title>
			</Helmet>
			<PageHeader title={t("groups")}>
				<div className='v__table__extras__actions'>
					{/* {userCan("add_groups") && ( */}

					{userCan("add_groups") && (
						<Button small bg status='danger' round={!isMedium} onClick={() => setGroupModal({ state: true, payload: null })}>
							{isMedium ? t("addNew") : <Icon>add</Icon>}
						</Button>
					)}
				</div>
			</PageHeader>
			<PageContent>
				<Table {...otherProps} loading={loading || isLoading} scrollToTopOnChange />
			</PageContent>
			{groupModal.state && <GroupModal payload={groupModal.payload} onClose={closeModal} />}
		</PageWrapper>
	);
};

export default Groups;
