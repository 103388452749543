import axios from "./axios";

const api = {
	getDivisions(params) {
		return axios
			.get(`/divisions`, {
				params,
			})
			.then(({ data }) => data);
	},
};

export default api;
