import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { useCompanies, useDataTableGenerate, useDebounce, useMedia, useMediaQueries, useServicePartners, useUsers } from "hooks";
import { useAuth } from "context";

import { Button, Collapsible, Icon, ActionsCell, AvatarCell, ConnectionsCell, DivisionCell, RateCell, Table } from "components";
import PageContent from "../../components/util/PageContent";
import PageHeader from "../../components/util/PageHeader";
import PageWrapper from "../../components/util/PageWrapper";
import { FormInput, FormSelect } from "components/forms";
import { UsersModal } from "modules/users";

const MIN_SEARCH_LENGTH = 2;

const INITIAL_PARAMS_VALUES = {
	includes: ["servicePartners", "joinedDepos", "media"],
	lastMonthChart: ["overall_rating_daily_avg"],
	search: "",
	depo_id: null,
	service_partner_id: null,
};

const UserFilters = ({ onFilter, filters, filtersClosed }) => {
	const { t } = useTranslation();

	const [searchTerm, setSearchTerm] = useState(() => filters?.search ?? "");
	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	const [searchTermHolder] = useState(() => filters?.searchHolder ?? "");
	const debouncedSearchTermHolder = useDebounce(searchTermHolder, 500);

	useEffect(() => {
		onFilter?.({ search: debouncedSearchTerm });
	}, [onFilter, debouncedSearchTerm]);

	useEffect(() => {
		onFilter?.({ searchHolder: debouncedSearchTermHolder });
	}, [onFilter, debouncedSearchTermHolder]);

	const disable = searchTerm.length !== 0 && searchTerm.length <= MIN_SEARCH_LENGTH;
	const disableHolder = searchTermHolder.length !== 0 && searchTermHolder.length <= MIN_SEARCH_LENGTH;

	if (!filters) {
		return null;
	}

	return (
		<div className='v__table__extras__filters'>
			<Collapsible closed={filtersClosed} allowOverflow={true} className='body-no-pad no-mar'>
				<div className='v__grid'>
					<FormInput
						groupClassName={cn(
							"v__grid__item v__grid__item--col-4 v__grid__item--col-md-6 v__grid__item--col-sm-12",
							disable && "v__form__group--incorrect"
						)}
						label={t("name")}
						name='search'
						error={disable && t("minLength", { value: MIN_SEARCH_LENGTH })}
						value={searchTerm}
						onChange={({ target }) => setSearchTerm(target.value)}
					/>

					<FormInput
						groupClassName={cn(
							"v__grid__item v__grid__item--col-4 v__grid__item--col-md-6 v__grid__item--col-sm-12",
							disableHolder && "v__form__group--incorrect"
						)}
						label={t("name")}
						name='searchHolder'
						error={disableHolder && t("minLength", { value: MIN_SEARCH_LENGTH })}
						value={searchTermHolder}
						onChange={({ target }) => setSearchTerm(target.value)}
					/>

					<FormSelect
						groupClassName={cn(
							"v__grid__item v__grid__item--col-4 v__grid__item--col-md-6 v__grid__item--col-sm-12",
							disable && "disabled"
						)}
						label={t("depo")}
					    isAsync	
						useFetch={useCompanies}
						isGrouped
						isClearable
						queryConfig={{ custom: { type: "delivery_daily", includes: ["depos"] } }}
						groupParse={{ label: "title", options: "depos" }}
						value={filters.depo_id}
						onChange={(option) =>
							onFilter?.({
								depo_id: option,
								service_partner_id: null,
							})
						}
					/>

					<FormSelect
						groupClassName={cn(
							"v__grid__item v__grid__item--col-4 v__grid__item--col-md-6 v__grid__item--col-sm-12",
							disable && "disabled"
						)}
						label={t("servicePartner")}
					    isAsync	
						asyncSearch
						isClearable
						useFetch={useServicePartners}
						queryConfig={{ custom: { depo_id: filters.depo_id?.value, page: 0, per_page: 25 } }}
						value={filters.service_partner_id}
						onChange={(option) =>
							onFilter?.({
								service_partner_id: option,
							})
						}
					/>
				</div>
			</Collapsible>
		</div>
	);
};

const Users = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { userCan } = useAuth();

	const { MEDIUM } = useMediaQueries();
	const isMedium = useMedia(MEDIUM);

	const [modal, setModal] = useState({
		state: false,
		payload: null,
	});

	const closeModal = () => setModal({ state: false, payload: null });

	const [filtersClosed, setFiltersClosed] = useState(true);
	const [filters, setFilters] = useState();

	const onFiltersChange = useCallback((newFilters) => setFilters((prev) => ({ ...prev, ...newFilters })), []);

	const columns = useCallback(
		(columns) => {
			return columns.map((col) => {
				if (col.accessor === "full_name") {
					return {
						...col,
						sticky: true,
						secondaryData: "online_status",
						Cell: ({ cell }) => <AvatarCell cell={cell} />,
					};
				}
				if (col.accessor === "connections") {
					return {
						...col,
						Cell: ({ cell }) => <ConnectionsCell cell={cell} />,
					};
				}
				if (col.accessor === "rateChart") {
					return {
						...col,
						Cell: ({ cell }) => <RateCell cell={cell} />,
					};
				}
				if (col.accessor === "current_division") {
					return {
						...col,
						Cell: ({ cell }) => <DivisionCell cell={cell} />,
					};
				}
				if (col.accessor === "actions") {
					return {
						...col,
						Cell: ({ cell }) => (
							<ActionsCell>
								{userCan("view_user") && (
									<Button small bg status='accent' onClick={() => navigate(`${cell.row.original?.id}`)}>
										<Icon>visibility</Icon>
									</Button>
								)}
								{userCan("add_user") && cell.row.values.ref_number !== "No Permission" && (
									<Button small bg status='warning' onClick={() => setModal({ payload: cell.row.original, state: true })}>
										<Icon>edit</Icon>
									</Button>
								)}
							</ActionsCell>
						),
					};
				}
				return null;
			});
		},
		[userCan, navigate]
	);

	const {
		tableProps,
		state: { setQueryParams },
	} = useDataTableGenerate(useUsers, columns, filters);

	useEffect(() => {
		const canSearchByName = filters?.search?.length > MIN_SEARCH_LENGTH;
		const canSearchByHolder = filters?.searchHolder?.length > MIN_SEARCH_LENGTH;

		setQueryParams((prev) => ({
			...prev,
			...INITIAL_PARAMS_VALUES,
			page: 1,
			search: canSearchByName ? filters.search : "",
			searchHolder: canSearchByHolder ? filters.searchHolder : "",
			depo_id: filters?.depo_id?.value,
			service_partner_id: filters?.service_partner_id?.value,
		}));
	}, [filters, setQueryParams]);

	return (
		<PageWrapper>
			<Helmet>
				<title>Veniway | Users</title>
			</Helmet>
			<PageHeader title={t("users")}>
				<div className='v__table__extras__actions'>
					{userCan("add_user") && (
						<Button small bg status='danger' round={!isMedium} onClick={() => setModal({ state: true, payload: null })}>
							{isMedium ? t("addNew") : <Icon>add</Icon>}
						</Button>
					)}
					<Button small bg round={!isMedium} status='accent' onClick={() => setFiltersClosed((prev) => !prev)}>
						{isMedium ? t("filters") : <Icon>filter_alt</Icon>}
					</Button>
				</div>
				<UserFilters filters={filters} onFilter={onFiltersChange} filtersClosed={filtersClosed} />
			</PageHeader>
			<PageContent>
				<Table {...tableProps} scrollToTopOnChange />
			</PageContent>

			{modal.state && <UsersModal payload={modal.payload} onClose={closeModal} />}
		</PageWrapper>
	);
};

export default Users;
