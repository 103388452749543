import { CSSTransition } from "react-transition-group";
import cn from "classnames";

const Dropdown = ({ shown = false, hide = null, className, children, ...rest }) => {
	return (
		<>
			{shown && <div className='v__dropdown__overlay' onClick={hide} />}

			<CSSTransition in={shown} timeout={300} unmountOnExit classNames='dropdown__animate'>
				<div className={cn("v__dropdown", className)} {...rest}>
					{children}
				</div>
			</CSSTransition>
		</>
	);
};

export default Dropdown;
