import { useTranslation } from "react-i18next";
import { Icon } from "../..";
import { getCss } from "../../../utils";

const parseCorrectChartColor = (data) => {
	if (data[0] && data[data.length - 1] && data.length > 0) {
		const firstResult = Number(data[0]).toFixed(2);
		const lastResult = Number(data[data.length - 1]).toFixed(2);
		if (firstResult > lastResult) {
			return {
				icon: "south",
				color: getCss("--danger"),
			};
		}
		if (firstResult < lastResult) {
			return {
				icon: "north",
				color: getCss("--success"),
			};
		}
		if (firstResult === lastResult) {
			return {
				icon: "horizontal_rule",
				color: getCss("--accent"),
			};
		}
	}
};

const RateCell = ({ cell }) => {
	const v = cell.row.original?.lastMonthChart?.data;

	const { t } = useTranslation();

	return (
		<div className='v__table__body__row__cell__chart'>
			<div className='v__table__body__row__cell__chart__info'>
				{v?.[0]?.data.length > 0 ? (
					<>
						<Icon
							style={{
								color: parseCorrectChartColor(v?.[0]?.data)?.color,
							}}
						>
							{parseCorrectChartColor(v?.[0]?.data)?.icon}
						</Icon>
						<p>
							<span className='sub-text'>{t("min")}:</span> {v?.[0]?.constraints?.min}
							<br />
							<span className='sub-text'>{t("max")}:</span> {v?.[0]?.constraints?.max}
						</p>
					</>
				) : (
					<>
						<Icon
							style={{
								color: getCss("--warning"),
							}}
						>
							block
						</Icon>
						<p>{t("noData")}</p>
					</>
				)}
			</div>
		</div>
	);
};

export default RateCell;
