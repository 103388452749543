import { forwardRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { IndeterminateCheckbox } from ".";

const FormCheckbox = forwardRef(
	({ groupClassName, withLabel = true, name, id = name, label, onChange, checkboxLabel, children, ...rest }, ref) => {
		return (
			<div className={cn(`v__form__group`, groupClassName)}>
				<label htmlFor={id} className={cn("v__form__group__label", !withLabel && "v__form__group__label--invisible")}>
					<span>{label}</span>
				</label>

				<label className='flex items-center space-x-2 cursor-pointer'>
					<IndeterminateCheckbox name={name} id={id} ref={ref} onChange={(e) => onChange(e.target.checked)} {...rest} />
					<span>{checkboxLabel}</span>
				</label>

				{children}
			</div>
		);
	}
);

FormCheckbox.propTypes = {
	groupClassName: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	withLabel: PropTypes.bool,
	loading: PropTypes.bool,
};

export default FormCheckbox;
