import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { forwardRef } from "react";

const Badge = forwardRef(({ type = "", size = "", className, children, ...rest }, ref) => {
	return (
		<div
			className={classnames(
				{
					v__badge__clickable: !!rest.onClick,
				},
				{
					v__badge: type === "",
					[`v__badge--${type}`]: !!type,
				},
				{
					v__badge__md: size === "md",
					v__badge__lg: size === "lg",
				},
				className
			)}
			{...rest}
			ref={ref}>
			{children}
		</div>
	);
});

Badge.propTypes = {
	type: PropTypes.string,
	size: PropTypes.string,
};

export default Badge;
