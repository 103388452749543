import cn from "classnames";

const LineLoader = ({ loading = true }) => {
	return (
		<div
			className={cn("v__line__loader", {
				"v__line__loader--visible": loading,
			})}>
			<div className='v__line__loader__line' />
		</div>
	);
};

export default LineLoader;
