import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useVirtual } from "react-virtual";

import { useGroup, useWindowSize } from "hooks";

import { Button, Card, Icon } from "components";
import { FormInput } from "components/forms";

import Avatar from "../../ui/Avatar";

const StaffModal = ({ onClose, payload }) => {
	const { t } = useTranslation();

	const [staff, setStaff] = useState([]);
	const [search, setSearch] = useState("");

	const results = staff?.filter((x) => {
		if (payload?.accountType === "depo_id") {
			return x.title?.trim().toLowerCase().includes(search?.trim().toLowerCase());
		}
		return x.full_name?.trim().toLowerCase().includes(search?.trim().toLocaleLowerCase());
	});

	const parentRef = useRef();

	const rowVirtualizer = useVirtual({
		size: results?.length,
		estimateSize: useCallback(() => 75, []),
		parentRef,
	});

	const { data, isLoading } = useGroup(payload?.currentAccount?.id, {
		custom: {
			includes: ["members", "members.media"],
		},
		enabled: Boolean(payload?.accountType === "group_id"),
		onError: (err) => {
			//TODO Toast || redirect
			console.log(err);
		},
	});

	useEffect(() => {
		if (data?.data || payload?.currentAccount?.staff || payload?.currentAccount?.servicePartners) {
			if (payload?.accountType === "depo_id") {
				setStaff(payload?.currentAccount?.servicePartners);
			}
			if (payload?.accountType === "service_partner_id") {
				setStaff(payload?.currentAccount?.staff);
			}
			if (payload?.accountType === "group_id") {
				setStaff(data?.data?.members ?? []);
			}
		}
	}, [payload, data]);

	return (
		<Card
			className='v__modal'
			relativeLoading={isLoading}
			header={payload?.accountType === "depo_id" ? t("servicePatners") : t("drivers")}
			dismissible={onClose}
		>
			<FormInput
				name='staff_search'
				placeholder={t("searchName")}
				autoFocus
				value={search}
				onChange={(e) => setSearch(e.target.value)}
			/>
			<div className='staff__modal__container' ref={parentRef}>
				{Boolean(results.length) ? (
					<div
						className={"staff__member__item__container relative"}
						style={{
							height: `${rowVirtualizer.totalSize}px`,
						}}
					>
						{rowVirtualizer.virtualItems.map((virtualRow) => {
							const child = results[virtualRow.index];
							return (
								<div
									key={virtualRow.index}
									ref={virtualRow.measureRef}
									className='absolute left-0 top-0 w-full pb-2'
									style={{
										height: `${child}px`,
										transform: `translateY(${virtualRow.start}px)`,
									}}
								>
									<Row data={child} accountType={payload?.accountType} />
								</div>
							);
						})}
					</div>
				) : (
					<div className='text-center pad-xy-2'>{t("noResults")}</div>
				)}
			</div>
		</Card>
	);
};

const Row = ({ data, accountType }) => {
	const { width } = useWindowSize();
	const { t } = useTranslation();

	const isDepo = accountType === "depo_id";

	const link = isDepo ? `/app/partners/${data?.id}` : `/app/users/${data?.id}`;

	return (
		<div className='staff__member__item'>
			{!isDepo && <Avatar user={data} />}
			<span>{isDepo ? data?.title : data?.full_name}</span>
			<Link to={link}>
				<Button small bg round={width < 800} status='warning' iconLeft={width < 800 ? null : <Icon>arrow_forward</Icon>}>
					{width < 800 ? <Icon>arrow_forward</Icon> : t("view")}
				</Button>
			</Link>
		</div>
	);
};

export default StaffModal;
